import MuiCheckbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ReactNode } from 'react'

const useStyles = makeStyles({
  root: {
    marginRight: 0
  },
  label: (props: any) => ({
    fontSize: '0.875rem',
    color: props.error ? '#ff5733' : '#fafafa'
  }),
  checkbox: (props: any) => ({
    color: props.error ? '#ff5733' : '#D733FF'
  }),
  colorPrimary: {
    '&.Mui-checked': {
      color: '#D733FF'
    }
  }
})

interface CheckboxProps {
  value: boolean
  onChange: (value: boolean) => void
  label?: string | ReactNode
  className?: string
  error?: string
}

export default function Checkbox({
  value,
  onChange,
  label,
  className,
  error
}: CheckboxProps) {
  const classes = useStyles({ error })
  return (
    <Grid container className={className}>
      <FormControlLabel
        control={
          <MuiCheckbox
            checked={value}
            color="primary"
            onChange={(e) => onChange(e.target.checked)}
            classes={{
              root: classes.checkbox,
              colorPrimary: classes.colorPrimary
            }}
          />
        }
        classes={{
          label: classes.label,
          root: classes.root
        }}
        label={label}
      />
    </Grid>
  )
}
