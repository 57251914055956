import usePoolsTypes from 'hooks/usePoolsTypes'
import { formatPoolTypesToOptions } from 'utils/pools'

import Select, { SelectProps } from './Select'

export default function SelectAssetType(props: Partial<SelectProps>) {
  const poolsTypes = usePoolsTypes()
  const options = formatPoolTypesToOptions(poolsTypes.types)
  return <Select options={options} {...props} />
}
