import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import LineChart from 'components/charts/LineChart'
import Tabs from 'components/tabs/Tabs'
import TooltipDropdown from 'components/TooltipDropdown'
import useRangeChart from 'hooks/useRangeChart'
import { ASSET_CHART_RANGES } from 'pages/premium/performance/data'
import { useMemo } from 'react'
import { parsePercent } from 'utils/api'
import {
  formatChartDate,
  formatTooltipKey,
  getChartId,
  renderTooltipOptions
} from 'utils/charts'
import { getRandomColors } from 'utils/colors'
import { RANGE } from 'utils/filters'
import { device } from 'utils/screen'

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(111.6deg, #303659 -2.51%, #292E4D 104.46%)',
    padding: '1.25rem 2rem 1.25rem 1.5rem',
    borderRadius: 15,
    maxWidth: '100%'
  },
  chart: {
    height: 300,
    maxWidth: '100%',

    '& .recharts-wrapper': {
      maxWidth: '100% !important',
      width: '100% !important'
    }
  },
  titleContainer: {
    padding: '0 0 1.25rem 0',
    gap: '1.25rem'
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,

    [`@media ${device.tablet}`]: {
      fontSize: '0.75rem'
    }
  },
  rangeTab: {
    minWidth: 'auto'
  },
  tooltip: {
    background: 'linear-gradient(107.39deg, #474F7E 3.48%, #343B61 97.52%)',
    padding: '0.5rem 0.75rem',
    borderRadius: 10,

    '& p': {
      fontSize: '0.75rem',
      display: 'flex',
      alignItems: 'center',

      '& span[data-color]': {
        width: 10,
        height: 10,
        borderRadius: 9999,
        marginRight: '0.25rem'
      },

      '& span[data-footer]': {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0.75rem'
      }
    }
  },
  legend: {
    fontSize: '0.875rem',
    fontWeight: 700,
    marginRight: '1rem',
    flexWrap: 'nowrap',

    '& span[data-legend]': {
      width: 40,
      height: 2,
      minWidth: 10,
      margin: '0 0 0 0.75rem'
    }
  }
})

interface AssetLineChartProps {
  data: any[]
  keys: string[]
  tooltipKeys?: string[]
  className?: string
  yTickFormatter: any
  title: string
  colors?: number
  hasRange?: boolean
}

export default function AssetLineChart({
  data,
  keys,
  title,
  className,
  yTickFormatter,
  tooltipKeys,
  colors = 3,
  hasRange = false
}: AssetLineChartProps) {
  const classes = useStyles()
  const COLORS = useMemo(() => getRandomColors(colors), [colors])

  const { range, setRange, rangeParsedData } = useRangeChart({
    data,
    initialRange: hasRange ? RANGE._30d : RANGE._max
  })

  const renderLegends = () => (
    <Box sx={{ display: 'flex' }}>
      {keys.map((key, index) => (
        <Grid
          container
          alignItems="center"
          className={classes.legend}
          key={index}
        >
          <TooltipDropdown content={formatTooltipKey(key)}>
            <p>{formatTooltipKey(key)}</p>
          </TooltipDropdown>
          <span data-legend={1} style={{ backgroundColor: COLORS[index] }} />
        </Grid>
      ))}
    </Box>
  )

  return (
    <Grid
      container
      direction="column"
      className={classNames(classes.root, className)}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.titleContainer}
      >
        <Box
          display="flex"
          alignItems="center"
          flexGrow={1}
          justifyContent="space-between"
        >
          <Typography className={classes.title}>{title}</Typography>
          {renderLegends()}
        </Box>
        {hasRange && (
          <Tabs
            tabClassName={classes.rangeTab}
            width="auto"
            tabs={ASSET_CHART_RANGES}
            value={range}
            onChange={setRange}
          />
        )}
      </Grid>

      <LineChart
        memo={keys.join('-')}
        noData={!rangeParsedData.length}
        data={rangeParsedData}
        id={getChartId(title)}
        dataKey={keys}
        colors={COLORS}
        yTickFormatter={yTickFormatter}
        xTickFormatter={formatChartDate}
        tooltipOptions={renderTooltipOptions(
          tooltipKeys ?? keys,
          false,
          COLORS,
          parsePercent
        )}
      />
    </Grid>
  )
}
