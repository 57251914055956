import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useMemo, useState } from 'react'

import { EditIcon } from '../../assets/icons'
import useNftOverview, {
  UseNftOverviewDataItem
} from '../../hooks/useNftOverview'
import { TableCol } from '../../types/components/table'
import IconButton from '../buttons/IconButton'
import EditNftViewDialog from '../dialogs/EditNftViewDialog'
import Table from '../table/Table'
import TableCell from '../table/TableCell'
import TableRow from '../table/TableRow'

const useStyles = makeStyles({
  action: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between'
  },
  img: {
    maxWidth: 200
  },
  name: {
    display: 'flex',
    alignItems: 'center'
  },
  iconsCell: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: '1rem',
      cursor: 'pointer',

      '&:last-child': {
        marginRight: 0
      }
    }
  },
  iconBtn: {
    marginRight: '0.5rem'
  }
})

export default function NftView() {
  const classes = useStyles()

  const { data, isLoading, onUpdate } = useNftOverview({ inactive: 1 })

  const [editedNFT, setEditedNFT] = useState<UseNftOverviewDataItem | null>(
    null
  )

  const COLS: TableCol[] = useMemo(
    () => [
      {
        title: '',
        render: (row) => (
          <Grid className={classes.iconsCell}>
            <IconButton size="sm" onClick={() => setEditedNFT(row)}>
              <EditIcon />
            </IconButton>
          </Grid>
        )
      },
      { title: 'Assets', render: (row) => row.display_name },
      { title: 'Active', render: (row) => (row.active ? 'Yes' : 'No') },
      { title: 'Image Url', render: (row) => String(row.image_url) },
      {
        title: 'Image',
        render: (row) => (
          <img className={classes.img} src={row.image_url} alt="" />
        )
      }
    ],
    [classes]
  )

  return (
    <>
      <Table cols={COLS} empty={!data.length} loading={isLoading}>
        {data.map((row, index) => (
          <TableRow key={index}>
            {COLS.map((col, index) => (
              <TableCell key={index}>{col.render?.(row)}</TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
      <EditNftViewDialog
        nft={editedNFT}
        onUpdate={onUpdate}
        open={!!editedNFT}
        onClose={() => setEditedNFT(null)}
      />
    </>
  )
}
