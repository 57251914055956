import { OnTableSort } from 'components/table/Table'
import { SortOrder } from 'components/table/TableHeadCell'
import { OnPage } from 'components/table/TablePagination'
import debounce from 'lodash.debounce'
import { useCallback, useEffect } from 'react'
import { getAssets } from 'services/api/assets'
import { Chain } from 'services/types/globals'
import useSWR from 'swr'
import { formatParams } from 'utils/assets'
import { stringifyURL } from 'utils/query'

import useStateUrlSync from './ui/useStateUrlSync'

export interface AssetsFilters {
  pg: number
  tvl_min: number
  sort: string
  sort_order: SortOrder
  blockchain: Chain[]
  farm_ids: any[]
  search: string
  tvl_change_min?: number
  apr_yearly_min?: number
  active?: boolean
  exchanges: any[]
  yield_types?: string[]
  withdrawal_fee_lte?: number
  deposit_fee_lte?: number
  harvest_lockup_bool?: boolean
  transfer_tax_bool?: boolean
  coin_groups?: string[]
  coin_group?: string
  num_per_page?: number
  farms_tvl_staked_gte?: number
}

type OnFilters = (filters: AssetsFilters) => void
type OnFilter = (filter: keyof AssetsFilters, value: any) => void
type OnSearch = (search: string) => void
type OnClearFilter = (key: keyof AssetsFilters) => void

export interface UseAssets {
  isLoading: boolean
  assets: any[]
  maxPages: number
  filters: AssetsFilters
  onPage: OnPage
  onFilter: OnFilter
  onFilters: OnFilters
  onSearch: OnSearch
  onSort: OnTableSort
  onClearFilter: OnClearFilter
  onClearFilters: () => void
}

interface UseAssetsConfig {
  filters?: Partial<AssetsFilters>
  syncFiltersUrl?: boolean
}

function getKey(params) {
  return stringifyURL('/get_assets', formatParams(params))
}

const initialFilters: AssetsFilters = {
  pg: 1,
  tvl_min: 50000,
  sort: 'tvlStaked',
  sort_order: 'desc',
  blockchain: [''],
  farm_ids: [],
  search: '',
  tvl_change_min: 0,
  apr_yearly_min: 0,
  active: undefined,
  exchanges: [],
  yield_types: [],
  withdrawal_fee_lte: 0,
  deposit_fee_lte: 0,
  harvest_lockup_bool: undefined,
  transfer_tax_bool: undefined,
  coin_groups: [],
  farms_tvl_staked_gte: 10000000
}

export default function useAssets(config: UseAssetsConfig = {}): UseAssets {
  const [filters, setFilters] = useStateUrlSync<AssetsFilters>(
    {
      ...initialFilters,
      ...config.filters
    },
    { disabled: !config.syncFiltersUrl }
  )

  const params = {
    ...filters
  }

  const { data, error } = useSWR(() => getKey(params), getAssets)

  const filtersKey = JSON.stringify(config.filters)

  useEffect(() => {
    setFilters((filters) => ({
      ...filters,
      ...config.filters
    }))
  }, [filtersKey])

  const onFilters = (filters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...filters
    }))
  }

  const onFilter: OnFilter = (key, value) => {
    setFilters((filters) => ({
      ...filters,
      pg: 1,
      [key]: value
    }))
  }

  const onSort: OnTableSort = (name, order) => {
    setFilters((filters) => ({
      ...filters,
      sort: name,
      sort_order: order
    }))
  }

  const onClearFilter: OnClearFilter = (key) => {
    setFilters((filters) => ({
      ...filters,
      pg: 1,
      [key]: ['tvl_min', 'farms_tvl_staked_gte'].includes(key)
        ? 0
        : initialFilters[key]
    }))
  }

  const onClearFilters = () => {
    setFilters(initialFilters)
  }

  const onSearch: OnSearch = useCallback(
    debounce((search) => {
      setFilters((filters) => ({
        ...filters,
        pg: 1,
        search
      }))
    }, 400),
    []
  )

  const onPage: OnPage = (page) => {
    setFilters((filters) => ({
      ...filters,
      pg: page
    }))
  }

  const isLoading = !data && !error
  const assets = data?.data || []
  const maxPages = data?.max_pages || 1

  return {
    isLoading,
    assets,
    maxPages,
    filters,
    onPage,
    onSearch,
    onFilter,
    onFilters,
    onSort,
    onClearFilter,
    onClearFilters
  }
}
