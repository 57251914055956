import Grid from '@material-ui/core/Grid'
import usePool from 'hooks/usePool'

import PremiumSectionCard from './PremiumSectionCard'
import { useStrategiesStyles } from './styles'

interface PremiumSectionsProps {
  pool: any
}

export default function PremiumSections({ pool }: PremiumSectionsProps) {
  const classes = useStrategiesStyles()

  const { onUpdate } = usePool({ poolId: pool.poolId })

  const handleEdit = (name, newText, onSuccess) => {
    onUpdate(pool.poolId, { [name]: newText }, pool.weights, onSuccess)
  }

  return (
    <Grid container className={classes.cards}>
      <PremiumSectionCard
        poolId={pool.poolId}
        name="about"
        title="About"
        text={pool.about || 'N/A'}
        onEdit={handleEdit}
      />
      <PremiumSectionCard
        poolId={pool.poolId}
        name="strategies"
        title="Strategies"
        text={pool.strategies || 'N/A'}
        onEdit={handleEdit}
      />
      <PremiumSectionCard
        poolId={pool.poolId}
        name="riskDescription"
        title="Risk description"
        text={pool.riskDescription || 'N/A'}
        onEdit={handleEdit}
      />
      <PremiumSectionCard
        poolId={pool.poolId}
        name="howToUse"
        title="How to use"
        text={pool.howToUse || 'N/A'}
        onEdit={handleEdit}
      />
    </Grid>
  )
}
