import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { DeleteIcon, EditIcon } from 'assets/icons'
import Button from 'components/buttons/Button'
import IconButton from 'components/buttons/IconButton'
import AddPartnerDialog from 'components/dialogs/AddPartnerDialog'
import CellImage from 'components/images/CellImage'
import { PageTitle } from 'components/typography'
import useCmsPartner from 'hooks/useCmsPartner'
import useCmsPartners from 'hooks/useCmsPartners'
import CmsPartnersProvider from 'providers/CmsPartnersProvider'
import { useState } from 'react'

import { EmptyPlaceholder } from '../../../components/placeholders'

const useStyles = makeStyles({
  root: {
    marginBottom: '2.5rem'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1.25rem'
  },
  cards: {
    margin: '-0.75rem'
  },
  card: {
    margin: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    minWidth: 100,
    padding: '0.75rem',
    background: 'linear-gradient(111.6deg, #39406A -2.51%, #343A60 104.46%)',
    borderRadius: 15
  },
  cardTitle: {
    fontSize: '0.875rem',
    marginRight: '1rem'
  },
  removeBtn: {
    '& svg': {
      width: 16
    }
  },
  editBtn: {
    '& svg': {
      width: 16
    }
  }
})

function isActive(row) {
  return row.active
}

export default function Partners() {
  const classes = useStyles()
  const cmsPartners = useCmsPartners()
  const cmsPartner = useCmsPartner({ revalidate: cmsPartners.revalidate })

  const [add, setAdd] = useState(false)
  const [edit, setEdit] = useState('')

  const filtered = cmsPartners.partners.filter(isActive)

  return (
    <CmsPartnersProvider revalidate={cmsPartners.revalidate}>
      <Grid container direction="column" className={classes.root}>
        <PageTitle className={classes.title}>
          <span>Partnership</span>

          <Button variant="contained-2" size="sm" onClick={() => setAdd(true)}>
            Add new Partner
          </Button>
        </PageTitle>

        <Grid container wrap="wrap" className={classes.cards}>
          {!filtered.length ? (
            <EmptyPlaceholder />
          ) : (
            filtered.map((row) => (
              <Grid key={row['item_id']} className={classes.card}>
                {row['image'] && <CellImage src={row['image']} />}

                <Typography className={classes.cardTitle}>
                  {row['partnership'] || 'N/A'}
                </Typography>

                <IconButton
                  className={classes.editBtn}
                  onClick={() => setEdit(row['item_id'])}
                >
                  <EditIcon />
                </IconButton>

                <IconButton
                  className={classes.removeBtn}
                  onClick={() => cmsPartner.onRemove(row['item_id'])}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            ))
          )}
        </Grid>
      </Grid>

      <AddPartnerDialog
        open={add || !!edit}
        editId={edit}
        onClose={() => {
          setAdd(false)
          setEdit('')
        }}
      />
    </CmsPartnersProvider>
  )
}
