import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import CelerBridgeBanner from 'components/banners/CelerBridgeBanner'
import BridgeComboCard from 'components/cards/BridgeComboCard'
import Select from 'components/form/Select'
import { EmptyPlaceholder } from 'components/placeholders'
import Table from 'components/table/Table'
import TableCell from 'components/table/TableCell'
import TableRow from 'components/table/TableRow'
import { Link } from 'components/typography'
import useBridges from 'hooks/useBridges'
import useBridgesCombination from 'hooks/useBridgesCombination'
import useMediaQueries from 'hooks/useMediaQueries'
import { useSpinner } from 'providers/SpinnerProvider'
import { useState } from 'react'
import { Bridge } from 'services/types/bridges'
import { Option } from 'types/components/form'
import { TableCol } from 'types/components/table'

const useStyles = makeStyles((theme) => {
  return {
    control: {
      width: '100%'
    },
    controlsContainer: {
      marginBottom: '2.5rem',
      display: 'grid',
      gridTemplateColumns: '200px 40px 200px',
      gap: '1.25rem',

      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr'
      }
    },
    title: {
      fontSize: '1rem',
      fontWeight: 700,
      color: '#FAFAFA',
      marginBottom: '1.25rem',

      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        fontSize: '1.5rem'
      }
    },
    tableContainer: {},
    btn: {
      background: '#474F7E',
      borderRadius: 15,
      color: '#fff',
      height: 40,

      '&:disabled': {
        opacity: 0.5,
        cursor: 'default'
      }
    }
  }
})

const COLS: TableCol[] = [
  { title: 'Name', minWidth: 200 },
  { title: 'Comment' },
  { title: 'Rank' },
  { title: 'Fee', align: 'right' }
]

export default function Bridges() {
  const classes = useStyles()
  const { bridges } = useBridges()
  const { isTablet } = useMediaQueries()

  const [values, setValues] = useState<Record<string, any>>({})

  const bridgeValues = Object.values(values)

  const { combinations, isLoading } = useBridgesCombination({
    bridge: bridgeValues
  })

  useSpinner(isLoading)

  const handleChange = (name: string, option: Option) => {
    setValues((values) => {
      if (Object.values(values).includes(option.value)) {
        const copy = { ...values }
        delete copy[name]
        return copy
      }
      return {
        ...values,
        [name]: option.value
      }
    })
  }

  const handleSwap = () => {
    setValues((values) => {
      const objValues = Object.values(values)
      const objKeys = Object.keys(values)
      return {
        [objKeys[0]]: objValues[1],
        [objKeys[1]]: objValues[0]
      }
    })
  }

  const bridge1 = bridges[0]
  const bridge2 = bridges[1]

  const getProps = (bridge: Bridge) => {
    return {
      className: classes.control,
      options: bridge.blockchains
        .filter(
          (b) =>
            !Object.keys(values)
              .filter((key) => key !== bridge.bridgeName)
              .map((key) => values[key])
              .includes(b)
        )
        .map((b) => ({ value: b, label: b })),
      placeholder: bridge.bridgeName,
      value: values[bridge.bridgeName],
      onChange: (option) => handleChange(bridge.bridgeName, option)
    }
  }

  return (
    <Grid container direction="column">
      <CelerBridgeBanner />

      {bridge1 && bridge2 && (
        <Grid container className={classes.controlsContainer}>
          <Select {...getProps(bridge1)} />

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            component="button"
            className={classes.btn}
            onClick={handleSwap}
            disabled={bridgeValues.length !== 2}
          >
            <SwapHorizIcon />
          </Grid>

          <Select {...getProps(bridge2)} />
        </Grid>
      )}

      <Typography className={classes.title}>Available Bridges</Typography>

      <Grid container className={classes.tableContainer}>
        {bridgeValues.length !== 2 ? (
          <EmptyPlaceholder text="Select Bridges" />
        ) : (
          <>
            {isTablet ? (
              <Grid container direction="column">
                {combinations.map((combination, index) => (
                  <BridgeComboCard key={index} {...combination} />
                ))}
              </Grid>
            ) : (
              <Table cols={COLS} empty={!combinations.length}>
                {combinations.map((combination, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Link to={combination.url} native target="_blank">
                        {combination.bridgeName}
                      </Link>
                    </TableCell>
                    <TableCell wrap="wrap">
                      {combination.comment || 'n/a'}
                    </TableCell>
                    <TableCell>{combination.rank || 'n/a'}</TableCell>
                    <TableCell align="right">
                      {combination.fee || 'n/a'}
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}
