import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'

import Dialog from './Dialog'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  text: {
    fontSize: '0.875rem'
  }
})

export default function CheckoutSuccessDialog({ open, onClose }) {
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      size="sm"
      height={175}
      variant="secondary"
      mobileHeight={false}
      title="Success"
      onClose={onClose}
    >
      <DialogBody>
        <Typography className={classes.text}>
          You successfully completed checkout! Please check your email address
          for next steps.
        </Typography>
      </DialogBody>
    </Dialog>
  )
}
