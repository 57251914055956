import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import classNames from 'classnames'
import { ChangeEventHandler } from 'react'

const useStyles = makeStyles({
  textarea: {
    width: '100%',
    outline: 'none',
    fontFamily: 'Roboto',
    borderRadius: 15,
    fontSize: '0.875rem',
    backgroundColor: '#373D66',
    border: '1px solid rgba(255,255,255,0.4)',
    padding: '0.625rem 1.25rem',
    margin: 0,
    color: '#fafafa',
    resize: 'none'
  },
  label: (props: any) => ({
    fontSize: '0.875rem',
    color: props.error ? '#ff5733' : '#fafafa',
    marginBottom: '0.5rem'
  })
})

interface TextareaProps {
  value: string
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  placeholder?: string
  className?: string
  label?: string
}

export default function Textarea({
  value,
  onChange,
  placeholder,
  className,
  label
}: TextareaProps) {
  const classes = useStyles({})
  return (
    <Grid container direction="column">
      {label && <label className={classes.label}>{label}</label>}

      <TextareaAutosize
        className={classNames(classes.textarea, className)}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </Grid>
  )
}
