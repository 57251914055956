import { fetcher } from 'services/api/common'
import useSWR from 'swr'

interface UseCmsPartnersProps {
  isLoading: boolean
  partners: any[]
  revalidate: any
}

export default function useCmsPartners(): UseCmsPartnersProps {
  const {
    data,
    error,
    mutate: revalidate
  } = useSWR('/auth/cms/get_partnership_list', fetcher)

  const isLoading = !data && !error
  const partners = data || []

  return {
    isLoading,
    partners,
    revalidate
  }
}
