import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { MultifarmIcon } from 'assets/icons'

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    zIndex: 9999,
    backgroundColor: 'rgba(0,0,0,0.4)',

    '& svg': {
      width: 200,
      height: 200,
      animation: 'pulse 2s linear 1s infinite'
    }
  }
})

export default function LoadingSpinner() {
  const classes = useStyles()
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <MultifarmIcon />
    </Grid>
  )
}
