import f from 'date-fns/format'
import _formatISO from 'date-fns/formatISO'
import _isAfter from 'date-fns/isAfter'
import _isToday from 'date-fns/isToday'
import v from 'date-fns/isValid'
import p from 'date-fns/parse'
import _parseISO from 'date-fns/parseISO'
import _sub from 'date-fns/sub'

export const DATE_FORMAT_PRIMARY = 'dd.MM.yy'
export const DATE_FULL_FORMAT_PRIMARY = 'dd/MM/yyyy HH:mm O'
export const DATE_CHART_FORMAT = 'dd.MM'
export const CHART_TOOLTIP_DATE_PRIMARY = 'MMMM dd, yyyy'
export const API_DATE_FORMAT_SECONDARY = 'yyyy-MM-dd'

type Format =
  | typeof DATE_FORMAT_PRIMARY
  | typeof DATE_FULL_FORMAT_PRIMARY
  | typeof DATE_CHART_FORMAT
  | typeof CHART_TOOLTIP_DATE_PRIMARY
  | typeof API_DATE_FORMAT_SECONDARY

export const isToday = _isToday
export const isAfter = _isAfter
export const subDate = _sub
export const formatISO = _formatISO
export const parseISO = _parseISO

export function format(date: Date, format: Format) {
  try {
    return f(date, format)
  } catch (e) {
    return ''
  }
}

export function getNow(): Date {
  return new Date()
}

export function subDays(date: Date, days: number): Date {
  return subDate(date, {
    days
  })
}

export function parseDate(date: string, format: string) {
  return p(date, format, new Date())
}

export function convertChartDate(date: string): Date {
  return p(date, 'yyyy-MM-dd', new Date())
}

export function isValidDate(date: any): boolean {
  return v(date)
}
