import { fetcher } from 'services/api/common'
import useSWR from 'swr'

export interface BlockchainSelectGroups {
  [group: string]: string[]
}

export interface UseBlockchainSelect {
  isLoading: boolean
  groups: BlockchainSelectGroups
}

export default function useBlockchainSelect(): UseBlockchainSelect {
  const { data, error } = useSWR('/get_blockchain_select', fetcher)

  const isLoading = !data && !error

  const groups = data || {}
  return {
    isLoading,
    groups
  }
}
