import { fetcher } from 'services/api/common'
import useSWR from 'swr'
import { stringifyURL } from 'utils/query'

interface UseSummedIncomeConfig {
  category?: string
  range?: string
}

interface UseSummedIncome {
  isLoading: boolean
  income: any[]
}

function getKey(params) {
  return params.category
    ? stringifyURL('/auth/get_summed_income', params)
    : null
}

export default function useSummedIncome(
  config: UseSummedIncomeConfig = {}
): UseSummedIncome {
  const params = {
    category: config.category,
    range: config.range
  }

  const { data, error } = useSWR(() => getKey(params), fetcher)

  const isLoading = !data && !error
  const income = data?.data || []

  return {
    isLoading,
    income
  }
}
