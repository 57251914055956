import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'

import { ArrowRightIcon } from '../../assets/icons'
import { device } from '../../utils/screen'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    color: '#66C8FF',

    '& svg': {
      cursor: 'pointer',

      '&:first-child': {
        transform: 'rotate(180deg)',
        color: (props: any) => (props.page === 1 ? '#BEBEBE' : 'inherit'),
        pointerEvents: (props: any) => (props.page === 1 ? 'none' : 'auto')
      },

      '&:last-child': {
        color: (props: any) =>
          props.page === props.count ? '#BEBEBE' : 'inherit',
        pointerEvents: (props: any) =>
          props.page === props.count ? 'none' : 'auto'
      }
    }
  },
  text: {
    fontSize: '1rem',
    fontWeight: 700,
    color: '#fff',
    margin: '0 0.5rem',

    [`@media ${device.tablet}`]: {
      fontSize: '0.875rem'
    }
  }
})

export type OnPage = (page: number) => void

interface TablePaginationProps {
  count: number
  page: number
  onPage: OnPage
}

export default function TablePagination({
  count,
  page,
  onPage
}: TablePaginationProps) {
  const classes = useStyles({ page, count })
  return (
    <Grid className={classes.root}>
      <ArrowRightIcon onClick={() => onPage(page - 1)} />
      <Typography className={classes.text}>
        Page {page} of {count}
      </Typography>
      <ArrowRightIcon onClick={() => onPage(page + 1)} />
    </Grid>
  )
}
