import ChartCard from 'components/cards/ChartCard'
import LineChart from 'components/charts/LineChart'
import Tabs from 'components/tabs/Tabs'
import ChartTooltip from 'components/tooltips/ChartTooltip'
import useSummedIncome from 'hooks/useSummedIncome'
import { useMemo, useState } from 'react'
import { parseMoney } from 'utils/api'
import {
  formatChartDate,
  formatChartMoney,
  getChartId,
  renderTooltipOptions
} from 'utils/charts'
import { getRandomColors } from 'utils/colors'
import { CURRENCY, RANGE, RANGE_API } from 'utils/filters'
import { getSummedIncomeKey, parseSummedIncomeChart } from 'utils/pools'
import { TOOLTIP } from 'utils/tooltip'

import { CURRENCIES, RANGES } from '../performance/data'

const COLORS_3 = getRandomColors(20)
const TITLE = 'Daily Earnings Accumulated'

interface DailyEarningsBarProps {
  category?: string
}

export default function DailyEarningsBar({ category }: DailyEarningsBarProps) {
  const [dailyRange, setDailyRange] = useState(RANGE._7d)
  const [currency, setCurrency] = useState(CURRENCY._USD)

  const summedIncome = useSummedIncome({
    category,
    range: RANGE_API[dailyRange]
  })

  const key = getSummedIncomeKey(summedIncome.income)

  const chart = useMemo(
    () => parseSummedIncomeChart(summedIncome.income),
    [key, dailyRange]
  )

  const chartData = currency === CURRENCY._USD ? chart.usd : chart.eth

  return (
    <ChartCard
      title={TITLE}
      tooltip={<ChartTooltip content={TOOLTIP.BAR_DAILY_EARNINGS} />}
      titleComponent={
        <Tabs
          width="auto"
          tabs={CURRENCIES}
          value={currency}
          onChange={setCurrency}
        />
      }
      headerComponent={
        <Tabs
          width="auto"
          tabs={RANGES}
          value={dailyRange}
          onChange={setDailyRange}
        />
      }
    >
      <LineChart
        bar
        memo={[key, dailyRange].join('_')}
        noData={!chartData.length}
        id={getChartId(TITLE)}
        data={chartData}
        dataKey={chart.keys}
        colors={COLORS_3}
        yTickFormatter={(tick) => formatChartMoney(tick, currency)}
        xTickFormatter={formatChartDate}
        tooltipOptions={renderTooltipOptions(chart.keys, true, COLORS_3, (e) =>
          parseMoney(e, 2, true, currency)
        )}
      />
    </ChartCard>
  )
}
