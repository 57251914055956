import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { DeleteIcon, EditIcon } from 'assets/icons'
import Button from 'components/buttons/Button'
import IconButton from 'components/buttons/IconButton'
import AddDaoGohmLeaderboardDialog from 'components/dialogs/AddDaoGohmLeaderboardDialog'
import Table from 'components/table/Table'
import TableCell from 'components/table/TableCell'
import TableRow from 'components/table/TableRow'
import { PageTitle } from 'components/typography'
import useDaoGohmLeaderboard from 'hooks/useDaoGohmLeaderboard'
import useDaoGohmLeaderboards from 'hooks/useDaoGohmLeaderboards'
import DaoGohmLeaderboardsProvider from 'providers/DaoGohmLeaderboardsProvider'
import { useState } from 'react'
import { TableCol } from 'types/components/table'
import { nFormatter } from 'types/utils'

import CellImage from '../../../components/images/CellImage'

const useStyles = makeStyles({
  root: {},
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1.25rem'
  },
  editBtn: {
    '& svg': {
      width: 18
    }
  },
  actionsCell: {
    display: 'flex',
    alignItems: 'center'
  },
  rowTitle: {
    margin: '0 1.25rem',
    display: 'flex',
    alignItems: 'center'
  }
})

const COLS: TableCol[] = [
  { title: 'DAO', key: 'dao' },
  { title: 'Total GOHM', key: 'total' },
  { title: '7 Day Change', key: 'day7' },
  { title: '30 Day Change', key: 'day30' }
]

function isActive(row) {
  return row.active
}

export default function DaoGohmLeaderboards() {
  const classes = useStyles()
  const daoGohmLeaderboards = useDaoGohmLeaderboards()
  const daoGohmLeaderboard = useDaoGohmLeaderboard()

  const [add, setAdd] = useState(false)
  const [edit, setEdit] = useState('')

  return (
    <DaoGohmLeaderboardsProvider revalidate={daoGohmLeaderboards.revalidate}>
      <Grid container direction="column" className={classes.root}>
        <PageTitle className={classes.title}>
          <span>DAO GOHM Leaderboards</span>

          <Button variant="contained-2" size="sm" onClick={() => setAdd(true)}>
            Add new Leaderboard
          </Button>
        </PageTitle>

        <Table
          cols={COLS}
          loading={daoGohmLeaderboards.isLoading}
          empty={!daoGohmLeaderboards.leaderboards.length}
        >
          {daoGohmLeaderboards.leaderboards.filter(isActive).map((row) => (
            <TableRow key={row['item_id']}>
              <TableCell>
                <Grid className={classes.actionsCell}>
                  <IconButton
                    className={classes.editBtn}
                    onClick={() => setEdit(row['item_id'])}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      daoGohmLeaderboard.onRemove(row['item_id'], () =>
                        daoGohmLeaderboards.revalidate?.()
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>

                  <div className={classes.rowTitle}>
                    {row['image'] && <CellImage src={row['image']} />}
                    <span>{row['dao']}</span>
                  </div>
                </Grid>
              </TableCell>
              <TableCell>{nFormatter(row['total_cvx'])}</TableCell>
              <TableCell>{'N/A'}</TableCell>
              <TableCell>{'N/A'}</TableCell>
            </TableRow>
          ))}
        </Table>
      </Grid>

      <AddDaoGohmLeaderboardDialog
        open={add || !!edit}
        editId={edit}
        onClose={() => {
          setAdd(false)
          setEdit('')
        }}
      />
    </DaoGohmLeaderboardsProvider>
  )
}
