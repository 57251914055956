import { Box, makeStyles } from '@material-ui/core'
import classNames from 'classnames'

import Button from '../buttons/Button'
import Dialog from './Dialog'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  dialog: {
    border: '2px solid rgb(133, 89, 254)'
  },
  columns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    height: 420,
    fontSize: '1rem',
    textAlign: 'center',
    gap: 32
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 24
  },
  conditions: {
    display: 'flex',
    fontSize: '0.75rem',
    height: 60,
    alignItems: 'center'
  },
  roleContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: 90,
    padding: '16px 24px'
  },
  analystContainer: {
    color: 'rgb(110, 115, 254)',
    border: '2px solid rgb(81, 91, 185)',
    borderRadius: 32
  },
  role: {
    fontSize: '3rem',
    fontWeight: 'bold'
  },
  access: {
    position: 'absolute',
    left: 0,
    right: 0,
    lineHeight: '1rem',
    bottom: '-0.5rem',
    fontWeight: 'bold'
  },
  accessFree: {
    color: 'rgb(89, 248, 65)'
  },
  featuresDescription: {},
  featuresList: {
    listStyle: 'disc',
    textAlign: 'initial',
    lineHeight: '2.5rem',
    '& li': {
      listStyle: 'inherit'
    }
  },
  featuresButtons: {
    width: '100%'
  },
  featuresButton: {
    fontWeight: 'bold'
  },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    fontWeight: 'bold',
    gap: 48,
    marginBottom: 0,
    marginTop: 'auto'
  },
  button: {
    width: 120
  }
})

interface SignUpPlanDialogProps {
  open: boolean
  onClose: () => void
  onLogin: () => void
  onSignUp: () => void
  onGetDemo: () => void
}

export default function SignUpPlanDialog({
  open,
  onClose,
  onLogin,
  onSignUp,
  onGetDemo
}: SignUpPlanDialogProps) {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      size="xl"
      mobileHeight={false}
      variant="secondary"
      onClose={onClose}
      height={610}
      className={classes.dialog}
    >
      <DialogBody>
        <Box className={classes.columns}>
          <Box className={classes.column}>
            <div
              className={classNames(
                classes.roleContainer,
                classes.analystContainer
              )}
            >
              <div className={classes.role}>ANALYST</div>
              <div className={classNames(classes.access, classes.accessFree)}>
                FREE
              </div>
            </div>
            <div className={classes.conditions}>NO LOGIN REQUIRED</div>
            <div className={classes.featuresDescription}>
              ACCESS ALL FEATURES WITHOUT PAYWALL
            </div>
          </Box>
          <Box className={classes.column}>
            <div className={classes.roleContainer}>
              <div className={classes.role}>INVESTOR</div>
              <div className={classNames(classes.access, classes.accessFree)}>
                FREE
              </div>
            </div>
            <div className={classes.conditions}>
              (CREATE YOUR ACCOUNT TO ACCESS BASIC FEATURES)
            </div>
            <div className={classes.featuresDescription}>
              (CREATE YOUR ACCOUNT TO ACCESS BASIC FEATURES)
            </div>
            <ul className={classes.featuresList}>
              <li>Save Assets</li>
              <li>Save Farms</li>
              <li>Advanced Data Metrics</li>
              <li>Exchange Data</li>
              <li>Trending Pools</li>
            </ul>
            <div className={classes.buttons}>
              <Button
                className={classes.button}
                color="primary"
                size="sm"
                variant="outlined"
                onClick={() => onSignUp()}
              >
                SIGN UP
              </Button>
              <Button
                className={classes.button}
                color="secondary"
                size="sm"
                variant="outlined"
                onClick={() => onLogin()}
              >
                LOGIN
              </Button>
            </div>
          </Box>
          <Box className={classes.column}>
            <div className={classes.roleContainer}>
              <div className={classes.role}>DAO</div>
              <div className={classes.access}>CONTACT US</div>
            </div>
            <div className={classes.conditions}>
              ADVANCED FEATURES FOR PROTOCOL OWNERS
            </div>
            <div className={classes.featuresButtons}>
              <Button
                className={classes.featuresButton}
                color="primary"
                variant="outlined"
                onClick={onGetDemo}
              >
                GET A DEMO
              </Button>
            </div>
            <div className={classes.buttons}>
              <Button
                className={classes.button}
                color="secondary"
                size="sm"
                variant="outlined"
                onClick={() => onLogin()}
              >
                LOGIN
              </Button>
            </div>
          </Box>
        </Box>
      </DialogBody>
    </Dialog>
  )
}
