import DaoGohmLeaderboards from './cms/DaoGohmLeaderboards'
import Partners from './cms/Partners'
import ProteusDescription from './cms/ProteusDescription'

export default function Cms() {
  return (
    <>
      <Partners />
      <ProteusDescription />
      <DaoGohmLeaderboards />
    </>
  )
}
