import { fetcher } from 'services/api/common'
import useSWR from 'swr'

interface MultiSelectsResponse {
  data: {
    chains_available: string[]
  }
}
interface UseMultiSelectsResponse {
  availableChains: string[]
}

export const useGlobalMultiSelects = (): UseMultiSelectsResponse => {
  const { data } = useSWR<MultiSelectsResponse>(
    '/get_global_multi_selects',
    fetcher
  )

  return {
    availableChains: data?.data.chains_available || []
  }
}
