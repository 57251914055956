import makeStyles from '@material-ui/core/styles/makeStyles'
import Card from 'components/cards/Card'
import {
  formatActive,
  formatDate,
  formatFarm,
  formatReward
} from 'utils/assets'

import Title from './Title'

const useStyles = makeStyles({
  table: {
    fontSize: '0.75rem',
    borderCollapse: 'separate',
    borderSpacing: '0 0.75rem',
    margin: '-0.75rem 0',

    '& tr': {
      '& td': {
        height: 20,
        padding: '0.25rem',
        backgroundColor: '#39406A',

        '&:first-child': {
          paddingLeft: '1.25rem',
          borderRadius: '1.25rem 0 0 1.25rem'
        },

        '&:last-child': {
          paddingRight: '1.25rem',
          borderRadius: '0 1.25rem 1.25rem 0'
        }
      }
    }
  },
  nameCell: {
    fontWeight: 700
  }
})

interface InfoProps {
  asset: any
}

export default function Info({ asset }: InfoProps) {
  const classes = useStyles()

  const data = [
    { title: 'Blockchain', value: asset.blockchain },
    { title: 'Exchange', value: asset.exchangeName },
    { title: 'Farm', value: formatFarm(asset) },
    { title: 'Last Updated', value: formatDate(asset.dateUpdated) },
    { title: 'Info', value: asset.info },
    { title: 'Yield Type', value: asset.yieldType },
    { title: 'Rewards Token', value: formatReward(asset) },
    { title: 'Active', value: formatActive(asset.asset) }
  ]

  return (
    <Card direction="column">
      <Title>Info</Title>

      <table className={classes.table}>
        <tbody>
          {data
            .filter((row) => Boolean(row.value))
            .map((row, index) => (
              <tr key={index}>
                <td>
                  <span className={classes.nameCell}>{row.title}</span>
                </td>
                <td>{row.value}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </Card>
  )
}
