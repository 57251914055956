import { BlockchainDropdownOption } from '../../interfaces/blockchain-dropdown-option'
import { Chain } from '../../services/types/globals'
import { BlockchainDropdownStrategy } from './BlockchainDropdownStrategy'

/**
 * Strategies for blockchain dropdown single option behaviour.
 */
export class BlockchainDropdownSingleStrategy extends BlockchainDropdownStrategy {
  /**
   * Returns the value that should be passed to the filter to get an empty result.
   * @returns Filter sequence.
   */
  public getChainClearValue(): Chain[] {
    return ['']
  }

  /**
   * Processes selected options according to concrete strategy and returns value suitable for the blockchains filter.
   * @param selection - Options passed.
   * @returns Filter sequence.
   */
  public getChainsFromSelection(
    selection?: BlockchainDropdownOption[]
  ): Chain[] {
    if (!(selection && this.selected)) {
      return ['']
    }
    let newValue
    if (!selection.length) {
      newValue = this.selected[0]
    } else if (selection.length === 2) {
      newValue = selection.find((item) => !this.selected.includes(item))
    } else {
      newValue = this.selected.find((item) => !selection.includes(item))
    }
    if (!newValue) {
      return ['']
    }
    return [newValue.name]
  }

  /**
   * Returns title value to display on the dropdown component.
   * @returns string.
   */
  public getTitle(): string {
    if (this.selected?.length === 1) {
      return `Filtered by blockchain (${this.selected[0].name})`
    }
    return 'Filter by blockchain'
  }

  /**
   * Transforms filtered blockchains into option / options to select.
   */
  protected getOptionsFromChains(): BlockchainDropdownOption[] {
    const { active } = this.props
    if (active.includes('')) {
      return this.options
    }
    return (
      this.options?.filter(
        (item: BlockchainDropdownOption) => item.name === active[0]
      ) || this.options?.[0]
    )
  }
}
