import { toast } from 'react-toastify'

interface ValidateParams {
  name: string
  email: string
  privacy: string
}

export const validateForm = ({ name, email, privacy }: ValidateParams) => {
  let valid = true
  if (!validateName(name)) {
    valid = false
    toast.error('Enter a valid name of DAO/Company')
  }
  if (!email) {
    valid = false
    toast.error('Enter contact details')
  }
  if (!privacy) {
    valid = false
    toast.error('Check Privacy Policy checkbox')
  }
  return valid
}

export const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )

export const validateName = (name) => name && String(name).trim().length > 0
