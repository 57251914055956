import { fetcher } from 'services/api/common'
import useSWR from 'swr'

export interface UseBlockchainTypes {
  isLoading: boolean
  types: string[]
}

export default function useBlockchainTypes(): UseBlockchainTypes {
  const { data, error } = useSWR('/auth/get_blockchains', fetcher)

  const isLoading = !data && !error
  const types = data
  return {
    isLoading,
    types
  }
}
