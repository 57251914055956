import { fetcher } from 'services/api/common'
import useSWR from 'swr'

interface UseApiDomains {
  isLoading: boolean
  domains: any[]
  revalidate: any
}

export default function useApiDomains(): UseApiDomains {
  const {
    data,
    error,
    mutate: revalidate
  } = useSWR('/auth/get_api_domains', fetcher)

  const isLoading = !data && !error
  const domains = data || []

  return {
    isLoading,
    domains,
    revalidate
  }
}
