import { AssetsFilters } from 'hooks/useAssets'
import { ReactNode } from 'react'
import { TableCol } from 'types/components/table'
import { nFormatter } from 'types/utils'

import { Link } from '../components/typography'
import { DATE_FULL_FORMAT_PRIMARY, format } from './date'
import { checkGroups } from './filters'
import { getRemoteAssetIcon } from './icons'
import { omitEmpty } from './obj'

export function getAssetsCols(pagination: ReactNode): TableCol[] {
  return [
    { title: 'Assets', name: 'asset', sortable: true },
    { title: 'TVL Staked', name: 'tvlStaked', sortable: true },
    { title: 'APR (y)', name: 'aprYearly', sortable: true },
    { title: 'APR (d)', name: 'aprYearly', sortable: true },
    {
      title: 'Blockchain',
      name: 'blockchain',
      sortable: true
    },
    { title: 'Farm', name: 'farm', sortable: true },
    { title: 'Rewards', name: 'rewardTokenA', sortable: true },
    {
      title: pagination,
      align: 'right'
    }
  ]
}

export function formatName(name) {
  return name ? (name.length > 13 ? `${name.substr(0, 13)}...` : name) : '-'
}

export function formatApr(apr) {
  let aprFixed, aprValue

  if (!apr) {
    return {
      aprValue: '-',
      aprTooltip: ''
    }
  } else if (typeof apr === 'number') {
    aprFixed = apr.toFixed(2) || ''
    aprValue = `${apr > 10000 ? '>10,000.00' : aprFixed} %`
  } else {
    aprFixed = apr
    aprValue = apr
  }

  return {
    aprValue,
    aprTooltip: aprFixed
  }
}

export function formatRows(data) {
  try {
    return data.map((row) => ({
      assetId: row.assetId,
      asset: formatName(row.asset),
      tvlStaked: `$${nFormatter(row.tvlStaked)}`,
      aprYearly: formatApr(row.aprYearly).aprValue,
      aprYearlyTooltip: formatApr(row.aprYearly).aprTooltip,
      aprDaily: formatApr(row.aprDaily).aprValue,
      aprDailyTooltip: formatApr(row.aprDaily).aprTooltip,
      blockchain: row.blockchain || '-',
      farm: row.farm || '-',
      farmName: row.farmName,
      rewards: [row.rewardTokenA, row.rewardTokenB].filter(Boolean).length
        ? [row.rewardTokenA, row.rewardTokenB].filter(Boolean).join(', ')
        : '-',
      investLink: row.links?.staking ? row.links.staking : null,
      depositFee: row.depositFee,
      withdrawalFee: row.withdrawalFee,
      harvestLockup: row.harvestLockup,
      transferTax: row.transferTax,
      antiWhale: row.antiWhale,
      imageUrl: getRemoteAssetIcon(row)
    }))
  } catch (e) {
    console.error(e)
    return []
  }
}

export function formatParams(params: AssetsFilters) {
  try {
    const newParams: any = {}

    Object.keys(params).forEach((key) => {
      newParams[key] = params[key]
    })

    delete newParams.coin_groups
    delete newParams.coin_group

    newParams['coin_groups'] = (
      params.coin_groups?.length
        ? checkGroups(params.coin_groups)
        : params.coin_group
        ? [params.coin_group]
        : []
    ).join(',')

    newParams['blockchain'] = newParams.blockchain.join(',')
    newParams['farm_ids'] = newParams.farm_ids.join(',')
    newParams['yield_types'] = newParams.yield_types.join(',')
    newParams['exchanges'] = newParams.exchanges.join(',')

    return omitEmpty(newParams)
  } catch (e) {
    return {}
  }
}

export function formatDate(date) {
  return date ? format(new Date(date), DATE_FULL_FORMAT_PRIMARY) : null
}

export function formatReward(asset) {
  return (
    [asset.rewardTokenA, asset.rewardTokenB].filter(Boolean).join(', ') || null
  )
}

export function formatActive(active) {
  return typeof active === 'boolean' ? (active ? 'Yes' : 'No') : null
}

export function formatFarm(asset) {
  return (
    <Link to={`/farms/${asset.farmId}`}>{asset.farmName || asset.farm}</Link>
  )
}

export function formatCustomAssetValues(values) {
  return {
    ...values,
    holdings: Number(values.holdings),
    ...(values.manualAssetPriceUsd && {
      manualAssetPriceUsd: Number(values.manualAssetPriceUsd)
    })
  }
}
