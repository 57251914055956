import Grid from '@material-ui/core/Grid'
import { useBalanceSheet } from 'hooks/useBalanceSheet'
import { BalanceSheetColumn, BalanceSheetRow } from 'interfaces/balance-sheet'
import { useCallback, useState } from 'react'
import { addBalanceRecord } from 'services/api/balanceSheet'
import { parseMoney } from 'utils/api'

import BalanceSheetAccordion from './balanceSheet/BalanceSheetAccordion'

const DEFAULT_COLUMNS: BalanceSheetColumn[] = [
  {
    title: 'Q1',
    key: 'q1',
    align: 'center',
    render: (item) => parseMoney(item),
    width: '10%'
  },
  {
    title: 'Q2',
    key: 'q2',
    align: 'center',
    render: (item) => parseMoney(item),
    width: '10%'
  },
  {
    title: 'Q3',
    key: 'q3',
    align: 'center',
    render: (item) => parseMoney(item),
    width: '10%'
  },
  {
    title: 'Q4',
    key: 'q4',
    align: 'center',
    render: (item) => parseMoney(item),
    width: '10%'
  },
  {
    title: 'Full Year',
    key: 'year',
    align: 'center',
    render: (item) => parseMoney(item),
    width: '10%'
  }
]

const REVENUE_COLUMNS: BalanceSheetColumn[] = [
  { title: 'Revenue Cateogry', key: 'product', align: 'left', width: '45%' },
  ...DEFAULT_COLUMNS
]

const EXPENSE_COLUMNS: BalanceSheetColumn[] = [
  { title: 'Expense Category', key: 'product', align: 'left', width: '45%' },
  ...DEFAULT_COLUMNS
]

enum AddRowContext {
  REVENUE = 'revenues',
  EXPENSE = 'expenses'
}

export default function BalanceSheet() {
  const [year] = useState(new Date().getFullYear())
  const { isLoading, revenues, expenses, revalidate } = useBalanceSheet({
    year
  })

  const handleUpdate = useCallback(
    (context: AddRowContext) => async (balanceSheet: BalanceSheetRow[]) => {
      const data = { [context]: balanceSheet }
      await addBalanceRecord(year, data)
      revalidate()
    },
    [year, revalidate]
  )

  return (
    <>
      <Grid container direction="column">
        <BalanceSheetAccordion
          title="Revenues"
          buttonTitle="Add a revenue"
          items={revenues}
          columns={REVENUE_COLUMNS}
          isLoading={isLoading}
          onUpdate={handleUpdate(AddRowContext.REVENUE)}
        />
        <BalanceSheetAccordion
          title="Expenses"
          buttonTitle="Add an expense"
          items={expenses}
          columns={EXPENSE_COLUMNS}
          isLoading={isLoading}
          onUpdate={handleUpdate(AddRowContext.EXPENSE)}
        />
      </Grid>
    </>
  )
}
