import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import useSaveSearch from 'hooks/useSaveSearch'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import Dialog from './Dialog'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.25rem',
    marginBottom: '2.5rem'
  },
  button: {
    marginRight: '1.25rem',

    '&:last-child': {
      marginRight: 0
    }
  }
})

interface ContentProps {
  savedId: string
  onClose: () => void
}

const defaultValues = {
  risk: '',
  about: '',
  notes: '',
  strategies: '',
  transaction_link: '',
  risk_description: ''
}

function Content({ savedId, onClose }: ContentProps) {
  const classes = useStyles()

  const saveSearch = useSaveSearch({ id: savedId })

  const methods = useForm({
    defaultValues
  })

  useEffect(() => {
    if (saveSearch.savedSearch.saved_id && saveSearch.savedSearch.edits) {
      methods.reset({
        ...defaultValues,
        ...saveSearch.savedSearch.edits
      })
    }
  }, [saveSearch.savedSearch.saved_id])

  const handleSubmit = (values) => {
    saveSearch.onEditSearch(savedId, values, onClose)
  }

  return (
    <DialogBody>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <Grid container direction="column" className={classes.content}>
          <Input
            variant="secondary"
            placeholder="Risk"
            label="Risk"
            {...methods.register('risk')}
          />
          <Input
            variant="secondary"
            placeholder="About"
            label="About"
            {...methods.register('about')}
          />
          <Input
            variant="secondary"
            placeholder="Notes"
            label="Notes"
            {...methods.register('notes')}
          />
          <Input
            variant="secondary"
            placeholder="Strategies"
            label="Strategies"
            {...methods.register('strategies')}
          />
          <Input
            variant="secondary"
            placeholder="Transaction Link"
            label="Transaction Link"
            {...methods.register('transaction_link')}
          />
          <Input
            variant="secondary"
            placeholder="Risk Description"
            label="Risk Description"
            {...methods.register('risk_description')}
          />
        </Grid>

        <Grid container justifyContent="flex-end" alignItems="center">
          <Button
            variant="contained-2"
            size="sm"
            type="button"
            color="secondary"
            className={classes.button}
            onClick={onClose}
          >
            Reset
          </Button>
          <Button
            variant="contained-2"
            size="sm"
            type="submit"
            className={classes.button}
          >
            Save
          </Button>
        </Grid>
      </form>
    </DialogBody>
  )
}

interface EditStrategyDialogProps {
  open: boolean
  onClose: () => void
}

export default function EditSavedAssetDialog({
  onClose,
  open,
  ...props
}: EditStrategyDialogProps & ContentProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Edit Values"
      variant="secondary"
      height={435}
      size="lg"
    >
      <Content {...props} onClose={onClose} />
    </Dialog>
  )
}
