import { omitEmpty } from '../../utils/obj'
import { stringifyURL } from '../../utils/query'
import Api from '../Api'

export async function fetcher(url: string) {
  const response = await Api.get(url)
  return response.data
}

/**
 * Stringifies the request params.
 * @param {string} route - Route endpoint.
 * @param {Record<string, any>} params - Data to add to query params.
 * @returns Stringified url.
 */
export const getKey = (route: string, params: { [key: string]: any }) => {
  return stringifyURL(route, omitEmpty(params))
}
