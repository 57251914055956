import Api from '../Api'

export async function addWallet(wallet: string) {
  const response = await Api.post('/auth/add_wallet', {
    wallet_id: wallet
  })
  return response.data
}

export async function getWallets(url: string) {
  const response = await Api.post(url, {})
  return response.data
}

export async function removeWallet(id: string) {
  const response = await Api.post('/auth/delete_wallet', {
    wallet_id: id
  })
  return response.data
}
