import usePoolsNames, { StrategyTypeEnum } from 'hooks/usePoolsNames'
import { useEffect } from 'react'
import { poolsNamesToOptions } from 'utils/pools'

import Select from './Select'

interface SelectStrategyProps {
  className: string
  value: string
  onChange: any
  strategyType?: StrategyTypeEnum
}

export default function SelectStrategy({
  className,
  value,
  onChange,
  strategyType = StrategyTypeEnum.PRODUCTIVE_ASSETS
}: SelectStrategyProps) {
  const { poolsNames } = usePoolsNames({ type: strategyType })
  const options = poolsNamesToOptions(poolsNames)

  useEffect(() => {
    if (!value && options.length) {
      onChange(options[0])
    }
  }, [options.length])

  return (
    <Select
      className={className}
      placeholder="Select Strategy"
      options={options}
      value={value || ''}
      onChange={onChange}
    />
  )
}
