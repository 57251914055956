import qs from 'qs'
import query from 'query-string'

import { ARRAY_FIELDS, BOOLEAN_FIELDS } from './filters'

export function parseURL(url: string): query.ParsedUrl {
  return query.parseUrl(url)
}

export function stringifyURL(
  url,
  params,
  options?: qs.IStringifyOptions
): string {
  return `${url}?${qs.stringify(params, { arrayFormat: 'repeat', ...options })}`
}

export function stringifyQuery(url, params) {
  return stringifyURL(url, params)
}

export function parseQuery(url) {
  const obj: Record<string, any> = qs.parse(url.substring(url.indexOf('?') + 1))

  Object.keys(obj).forEach((key) => {
    if (obj[key] && !isNaN(Number(obj[key]))) {
      obj[key] = Number(obj[key])
    }
    if (BOOLEAN_FIELDS.includes(key)) {
      obj[key] = obj[key] === 'true'
    }
    if (ARRAY_FIELDS.includes(key)) {
      if (!Array.isArray(obj[key])) {
        obj[key] = [obj[key]]
      }
    }
  })

  return obj
}

export function getQuery(url): string {
  return url?.split('?')?.[1] || ''
}

export function urlDomain(url: string): string {
  if (typeof URL === 'function') {
    const urlObj = new URL(url)
    return urlObj.hostname
  }
  return url
}
