import { DeleteIcon } from 'assets/icons'
import IconButton from 'components/buttons/IconButton'
import Table from 'components/table/Table'
import TableCell from 'components/table/TableCell'
import TableRow from 'components/table/TableRow'
import { TableCol } from 'types/components/table'

interface WalletsTableProps {
  data: any[]
  loading: boolean
  onRemove: (id: string) => void
}

const COLS: TableCol[] = [
  { title: 'Wallet ID' },
  { title: 'Username' },
  { title: '', align: 'right' }
]

export default function WalletsTable({
  data,
  loading,
  onRemove
}: WalletsTableProps) {
  return (
    <Table cols={COLS} empty={!data.length} loading={loading}>
      {data.map((row, index) => (
        <TableRow key={index}>
          <TableCell>{row.wallet_id}</TableCell>
          <TableCell>{row.username}</TableCell>

          <TableCell align="right">
            <IconButton onClick={() => onRemove(row.wallet_id)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
