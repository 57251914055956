import { Option } from '../types/components/form'
import { formatDaoKey, formatSushiswapKey } from './charts'
import { getRandomColors } from './colors'
import {
  API_DATE_FORMAT_SECONDARY,
  convertChartDate,
  isAfter,
  isToday,
  parseDate,
  subDate
} from './date'
import { RANGE } from './filters'
import { reverse } from './obj'
import { toStartCase } from './strings'

export function getAssetKey({
  customAprYearlyHistorical: _customAprYearlyHistorical,
  dailyEarningsHistorical: _dailyEarningsHistorical,
  monthlyEarningsHistorical: _monthlyEarningsHistorical,
  positionSizeEthHistorical: _positionSizeEthHistorical,
  positionSizeUsdHistorical: _positionSizeUsdHistorical,
  sizeHistorical: _sizeHistorical,
  ...asset
}: any): string {
  return JSON.stringify(asset)
}

export function getFarmDisplayNameOrName(asset: any): string {
  return asset.farmDisplayName || asset.farm
}

export function getStrategyDisplayNameOrAssetName(asset: any): string {
  return asset.strategyDisplayName || getAssetDisplayNameOrName(asset)
}

export function getAssetDisplayNameOrName(asset: any): string {
  return asset.displayName || asset.asset
}

export function getAssetName(asset: any): string {
  return [asset.farm, getAssetDisplayNameOrName(asset)]
    .filter(Boolean)
    .join(': ')
}

export function getStrategyName(asset: any): string {
  return asset.strategyDisplayName || getAssetName(asset)
}

export function poolsNamesToOptions(pools: any[]) {
  try {
    return pools.map((pool) => ({
      label: getStrategyName(pool),
      value: pool.poolId
    }))
  } catch (e) {
    return []
  }
}

export function formatHighlightsData(pool: any) {
  try {
    return {
      usdChart: reverse(pool['positionSizeUsdHistorical'] || []),
      ethChart: reverse(pool['positionSizeEthHistorical'] || [])
    }
  } catch (e) {
    return {
      usdChart: [],
      ethChart: []
    }
  }
}

export function formatAprData(pool: any) {
  try {
    return reverse(pool['customAprYearlyHistorical'] || [])
  } catch (e) {
    return []
  }
}

export function getPoolsKey(pools: any[]) {
  return pools
    .map((pool) =>
      [
        pool.poolId,
        getStrategyDisplayNameOrAssetName(pool),
        getFarmDisplayNameOrName(pool),
        pool.imageUrl,
        pool.farmIconUrl
      ].join('-')
    )
    .join('_')
}

export function parseStrategiesAssets(pools: any[]) {
  const assets: any[] = []

  pools.forEach(
    // eslint-disable-next-line
    ({
      customAprYearlyHistorical: _omit1,
      positionSizeEthHistorical: _omit2,
      positionSizeUsdHistorical: _omit3,
      dailyEarningsHistorical: _omit4,
      ...otherPool
    }) => {
      assets.push(otherPool)
    }
  )

  return assets
}

export function sortTotalKey(arr: string[]): string[] {
  return arr.includes('Total')
    ? ['Total', ...arr.filter((e) => e !== 'Total')]
    : arr
}

export function parseStrategiesCharts(pools: any[], skip?: boolean) {
  try {
    if (skip) {
      return {
        aprChart: [],
        usdChart: [],
        dailyChart: [],
        ethChart: [],
        chartKeys: []
      }
    }

    const aprChart: any[] = []
    const dailyChart: any[] = []
    const usdChart: any[] = []
    const ethChart: any[] = []
    const chartKeys: string[] = pools.map((pool) => pool.asset)

    const initChartObj = {}
    chartKeys.forEach((key) => {
      initChartObj[key] = 0
    })

    pools.forEach((pool) => {
      const {
        customAprYearlyHistorical,
        positionSizeEthHistorical,
        positionSizeUsdHistorical,
        dailyEarningsHistorical
      } = pool

      dailyEarningsHistorical?.forEach((row, rowInd) => {
        dailyChart[rowInd] = {
          ...dailyChart[rowInd],
          date: dailyChart[rowInd]?.date || row.date,
          [pool.asset]: row.value
        }
      })

      customAprYearlyHistorical.forEach((row, rowInd) => {
        aprChart[rowInd] = {
          ...aprChart[rowInd],
          date: aprChart[rowInd]?.date || row.date,
          [pool.asset]: row.value
        }
      })

      positionSizeUsdHistorical.forEach((row, rowInd) => {
        usdChart[rowInd] = {
          ...usdChart[rowInd],
          date: usdChart[rowInd]?.date || row.date,
          total: (usdChart[rowInd]?.total || 0) + (Number(row.value) || 0),
          [pool.asset]: Number(row.value) || 0
        }
      })

      positionSizeEthHistorical.forEach((row, rowInd) => {
        ethChart[rowInd] = {
          ...ethChart[rowInd],
          date: ethChart[rowInd]?.date || row.date,
          total: (ethChart[rowInd]?.total || 0) + (Number(row.value) || 0),
          [pool.asset]: Number(row.value) || 0
        }
      })
    })

    return {
      aprChart: reverse(aprChart),
      dailyChart: reverse(dailyChart),
      usdChart: reverse(usdChart),
      ethChart: reverse(ethChart),
      chartKeys: sortTotalKey(chartKeys)
    }
  } catch (e) {
    console.error(e)
    return {
      aprChart: [],
      dailyChart: [],
      usdChart: [],
      ethChart: [],
      chartKeys: []
    }
  }
}

export function parseDailyEarnings(data: any, otherIncome?: boolean) {
  try {
    return {
      usdChart: reverse(
        (otherIncome
          ? data.otherIncomeDailyEarningsHistoricalUsd
          : data.dailyEarningsHistoricalUsd) || []
      ).map((row) => ({ ...row, date: convertChartDate(row.date) })),
      ethChart: reverse(
        (otherIncome
          ? data.otherIncomeDailyEarningsHistoricalEth
          : data.dailyEarningsHistoricalEth) || []
      ).map((row) => ({ ...row, date: convertChartDate(row.date) }))
    }
  } catch (e) {
    console.error(e)
    return {
      usdChart: [],
      ethChart: []
    }
  }
}

export function parseTopAssets(data: any[]) {
  try {
    const colors = getRandomColors(data.length)
    return data.map((row, index) => {
      return {
        color: colors[index],
        asset: row['asset'],
        value: row['percentage_distribution'],
        farm: row['farm'],
        blockchain: row['blockchain']
      }
    })
  } catch (e) {
    console.error(e)
    return []
  }
}

export function getBlockchainDistributionKey(data: Record<string, any>) {
  return Object.keys(data).join('-')
}

export function parseBlockchainDistribution(data: Record<string, any>) {
  try {
    const keys = Object.keys(data)
    const colors = getRandomColors(keys.length)
    return keys.map((key, index) => ({
      color: colors[index],
      blockchain: key,
      value: data[key]
    }))
  } catch (e) {
    console.error(e)
    return []
  }
}

export function getYieldTypeDistributionKey(data: Record<string, any>) {
  return Object.keys(data).join('-')
}

export function parseYieldTypeDistribution(data: Record<string, any>) {
  try {
    const keys = Object.keys(data)
    const colors = getRandomColors(keys.length)
    return keys.map((key, index) => ({
      color: colors[index],
      yieldType: key,
      value: data[key]
    }))
  } catch (e) {
    console.error(e)
    return []
  }
}

export function parseDistribution(data: Record<string, any>) {
  try {
    const keys = Object.keys(data)
    const colors = getRandomColors(keys.length)
    return keys.map((key, index) => ({
      color: colors[index],
      key: key,
      value: data[key]
    }))
  } catch (e) {
    console.error(e)
    return []
  }
}

export function parsePoolValues(
  pool: any,
  defaultFields: string[],
  customFields: string[]
) {
  const values = {}

  values['imageUrl'] = pool['imageUrl']
  values['farmIconUrl'] = pool['farmIconUrl']
  values['weights'] = pool['weights']

  defaultFields.forEach((key) => {
    values[key] = pool[key] ?? ''
  })

  customFields.forEach((key) => {
    values[key] = pool[key] ?? ''
  })

  return values
}

function filterDate(row: any, fn: any) {
  return row.date ? fn(row.date) : false
}

export function parseRangeChart(range: string, data: any[]) {
  switch (range) {
    case RANGE._24h:
      return data.filter((row) =>
        filterDate(row, (date) => isToday(new Date(date)))
      )
    case RANGE._7d:
      return data.filter((row) =>
        filterDate(row, (date) =>
          isAfter(new Date(date), subDate(new Date(), { weeks: 1 }))
        )
      )
    case RANGE._14d:
      return data.filter((row) =>
        filterDate(row, (date) =>
          isAfter(new Date(date), subDate(new Date(), { weeks: 2 }))
        )
      )
    case RANGE._30d:
      return data.filter((row) =>
        filterDate(row, (date) =>
          isAfter(new Date(date), subDate(new Date(), { months: 1 }))
        )
      )
    case RANGE._90d:
      return data.filter((row) =>
        filterDate(row, (date) =>
          isAfter(new Date(date), subDate(new Date(), { months: 3 }))
        )
      )
    case RANGE._180d:
      return data.filter((row) =>
        filterDate(row, (date) =>
          isAfter(new Date(date), subDate(new Date(), { months: 6 }))
        )
      )
    case RANGE._1y:
      return data.filter((row) =>
        filterDate(row, (date) =>
          isAfter(new Date(date), subDate(new Date(), { years: 1 }))
        )
      )
    case RANGE._max:
      return data
    default:
      return data
  }
}

export function formatPoolTypesToOptions(data): Option[] {
  try {
    return data.map((row) => ({
      label: toStartCase(row),
      value: row
    }))
  } catch (e) {
    console.error(e)
    return []
  }
}

export function getSummedIncomeKey(data) {
  return data.map((row) => row.asset).join('_')
}

export function parseSummedIncomeChart(data) {
  try {
    const keys: string[] = []
    const usd: any[] = []
    const eth: any[] = []

    data.forEach((row) => {
      keys.push(formatSushiswapKey(formatDaoKey(row.asset)))

      row.incomeUsdHistoricalDaily.forEach((inRow, i) => {
        usd[i] = {
          ...usd[i],
          date:
            usd[i]?.date || parseDate(inRow.date, API_DATE_FORMAT_SECONDARY),
          [formatSushiswapKey(formatDaoKey(row.asset))]: inRow.value
        }
      })

      row.incomeEthHistoricalDaily.forEach((inRow, i) => {
        eth[i] = {
          ...eth[i],
          date:
            eth[i]?.date || parseDate(inRow.date, API_DATE_FORMAT_SECONDARY),
          [formatSushiswapKey(formatDaoKey(row.asset))]: inRow.value
        }
      })
    })

    return {
      keys,
      usd: reverse(usd),
      eth: reverse(eth)
    }
  } catch (e) {
    return {
      keys: [],
      usd: [],
      eth: []
    }
  }
}

export function isAssetStrategy(asset: any): boolean {
  return asset.type === 'productive_asset' && asset.customAprYearly > 0
}

export function allPoolsToOptions(pools: any[]): Option[] {
  return pools.map((pool) => ({
    label: [pool.farm, pool.asset].filter(Boolean).join(': '),
    value: pool.poolId
  }))
}
