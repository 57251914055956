import { getChains } from 'services/api/chains'
import { ChainsResponse } from 'services/types/chains'
import useSWR, { cache } from 'swr'

import { formatChainsKey } from '../utils/chains'

interface UseChains {
  isLoading: boolean
  chains: ChainsResponse | {}
  chainsKeys: string[]
}

const key = '/get_all_blockchain_30d_tvl_graph'

export default function useChains(): UseChains {
  const { data, error } = useSWR(key, getChains, {
    revalidateOnMount: !cache.has(key),
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  })

  const isLoading = !data && !error
  const chains = data || {}
  return {
    isLoading,
    chains,
    chainsKeys: formatChainsKey(chains)
  }
}
