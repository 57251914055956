import { ICON_DOMAIN } from '../config'

export function getRemoteAssetIcon(row: any) {
  return (
    row.imageUrl ||
    row.image ||
    getRemoteIcon('asset', row.asset || row.assetId)
  )
}

export function getRemoteFarmIcon(row: any) {
  return row.farmIconUrl || getRemoteIcon('farm', row.farmId || row.farm)
}

export function getRemoteIcon(type: 'farm' | 'asset', id: string) {
  return `${ICON_DOMAIN}/${type}/${id}`
}
