import makeStyles from '@material-ui/core/styles/makeStyles'
import { PropsWithChildren } from 'react'

const useStyles = makeStyles({
  list: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    fontSize: '0.875rem'
  },
  listItem: {
    padding: '0.25rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    marginBottom: '0.75rem',
    background: 'linear-gradient(111.6deg, #39406A -2.51%, #343A60 104.46%)',
    borderRadius: 15,

    '&:last-child': {
      marginBottom: 0
    }
  },
  text: {
    padding: '0 0.5rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&:first-child': {
      paddingLeft: '1.25rem',
      fontWeight: 700
    },

    '&:last-child': {
      paddingRight: '1.25rem'
    }
  }
})

export default function InfoList({ children }: PropsWithChildren<any>) {
  const classes = useStyles()
  return <ul className={classes.list}>{children}</ul>
}

export function InfoListItem({ value, title }) {
  const classes = useStyles()
  return (
    <li className={classes.listItem}>
      <p className={classes.text}>{title}:</p>
      <p className={classes.text}>{value}</p>
    </li>
  )
}
