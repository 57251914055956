import { Option } from 'types/components/form'

import Select, { SelectProps } from './Select'

const OPTIONS: Option[] = [
  { label: 'Olympus Pro', value: 'Olympus Pro' },
  { label: 'Bonding', value: 'Own Bonding' },
  { label: 'Revenue Agreements', value: 'Revenue Share Agreements' },
  { label: 'Votium', value: 'Bribes' }
]

interface BondCategoriesSelectProps
  extends Pick<SelectProps, 'variant' | 'label' | 'tooltip'> {
  value: string
  onChange: (e: string) => void
  className?: string
}

export default function BondCategoriesSelect(props: BondCategoriesSelectProps) {
  return (
    <Select
      clearable={false}
      searchable={false}
      options={OPTIONS}
      value={props.value}
      onChange={(e) => props.onChange((e?.value || '') as string)}
      className={props.className}
      variant={props.variant}
      label={props.label}
      tooltip={props.tooltip}
    />
  )
}
