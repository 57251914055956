export const TOOLTIP = {
  PIE_ASSET:
    'The chart illustrates assets held by the treasury and the distribution over different farms and blockchains.',
  PIE_BLOCKCHAIN:
    'The chart illustrates the distribution of the treasury assets over different blockchains.',
  PIE_RISK:
    'The chart illustrates the risk distribution over low, medium, high-risk treasury deployment strategies.',
  PIE_YIELD_TYPE:
    'The chart illustrates the distribution over different yield-farming strategies',
  AREA_VALUE_TREASURY_ASSETS:
    'Value of Treasury Assets, is the sum of the value of all assets held by the treasury.',
  TABLE_PRODUCTIVE_ASSETS:
    'Productive assets refer to the assets that can generate interest or reward.The chart shows the productive assets held by the treasury and other critical information such as the deployed farms, blockchains, expected APR, holding amount and the estimated monthly earning.',
  TABLE_CLAIMABLE:
    'Claimable assets refer to the reward assets generated by the productive assets.The chart shows different claimable assets, the respective farms, blockchains, value holdings and the monthly earnings.',
  TABLE_WALLET:
    'The chart shows assets which are sitting in the treasury wallet and also shows other information such as the holding amount and the blockchain.',
  TABLE_COLLATERAL:
    'The chart shows assets which are used as collaterals in the lending protocols.',
  TABLE_DEBT:
    'The chart shows the liabilities(borrowed assets) of the treasury.',
  TABLE_NFT:
    'NFT refers to non-fungible token.The chart shows NFTs held by the treasury.',
  LINE_ASSET_APR:
    'Annual Percentage Yield, is the normalized representation of an interest rate, based on a compounding period over one year.    The chart shows the APY of the productive assets that the treasury holds.',
  LINE_SINGLE_ASSET_APR:
    'Annual Percentage Yield, is the normalized representation of an interest rate, based on a compounding period over one year.The chart shows the apy trend of the selected productive asset of OlympusDAO.',
  AREA_HOLDINGS:
    'The chart shows the market value of the treasury’s top holdings.',
  AREA_SINGLE_HOLDINGS:
    'Market value of the holdings of the selected strategy over time.',
  BAR_DAILY_EARNINGS:
    'Accumulated daily earnings by holding productive assets in the treasury',
  LINE_DAILY_VOLUME:
    'Proteus is a liquidity incentives program proposed in OIP-45. The goals of this program are to rapidly bootstrap liquidity on new chains and simulate Olympus’ performance on each one.Total daily volume refers to the sum up of the transaction volume of each pair.',
  EXCHANGE_NET_ROI:
    'This show the generated APR minus the Impermanent Loss of the last 30d.',
  EXCHANGE_EQ_APR:
    'This show how much APR (minus Impermanent Loss) the Pool generates on a yearly base. This number is calculated from the Fee + IL data from the last 30d.',
  EXCHANGE_DEV_A_B:
    'This gives an idea on how volatile the price of the two tokens is against each other, hence a pointer to expected Impermanent Loss.',
  EXCHANGE_DEV_LP_USD:
    "This gives an idea on how volatile the Principal (i.e. LP-Token is). If it's a stable LP (e.g. USDC-DAI) there is low standard deviation. This in particular is intersting for traders who want to profit from APR, but and want their principal to stay stable.",
  EXCHANGE_LP_PRICE: 'This shows the price of the LP token.',
  EXCHANGE_NET_PRINCIPAL_ROI:
    'This shows the price of the LP token against USD in the last 30d. In essence how much you have made by entering your long-position in for example ETH-wBTC - Sushiswap LP. This also accounts the accrued APR and the impermanent loss.',
  EXCHANGE_LP_VS_HODL:
    'This number shows how much higher the return was through depositing the tokens in the pool and providing liquidity vs. if you were just buying 50% of each of the tokens and holding them.',
  LINE_EXCHANGE_LP_HOLD:
    'This compares the profits and losses of liquidity providing vs. holding the tokens in your wallet (farming rewards excluded).\nThe USD Value in this line is just assuming that we are owning 1% of the total liquidity of that pool from day 1. So we can compare the HODL vs LP over time.',
  ROI: 'ROI = Fees - IL',
  DAILY_APR_ANNUALIZED:
    'How much fees are generated (without IL) for LPs - Annualized.'
}
