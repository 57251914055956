import { Grid, makeStyles } from '@material-ui/core'
import InfoCard from 'components/cards/InfoCard'
import usePool from 'hooks/usePool'

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr'
  },
  cards: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '1.25rem',
    marginBottom: '2rem'
  },
  secondRowCards: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '1.25rem'
  }
})

export default function DashboardCards({ pool }) {
  const classes = useStyles()

  const { onUpdate } = usePool({ poolId: pool.poolId })

  const handleEdit = (name, newText, onSuccess) => {
    onUpdate(pool.poolId, { [name]: newText }, pool.weights, onSuccess)
  }

  const cards = [
    {
      title: 'About',
      text: 'Test text',
      name: 'About',
      website: 'https://www.multifarm.fi/',
      vault: '0x2eee666ddd777'
    },
    {
      title: 'Strategies',
      text: 'Test text 2',
      name: 'Strategies',
      website: 'https://www.test.fi/',
      vault: '0x2eee666ddd777'
    },
    {
      title: 'Risk description',
      text: 'Test text 3',
      name: 'description',
      website: 'https://www.test.com/',
      vault: '0x2eee666ddd777'
    },
    {
      title: 'How to use',
      text: 'Test text 3',
      name: 'description',
      website: 'https://www.test.com/',
      vault: '0x2eee666ddd777'
    }
  ]

  const secondRowCards = [
    {
      title: 'BUSD',
      text: 'Test text',
      name: 'About',
      website: 'https://www.multifarm.fi/',
      vault: '0x2eee666ddd777'
    },
    {
      title: 'USDT',
      text: 'Test text 2',
      name: 'Strategies',
      website: 'https://www.test.fi/',
      vault: '0x2eee666ddd777'
    },
    {
      title: 'USDC',
      text: 'Test text 3',
      name: 'description',
      website: 'https://www.test.com/',
      vault: '0x2eee666ddd777'
    }
  ]
  return (
    <Grid container className={classes.container}>
      <Grid container className={classes.cards}>
        {cards.map((card) => (
          <InfoCard
            onEdit={handleEdit}
            {...card}
            poolId={pool.poolId}
            key={card.title}
          />
        ))}
      </Grid>
      <Grid container className={classes.secondRowCards}>
        {secondRowCards.map((card) => (
          <InfoCard
            onEdit={handleEdit}
            {...card}
            poolId={pool.poolId}
            key={card.title}
          />
        ))}
      </Grid>
    </Grid>
  )
}
