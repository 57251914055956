import { formatWKey } from './charts'
import { API_DATE_FORMAT_SECONDARY, parseDate } from './date'
import { reverse } from './obj'

function getKey(row) {
  return `${row['exchangeName']}-${formatWKey(row['asset'])}`
}

export function getExchangesKey(data) {
  return data.map(getKey).join('_')
}

export function parseExchangesChart(data) {
  try {
    const usd: any[] = []
    const eth: any[] = []
    const keys: string[] = []

    data.forEach((row) => {
      keys.push(getKey(row))

      row.dailyVolumeUSDHistorical.forEach((subRow, i) => {
        usd[i] = {
          ...usd[i],
          total: (usd[i]?.total || 0) + (Number(subRow.value) || 0),
          date:
            usd[i]?.date || parseDate(subRow.date, API_DATE_FORMAT_SECONDARY),
          [getKey(row)]: Number(subRow.value)
        }
      })
    })

    return {
      usd: reverse(usd),
      eth: reverse(eth),
      keys
    }
  } catch (e) {
    console.error(e)
    return {
      usd: [],
      eth: [],
      keys: []
    }
  }
}

export function getPartnersKey(keys) {
  return keys.join('-')
}

export function parsePartnersPieChart(data, colors, values) {
  try {
    const keys: string[] = Object.keys(data)
    const chart: any[] = []

    keys.forEach((key, index) => {
      chart.push({
        key,
        value: data[key],
        tvl: values[key],
        color: colors[index]
      })
    })

    return {
      keys,
      chart
    }
  } catch (e) {
    console.error(e)
    return {
      keys: [],
      chart: []
    }
  }
}
