import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import LineChart from 'components/charts/LineChart'
import Tabs from 'components/tabs/Tabs'
import useChains from 'hooks/useChains'
import React, { useState } from 'react'
import { Chain } from 'services/types/globals'
import { parseMoney } from 'utils/api'
import {
  formatChartDate,
  formatChartMoney,
  renderTooltipOptions
} from 'utils/charts'
import { convertChartDate } from 'utils/date'
import { reverse } from 'utils/obj'
import { device } from 'utils/screen'

import TopVaultsTable from './TopVaultsTable'

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(111.6deg, #303659 -2.51%, #292E4D 104.46%)',
    padding: '1.25rem 1.25rem 1.25rem 1.25rem',
    borderRadius: 15,
    marginBottom: '2rem'
  },
  titleContainer: {
    padding: '0 0 1.25rem 0'
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,

    [`@media ${device.tablet}`]: {
      fontSize: '0.75rem'
    }
  },
  legends: {
    padding: '0.25rem 0 0 1.25rem'
  },
  legend: {
    fontSize: '1rem',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',

    '& span': {
      display: 'flex',
      width: 60,
      height: 2,
      borderRadius: 1,
      backgroundColor: '#24A2E9',
      margin: '0 1rem'
    },

    [`@media ${device.tablet}`]: {
      fontSize: '0.75rem'
    }
  },
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',

    [`@media ${device.tablet}`]: {
      flexDirection: 'column',
      alignItems: 'flex-end'
    }
  },
  tvlText: {
    whiteSpace: 'nowrap',
    margin: '0 0.75rem',

    [`@media ${device.tablet}`]: {
      marginBottom: '0.75rem'
    }
  },
  topVaults: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '1.25rem',

    [`@media ${device.tablet}`]: {
      display: 'flex',
      flexDirection: 'column'
    }
  }
})

interface ChartProps {
  blockchain: Chain[]
}

const TAB = {
  _TVL: 'TVL',
  _VAULTS: 'Vaults'
}

const TITLE = {
  [TAB._TVL]: 'TVL per Blockchain',
  [TAB._VAULTS]: 'Top Vaults'
}

const TABS = [
  {
    name: 'TVL',
    value: TAB._TVL
  },
  {
    name: 'Vaults',
    value: TAB._VAULTS
  }
]

export default function Chart({ blockchain }: ChartProps) {
  const classes = useStyles()
  const { chains, isLoading } = useChains()

  const [tab, setTab] = useState(TAB._TVL)

  const key = blockchain[0] || 'ALL'

  const data = reverse(chains[key] || [])

  const tvl = data[data.length - 1]?.value

  return (
    <Grid
      container
      direction="column"
      className={classes.root}
      key={key}
      wrap="nowrap"
    >
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        className={classes.titleContainer}
      >
        <Typography className={classes.title}>{TITLE[tab]}</Typography>

        <Grid className={classes.tabsContainer}>
          {tab === TAB._TVL && (
            <Typography className={classNames(classes.title, classes.tvlText)}>
              TVL {key}: {parseMoney(tvl)}
            </Typography>
          )}

          <Tabs width="auto" tabs={TABS} value={tab} onChange={setTab} />
        </Grid>
      </Grid>

      {tab === TAB._TVL ? (
        <>
          <LineChart
            id="farms-chart"
            data={data}
            memo={data.length.toFixed()}
            dataKey={['value']}
            colors={['#24A2E9']}
            legends={[key]}
            loading={isLoading}
            yTickFormatter={(tick) => formatChartMoney(tick)}
            xTickFormatter={(date) =>
              date && date !== 'auto'
                ? formatChartDate(convertChartDate(date))
                : 'N/A'
            }
            tooltipOptions={renderTooltipOptions(
              ['value'],
              false,
              ['#24A2E9'],
              (e) => parseMoney(e, 2, true),
              undefined,
              convertChartDate
            )}
          />
        </>
      ) : (
        <Grid container className={classes.topVaults}>
          <Grid container direction="column">
            <Typography className={classes.title}>
              Top Stablecoin (Single)
            </Typography>

            <TopVaultsTable
              filters={{
                coin_group: 'STABLECOINS',
                sort: 'aprYearly',
                sort_order: 'desc'
              }}
            />
          </Grid>

          <Grid container direction="column">
            <Typography className={classes.title}>
              Top Stablecoin (Pair)
            </Typography>

            <TopVaultsTable
              filters={{
                coin_groups: ['STABLECOINS'],
                sort: 'aprYearly',
                sort_order: 'desc'
              }}
            />
          </Grid>

          <Grid container direction="column">
            <Typography className={classes.title}>Top Quality Coins</Typography>

            <TopVaultsTable
              filters={{
                coin_groups: ['LAYER1_COINS_GROUP'],
                sort: 'aprYearly',
                sort_order: 'desc'
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
