export const COLORS = [
  '#F4BE37',
  '#FF9F40',
  '#D733FF',
  '#66C8FF',
  '#7DFF5A',
  '#FF5A5A',
  '#f72585',
  '#90e0ef',
  '#06d6a0',
  '#b5e48c',
  '#38b000',
  '#fee440'
]

export const GRADIENTS = {
  [COLORS[0]]:
    'linear-gradient(180deg, rgba(244,190,55,1) 0%, rgba(255,255,255,1) 100%)'
}

export function getRandomColor(colors = COLORS) {
  return colors[Math.floor(Math.random() * colors.length)]
}

export function getRandomColors(count) {
  const colorsArr: string[] = []

  while (colorsArr.length < count) {
    const color = getRandomColor()

    if (!colorsArr.includes(color) || colorsArr.length >= COLORS.length) {
      colorsArr.push(color)
    }
  }

  return colorsArr
}
