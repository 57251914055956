import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  AssetsIcon,
  CryptoIcon,
  FarmsIcon,
  LogoIcon,
  PremiumIcon,
  UserIcon

} from 'assets/icons'
import Button from 'components/buttons/Button'
import CheckoutSuccessDialog from 'components/dialogs/CheckoutSuccessDialog'
import CreatePasswordDialog from 'components/dialogs/CreatePasswordDialog'
import LoginDialog from 'components/dialogs/LoginDialog'
import ResetPasswordDialog from 'components/dialogs/ResetPasswordDialog'
import { Link } from 'components/typography'
import { PREMIUM_NO_ACCESS } from 'config'
import useMediaQueries from 'hooks/useMediaQueries'
import { useAuth } from 'providers/AuthProvider'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { parseURL } from 'utils/query'
import { device } from 'utils/screen'

import { useDialogsProvider } from '../providers/DialogsProvider'
import LoginAlertDialog from './dialogs/LoginAlertDialog'
import OrderSuccessDialog from './dialogs/OrderSuccessDialog'
import RegisterSuccessDialog from './dialogs/RegisterSuccessDialog'
import ScheduleDemoDialog from './dialogs/ScheduleDemoDialog'
import SignUpDialog from './dialogs/SignUpDialog'
import SignUpPlanDialog from './dialogs/SignUpPlanDialog'
import Hamburger from './header/Hamburger'
import MenuLink from './header/MenuLink'
import Tab from './header/Tab'

type NavItem = {
  title: string
  icon: any
  href?: string
  deps: string[]
  native?: boolean
  onClick?: () => void
  exclude?: string[]
}

const useStyles = makeStyles({
  btn: {
    [`@media ${device.tablet}`]: {
      height: 30,
      fontSize: '0.875rem',
      borderRadius: 10
    }
  },
  accountLink: {
    color: '#fafafa',
    height: 24,
    display: 'flex',
    alignItems: 'center'
  },
  menu: {
    position: 'fixed',
    zIndex: 99,
    top: 64,
    backgroundColor: 'rgba(27, 31, 51, 0.95)',
    borderBottom: '2px solid #41486e'
  },
  headerWrapper: {
    width: '100%',
    position: 'sticky',
    top: 0,
    zIndex: 99,
    boxSizing: 'border-box',
    color: 'rgb(228, 236, 255)',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    padding: '0 2.5rem',
    background: 'linear-gradient(111.6deg, #303659 -2.51%, #292e4d 104.46%)',
    borderBottom: '2px solid #41486e',

    [`@media ${device.laptop}`]: {
      minHeight: 64,
      padding: '0 1.25rem'
    },

    '&[data-active="true"]': {
      background: 'rgba(27, 31, 51, 0.95)',
      borderColor: 'transparent'
    }
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center'
  },
  headerTabs: {
    width: 'fit-content'
  },
  logo: {
    width: 108
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 40,

    [`@media ${device.tablet}`]: {
      marginRight: 10
    }
  },
  tabs: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: 'fit-content'
  }
})

export default function Header() {
  const { isLaptop } = useMediaQueries()
  const [open, setOpen] = useState(false)
  const [loginAlertOpen, setLoginAlertOpen] = useState(false)
  const [signUpPlanDialogOpen, setSignUpPlanDialogOpen] = useState(false)
  const [signUpDialogOpen, setSignUpDialogOpen] = useState(false)
  const [resetDialog, setResetDialog] = useState(false)
  const [newPasswordDialog, setNewPasswordDialog] = useState(false)
  const [checkoutSuccessDialog, setCheckoutSuccessDialog] = useState(false)
  const [registerSuccessDialog, setRegisterSuccessDialog] = useState(false)
  const [orderSuccessDialog, setOrderSuccessDialog] = useState(false)
  const [scheduleDemoDialogOpen, setScheduleDemoDialogOpen] = useState(false)

  const dialogsProvider = useDialogsProvider()

  const location = useLocation()
  const history = useHistory()
  const classes = useStyles()

  const auth = useAuth()

  const NAV = useMemo(() => {
    const nav: NavItem[] = [
      {
        title: 'Farms',
        href: '/farms',
        icon: <FarmsIcon />,
        deps: ['farms'],
        exclude: ['premium']
      },
      {
        title: 'Assets',
        href: '/assets',
        icon: <AssetsIcon />,
        deps: ['assets'],
        exclude: ['premium']
      },
      {
        title: 'Bridges',
        href: '/bridges',
        icon: <CryptoIcon />,
        deps: ['bridges']
      }
    ]
    if (!PREMIUM_NO_ACCESS || auth.isLogged) {
      nav.push({
        title: 'Premium',
        href: !auth.isLogged ? undefined : '/premium',
        icon: <PremiumIcon />,
        deps: ['premium'],
        native: !auth.isLogged,
        onClick: !auth.isLogged
          ? () => setSignUpPlanDialogOpen(true)
          : undefined
      })
    }
    return nav
  }, [auth.isLogged])

  const query = parseURL(location.search).query

  useEffect(() => {
    if (query.pt) {
      setNewPasswordDialog(true)
    }
    if (query.cs) {
      setCheckoutSuccessDialog(true)
      history.replace(location.pathname)
    }
    if (query.rs) {
      setRegisterSuccessDialog(true)
      history.replace(location.pathname)
    }
    if (query.os) {
      setOrderSuccessDialog(true)
      history.replace(location.pathname)
    }
  }, [query.pt, query.cs, query.rs, query.os])

  const handleLoginClick = () => {
    PREMIUM_NO_ACCESS
      ? dialogsProvider.onLogin(true)
      : setSignUpPlanDialogOpen(true)
  }

  return (
    <>
      <Grid container className={classes.headerWrapper}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.header}
          data-active={open}
        >
          <Grid container alignItems="center" className={classes.headerTabs}>
            <Link to="/farms" className={classes.logoContainer}>
              <LogoIcon className={classes.logo} />
            </Link>

            {!isLaptop && (
              <Grid className={classes.tabs}>
                {NAV.map((row, index) => (
                  <Tab {...row} key={index} />
                ))}
              </Grid>
            )}
          </Grid>

          <Grid className={classes.headerActions}>
            {auth.isLogged ? (
              <Link to="/account" className={classes.accountLink}>
                <UserIcon />
              </Link>
            ) : (
              <Button
                size="sm"
                variant="contained-2"
                onClick={handleLoginClick}
                className={classes.btn}
              >
                Pro Log In
              </Button>
            )}

            {isLaptop && (
              <Hamburger active={open} onClick={() => setOpen(!open)} />
            )}
          </Grid>
        </Grid>
      </Grid>

      {isLaptop && open && (
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.menu}
        >
          {NAV.map((row, index) => (
            <MenuLink
              {...row}
              onClick={() => {
                setOpen(false)
                row.onClick?.()
              }}
              key={index}
            />
          ))}
        </Grid>
      )}

      <LoginAlertDialog
        open={loginAlertOpen}
        onClose={() => setLoginAlertOpen(false)}
      />
      <SignUpPlanDialog
        open={signUpPlanDialogOpen}
        onClose={() => setSignUpPlanDialogOpen(false)}
        onLogin={() => dialogsProvider.onLogin(true)}
        onSignUp={() => setSignUpDialogOpen(true)}
        onGetDemo={() => setScheduleDemoDialogOpen(true)}
      />
      <LoginDialog
        open={dialogsProvider.login}
        onClose={() => dialogsProvider.onLogin(false)}
        onSuccess={() => {
          dialogsProvider.onLogin(false)
          setSignUpPlanDialogOpen(false)
        }}
        onForgot={() => {
          dialogsProvider.onLogin(false)
          setResetDialog(true)
        }}
        onSignUp={() => {
          dialogsProvider.onLogin(false)
          setSignUpDialogOpen(true)
        }}
      />
      <SignUpDialog
        open={signUpDialogOpen}
        onClose={() => setSignUpDialogOpen(false)}
        onSuccess={() => {
          setSignUpDialogOpen(false)
          setSignUpPlanDialogOpen(false)
        }}
      />
      <ScheduleDemoDialog
        open={scheduleDemoDialogOpen}
        onClose={() => setScheduleDemoDialogOpen(false)}
        onSuccess={() => {
          setScheduleDemoDialogOpen(false)
          setSignUpPlanDialogOpen(false)
        }}
      />
      <ResetPasswordDialog
        open={resetDialog}
        onClose={() => setResetDialog(false)}
      />
      <CreatePasswordDialog
        open={newPasswordDialog}
        onClose={() => setNewPasswordDialog(false)}
        onLogin={() => dialogsProvider.onLogin(true)}
      />
      <CheckoutSuccessDialog
        open={checkoutSuccessDialog}
        onClose={() => setCheckoutSuccessDialog(false)}
      />
      <RegisterSuccessDialog
        open={registerSuccessDialog}
        onClose={() => setRegisterSuccessDialog(false)}
      />
      <OrderSuccessDialog
        open={orderSuccessDialog}
        onClose={() => setOrderSuccessDialog(false)}
      />
    </>
  )
}
