import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { parseMoney } from 'utils/api'
import { convertChartDate, DATE_CHART_FORMAT, format } from 'utils/date'
import { device } from 'utils/screen'

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(111.6deg, #303659 -2.51%, #292E4D 104.46%)',
    padding: '1.25rem 2rem 1.25rem 1rem',
    borderRadius: 15,
    marginBottom: '2rem'
  },
  chart: {
    height: 300
  },
  titleContainer: {
    padding: '0 0 1.25rem 1.25rem',
    display: 'flex',
    columnGap: 100,

    '& span': {
      marginLeft: '0.25rem'
    },

    [`@media ${device.tablet}`]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,

    '& span': {
      fontWeight: 400
    },

    [`@media ${device.tablet}`]: {
      fontSize: '0.75rem',
      marginBottom: '0.25rem'
    }
  },
  tooltip: {
    background: 'linear-gradient(107.39deg, #474F7E 3.48%, #343B61 97.52%)',
    padding: '0.5rem 0.75rem',
    borderRadius: 10,

    '& p': {
      fontSize: '0.75rem',
      display: 'flex',
      alignItems: 'center',

      '& span[data-color]': {
        width: 10,
        height: 10,
        borderRadius: 9999,
        marginRight: '0.25rem'
      },

      '& span[data-footer]': {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0.75rem'
      }
    }
  }
})

interface FarmChartProps {
  data: any[]
  chartData: any
  tooltipOptions?: any[]
}

export default function FarmChart({
  data,
  chartData,
  tooltipOptions
}: FarmChartProps) {
  const classes = useStyles()
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="flex-start"
        className={classes.titleContainer}
      >
        <Typography className={classes.title}>
          TVL:
          <span>
            {chartData.tvlStaked} ({chartData.tvlChange24h})
          </span>
        </Typography>
        <Typography className={classes.title}>
          Blockchain:<span>{chartData.blockchain}</span>
        </Typography>
      </Grid>

      <Grid container className={classes.chart}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={data} margin={{ left: -10 }}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#DC4DFF" stopOpacity={0.1} />
                <stop offset="100%" stopColor="#4DBFFF" stopOpacity={0.3} />
              </linearGradient>
            </defs>

            <YAxis
              width={100}
              padding={{ top: 0, bottom: 0 }}
              fontSize="0.625rem"
              domain={['auto', 'auto']}
              tickFormatter={(tick) => parseMoney(tick, 2, true)}
              tick={{
                fill: '#B2BDFF'
              }}
              axisLine={false}
              tickLine={false}
              stroke="0"
            />
            <XAxis
              dataKey="date"
              tickFormatter={(date) =>
                date && date !== 'auto'
                  ? format(convertChartDate(date), DATE_CHART_FORMAT)
                  : ''
              }
              fontSize="0.625rem"
              tickMargin={10}
              axisLine={false}
              tickLine={false}
              stroke="0"
              tick={{
                fill: '#B2BDFF'
              }}
            />
            <Area
              type="monotoneX"
              unit="M"
              dataKey="value"
              strokeLinecap="round"
              strokeWidth={2}
              lightingColor="#000"
              stroke="#DC4DFF"
              fillOpacity={1}
              fill="url(#colorUv)"
              dot={false}
              legendType="none"
            />
            {tooltipOptions && tooltipOptions.length && (
              <Tooltip
                content={(props) => (
                  <Grid className={classes.tooltip}>
                    {tooltipOptions.map((row, index) => (
                      <Typography key={index}>
                        {row(props.payload?.[0]?.payload || {})}
                      </Typography>
                    ))}
                  </Grid>
                )}
              />
            )}
            <CartesianGrid
              stroke="#6B6BB2"
              strokeOpacity="0.4"
              horizontalPoints={[]}
            />
          </AreaChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  )
}
