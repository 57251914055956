import { useMemo } from 'react'
import { fetcher } from 'services/api/common'
import useSWR from 'swr'
import {
  getBlockchainDistributionKey,
  parseBlockchainDistribution
} from 'utils/pools'

interface UseBlockchainDistributions {
  isLoading: boolean
  blockchainDistributions: any
  chartData: any[]
}

export default function useBlockchainDistributions(): UseBlockchainDistributions {
  const { data, error } = useSWR('/auth/get_blockchain_distributions', fetcher)

  const isLoading = !data && !error
  const blockchainDistributions = data || {}

  const key = getBlockchainDistributionKey(blockchainDistributions)

  const chartData = useMemo(
    () => parseBlockchainDistribution(blockchainDistributions),
    [key]
  )

  return {
    isLoading,
    blockchainDistributions,
    chartData
  }
}
