import { useAuth } from 'providers/AuthProvider'
import { useSpinner } from 'providers/SpinnerProvider'
import { toast } from 'react-toastify'
import { login } from 'services/api/auth'

type OnLogin = (data: any, onSuccess?: any) => void

interface UseAuthAccount {
  onLogin: OnLogin
}

export default function useAuthAccount(): UseAuthAccount {
  const auth = useAuth()
  const spinner = useSpinner()

  const onLogin = async (data, onSuccess) => {
    try {
      spinner.open()
      const response = await login(data)
      auth.onLogin(response.access_token)
      spinner.close()
      toast.success('Login Succeeded!')
      onSuccess?.()
    } catch (e: any) {
      spinner.close()
      toast.error(e?.response?.data || 'Something went wrong')
      console.error(e)
    }
  }

  return {
    onLogin
  }
}
