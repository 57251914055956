import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { PropsWithChildren } from 'react'

const useStyles = makeStyles({
  root: {
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    marginBottom: (props: any) => (props.spacing ? '1rem' : 0),
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    height: 'fit-content'
  }
})

interface TitleProps {
  spacing?: boolean
}

export default function Title({
  children,
  spacing = true
}: PropsWithChildren<TitleProps>) {
  const classes = useStyles({ spacing })
  return <Typography className={classes.root}>{children}</Typography>
}
