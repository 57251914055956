import Table from 'components/table/Table'
import { TableCol } from 'types/components/table'
import { formatBlockchain, formatPartner, parseMoney } from 'utils/api'

import useIncomePerPartner from '../../hooks/useIncomePerPartner'
import { useSpinner } from '../../providers/SpinnerProvider'
import TableCell from '../table/TableCell'
import TableRow from '../table/TableRow'

const COLS: TableCol[] = [
  { title: 'DEX name', key: 'dex' },
  { title: 'Total gOHM Liquidity (USD)', key: 'totalGohm' },
  { title: 'Total Trading Volume (24h)', key: 'totalTrading' },
  { title: 'Blockchain', key: 'blockchain' }
]

const Renderer = {
  dex: (row) => formatPartner(row['dex_id'] || '') || 'N/A',
  totalGohm: (row) => parseMoney(row['liquidity_sum'], 2, true),
  totalTrading: (row) => parseMoney(row['daily_volume_sum'], 2, true),
  blockchain: (row) => formatBlockchain(row['blockchain'])
}

export default function IncomesPerPartnerTable() {
  const incomePerPartner = useIncomePerPartner()

  useSpinner(incomePerPartner.isLoading)

  return (
    <Table
      cols={COLS}
      loading={incomePerPartner.isLoading}
      empty={!incomePerPartner.incomes.length}
    >
      {incomePerPartner.incomes.map((row, index) => (
        <TableRow key={index}>
          {COLS.map((col) => (
            <TableCell key={col.key} align={col.align}>
              {Renderer[col.key as string](row)}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </Table>
  )
}
