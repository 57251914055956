import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { InfoIcon } from 'assets/icons'
import Card from 'components/cards/Card'
import TooltipDropdown from 'components/TooltipDropdown'
import { parsePercent } from 'utils/api'
import { device } from 'utils/screen'

import Title from './Title'

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',

    [`@media ${device.laptopL}`]: {
      gridTemplateColumns: '1fr'
    }
  },
  text: {
    fontSize: '0.75rem',
    fontWeight: 400,
    marginBottom: '0.75rem',

    '& span': {
      fontWeight: 700
    }
  },
  tooltipIcon: {
    width: 14,
    height: 14,
    margin: '0 0.5rem'
  }
})

interface AprProps {
  asset: any
}

export default function Apr({ asset }: AprProps) {
  const classes = useStyles()
  const aprSplit = asset.aprSplit ?? {}
  return (
    <Card direction="column">
      <Title>
        APR
        <TooltipDropdown content="">
          <InfoIcon className={classes.tooltipIcon} />
        </TooltipDropdown>
      </Title>
      <Typography className={classes.text}>
        <span>Daily</span>{' '}
        {parsePercent(aprSplit.totalAPRDaily ?? asset.aprDaily)}
      </Typography>
      <Typography className={classes.text}>
        <span>Weekly</span>{' '}
        {parsePercent(aprSplit.totalAPRWeekly ?? asset.aprWeekly)}
      </Typography>
      <Typography className={classes.text}>
        <span>Yearly</span>{' '}
        {parsePercent(aprSplit.totalAPRYearly ?? asset.aprYearly)}
      </Typography>
    </Card>
  )
}
