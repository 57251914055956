import makeStyles from '@material-ui/core/styles/makeStyles'
import ChartTooltip from 'components/tooltips/ChartTooltip'
import { ReactNode } from 'react'

interface LabelProps {
  tooltip?: string
  children: ReactNode
  component?: ReactNode
  error?: string
}

const useStyles = makeStyles({
  labelContainer: {
    marginBottom: '0.5rem',
    display: 'flex',
    alignItems: 'center'
  },
  label: (props: LabelProps) => ({
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    color: props.error ? '#ff5733' : '#fafafa'
  })
})

export default function Label(props: LabelProps) {
  const classes = useStyles(props)
  return (
    <div className={classes.labelContainer}>
      <label className={classes.label}>
        <span>{props.children}</span>
        {props.tooltip && <ChartTooltip content={props.tooltip} />}
      </label>
      {props.component}
    </div>
  )
}
