import { useState } from 'react'
import { fetcher } from 'services/api/common'
import useSWR from 'swr'
import { omitEmpty } from 'utils/obj'
import { stringifyURL } from 'utils/query'

import { OnPage } from '../components/table/TablePagination'

interface PoolsByFieldFilters {
  field: string
  value: string
  page: number
  total?: number
  per_page?: number
}

interface UsePoolsByField {
  isLoading: boolean
  pools: any[]
  maxPages: number
  revalidate: any
  filters: PoolsByFieldFilters
  onPage: OnPage
}

interface UsePoolsByFieldConfig {
  filters?: Partial<PoolsByFieldFilters>
}

function getKey(params) {
  return params.field
    ? stringifyURL('/auth/get_pools_by_field', omitEmpty(params))
    : null
}

export default function usePoolsByField(
  config: UsePoolsByFieldConfig = {}
): UsePoolsByField {
  const [filters, setFilters] = useState<PoolsByFieldFilters>({
    page: 1,
    field: '',
    value: ''
  })

  const params = {
    ...filters,
    ...config.filters
  }

  const {
    data,
    error,
    mutate: revalidate
  } = useSWR(() => getKey(params), fetcher)

  const onPage: OnPage = (page) => {
    setFilters((filters) => ({
      ...filters,
      page
    }))
  }

  const isLoading = params.field ? !data && !error : false
  const pools = data?.pools || []
  const maxPages = data?.max_pages || 1

  return {
    isLoading,
    pools,
    filters,
    maxPages,
    revalidate,
    onPage
  }
}
