import makeStyles from '@material-ui/core/styles/makeStyles'
import MuiTypography, { TypographyProps } from '@material-ui/core/Typography'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom'
import { device } from 'utils/screen'

const useLinkStyles = makeStyles({
  root: {
    color: (props: any) =>
      props.color === 'secondary' ? '#D733FF' : '#66C8FF',
    fontWeight: 700,
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline'
    }
  },
  wrapper: {
    color: 'unset',
    textDecoration: 'unset',

    '& button': {
      width: '100%'
    }
  }
})

interface LinkProps extends RouterLinkProps {
  native?: boolean
  color?: 'secondary'
  wrapper?: boolean
}

export function Link({
  className,
  color,
  wrapper,
  native,
  ...props
}: LinkProps) {
  const classes = useLinkStyles({ color })

  const rootClassName = wrapper ? classes.wrapper : classes.root

  if (native) {
    return (
      <a
        href={props.to as string}
        className={classNames(rootClassName, className)}
        {...props}
      />
    )
  }

  return (
    <RouterLink className={classNames(rootClassName, className)} {...props} />
  )
}

const useTypographyStyles = makeStyles({
  root: {
    fontFamily: 'Montserrat'
  }
})

export function Typography(props: TypographyProps) {
  const classes = useTypographyStyles()
  return (
    <MuiTypography
      {...props}
      className={classNames(classes.root, props.className)}
    />
  )
}

const usePageTitleStyles = makeStyles({
  root: {
    fontSize: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',

    [`@media ${device.tablet}`]: {
      fontSize: '1.25rem',
      marginBottom: '0.5rem'
    }
  }
})

interface PageTitleProps {
  className?: string
}

export function PageTitle({
  children,
  className
}: PropsWithChildren<PageTitleProps>) {
  const classes = usePageTitleStyles()
  return (
    <MuiTypography className={classNames(classes.root, className)}>
      {children}
    </MuiTypography>
  )
}
