import Markdown from 'components/markdown/Markdown'
import { useEffect, useState } from 'react'

import content from '../privacy.md'

export default function PrivacyPolicy() {
  const [md, setMd] = useState('')

  useEffect(() => {
    fetch(content)
      .then((res) => res.text())
      .then((res) => setMd(res))
  }, [])

  return <Markdown>{md}</Markdown>
}
