import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import BookButton from 'components/buttons/BookButton'
import ReportErrorDialog from 'components/dialogs/ReportErrorDialog'
import AssetsTable from 'components/tables/AssetsTable'
import useAssets from 'hooks/useAssets'
import useFarm from 'hooks/useFarm'
import useSavedSearches from 'hooks/useSavedSearches'
import { useAuth } from 'providers/AuthProvider'
import { useSpinner } from 'providers/SpinnerProvider'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { parseMoney } from 'utils/api'
import { renderTooltipOptions } from 'utils/charts'
import { getRandomColors } from 'utils/colors'
import { formatChartData, parseChartData } from 'utils/farms'
import { CURRENCY } from 'utils/filters'
import { reverse } from 'utils/obj'
import { device } from 'utils/screen'
import { getSearchesIds } from 'utils/searches'

import FarmChart from './farm/FarmChart'

const useStyles = makeStyles({
  titleContainer: {
    margin: '-1.25rem 0 1.25rem 0',

    [`@media ${device.tablet}`]: {
      margin: '-0.75rem 0 1rem 0',
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  title: {
    fontSize: '1.875rem',
    display: 'flex',
    alignItems: 'center',

    [`@media ${device.tablet}`]: {
      fontSize: '1.25rem',
      marginBottom: '0.5rem'
    }
  },
  book: {
    margin: '0 1rem'
  },
  error: {
    color: '#D733FF',
    fontWeight: 700,
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline'
    }
  }
})

export default function Farm() {
  const classes = useStyles()
  const { isLogged } = useAuth()
  const params = useParams<any>()
  const [reportDialog, setReportDialog] = useState(false)

  const { isLoading: isFarmLoading, farm } = useFarm({ farmId: params.farmId })

  const { assets, isLoading, filters, maxPages, onSort, onPage } = useAssets({
    filters: {
      farm_ids: [params.farmId],
      tvl_min: farm.tvlStaked > 10000000 ? 50000 : 0,
      farms_tvl_staked_gte: 0
    }
  })

  useSpinner(isLoading || isFarmLoading)

  const { savedSearches, onRemoveSearch, onSaveSearch } = useSavedSearches({
    save_type: 'farm'
  })

  const savedIds = getSearchesIds(savedSearches, 'farmId')

  if (isLoading) {
    return null
  }

  return (
    <>
      <Grid container direction="column">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.titleContainer}
        >
          <Typography className={classes.title}>
            {farm.farmName}
            {isLogged && (
              <BookButton
                booked={savedIds.includes(farm.farmId)}
                onBook={() => onSaveSearch(farm.farmId, 'farm')}
                onUnBook={() => onRemoveSearch(farm.farmId)}
                className={classes.book}
              />
            )}
          </Typography>

          <Typography
            className={classes.error}
            onClick={() => setReportDialog(true)}
          >
            Report an error
          </Typography>
        </Grid>

        <FarmChart
          data={reverse(parseChartData(farm.tvlStakedHistory) || [])}
          chartData={formatChartData(farm, assets)}
          tooltipOptions={renderTooltipOptions(
            [['value', 'TVL']],
            false,
            getRandomColors(1),
            (item) => parseMoney(item, 2, true, CURRENCY._USD)
          )}
        />

        <AssetsTable
          isLoading={isLoading}
          assets={assets}
          maxPages={maxPages}
          filters={filters ?? []}
          onPage={onPage}
          onSort={onSort}
        />
      </Grid>

      <ReportErrorDialog
        open={reportDialog}
        onClose={() => setReportDialog(false)}
        id={farm.farmId}
        title="Report an Error (For this farm)"
        titleColor="error"
        idKey="farmId"
      />
    </>
  )
}
