import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Button from 'components/buttons/Button'
import AddTriggerDrawer from 'components/drawers/AddTriggerDrawer'
import Table from 'components/table/Table'
import TableCell from 'components/table/TableCell'
import TableRow from 'components/table/TableRow'
import useTriggers from 'hooks/useTriggers'
import { useSpinner } from 'providers/SpinnerProvider'
import TriggersProvider from 'providers/TriggersProvider'
import { useState } from 'react'
import { TableCol } from 'types/components/table'
import { device } from 'utils/screen'

const useStyles = makeStyles({
  addBtn: {
    textTransform: 'uppercase',
    fontWeight: 700,
    cursor: 'pointer'
  },
  titleContainer: {
    marginBottom: '1.5rem'
  },
  title: {
    fontSize: '1.875rem',
    display: 'flex',
    alignItems: 'center',

    [`@media ${device.tablet}`]: {
      fontSize: '1.25rem',
      marginBottom: '0.5rem'
    }
  },
  action: {
    fontWeight: 700,
    textTransform: 'uppercase',
    color: '#D733FF',
    cursor: 'pointer',
    width: 'fit-content',

    '&:hover': {
      textDecoration: 'underline'
    }
  }
})

const COLS: TableCol[] = [
  { title: 'Name' },
  { title: 'Status' },
  { title: 'Alert' },
  { title: 'Application' },
  { title: '', align: 'right' }
]

export default function Triggers() {
  const classes = useStyles()
  const [add, setAdd] = useState(false)
  const { isLoading, triggers, revalidate } = useTriggers()

  useSpinner(isLoading)

  return (
    <TriggersProvider revalidate={revalidate}>
      <Grid container direction="column">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.titleContainer}
        >
          <Typography className={classes.title}>Triggers</Typography>

          <Button
            variant="contained-2"
            color="secondary"
            size="sm"
            onClick={() => setAdd(true)}
          >
            Add trigger
          </Button>
        </Grid>

        <Table cols={COLS} empty={!triggers.length} loading={isLoading}>
          {triggers.map((row) => (
            <TableRow key={row}>
              <TableCell>BTC</TableCell>
              <TableCell>ON</TableCell>
              <TableCell>Telegram</TableCell>
              <TableCell>All</TableCell>
              <TableCell align="right">
                <Typography className={classes.action}>Delete</Typography>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Grid>

      <AddTriggerDrawer open={add} onClose={() => setAdd(false)} />
    </TriggersProvider>
  )
}
