import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Button'
import AssetBlockchainSelect from 'components/form/AssetBlockchainSelect'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import useCustomAsset from 'hooks/useCustomAsset'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { Option } from 'types/components/form'

import ChartTooltip from '../tooltips/ChartTooltip'
import Dialog, { DialogBaseProps } from './Dialog'
import DialogActions from './DialogActions'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.25rem'
  }
})

const PRICE_FEED: Option[] = [
  { label: 'Covalent', value: 'Covalent' },
  { label: 'GeckoTerminal', value: 'GeckoTerminal' },
  { label: 'Coingecko', value: 'Coingecko' },
  { label: 'Add manual USD price', value: 'Manual' }
]

interface ContentProps extends DialogBaseProps {}

function Content(props: ContentProps) {
  const classes = useStyles()
  const customAsset = useCustomAsset()

  const form = useForm()

  const [priceFeed] = useWatch({ control: form.control, name: ['priceFeed'] })

  const handleSubmit = (values) => {
    customAsset.onAdd(values, props.onClose)
  }

  return (
    <>
      <DialogBody>
        <Grid
          container
          id="add-custom-asset"
          component="form"
          onSubmit={form.handleSubmit(handleSubmit)}
          className={classes.root}
        >
          <Input
            variant="secondary"
            label="Asset ID"
            placeholder="Asset ID"
            disabled={priceFeed === 'Manual'}
            labelComponent={
              <ChartTooltip
                size="sm"
                content="Please insert the asset id for the selected price feed."
              />
            }
            {...form.register('assetId')}
          />
          <Input
            variant="secondary"
            label="Asset"
            placeholder="Asset"
            {...form.register('asset')}
          />
          <Input
            variant="secondary"
            label="Holdings"
            placeholder="Holdings"
            {...form.register('holdings')}
          />
          <Controller
            name="blockchain"
            control={form.control}
            render={(e) => (
              <AssetBlockchainSelect
                value={e.field.value}
                onChange={(_e) => form.setValue(e.field.name, _e?.value || '')}
                variant="secondary"
                label="Blockchain"
                tooltip="Blockchain the transactions will be sent on"
              />
            )}
          />
          <Controller
            control={form.control}
            render={(args) => (
              <Select
                options={PRICE_FEED}
                variant="secondary"
                label="Price Feed"
                placeholder="Price Feed"
                value={args.field.value}
                onChange={(e) => form.setValue(args.field.name, e?.value || '')}
              />
            )}
            name="priceFeed"
          />

          <Input
            variant="secondary"
            label="Manual Price USD"
            placeholder="Manual Price USD"
            disabled={priceFeed !== 'Manual'}
            labelComponent={
              <ChartTooltip
                size="sm"
                content="Only available when Price Feed = Manual"
              />
            }
            {...form.register('manualAssetPriceUsd')}
          />
        </Grid>
      </DialogBody>

      <DialogActions>
        <Button
          size="sm"
          variant="contained-2"
          color="secondary"
          onClick={props.onClose}
        >
          Reset
        </Button>
        <Button
          size="sm"
          variant="contained-2"
          color="primary"
          type="submit"
          form="add-custom-asset"
        >
          Save
        </Button>
      </DialogActions>
    </>
  )
}

export default function AddCustomAssetDialog(props: ContentProps) {
  return (
    <Dialog
      variant="secondary"
      height={435}
      title="Add Custom Asset"
      size="lg"
      open={props.open}
      onClose={props.onClose}
    >
      <Content {...props} />
    </Dialog>
  )
}
