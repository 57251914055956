import Account from 'pages/Account'
import Advertise from 'pages/Advertise'
import Asset from 'pages/Asset'
import Assets from 'pages/Assets'
import Bridges from 'pages/Bridges'
import CheckoutSuccess from 'pages/CheckoutSuccess'
import Disclaimer from 'pages/Disclaimer'
import Farm from 'pages/Farm'
import Farms from 'pages/Farms'
import Premium from 'pages/Premuim'
import PrivacyPolicy from 'pages/PrivacyPolicy'

import OrderSuccess from '../pages/OrderSuccess'
import RegisterSuccess from '../pages/RegisterSuccess'

interface Route {
  path: string
  Component: () => JSX.Element | null
  auth?: boolean
}

const routes: Route[] = [
  {
    path: '/assets',
    Component: Assets
  },
  {
    path: '/assets/:assetId',
    Component: Asset
  },
  {
    path: '/farms',
    Component: Farms
  },
  {
    path: '/farms/:farmId',
    Component: Farm
  },
  {
    path: '/disclaimer',
    Component: Disclaimer
  },
  {
    path: '/privacy',
    Component: PrivacyPolicy
  },
  {
    path: '/advertise',
    Component: Advertise
  },
  {
    path: '/bridges',
    Component: Bridges
  },
  {
    path: '/account',
    Component: Account,
    auth: true
  },
  {
    path: '/premium/:tab?/:param?',
    Component: Premium,
    auth: true
  },
  {
    path: '/checkout-success',
    Component: CheckoutSuccess
  },
  {
    path: '/register-success',
    Component: RegisterSuccess
  },
  {
    path: '/order-success',
    Component: OrderSuccess
  }
]

export default routes
