import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Button'
import BlockchainSelect from 'components/form/BlockchainSelect'
import BondCategoriesSelect from 'components/form/BondCategoriesSelect'
import Input from 'components/form/Input'
import useBondTransaction from 'hooks/useBondTransaction'
import { Controller, useForm } from 'react-hook-form'

import Dialog, { DialogBaseProps } from './Dialog'
import DialogActions from './DialogActions'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.25rem'
  }
})

interface ContentProps extends DialogBaseProps {}

function Content(props: ContentProps) {
  const classes = useStyles()
  const bondTransaction = useBondTransaction()

  const form = useForm()

  const handleSubmit = (values) => {
    bondTransaction.onAddTransaction(values, () => {
      props.onClose?.()
    })
  }

  return (
    <>
      <DialogBody>
        <Grid container className={classes.root}>
          <Input
            variant="secondary"
            label="Name"
            placeholder="Name"
            tooltip="Name of the transaction"
            {...form.register('name')}
          />
          <Controller
            name="category"
            control={form.control}
            render={(e) => (
              <BondCategoriesSelect
                value={e.field.value}
                onChange={(_e) => form.setValue(e.field.name, _e)}
                variant="secondary"
                label="Revenue Type"
                tooltip="Type (category) these transactions will fall to"
              />
            )}
          />
          <Input
            variant="secondary"
            label="Interacted with Smart Contract"
            placeholder="Interacted with Smart Contract"
            tooltip="Address of smart contract"
            {...form.register('contract_address')}
          />
          <Controller
            name="blockchain"
            control={form.control}
            render={(e) => (
              <BlockchainSelect
                value={e.field.value}
                onChange={(_e) => form.setValue(e.field.name, _e?.value || '')}
                variant="secondary"
                label="Blockchain"
                tooltip="Blockchain the transactions will be sent on"
              />
            )}
          />

          <Input
            variant="secondary"
            label="Receiving Address"
            tooltip="Address that will be receiving the transactions (Treasury for example)"
            placeholder="Receiving Address"
            {...form.register('receiver_address')}
          />
          <Input
            variant="secondary"
            label="Partner"
            tooltip="Partner, that you receive the transaction from (can be left empty)"
            placeholder="Partner"
            {...form.register('partner')}
          />
          <Input
            variant="secondary"
            label="Asset Paid Symbol"
            tooltip="Symbol of the asset received in the transaction (Ethreum -> ETH, Bitcoin -> BTC)"
            placeholder="Asset Paid Symbol"
            {...form.register('asset_paid_symbol')}
          />
          <Input
            variant="secondary"
            label="Asset Paid Address"
            tooltip="Address of the asset paid"
            placeholder="Asset Paid Address"
            {...form.register('asset_paid_address')}
          />
        </Grid>
      </DialogBody>

      <DialogActions>
        <Button size="sm" variant="contained-2" color="secondary">
          Reset
        </Button>
        <Button
          size="sm"
          variant="contained-2"
          onClick={() => form.handleSubmit(handleSubmit)()}
        >
          Save
        </Button>
      </DialogActions>
    </>
  )
}

export default function AddBondTransactionDialog(props: ContentProps) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      variant="secondary"
      title="Import Transactions"
      height={525}
      size="lg"
    >
      <Content {...props} />
    </Dialog>
  )
}
