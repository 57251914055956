import makeStyles from '@material-ui/core/styles/makeStyles'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'
import classNames from 'classnames'
import React from 'react'
import { device } from 'utils/screen'

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
    color: '#33B6FF',
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 9999,
    backgroundColor: 'transparent',
    margin: '-0.25rem 0.5rem -0.25rem -0.25rem',

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    },

    [`@media ${device.tablet}`]: {
      width: 30,
      height: 30,

      '& svg': {
        width: 20
      }
    }
  }
})

interface BookButtonProps {
  booked: boolean
  onBook: any
  onUnBook: any
  className?: string
}

export default function BookButton({
  booked,
  onUnBook,
  onBook,
  className
}: BookButtonProps) {
  const classes = useStyles()
  return (
    <button
      className={classNames(classes.root, className)}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        booked ? onUnBook() : onBook()
      }}
    >
      {booked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
    </button>
  )
}
