import Api from '../Api'

export async function getPools(url: string) {
  const response = await Api.post(url, {})
  return response.data
}

export async function updatePool(poolId: string, updates: any, weights: any) {
  const response = await Api.post('/auth/edit_user_pool', {
    poolId,
    updates,
    weights
  })
  return response.data
}
