import Grid from '@material-ui/core/Grid'
import SelectStrategy from 'components/form/SelectStrategy'
import { PageTitle } from 'components/typography'
import usePool from 'hooks/usePool'
import useSinglePnl from 'hooks/useSinglePnl'
import { useSpinner } from 'providers/SpinnerProvider'
import { useMemo, useState } from 'react'
import { formatAprData, formatHighlightsData } from 'utils/pools'

import InfoCard from '../strategies/InfoCard'
import PremiumSections from '../strategies/PremiumSections'
import { useStrategiesStyles } from '../strategies/styles'
import AprSingleLineChart from '../strategiesDetailed/AprSingleLineChart'
import HoldingsSingleLineChart from '../strategiesDetailed/HoldingsSingleLineChart'
import Pnl from '../strategiesDetailed/Pnl'
import AssetDetails from './assetDetails/AssetDetails'

export default function AssetsStrategiesDetailed() {
  const classes = useStrategiesStyles()
  const [poolId, setPoolId] = useState<string>('')

  const pool = usePool({ poolId })
  useSpinner(pool.isLoading)
  const pnl = useSinglePnl({ poolId })

  const [holdingsChart, aprChart] = useMemo(() => {
    return [formatHighlightsData(pool.pool), formatAprData(pool.pool)]
  }, [pool.pool])

  const handleChange = (id: string) => {
    setPoolId(id)
  }

  return (
    <>
      <Grid container direction="column">
        <SelectStrategy
          value={pool.pool.poolId as string}
          className={classes.selectStrategy}
          onChange={(e) => handleChange(e?.value || '')}
        />
        <InfoCard pool={pool.pool} />
        <PremiumSections pool={pool.pool} />
        <Pnl pool={pool.pool} pnl={pnl.data} />
        <AssetDetails usePool={pool} />

        <PageTitle className={classes.title}>Holdings Over Time</PageTitle>

        <HoldingsSingleLineChart
          poolId={pool.pool.poolId}
          poolAsset={pool.pool.asset}
          usdData={holdingsChart.usdChart}
          ethData={holdingsChart.ethChart}
          loading={false}
        />

        <PageTitle className={classes.title}>Asset APR (y) Over Time</PageTitle>

        <AprSingleLineChart
          poolId={pool.pool.poolId}
          loading={false}
          data={aprChart}
        />
      </Grid>
    </>
  )
}
