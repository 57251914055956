import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Card from 'components/cards/Card'
import PieChart from 'components/charts/PieChart'
import { useMemo } from 'react'
import { TableCol } from 'types/components/table'
import { parsePercent } from 'utils/api'
import { getPoolsKey, parseTopAssets } from 'utils/pools'
import { TOOLTIP } from 'utils/tooltip'

import ChartTable, { Cell, Row } from './ChartTable'

const useStyles = makeStyles({
  chartTable: {
    flex: 1,
    height: 'fit-content',
    paddingLeft: '2rem'
  },
  percent: {
    fontWeight: 700
  },
  colorCell: {}
})

const COLS: TableCol[] = [
  { title: '' },
  { title: 'Asset' },
  { title: 'Farm' },
  { title: 'Blockchain' },
  { title: '', align: 'right' }
]

interface AssetsChartProps {
  data: any[]
}

export default function AssetsChart({ data }: AssetsChartProps) {
  const classes = useStyles()

  const poolsKey = getPoolsKey(data)

  const parsedData = useMemo(() => {
    return parseTopAssets(data)
  }, [poolsKey])

  return (
    <Card container direction="row">
      <PieChart
        customTooltip={TOOLTIP.PIE_ASSET}
        data={parsedData}
        dataKey="value"
        tooltipTitle={(payload) => payload?.asset}
        tooltipText={(payload) => parsePercent(payload?.value)}
      />
      <Grid container className={classes.chartTable}>
        <ChartTable cols={COLS}>
          {parsedData.map((row, index) => (
            <Row key={index}>
              <Cell color={row.color}>
                <span />
              </Cell>
              <Cell>{row.asset}</Cell>
              <Cell>{row.farm}</Cell>
              <Cell>{row.blockchain}</Cell>
              <Cell align="right">
                <span className={classes.percent}>
                  {parsePercent(row.value)}
                </span>
              </Cell>
            </Row>
          ))}
        </ChartTable>
      </Grid>
    </Card>
  )
}
