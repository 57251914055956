import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { PasswordData } from '../interfaces/auth'
import { useAuth } from '../providers/AuthProvider'
import { useSpinner } from '../providers/SpinnerProvider'
import { setPassword } from '../services/api/auth'

interface UseAuthPassword {
  onSetPassword: (
    data: PasswordData,
    token: string,
    onSuccess?: (loggedIn: boolean) => void
  ) => void
}

export default function useAuthPassword(): UseAuthPassword {
  const auth = useAuth()
  const history = useHistory()
  const location = useLocation()
  const spinner = useSpinner()

  const onSetPassword = async (
    data: PasswordData,
    token: string,
    onSuccess?: (loggedIn: boolean) => void
  ) => {
    try {
      spinner.open()
      const response = await setPassword(data, token)
      // auto login if api returned access_token
      response.access_token && auth.onLogin(response.access_token)
      spinner.close()
      toast.success('Password successfully updated')
      history.replace(location.pathname)
      onSuccess?.(!!response.access_token)
    } catch (e) {
      spinner.close()
      toast.error('Something went wrong')
      console.error(e)
    }
  }

  return {
    onSetPassword
  }
}
