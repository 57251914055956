import Grid from '@material-ui/core/Grid'
import BookButton from 'components/buttons/BookButton'
import CellImage from 'components/images/CellImage'
import Table from 'components/table/Table'
import TableCell from 'components/table/TableCell'
import TablePagination from 'components/table/TablePagination'
import TableRow from 'components/table/TableRow'
import { Link, PageTitle } from 'components/typography'
import useSavedSearches from 'hooks/useSavedSearches'
import { useSpinner } from 'providers/SpinnerProvider'
import React, { useMemo } from 'react'
import { TableCol } from 'types/components/table'
import { formatRows } from 'utils/farms'

import { useStrategiesStyles } from './strategies/styles'

export default function Assets() {
  const classes = useStrategiesStyles()

  const { isLoading, savedSearches, onRemoveSearch } = useSavedSearches({
    save_type: 'farm'
  })

  useSpinner(isLoading)

  const rows = formatRows(savedSearches)

  const COLS: TableCol[] = useMemo(
    () => [
      { title: 'Farms' },
      { title: 'Blockchain' },
      { title: 'TVL' },
      { title: 'Change (24h)' },
      { title: 'Date added' },
      {
        title: <TablePagination page={1} count={1} onPage={() => {}} />,
        align: 'right'
      }
    ],
    []
  )

  return (
    <>
      <Grid container className={classes.titleContainer}>
        <PageTitle>Saved Assets</PageTitle>
      </Grid>

      <Table cols={COLS} empty={!savedSearches.length}>
        {rows.map((row, index) => (
          <TableRow key={index}>
            <TableCell>
              <div className={classes.cellActions}>
                <BookButton
                  booked
                  onUnBook={() => onRemoveSearch(row.farmId)}
                  onBook={() => {}}
                />

                <CellImage src={row.farmIconUrl} />

                <span>{row.farmName}</span>
              </div>
            </TableCell>
            <TableCell>{row.blockchain}</TableCell>
            <TableCell>{row.tvlStaked}</TableCell>
            <TableCell>{row.tvlChange24hValue}</TableCell>
            <TableCell>{row.dateAdded}</TableCell>
            <TableCell align="right">
              <Link to={`/farms/${row.farmId}`}>Farm page</Link>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </>
  )
}
