import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import useCmsPartner from 'hooks/useCmsPartner'
import { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'

import Dialog, {
  DialogBaseProps,
  DialogTitleComponentProps,
  OnTitleComponent
} from './Dialog'
import DialogBody from './DialogBody'
import DialogHeadIconButton, {
  DialogHeadIconButtonContainer
} from './DialogHeadIconButton'
import DialogTitleComponent from './DialogTitleComponent'
import UserIconsDialog from './UserIconsDialog'

const useStyles = makeStyles({
  actions: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.25rem',
    padding: '1.5rem'
  },
  input: {
    marginBottom: '1.25rem'
  }
})

interface ContentProps {
  editId?: string
}

function Content(
  props: ContentProps & DialogBaseProps & DialogTitleComponentProps
) {
  const [iconsDialog, setIconsDialog] = useState(false)
  const classes = useStyles()

  const cmsPartner = useCmsPartner({
    id: props.editId
  })

  const form = useForm()

  const [image] = useWatch({
    control: form.control,
    name: ['image']
  })

  const key = JSON.stringify(cmsPartner.partner)

  useEffect(() => {
    if (cmsPartner.partner.item_id) {
      form.reset({ ...cmsPartner.partner })
    }
  }, [key])

  const handleSubmit = (values) => {
    cmsPartner.onAdd(values, props.onClose)
  }

  useEffect(() => {
    props.onTitleComponent(
      <DialogTitleComponent>
        <span>Add New Partner</span>
        <DialogHeadIconButtonContainer>
          <DialogHeadIconButton
            onClick={() => setIconsDialog(true)}
            icon={image}
            tooltip="Upload Icon"
          />
        </DialogHeadIconButtonContainer>
      </DialogTitleComponent>
    )
  }, [image])

  return (
    <>
      <DialogBody>
        <Input
          variant="secondary"
          label="Partner"
          placeholder="Partner name"
          className={classes.input}
          {...form.register('partnership')}
        />
      </DialogBody>

      <Grid container alignItems="center" className={classes.actions}>
        <Button
          variant="contained-2"
          size="sm"
          color="secondary"
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained-2"
          size="sm"
          onClick={() => form.handleSubmit(handleSubmit)()}
        >
          Save
        </Button>
      </Grid>

      <UserIconsDialog
        open={iconsDialog}
        onClose={() => setIconsDialog(false)}
        onConfirm={(url) => {
          form.setValue('image', url)
          setIconsDialog(false)
        }}
        initialUrl={image}
      />
    </>
  )
}

export default function AddPartnerDialog(
  props: ContentProps & DialogBaseProps
) {
  const [titleComponent, onTitleComponent] = useState()
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      variant="secondary"
      title={titleComponent}
      height={300}
    >
      <Content
        {...props}
        onTitleComponent={onTitleComponent as OnTitleComponent}
      />
    </Dialog>
  )
}
