import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useState } from 'react'

import Button from '../buttons/Button'
import Input from '../form/Input'
import Dialog from './Dialog'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  input: {
    marginBottom: '1.25rem'
  }
})

interface AddFieldDialogProps {
  open: boolean
  onClose: () => void
  onApply: (field: string) => void
}

export default function AddFieldDialog({
  open,
  onClose,
  onApply
}: AddFieldDialogProps) {
  const classes = useStyles()
  const [value, setValue] = useState('')
  return (
    <Dialog
      open={open}
      onClose={() => {
        setValue('')
        onClose()
      }}
      title="Add Field"
      variant="secondary"
      height={220}
      size="sm"
    >
      <DialogBody>
        <Input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          variant="secondary"
          placeholder="Field Name"
          className={classes.input}
        />

        <Grid container justifyContent="flex-end">
          <Button
            variant="contained-2"
            size="sm"
            onClick={() => {
              onApply(value)
              setValue('')
              onClose()
            }}
          >
            Add
          </Button>
        </Grid>
      </DialogBody>
    </Dialog>
  )
}
