import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import { Link } from 'components/typography'
import { BridgeCombination } from 'services/types/bridges'

const useStyles = makeStyles({
  root: {
    padding: '1rem',
    backgroundColor: '#303659',
    borderRadius: 15,
    marginBottom: '1.5rem'
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700
  },
  label: {
    color: '#B2BDFF',
    fontWeight: 700
  },
  text: {
    wordBreak: 'break-all',
    color: '#FAFAFA',
    marginBottom: '0.5rem'
  }
})

export default function BridgeComboCard({
  bridgeName,
  comment,
  fee,
  url,
  rank
}: BridgeCombination) {
  const classes = useStyles()
  return (
    <Grid container direction="column" className={classes.root}>
      <Typography className={classNames(classes.text, classes.title)}>
        <span className={classes.label}>Name:</span>{' '}
        <Link native to={url} target="_blank" rel="noopener noreferrer">
          {bridgeName}
        </Link>
      </Typography>

      <Typography className={classes.text}>
        <span className={classes.label}>Comment:</span> <span>{comment}</span>
      </Typography>

      <Typography className={classes.text}>
        <span className={classes.label}>Fee:</span> <span>{fee}</span>
      </Typography>

      <Typography className={classes.text}>
        <span className={classes.label}>Rank:</span> <span>{rank}</span>
      </Typography>
    </Grid>
  )
}
