import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { DemoOrderData } from '../interfaces/demo'
import { useSpinner } from '../providers/SpinnerProvider'
import { order } from '../services/api/order'

type OnOrder = (data: DemoOrderData, onSuccess?: () => void) => void

interface UseOrderDemo {
  onOrder: OnOrder
}

export default function useOrderDemo(): UseOrderDemo {
  const spinner = useSpinner()
  const history = useHistory()

  const onOrder: OnOrder = async (data, onSuccess) => {
    try {
      spinner.open()
      await order(data)
      spinner.close()
      toast.success('Demo Order Succeeded!')
      history.push('/order-success')
      onSuccess?.()
    } catch (e: any) {
      spinner.close()
      toast.error(e?.response?.data || 'Something went wrong')
      console.error(e)
    }
  }

  return {
    onOrder
  }
}
