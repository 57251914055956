import { useSpinner } from 'providers/SpinnerProvider'
import { toast } from 'react-toastify'
import {
  addDaoGohmLeaderboard,
  removeDaoGohmLeaderboard
} from 'services/api/cms'
import { fetcher } from 'services/api/common'
import useSWR from 'swr'
import { stringifyURL } from 'utils/query'

type OnAdd = (data: any, onSuccess?: any) => void
type OnRemove = (id: string, onSuccess?: any) => void

interface UseDaoGohmLeaderboard {
  onAdd: OnAdd
  onRemove: OnRemove
  isLoading: boolean
  leaderboard: any
}

interface UseDaoGohmLeaderboardConfig {
  id?: string
}

function getKey(params) {
  return params.item_id
    ? stringifyURL('/auth/cms/get_single_dao_gohm_item', params)
    : null
}

export default function useDaoGohmLeaderboard(
  config: UseDaoGohmLeaderboardConfig = {}
): UseDaoGohmLeaderboard {
  const spinner = useSpinner()

  const params = {
    item_id: config.id
  }

  const {
    data,
    error,
    mutate: revalidate
  } = useSWR(() => getKey(params), fetcher)

  const onAdd: OnAdd = async (data, onSuccess) => {
    try {
      spinner.open()

      const values = {
        ...data,
        total_cvx: Number(data.total_cvx)
      }

      await addDaoGohmLeaderboard(values)

      toast.success('Leaderboard added successfully')
      spinner.close()
      revalidate?.()
      onSuccess?.()
    } catch (e) {
      spinner.close()
      console.error(e)
      toast.error('Oops error')
    }
  }

  const onRemove: OnRemove = async (id, onSuccess) => {
    try {
      spinner.open()

      await removeDaoGohmLeaderboard(id)

      toast.success('Leaderboard removed successfully')
      spinner.close()
      onSuccess?.()
    } catch (e) {
      spinner.close()
      console.error(e)
      toast.error('Oops error')
    }
  }

  const isLoading = !data && !error
  const leaderboard = data || {}

  return {
    isLoading,
    leaderboard,
    onAdd,
    onRemove
  }
}
