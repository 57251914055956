import { yupResolver } from '@hookform/resolvers/yup'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import { BalanceSheetRow } from 'interfaces/balance-sheet'
import { useEffect } from 'react'
import { useForm, useFormState } from 'react-hook-form'
import { getErrorMsg, requiredMsg } from 'utils/form'
import * as yup from 'yup'

import Dialog, { DialogBaseProps } from '../Dialog'
import DialogActions from '../DialogActions'
import DialogBody from '../DialogBody'

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.25rem'
  }
})

const validationSchema = yup.object().shape({
  product: yup.string().required(requiredMsg())
})

interface ContentProps extends DialogBaseProps {
  onSubmit: (data: BalanceSheetRow) => void
  row?: BalanceSheetRow
}

const calculateYearValue = (quarters: (number | undefined)[]) => {
  return quarters.reduce((result: number, item) => {
    const value: number = item || 0

    return result + value
  }, 0)
}

const defaultValues: BalanceSheetRow = {
  product: ''
}

function Content({ row, onSubmit }: ContentProps) {
  const classes = useStyles()

  const form = useForm<BalanceSheetRow>({
    defaultValues: row || defaultValues,
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema)
  })

  const { dirtyFields, errors } = useFormState({
    control: form.control
  })

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (dirtyFields.year || name === 'year') {
        return
      }

      const quarters = form.getValues(['q1', 'q2', 'q3', 'q4'])

      form.setValue('year', calculateYearValue(quarters))
    })
    return () => subscription.unsubscribe()
  }, [form, dirtyFields.year])
  /**
   * Submits the form
   * @param values - Form values
   */
  const handleSubmit = (values: BalanceSheetRow): void => {
    onSubmit(values)
  }

  return (
    <>
      <DialogBody>
        <Grid container id="add-custom-row" className={classes.root}>
          <Input
            variant="secondary"
            label="Product"
            placeholder="Product's name"
            error={getErrorMsg(errors, 'product')}
            {...form.register('product')}
          />
          <Input
            variant="secondary"
            label="Q1"
            placeholder="Q1 value"
            error={getErrorMsg(errors, 'q1')}
            {...form.register('q1', {
              valueAsNumber: true
            })}
          />
          <Input
            variant="secondary"
            label="Q2"
            placeholder="Q2 value"
            {...form.register('q2', {
              valueAsNumber: true
            })}
          />
          <Input
            variant="secondary"
            label="Q3"
            placeholder="Q3 value"
            {...form.register('q3', {
              valueAsNumber: true
            })}
          />
          <Input
            variant="secondary"
            label="Q4"
            placeholder="Q4 value"
            {...form.register('q4', {
              valueAsNumber: true
            })}
          />
          <Input
            variant="secondary"
            label="Full year"
            placeholder="Full year value"
            {...form.register('year', {
              valueAsNumber: true
            })}
          />
        </Grid>
      </DialogBody>

      <DialogActions>
        <Button
          size="sm"
          variant="contained-2"
          color="primary"
          form="add-custom-row"
          onClick={() => form.handleSubmit(handleSubmit)()}
        >
          Save
        </Button>
      </DialogActions>
    </>
  )
}

export default function AddRowDialog(props: ContentProps) {
  return (
    <Dialog
      variant="secondary"
      height={450}
      title="Add a balance sheet row"
      size="lg"
      open={props.open}
      onClose={props.onClose}
    >
      <Content {...props} />
    </Dialog>
  )
}
