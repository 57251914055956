import MuiDrawer from '@material-ui/core/Drawer'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'

import useMediaQueries from '../../hooks/useMediaQueries'
import { device } from '../../utils/screen'

const useStyles = makeStyles({
  paper: {
    width: 500,
    height: '100vh',
    background: 'linear-gradient(111.6deg, #303659 -2.51%, #292E4D 104.46%)',
    color: '#B2BDFF',
    boxShadow: '0px 8px 60px rgba(34, 39, 64, 0.4)',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '25px 0 0 25px',
    border: '1px solid #41486E',
    fontFamily: 'Roboto',

    [`@media ${device.tablet}`]: {
      width: '100%',
      height: '75vh',
      borderRadius: '25px 25px 0 0'
    },

    '& *': {
      boxSizing: 'border-box'
    }
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)'
  }
})

export interface DrawerBaseProps {
  open: boolean
  onClose: () => void
}

interface DrawerProps extends DrawerBaseProps {
  className?: string
}

export default function Drawer({
  open,
  children,
  onClose,
  className
}: PropsWithChildren<DrawerProps>) {
  const { isTablet } = useMediaQueries()
  const classes = useStyles()
  return (
    <MuiDrawer
      anchor={isTablet ? 'bottom' : 'right'}
      open={open}
      onClose={onClose}
      classes={{ paper: classNames(classes.paper, className) }}
      BackdropProps={{
        className: classes.backdrop
      }}
    >
      {children}
    </MuiDrawer>
  )
}
