import Grid from '@material-ui/core/Grid'
import Tabs from 'components/tabs/Tabs'
import { useState } from 'react'

import { useStrategiesStyles } from './strategies/styles'
import AssetsStrategiesDetailed from './strategiesDetailed/AssetsStrategiesDetailed'
import NFTStrategiesDetailed from './strategiesDetailed/NFTStrategiesDetailed'

enum TabTypeEnum {
  ASSETS = 'productive_asset',
  NFTS = 'NFT'
}

const TABS = [
  { value: TabTypeEnum.ASSETS, name: 'Assets' },
  { value: TabTypeEnum.NFTS, name: 'NFTS' }
]

const Components = {
  [TabTypeEnum.ASSETS]: AssetsStrategiesDetailed,
  [TabTypeEnum.NFTS]: NFTStrategiesDetailed
}

export default function StrategiesDetailed() {
  const classes = useStrategiesStyles()
  const [tab, setTab] = useState<string>(TabTypeEnum.ASSETS)

  const Component = Components[tab]

  return (
    <>
      <Grid container direction="column">
        <Tabs
          width="auto"
          className={classes.tabs}
          tabs={TABS}
          value={tab}
          onChange={setTab}
        />
        <Component />
      </Grid>
    </>
  )
}
