import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import {
  CaretDown,
  CloseIcon,
  DiscordIcon,
  SearchIcon,
  TwitterIcon,
  WebIcon
} from 'assets/icons'
import classNames from 'classnames'
import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import Table from 'components/table/Table'
import TableCell from 'components/table/TableCell'
import TablePagination from 'components/table/TablePagination'
import TableRow from 'components/table/TableRow'
import useLeaderboards from 'hooks/useLeaderboards'
import { useSpinner } from 'providers/SpinnerProvider'
import { useMemo } from 'react'
import { TableCol } from 'types/components/table'
import { formatRows } from 'utils/leaderboards'

import { device } from '../../utils/screen'

const useStyles = makeStyles({
  alertsBanner: {
    height: 60,
    borderRadius: 15,
    background: 'linear-gradient(111.6deg, #303659 -2.51%, #292E4D 104.46%)',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    padding: '0 1.25rem',
    marginBottom: '2.5rem'
  },
  alertText: {
    fontSize: '1rem',

    [`@media ${device.tablet}`]: {
      fontSize: '0.75rem'
    }
  },
  alertBtn: {
    margin: '0 2rem',
    whiteSpace: 'nowrap',

    [`@media ${device.tablet}`]: {
      fontSize: '0.875rem',
      height: 35
    }
  },
  inputContainer: {
    flex: 1
  },
  inputText: {
    display: 'flex',
    margin: '0 0.5rem',
    whiteSpace: 'pre',

    '& span': {
      cursor: 'pointer'
    },

    '&:first-child': {
      marginLeft: 0
    },

    '&[data-active="true"]': {
      '& span': {
        color: '#B2BDFF',
        fontWeight: 700
      }
    }
  },
  searchWrapper: {
    marginBottom: '1.25rem',

    [`@media ${device.tablet}`]: {
      flexDirection: 'column',
      marginBottom: '1.25rem'
    }
  },
  searchFilters: {
    display: 'flex',
    alignItems: 'center',

    [`@media ${device.tablet}`]: {
      marginBottom: '1rem'
    }
  },
  select: {
    width: 100,
    margin: '0 1rem',

    [`@media ${device.tablet}`]: {
      margin: '1rem 0'
    }
  },
  selectInput: {
    background: 'linear-gradient(107.39deg, #474F7E 3.48%, #343B61 97.52%)'
  },
  detectionSelect: {
    width: 200
  },
  selectContainer: {
    marginBottom: '1.25rem',

    [`@media ${device.tablet}`]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: '#41486E',
    marginBottom: '1.875rem'
  },
  filters: {
    marginBottom: '2rem'
  },
  iconsCell: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',

    '& svg': {
      cursor: 'pointer',
      margin: '0 0.5rem',
      color: '#B2BDFF'
    }
  },
  voteCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',

    '& span': {
      margin: '0.25rem 0'
    },

    '& button': {
      color: '#ff5733',
      position: 'absolute',
      borderRadius: 9999,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 18,
      height: 18,
      backgroundColor: 'transparent',

      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.25)'
      },

      '& svg': {
        width: 12,
        height: 12,
        minWidth: 12,
        minHeight: 12
      },

      '&:first-child': {
        top: '-0.75rem',

        '& svg': {
          transform: 'rotate(180deg)',
          color: '#00FF00'
        }
      },

      '&:last-child': {
        bottom: '-0.75rem'
      }
    }
  }
})

export default function Leaderboard() {
  const classes = useStyles()
  const {
    isLoading,
    leaderboards,
    maxPages,
    filters,
    onPage,
    onSort,
    onSearch,
    onNewest,
    onHottest,
    onVote,
    onDownVote
  } = useLeaderboards()
  useSpinner(isLoading)

  const rows = formatRows(leaderboards)

  const COLS: TableCol[] = useMemo(
    () => [
      { title: 'Name', sortable: true, name: 'sortable_name' },
      { title: 'Vote', sortable: true, name: 'vote' },
      { title: 'Short Description', sortable: true, name: 'description' },
      { title: 'Blockchain', sortable: true, name: 'blockchain' },
      { title: 'Category', sortable: true, name: 'category' },
      { title: 'Twitter Followers', sortable: true, name: 'twitter_followers' },
      { title: 'Change 24h', sortable: true, name: 'change24h' },
      { title: 'Change 7d', sortable: true, name: 'change7d' },
      { title: 'Created', sortable: true, name: 'createdDate' },
      {
        title: (
          <TablePagination
            page={filters.page}
            count={maxPages}
            onPage={onPage}
          />
        ),
        align: 'right'
      }
    ],
    []
  )

  return (
    <Grid container direction="column">
      <Grid className={classes.alertsBanner}>
        <Typography className={classes.alertText}>
          Get alerts when new projects are detected.
        </Typography>

        <Button
          className={classNames(classes.alertBtn)}
          size="sm"
          variant="contained-2"
        >
          Set Alerts
        </Button>

        <CloseIcon />
      </Grid>

      <Grid container alignItems="center" className={classes.searchWrapper}>
        <Grid className={classes.searchFilters}>
          <Typography
            className={classes.inputText}
            data-active={!!filters.hottest}
          >
            <span onClick={onHottest}>HOTTEST</span>
            {'  /'}
          </Typography>
          <Typography
            className={classes.inputText}
            data-active={!!filters.newest}
          >
            <span onClick={onNewest}>NEWEST</span>
            {'  /'}
          </Typography>
        </Grid>

        <Input
          prefix={<SearchIcon />}
          placeholder="What would you like to search"
          className={classes.inputContainer}
          font="Roboto"
          onChange={(e) => onSearch(e.target.value)}
        />
      </Grid>

      <Grid container alignItems="center" className={classes.selectContainer}>
        <Typography>1501 new projects discovered during the last</Typography>
        <Select
          options={[]}
          searchable={false}
          className={classes.select}
          inputClassName={classes.selectInput}
          value={{ label: 'Day', value: 'Day' }}
        />
        <Typography>sorted by</Typography>

        <Select
          options={[]}
          searchable={false}
          className={classNames(classes.select, classes.detectionSelect)}
          inputClassName={classes.selectInput}
          value={{ label: 'Detection day', value: 'Detection day' }}
        />
      </Grid>

      <div className={classes.divider} />

      <Grid container className={classes.filters}>
        <Button size="sm" variant="contained-2">
          Apply filters
        </Button>
      </Grid>

      <Grid container direction="column">
        <Table
          cols={COLS}
          empty={!rows.length}
          loading={isLoading}
          sort={filters.sort}
          sortOrder={filters.sort_order}
          onSort={onSort}
        >
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.name}</TableCell>
              <TableCell>
                <Grid className={classes.voteCell}>
                  <button onClick={() => onVote(row.name)}>
                    <CaretDown />
                  </button>
                  <span>{row.vote}</span>
                  <button onClick={() => onDownVote(row.name)}>
                    <CaretDown />
                  </button>
                </Grid>
              </TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.blockchain}</TableCell>
              <TableCell>{row.category}</TableCell>
              <TableCell>{row.followers}</TableCell>
              <TableCell>{row.change24h}</TableCell>
              <TableCell>{row.change7d}</TableCell>
              <TableCell>{row.createdDate}</TableCell>
              <TableCell align="right">
                <Grid className={classes.iconsCell}>
                  <TwitterIcon />
                  <WebIcon />
                  <DiscordIcon />
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Grid>
    </Grid>
  )
}
