import makeStyles from '@material-ui/core/styles/makeStyles'
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'

interface TooltipDropdownProps {
  content: any
  className?: string
  maxWidth?: number
}

const useStyles = makeStyles({
  root: (props: Partial<TooltipDropdownProps>) => ({
    background: 'linear-gradient(107.39deg, #474F7E 3.48%, #343B61 97.52%)',
    borderRadius: 15,
    boxShadow: '0px 4px 15px rgba(20, 6, 75, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: '1rem 1.25rem',
    fontSize: '0.875rem',

    ...(props.maxWidth && {
      maxWidth: props.maxWidth
    })
  })
})

export default function TooltipDropdown({
  children,
  content,
  className,
  maxWidth,
  ...props
}: PropsWithChildren<TooltipDropdownProps & Partial<TooltipProps>>) {
  const classes = useStyles({ maxWidth })
  return (
    <Tooltip
      interactive
      title={content}
      classes={{ tooltip: classNames(classes.root, className) }}
      enterTouchDelay={0}
      {...props}
    >
      {children as any}
    </Tooltip>
  )
}
