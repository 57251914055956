import Api from '../Api'

export async function addCustom(field: string) {
  const response = await Api.post('/auth/add_custom_field', {
    field
  })
  return response.data
}

export async function removeCustom(field: string) {
  const response = await Api.post('/auth/delete_custom_field', {
    field
  })
  return response.data
}

export async function editFieldNote(field: string, value: string) {
  const response = await Api.post('/auth/edit_field_note', {
    field,
    value
  })
  return response.data
}
