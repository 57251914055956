import makeStyles from '@material-ui/core/styles/makeStyles'
import { DeleteIcon } from 'assets/icons'
import Button from 'components/buttons/Button'
import EditPnlDialog from 'components/dialogs/EditPnlDialog'
import EditStrategyDialog from 'components/dialogs/EditStrategyDialog'
import Table from 'components/table/Table'
import TableCell from 'components/table/TableCell'
import TablePagination from 'components/table/TablePagination'
import TableRow from 'components/table/TableRow'
import { useMemo, useState } from 'react'
import { TableCol } from 'types/components/table'
import { addCurrency, parseMoney } from 'utils/api'
import { CURRENCY } from 'utils/filters'
import { getAssetDisplayNameOrName } from 'utils/pools'

import { getRemoteAssetIcon } from '../../utils/icons'
import CellImage from '../images/CellImage'

const useStyles = makeStyles({
  actions: {
    '& button': {
      marginRight: '1.25rem',
      '&:last-child': {
        marginRight: 0
      }
    }
  },
  iconCell: {
    display: 'flex',
    alignItems: 'center'
  },
  cell: {
    '&[data-green="true"]': {
      color: '#6BFF44'
    },
    '&[data-red="true"]': {
      color: '#FF6464'
    }
  }
})

export default function PnlTable(props) {
  const classes = useStyles()
  const [editId, onEditId] = useState<string | undefined>()
  const [editAsset, onEditAsset] = useState<any>()

  const COLS: TableCol[] = useMemo(
    () => [
      {
        title: 'Assets',
        render: (row) =>
          row.pool ? (
            <div className={classes.iconCell}>
              <CellImage src={getRemoteAssetIcon(row.pool)} />
              <span>{getAssetDisplayNameOrName(row.pool)}</span>
            </div>
          ) : (
            'N/A'
          )
      },
      {
        title: 'Blockchain',
        render: (row) => (row.pool?.blockchain ? row.pool.blockchain : 'N/A')
      },
      {
        title: 'Farm',
        render: (row) => (row.pool?.farm ? row.pool.farm : 'N/A')
      },
      {
        title: 'Token Count',
        render: (row) => (row.pool?.size ? row.pool.size.toFixed(2) : 'N/A')
      },
      {
        title: 'Token Price Now',
        render: (row) => addCurrency(row.pool?.price, CURRENCY._USD)
      },
      {
        title: 'Initial Investment',
        name: 'initial_investment',
        render: (row) => parseMoney(row.initial_investment),
        sortable: true
      },
      {
        title: 'Current Value',
        name: 'current_value',
        render: (row) =>
          row.is_sold
            ? 'Sold'
            : row.is_refund
            ? 'Refunded'
            : parseMoney(row.current_value),
        sortable: true
      },
      {
        title: 'OUT',
        name: 'out',
        render: (row) => parseMoney(row.out),
        sortable: true
      },
      {
        title: 'P/L',
        name: 'profit_or_loss',
        render: (row) => (
          <div
            className={classes.cell}
            data-green={row.profit_or_loss > 0}
            data-red={row.profit_or_loss < 0}
          >
            {parseMoney(row.profit_or_loss)}
          </div>
        ),
        sortable: true
      },
      { title: 'Notes', render: (row) => row.notes || '-' },
      {
        title: (
          <TablePagination
            page={props.filters.page}
            count={props.maxPages}
            onPage={props.onPage}
          />
        ),
        align: 'right',
        render: (row) => (
          <div className={classes.actions}>
            <Button
              size="sm"
              variant="contained-2"
              onClick={() => onEditId(row.pool_id)}
            >
              Edit PnL data
            </Button>
            <Button
              size="sm"
              variant="contained-2"
              onClick={() => onEditAsset(row.pool_id)}
            >
              Edit asset
            </Button>
            <Button
              size="sm"
              variant="contained-2"
              onClick={() => props.onRemove(row.pool_id)}
            >
              <DeleteIcon />
            </Button>
          </div>
        )
      }
    ],
    [props.filters]
  )

  return (
    <>
      <Table
        cols={COLS}
        empty={!props.data.length}
        sort={props.filters.sort}
        sortOrder={props.filters.sort_order === 1 ? 'asc' : 'desc'}
        onSort={props.onSort}
        loading={props.isLoading}
      >
        {props.data.map((row) => (
          <TableRow key={row.pool_id}>
            {COLS.map((col, index) => (
              <TableCell key={index} align={col.align}>
                {col.render?.(row)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Table>

      <EditPnlDialog
        open={!!editId}
        poolId={editId as string}
        onClose={() => onEditId(undefined)}
        revalidate={props.revalidate}
      />
      <EditStrategyDialog
        open={!!editAsset}
        poolId={editAsset}
        onClose={() => onEditAsset(undefined)}
      />
    </>
  )
}
