import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import { PropsWithChildren, ReactNode } from 'react'

import Card from './Card'

const useStyles = makeStyles({
  root: {
    marginBottom: (props: any) =>
      props.spacing ? `${props.spacing * 0.25}rem` : 0
  },
  head: {
    marginBottom: '1.25rem'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontWeight: 700,
    marginRight: '1.25rem',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center'
  }
})

interface ChartCardProps {
  title: string
  tooltip?: ReactNode
  titleComponent?: ReactNode
  headerComponent?: ReactNode
  spacing?: number
}

export default function ChartCard({
  title,
  tooltip,
  titleComponent,
  headerComponent,
  children,
  spacing
}: PropsWithChildren<ChartCardProps>) {
  const classes = useStyles({ spacing })
  return (
    <Card direction="column" className={classes.root}>
      <Grid
        className={classes.head}
        container
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid className={classes.titleContainer}>
          <Typography className={classNames(classes.title)}>
            <span>{title}</span>

            {tooltip}
          </Typography>

          {titleComponent}
        </Grid>

        <Grid>{headerComponent}</Grid>
      </Grid>

      {children}
    </Card>
  )
}
