import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Card from 'components/cards/Card'
import usePnlStats from 'hooks/usePnlStats'
import { useSpinner } from 'providers/SpinnerProvider'
import { useState } from 'react'
import { parseMoney, parsePercent } from 'utils/api'

import EditPnlStatsDialog from '../../../components/dialogs/EditPnlStatsDialog'
import DateSelect from './DateSelect'

const useStyles = makeStyles({
  cards: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '2.5rem',
    padding: '1.25rem 0'
  },
  card: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.75rem 0'
  },
  text: {
    fontSize: '0.875rem',
    fontWeight: 600,
    '&[data-highlight="true"]': {
      '&[data-green="true"]': {
        color: '#6BFF44'
      },
      '&[data-red="true"]': {
        color: '#FF6464'
      }
    }
  }
})

const CARDS = [
  {
    title: 'Current Portfolio Value',
    key: 'total_monthly_pnl_value',
    format: (e) => parseMoney(e, 2, true)
  },
  {
    title: 'Start Total',
    key: 'total_assets_value_this_month',
    format: (e) => parseMoney(e, 2, true)
  },
  {
    title: 'Monthly Floating PnL',
    key: 'monthly_floating_pnl',
    format: (e) => parseMoney(e, 2, true),
    highlight: true
  },
  {
    title: 'Total Monthly PnL',
    key: 'total_monthly_pnl_profit',
    format: (e) => parseMoney(e, 2, true),
    highlight: true
  },
  {
    title: 'Total % Gain This Month',
    key: 'total_gain',
    format: (e) => parsePercent(e, 2, true),
    highlight: true
  },
  {
    title: 'Monthly Closed Trading PnL',
    key: 'total_monthly_sold_pnl_profit',
    format: (e) => parseMoney(e, 2, true),
    highlight: true
  }
]

export default function Stats() {
  const [editStats, onEditStats] = useState(false)

  const pnlStats = usePnlStats()
  const classes = useStyles()

  useSpinner(pnlStats.isLoading)
  return (
    <>
      <Grid container direction="column">
        <DateSelect
          dates={pnlStats.dates}
          filters={pnlStats.filters}
          onFilter={pnlStats.onFilter}
          onEditStats={() => onEditStats(true)}
        />

        <div className={classes.cards}>
          {CARDS.map((card) => (
            <Card key={card.key}>
              <div className={classes.card}>
                <Typography className={classes.text}>{card.title}</Typography>
                <Typography
                  className={classes.text}
                  data-highlight={card.highlight}
                  data-red={pnlStats.data[card.key] < 0}
                  data-green={pnlStats.data[card.key] > 0}
                >
                  {card.format(pnlStats.data[card.key])}
                </Typography>
              </div>
            </Card>
          ))}
        </div>
      </Grid>
      <EditPnlStatsDialog
        open={editStats}
        values={pnlStats.data}
        revalidate={pnlStats.revalidate}
        onClose={() => onEditStats(false)}
      />
    </>
  )
}
