import Grid from '@material-ui/core/Grid'
import Accordion from 'components/accordions/Accordion'
import usePoolsByType from 'hooks/usePoolsByType'
import { POOLS_TYPES_TITLES, POOLS_TYPES_TOOLTIPS } from 'hooks/usePoolsTypes'

import StrategiesTable from '../strategies/StrategiesTable'
import CustomStrategiesTable from './CustomStrategiesTable'
import InactiveAssets from './InactvieAssets'

export default function AssetsTypes() {
  const poolsByType = usePoolsByType()
  return (
    <Grid container direction="column">
      {poolsByType.types.map((type) => (
        <Accordion
          key={type}
          title={POOLS_TYPES_TITLES[type]}
          titleTooltip={POOLS_TYPES_TOOLTIPS[type]}
          open={type === poolsByType.filters.type}
          onClick={() =>
            poolsByType.onType(poolsByType.filters.type === type ? null : type)
          }
        >
          <StrategiesTable
            loading={poolsByType.isLoading}
            page={poolsByType.filters.page}
            maxPages={poolsByType.maxPages}
            data={poolsByType.poolsByTypes}
            onPage={poolsByType.onPage}
            revalidate={poolsByType.revalidate}
          />
        </Accordion>
      ))}

      <>
        <CustomStrategiesTable />
        <InactiveAssets />
      </>
    </Grid>
  )
}
