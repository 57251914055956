import { PropsWithChildren } from 'react'
import { Redirect, Route } from 'react-router-dom'

import { useAuth } from '../../providers/AuthProvider'

interface AuthRouteProps {
  path: string
  exact?: boolean
}

export default function AuthRoute({
  path,
  children,
  ...props
}: PropsWithChildren<AuthRouteProps>) {
  const auth = useAuth()

  return auth.isLogged ? (
    <Route path={path} {...props}>
      {children as any}
    </Route>
  ) : (
    <Redirect to="/farms" />
  )
}
