import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import Accordion from 'components/accordions/Accordion'
import IconButton from 'components/buttons/IconButton'
import AddBondDialog from 'components/dialogs/AddBondDialog'
import CellImage from 'components/images/CellImage'
import Table from 'components/table/Table'
import TableCell from 'components/table/TableCell'
import TablePagination from 'components/table/TablePagination'
import TableRow from 'components/table/TableRow'
import Tabs from 'components/tabs/Tabs'
import useBondsTransactions from 'hooks/useBondsTransactions'
import { useMemo, useState } from 'react'
import { TableCol } from 'types/components/table'
import { nFormatter } from 'types/utils'
import { formatBlockchain, parseMoney } from 'utils/api'
import { DATE_FORMAT_PRIMARY, format } from 'utils/date'
import { CURRENCY, RANGE } from 'utils/filters'
import { getRemoteAssetIcon } from 'utils/icons'

import { RANGES_SECONDARY } from '../performance/data'

const useStyles = makeStyles({
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  rangeContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content'
  },
  totalHead: {
    margin: '0 0.75rem',
    whiteSpace: 'nowrap'
  },
  editIcon: {}
})

const cellProps: any = {
  color: 'pool'
}

interface OtherIncomeTableProps {
  category: string
}

export default function OtherIncomeTable(props: OtherIncomeTableProps) {
  const classes = useStyles()
  const [range, setRange] = useState(RANGE._24h)

  const [editId, setEditId] = useState('')

  const bondsTransactions = useBondsTransactions({
    filters: {
      category: props.category,
      range: range === RANGE._24h ? 'daily' : 'monthly'
    }
  })

  const COLS: TableCol[] = useMemo(
    () => [
      { title: '', key: 'edit' },
      { title: 'Date', key: 'date' },
      { title: 'Revenue Type', key: 'incomeType' },
      { title: 'Bond Name', key: 'bondName' },
      { title: 'Partner', key: 'partner' },
      { title: 'Asset', key: 'asset' },
      { title: 'Revenue Amount', key: 'incomeAmount' },
      { title: 'Revenue (USD)', key: 'incomeUsd' },
      { title: 'Revenue (ETH)', key: 'incomeEth' },
      { title: 'Revenue Annualized (USD)', key: 'incomeAnnualizedUsd' },
      {
        title: (
          <TablePagination
            page={bondsTransactions.filters.page}
            count={bondsTransactions.maxPages}
            onPage={bondsTransactions.onPage}
          />
        ),
        key: 'blockchain',
        align: 'right'
      }
    ],
    [bondsTransactions.filters.page, bondsTransactions.maxPages]
  )

  const Renderer = {
    edit: (row) => (
      <IconButton
        size="sm"
        className={classes.editIcon}
        onClick={() => setEditId(row['hash'])}
      >
        <EditIcon />
      </IconButton>
    ),
    date: (row) => (
      <Grid className={classes.nameCell}>
        <span>
          {row['date']
            ? format(new Date(row['date']), DATE_FORMAT_PRIMARY)
            : 'N/A'}
        </span>
      </Grid>
    ),
    incomeType: (row) => row['label'] || 'N/A',
    bondName: (row) => (
      <Grid className={classes.nameCell}>
        <CellImage src={getRemoteAssetIcon(row)} />
        <span>{row['asset'] || 'N/A'}</span>
      </Grid>
    ),
    partner: (row) => row['partner'] || 'N/A',
    asset: (row) => (
      <Grid className={classes.nameCell}>
        <CellImage src={getRemoteAssetIcon(row)} />
        <span>{row['asset'] || 'N/A'}</span>
      </Grid>
    ),
    incomeAmount: (row) => nFormatter(row['value_asset']),
    incomeUsd: (row) => parseMoney(row['income_usd_daily']),
    incomeEth: (row) =>
      parseMoney(row['income_eth_daily'], 2, false, CURRENCY._ETH),
    incomeAnnualizedUsd: (row) => parseMoney(row['income_annualized_usd']),
    blockchain: (row) => formatBlockchain(row['blockchain']) || 'N/A'
  }

  return (
    <>
      <Accordion
        open
        arrow={false}
        title="Other Revenue"
        onClick={() => {}}
        headerComponent={
          <div className={classes.rangeContainer}>
            <Typography className={classes.totalHead}>
              {'Total Revenue: '}
              {parseMoney(bondsTransactions.totalIncome, 2, true)}
            </Typography>

            <Tabs
              width="auto"
              tabs={RANGES_SECONDARY}
              value={range}
              onChange={setRange}
            />
          </div>
        }
      >
        <Table
          cols={COLS}
          loading={bondsTransactions.isLoading}
          empty={!bondsTransactions.bonds.length}
        >
          {bondsTransactions.bonds.map((row, index) => (
            <TableRow key={index}>
              {COLS.map((col) => (
                <TableCell key={col.key} align={col.align} {...cellProps}>
                  {Renderer[col.key as string](row)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </Table>
      </Accordion>

      <AddBondDialog
        open={!!editId}
        hash={editId}
        onClose={() => setEditId('')}
        revalidate={bondsTransactions.revalidate}
      />
    </>
  )
}
