import useSWR from 'swr'

import { getAsset } from '../services/api/assets'

interface UseAssetConfig {
  id?: string
}

interface UseAsset {
  isLoading: boolean
  asset: any
}

export default function useAsset(config: UseAssetConfig = {}): UseAsset {
  const { data, error } = useSWR(
    config.id ? `/get_asset_details/${config.id}` : null,
    getAsset
  )
  const isLoading = !data && !error
  const asset = data || {}
  return {
    isLoading,
    asset
  }
}
