import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Button'
import DatePicker from 'components/form/DatePicker'
import Input from 'components/form/Input'
import usePnlStatSingle from 'hooks/usePnlStatSingle'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { formatStatsDataToForm } from 'utils/pnl'

import Dialog, { DialogBaseProps } from './Dialog'
import DialogActions from './DialogActions'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.25rem'
  }
})

interface ContentProps extends DialogBaseProps {
  values?: any
  revalidate?: any
}

function Content(props: ContentProps) {
  const classes = useStyles()

  const pnlStatSingle = usePnlStatSingle()

  const form = useForm({
    defaultValues: {
      total_monthly_pnl_profit: 0,
      total_monthly_pnl_value: 0,
      total_monthly_sold_pnl_profit: 0,
      monthly_floating_pnl: 0,
      total_gain: 0,
      total_assets_value_this_month: 0,
      date_updated: undefined
    }
  })

  const key = JSON.stringify(props.values)

  useEffect(() => {
    form.reset(formatStatsDataToForm(props.values))
  }, [key])

  const handleSubmit = (values) => {
    pnlStatSingle.onEdit(values, () => {
      props.onClose?.()
      props.revalidate?.()
    })
  }

  return (
    <>
      <DialogBody>
        <Grid container className={classes.root}>
          <Input
            variant="secondary"
            label="Total Monthly PnL"
            placeholder="Total Monthly PnL"
            {...form.register('total_monthly_pnl_profit')}
          />
          <Input
            variant="secondary"
            label="Current Portfolio Value"
            placeholder="Current Portfolio Value"
            {...form.register('total_monthly_pnl_value')}
          />
          <Input
            variant="secondary"
            label="Monthly Closed Trading PnL"
            placeholder="Monthly Closed Trading PnL"
            {...form.register('total_monthly_sold_pnl_profit')}
          />
          <Input
            variant="secondary"
            label="Total % Gain This Month"
            placeholder="Total % Gain This Month"
            {...form.register('total_gain')}
          />
          <Input
            variant="secondary"
            label="Start Total"
            placeholder="Start Total"
            {...form.register('total_assets_value_this_month')}
          />
          <Controller
            control={form.control}
            render={(e) => (
              <DatePicker
                label="Update Date"
                placeholder="Update Date"
                value={e.field.value as unknown as Date}
                onChange={(_e) => form.setValue(e.field.name, _e as any)}
              />
            )}
            name="date_updated"
          />
        </Grid>
      </DialogBody>

      <DialogActions>
        <Button
          size="sm"
          variant="contained-2"
          color="secondary"
          onClick={props.onClose}
        >
          Reset
        </Button>
        <Button
          size="sm"
          variant="contained-2"
          onClick={() => form.handleSubmit(handleSubmit)()}
        >
          Save
        </Button>
      </DialogActions>
    </>
  )
}

export default function EditPnlStatsDialog(props: ContentProps) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      variant="secondary"
      title="Edit PnL"
      height={450}
      size="lg"
    >
      <Content {...props} />
    </Dialog>
  )
}
