import { useSpinner } from 'providers/SpinnerProvider'
import { toast } from 'react-toastify'
import { reportError } from 'services/api/report'

type OnReportErrorParams = { assetId?: string; message: string }
type OnReportError = (data: OnReportErrorParams, onSuccess: any) => void

interface UseReportError {
  onReportError: OnReportError
}

export default function useReportError(): UseReportError {
  const spinner = useSpinner()

  const onReportError: OnReportError = async (data, onSuccess) => {
    try {
      spinner.open()
      await reportError(data)
      spinner.close()
      toast.success('Report successfully sent!')
      onSuccess()
    } catch (e) {
      console.error(e)
      spinner.close()
      toast.error('Something went wrong')
    }
  }

  return {
    onReportError
  }
}
