import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ApiIcon, SettingsIcon } from 'assets/icons'
import { useState } from 'react'
import { device } from 'utils/screen'

import Api from './account/Api'
import MyAccount from './account/MyAccount'

const useStyles = makeStyles({
  tabs: {
    display: 'flex',
    overflowX: 'auto',
    borderBottom: '1px solid #41486E',
    marginBottom: '3rem',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',

    '&::-webkit-scrollbar': {
      display: 'none'
    },

    [`@media ${device.mobileL}`]: {
      marginBottom: '1.25rem'
    }
  },
  tab: {
    padding: '0.625rem 1rem',
    cursor: 'pointer',
    position: 'relative',
    minWidth: 175,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1rem',

    '& svg': {
      marginRight: '0.5rem'
    },

    '&[data-active="true"]': {
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        bottom: 0,
        left: 0,
        height: 4,
        backgroundColor: '#33B6FF',
        borderRadius: '3px 3px 0 0'
      }
    },

    [`@media ${device.mobileL}`]: {
      fontSize: '0.875rem',
      minWidth: 'fit-content',

      '& svg': {
        width: 16,
        height: 16,
        minWidth: 16,
        minHeight: 16
      }
    }
  }
})

const TABS = [
  { title: 'My account', icon: <SettingsIcon /> },
  { title: 'API', icon: <ApiIcon /> }
]

export default function Account() {
  const classes = useStyles()
  const [tab, setTab] = useState('My account')
  return (
    <Grid container direction="column">
      <Grid container className={classes.tabs}>
        {TABS.map((row, index) => (
          <Grid
            item
            key={index}
            className={classes.tab}
            data-active={tab === row.title}
            onClick={() => setTab(row.title)}
          >
            {row.icon}
            {row.title}
          </Grid>
        ))}
      </Grid>

      {tab === 'My account' && <MyAccount />}
      {tab === 'API' && <Api />}
    </Grid>
  )
}
