import { PaginationRequestDTO } from 'interfaces/common.contracts'
import { Rule, RulesResponse } from 'interfaces/transactions'
import { useState } from 'react'
import { fetcher, getKey } from 'services/api/common'
import useSWR, { mutate } from 'swr'

interface UseRulesParams extends PaginationRequestDTO {}

interface UseRulesResponse {
  isLoading: boolean
  rules: Rule[]
  page: number
  maxPages: number
  onPage: (page: number) => void
  revalidate: () => Promise<any>
}

export const useRules = (params?: UseRulesParams): UseRulesResponse => {
  const [page, onPage] = useState<number>(params?.page ?? 1)

  const requestKey = getKey('/auth/tx_labeling/get_rules', { page })
  const { data, error } = useSWR<RulesResponse>(requestKey, fetcher)

  const isLoading = !data && !error

  return {
    isLoading,
    rules: data?.data ?? [],
    maxPages: data?.max_pages ?? 0,
    page,
    onPage,
    revalidate: () => mutate(requestKey)
  }
}
