import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Button from 'components/buttons/Button'
import Chip from 'components/chips/Chip'
import Input from 'components/form/Input'
import Slider from 'components/sliders/Slider'
import Tabs from 'components/tabs/Tabs'
import useGlobals from 'hooks/useGlobals'
import useTrigger from 'hooks/useTrigger'
import { useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { FILTER_STEPS, RENDER_VALUE } from 'utils/filters'

import Drawer from './Drawer'
import DrawerActions from './DrawerActions'
import DrawerContent from './DrawerContent'
import DrawerHead from './DrawerHead'
import { FiltersDrawerTitle } from './FiltersDrawer'

const useStyles = makeStyles({
  root: {},
  input: {
    marginBottom: '2rem'
  },
  tabs: {
    textTransform: 'uppercase',
    marginBottom: '1.5rem',
    fontWeight: 500
  },
  subtitle: {
    fontWeight: 500,
    marginBottom: '1rem',
    fontSize: '1.25rem'
  },
  typeTabs: {
    marginBottom: '2rem'
  },
  chains: {
    marginBottom: '2rem'
  }
})

interface ContentProps {
  onClose: () => void
}

function Content({ onClose }: ContentProps) {
  const classes = useStyles()
  const [type] = useState('new')
  const { chains } = useGlobals()
  const { onAdd } = useTrigger()

  const methods = useForm({
    defaultValues: {
      trigger_name: '',
      entity: 'asset',
      blockchain: '',
      tvl: 10000000,
      notifier_type: 'email'
    }
  })

  const [entity] = useWatch({ control: methods.control, name: ['entity'] })

  const handleSubmit = (values: any) => {
    onAdd(values, onClose)
  }

  return (
    <>
      <DrawerHead title="Add Trigger" onClose={onClose} />

      <DrawerContent>
        <Input
          label="Trigger name"
          variant="secondary"
          placeholder="Enter trigger name"
          className={classes.input}
          {...methods.register('trigger_name')}
        />

        <Controller
          name="entity"
          control={methods.control}
          render={({ field: { name, value } }) => (
            <Tabs
              value={value}
              onChange={(source) => methods.setValue(name, source)}
              tabs={[
                { name: 'Assets', value: 'asset' },
                { name: 'Farms', value: 'farm' }
              ]}
              className={classes.tabs}
            />
          )}
        />

        <Typography className={classes.subtitle}>
          {entity === 'asset' ? 'Asset Range' : 'Farm Range'}
        </Typography>

        <Tabs
          value={type}
          onChange={(type) => console.log(type)}
          className={classes.typeTabs}
          tabs={[
            {
              name: entity === 'asset' ? 'Existing assets' : 'Existing farms',
              value: 'existing'
            },
            {
              name:
                entity === 'asset' ? 'New asset listing' : 'New farm listing',
              value: 'new'
            }
          ]}
        />

        <Controller
          render={({ field: { name, value } }) => (
            <Grid container justifyContent="center" className={classes.chains}>
              {chains.map((chain) => (
                <Chip
                  key={chain}
                  onClick={() => methods.setValue(name, chain)}
                  active={chain === value}
                >
                  {chain}
                </Chip>
              ))}
            </Grid>
          )}
          control={methods.control}
          name="blockchain"
        />

        <Controller
          render={({ field: { name, value } }) => (
            <Grid container>
              <Slider
                min={0}
                max={11}
                step={1}
                defaultValue={FILTER_STEPS.findIndex((e) => e === value)}
                valueFormat={(value) => FILTER_STEPS[value as number]}
                renderValueFormat={RENDER_VALUE['tvl_min']}
                labelComponent={
                  <FiltersDrawerTitle>Liquidity higher than</FiltersDrawerTitle>
                }
                onChange={(e) => methods.setValue(name, e as number)}
              />
            </Grid>
          )}
          control={methods.control}
          name="tvl"
        />
      </DrawerContent>

      <DrawerActions>
        <Button variant="contained-2" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained-2"
          onClick={() => methods.handleSubmit(handleSubmit)()}
        >
          Create
        </Button>
      </DrawerActions>
    </>
  )
}

interface AddTriggerDrawerProps {
  open: boolean
}

export default function AddTriggerDrawer({
  open,
  ...props
}: AddTriggerDrawerProps & ContentProps) {
  return (
    <Drawer open={open} onClose={props.onClose}>
      <Content {...props} />
    </Drawer>
  )
}
