import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useAuth } from 'providers/AuthProvider'
import { device } from 'utils/screen'

import useRole from 'hooks/useRole'


const useStyles = makeStyles({
  infoActions: {
    marginBottom: '4rem',

    [`@media ${device.tablet}`]: {
      marginBottom: '2rem'
    }
  },
  actionBtn: {
    backgroundColor: 'transparent',
    border: 0,
    fontSize: '1rem',
    fontWeight: 700,
    color: '#66C8FF',
    marginRight: '2rem',
    padding: 0
  },
  currentPlan: {
    fontSize: '1rem',
    fontWeight: 700,
    color: '#fff',
    marginBottom: '1rem'
  },
  currentPlanContainer: {
    marginBottom: '4rem',

    [`@media ${device.tablet}`]: {
      marginBottom: '2rem'
    }
  },
  emailInput: {
    width: '100%',
    maxWidth: 400
  },
  saveEmailBtn: {
    height: 40,
    marginLeft: '2rem',

    [`@media ${device.tablet}`]: {
      margin: '0.5rem 0'
    }
  },
  emailContainer: {
    marginBottom: '4rem',

    [`@media ${device.tablet}`]: {
      marginBottom: '2rem'
    }
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    color: '#fff',
    marginBottom: '1.25rem'
  },
  payoutContainer: {
    display: 'flex',

    [`@media ${device.tablet}`]: {
      flexDirection: 'column'
    }
  },
  requestPayoutBtn: {
    width: 'fit-content',
    margin: '0 2rem',
    whiteSpace: 'nowrap',
    borderRadius: 15,
    background: 'linear-gradient(110.29deg, #33B6FF -3.21%, #1A6B9F 105.5%)',
    fontFamily: 'Roboto',
    fontWeight: 400,

    [`@media ${device.tablet}`]: {
      width: '100%',
      margin: 0
    }
  },
  select: {
    width: 200,

    [`@media ${device.tablet}`]: {
      width: '100%',
      marginBottom: '1.25rem'
    }
  },
  selectInput: {
    background: 'linear-gradient(107.39deg, #474F7E 3.48%, #343B61 97.52%)',
    fontWeight: 400
  },
  selectContainer: {
    marginBottom: '1.5rem',

    [`@media ${device.tablet}`]: {
      flexDirection: 'column'
    }
  },
  payoutInfoContainer: {
    [`@media ${device.tablet}`]: {
      flexDirection: 'column'
    }
  },
  payoutInfoText: {
    marginRight: '2rem',
    fontWeight: 700,
    fontSize: '0.875rem',

    [`@media ${device.tablet}`]: {
      marginRight: 0,
      marginBottom: '1rem'
    }
  },
  refContainer: {
    marginBottom: '4rem',

    [`@media ${device.tablet}`]: {
      marginBottom: '2rem',
      flexDirection: 'column'
    }
  },
  refLinkContainer: {
    marginRight: '4rem',

    [`@media ${device.tablet}`]: {
      marginRight: 0
    }
  },
  refContent: {
    [`@media ${device.tablet}`]: {
      flexDirection: 'column'
    }
  },
  refLinkLabel: {
    height: 40,
    borderRadius: 15,
    background: 'linear-gradient(107.39deg, #474F7E 3.48%, #343B61 97.52%)',
    padding: '0 1.25rem',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.5rem',
    width: 'fit-content',

    [`@media ${device.tablet}`]: {
      width: '100%'
    }
  },
  refLink: {
    fontWeight: 400,
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',

    [`@media ${device.tablet}`]: {
      marginBottom: '1.25rem'
    }
  },
  copyBtn: {
    margin: '0 0.25rem',
    background: 'transparent',
    border: 0,
    color: '#66C8FF'
  }
})

export default function MyAccount() {
  const classes = useStyles()
  const auth = useAuth()
  const role = useRole()
  return (
    <>


      <Grid container>
        <p className={classes.emailContainer}>
          Username: {role.data.username}
        </p>
      </Grid>

      <Grid container className={classes.infoActions}>
        <p className={classes.currentPlan}>
          Role: {role.data.role}
        </p>
      </Grid>

      <Grid container className={classes.infoActions}>
        <button className={classes.actionBtn}>Reset Password</button>
      </Grid>

      <Grid>
        <button className={classes.actionBtn} onClick={() => auth.onLogout()}>
          Log Out
        </button>
      </Grid>
    </>
  )
}
