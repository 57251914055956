import useBlockchainTypes from 'hooks/useBlockchainSelects'
import useExchangesSelect from 'hooks/useExchangesSelect'
import { blockchainsToOptions } from 'utils/exchanges'
import { formatPoolTypesToOptions } from 'utils/pools'

import Select, { SelectProps } from './Select'

export default function BlockchainSelect(props: Partial<SelectProps>) {
  const exchangesSelect = useExchangesSelect()
  const options = blockchainsToOptions(exchangesSelect.data.blockchains)
  return (
    <Select
      options={options}
      value={props.value}
      onChange={props.onChange}
      {...props}
    />
  )
}

export function AssetBlockchainSelect(props: Partial<SelectProps>) {
  const poolsTypes = useBlockchainTypes()
  const options = formatPoolTypesToOptions(poolsTypes.types)
  return <Select options={options} {...props} />
}
