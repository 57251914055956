import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem 0'
  }
})

interface LoadingPlaceholderProps {
  height?: string | number
}

export function LoadingPlaceholder({ height }: LoadingPlaceholderProps) {
  const classes = useStyles()
  return (
    <div className={classes.root} style={{ height }}>
      Loading...
    </div>
  )
}

interface EmptyPlaceholderProps {
  text?: string
  height?: string | number
  className?: string
}

export function EmptyPlaceholder({
  text = 'No Data',
  height,
  className
}: EmptyPlaceholderProps) {
  const classes = useStyles()
  return (
    <div className={classNames(classes.root, className)} style={{ height }}>
      {text}
    </div>
  )
}
