import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { EditIcon } from 'assets/icons'
import IconButton from 'components/buttons/IconButton'
import EditStrategyDialog from 'components/dialogs/EditStrategyDialog'
import Table from 'components/table/Table'
import TableCell from 'components/table/TableCell'
import TablePagination from 'components/table/TablePagination'
import TableRow from 'components/table/TableRow'
import useNft from 'hooks/useNft'
import { useState } from 'react'
import { TableCol } from 'types/components/table'
import { parseMoney } from 'utils/api'

import ChartTooltip from '../tooltips/ChartTooltip'

const useStyles = makeStyles({
  action: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between'
  },
  img: {
    maxWidth: 200
  },
  name: {
    display: 'flex',
    alignItems: 'center'
  },
  iconsCell: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: '1rem',
      cursor: 'pointer',

      '&:last-child': {
        marginRight: 0
      }
    }
  },
  iconBtn: {
    marginRight: '0.5rem'
  }
})

export default function NftHoldingsTable() {
  const classes = useStyles()
  const nft = useNft()
  const [editedNFT, setEditedNFT] = useState<any>(null)
  const COLS: TableCol[] = [
    {
      title: '',
      render: (row) => (
        <Grid className={classes.iconsCell}>
          <IconButton size="sm" onClick={() => setEditedNFT(row)}>
            <EditIcon />
          </IconButton>
        </Grid>
      )
    },
    {
      title: 'Name',
      render: (row) => (
        <div className={classes.name}>
          <span>{row.asset}</span>
          {row.image_url && (
            <ChartTooltip
              content={
                <img className={classes.img} src={row.image_url} alt="" />
              }
            />
          )}
        </div>
      )
    },
    { title: 'Project', render: (row) => row.farm || '-' },
    { title: 'Blockchain', render: (row) => row.blockchain || '-' },
    { title: 'Holdings', render: (row) => parseMoney(row.positionSizeUsd) },
    {
      align: 'right',
      title: (
        <div className={classes.action}>
          <TablePagination
            count={nft.maxPages}
            page={nft.filters.page}
            onPage={nft.onPage}
          />
        </div>
      ),
      render: () => null
    }
  ]
  return (
    <>
      <Table cols={COLS} empty={!nft.data.length} loading={nft.isLoading}>
        {nft.data.map((row, index) => (
          <TableRow key={index}>
            {COLS.map((col, index) => (
              <TableCell key={index}>{col.render?.(row)}</TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
      <EditStrategyDialog
        poolId={editedNFT?.poolId as string}
        open={!!editedNFT}
        onClose={() => setEditedNFT(null)}
      />
    </>
  )
}
