import useSWR from 'swr'

import { fetcher } from '../services/api/common'
import { stringifyURL } from '../utils/query'

interface UseExchangeAllChartsConfig {
  assetAddress?: string
  from?: string
  to?: string
  fields?: string[]
}

interface UseExchangeAllCharts {
  isLoading: boolean
  data: Record<string, Record<string, string>>
}

function getKey(params: any) {
  return params.asset_address &&
    params.fields &&
    params.date_from &&
    params.date_to
    ? stringifyURL('/auth/get_exchange_chart', params, {
        arrayFormat: 'comma'
      })
    : null
}

export default function useExchangeAllCharts(
  config: UseExchangeAllChartsConfig = {}
): UseExchangeAllCharts {
  const params = {
    asset_address: config.assetAddress,
    date_from: config.from,
    date_to: config.to,
    fields: config.fields
  }

  const { data: _data, error } = useSWR(() => getKey(params), fetcher)

  const isLoading = !_data && !error
  const data = _data || []

  return {
    isLoading,
    data
  }
}
