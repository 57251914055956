import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import { forwardRef, ReactNode } from 'react'

const COLORS = {
  contained: {
    primary: '#33B6FF',
    secondary: '#D733FF'
  },
  'semi-transparent': {
    primary: 'rgba(51, 184, 255, 0.25)',
    secondary: 'rgba(214, 51, 255, 0.25)'
  },
  'contained-2': {
    primary: 'linear-gradient(110.29deg, #33B6FF -3.21%, #1A6B9F 105.5%)',
    secondary: 'linear-gradient(110.29deg, #D733FF -3.21%, #66308B 105.5%)'
  },
  outlined: {
    primary: 'transparent',
    secondary: 'transparent'
  }
}

const TEXT_COLORS = {
  contained: {
    primary: '#fafafa',
    secondary: '#fafafa'
  },
  'contained-2': {
    primary: '#fafafa',
    secondary: '#fafafa'
  },
  'semi-transparent': {
    primary: '#33B6FF',
    secondary: '#D733FF'
  },
  outlined: {
    primary: '#33B6FF',
    secondary: '#D733FF'
  }
}

const BORDERS = {
  contained: {
    primary: '#33B6FF',
    secondary: '#D733FF'
  },
  outlined: {
    primary: '#33B6FF',
    secondary: '#D733FF'
  }
}

const useStyles = makeStyles({
  root: (props: any) => ({
    height: props.size === 'sm' ? 40 : 50,
    fontSize: '1rem',
    padding: '0 1.5rem',
    background: COLORS[props.variant][props.color],
    color: TEXT_COLORS[props.variant][props.color],
    border: BORDERS[props.variant]
      ? `2px solid ${BORDERS[props.variant][props.color]}`
      : 0,
    transition: 'all 0.25s',
    fontFamily: 'Roboto',
    borderRadius: 15,
    fontWeight: 400,

    '& svg': {
      marginRight: '0.5rem'
    },

    '&:disabled': {
      pointerEvents: 'none',
      opacity: 0.8
    }
  })
})

export interface ButtonProps {
  color?: 'primary' | 'secondary'
  variant?: 'contained' | 'semi-transparent' | 'contained-2' | 'outlined'
  className?: string
  onClick?: any
  size?: 'sm'
  font?: 'Roboto'
  type?: 'button' | 'submit'
  disabled?: boolean
  form?: string
  children: ReactNode
}

const Button = forwardRef(
  (
    {
      children,
      color = 'primary',
      variant = 'contained',
      className,
      onClick,
      size,
      font,
      type,
      disabled,
      form,
      ...props
    }: ButtonProps,
    ref
  ) => {
    const classes = useStyles({ color, variant, size, font })
    return (
      <button
        ref={ref as any}
        form={form}
        className={classNames(classes.root, className)}
        onClick={onClick}
        disabled={disabled}
        type={type}
        {...props}
      >
        {children}
      </button>
    )
  }
)

export default Button
