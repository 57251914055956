import { toast } from 'react-toastify'
import { addDomain, deleteDomain } from 'services/api/domains'

type OnAdd = (domain: string, onSuccess?: any) => void
type OnDelete = (domain: string, onSuccess?: any) => void

interface UseApiDomain {
  onAdd: OnAdd
  onDelete: OnDelete
}

interface UseApiDomainConfig {
  revalidate?: any
}

export default function useApiDomain(
  config: UseApiDomainConfig = {}
): UseApiDomain {
  const onAdd: OnAdd = async (domain, onSuccess) => {
    try {
      const data = {
        domain
      }
      await addDomain(data)
      toast.success('Successfully saved')
      config.revalidate?.()
      onSuccess?.()
    } catch (e) {
      toast.error('Oops error')
      console.error(e)
    }
  }

  const onDelete: OnDelete = async (domain, onSuccess) => {
    try {
      await deleteDomain(domain)
      toast.success('Successfully removed')
      config.revalidate?.()
      onSuccess?.()
    } catch (e) {
      toast.error('Oops error')
      console.error(e)
    }
  }

  return {
    onAdd,
    onDelete
  }
}
