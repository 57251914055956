import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'

import Button from '../buttons/Button'
import Dialog from './Dialog'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  actions: {
    paddingTop: '1.25rem',

    '& button': {
      '&:first-child': {
        marginRight: '1.25rem'
      }
    }
  }
})

const WARN =
  'Note, this action will hide this field from edit form and selection dropdown! You will be able to add this field again without losing the data for all Assets. If you want to clear this field only for this Asset, just leave this field empty.'

interface WarnEditFieldDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export default function WarnEditFieldDialog({
  onClose,
  open,
  onConfirm
}: WarnEditFieldDialogProps) {
  const classes = useStyles()
  return (
    <Dialog
      height={275}
      open={open}
      onClose={onClose}
      title="Warning!"
      variant="secondary"
    >
      <DialogBody>
        <Typography>{WARN}</Typography>

        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          className={classes.actions}
        >
          <Button variant="contained-2" size="sm" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained-2"
            size="sm"
            color="secondary"
            onClick={() => {
              onConfirm()
              onClose()
            }}
          >
            Delete
          </Button>
        </Grid>
      </DialogBody>
    </Dialog>
  )
}
