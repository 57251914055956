export function omitEmpty(obj: Record<string, any>, ignore?: string[]) {
  const res = {}

  Object.keys(obj).map((key) => {
    if (
      ignore?.includes(key) ||
      !(
        obj[key] === null ||
        obj[key] === undefined ||
        obj[key] === '' ||
        obj[key] === 0
      )
    ) {
      res[key] = obj[key]
    }
  })

  return res
}

export function reverse<T = any>(arr: T[]): T[] {
  return arr.slice().reverse()
}

export function omit(
  obj: Record<string, any>,
  keys: string | string[]
): Record<string, any> {
  const copy = { ...obj }

  if (typeof keys === 'string') {
    delete copy[keys]
  } else {
    keys.forEach((key) => {
      delete copy[key]
    })
  }

  return copy
}

export function numberFormatter(e: string): number {
  return Number(e)
}

export function objFormat<T, K>(
  obj: Record<string, T>,
  formatter: (e: T) => K
): Record<string, K> {
  return Object.keys(obj).reduce((acc, cur) => {
    return {
      ...acc,
      [cur]: formatter(obj[cur])
    }
  }, {})
}
