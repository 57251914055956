const formatter = {
  decimals: Number,
  value_asset: Number,
  value_eth: Number,
  value_usd: Number
}

export function formatBondValues(values) {
  return Object.keys(values).reduce((acc, cur) => {
    return {
      ...acc,
      [cur]: formatter[cur] ? formatter[cur](values[cur]) : values[cur]
    }
  }, {})
}
