import { OnPage } from 'components/table/TablePagination'
import { useState } from 'react'
import { fetcher } from 'services/api/common'
import useSWR from 'swr'
import { stringifyURL } from 'utils/query'

export interface AllPoolsFilters {
  page: number
  per_page?: number
  poolIds?: string[]
}

interface UseAllPools {
  isLoading: boolean
  allPools: any[]
  maxPages: number
  onPage: OnPage
  filters: AllPoolsFilters
  revalidate: any
}

interface UseAllPoolsConfig {
  filters?: Partial<AllPoolsFilters>
}

function isReq(params: Partial<AllPoolsFilters>) {
  return !params.poolIds || (!!params.poolIds && !!params.poolIds.length)
}

function getAllPoolsKey(params: Partial<AllPoolsFilters>) {
  return isReq(params)
    ? stringifyURL('/auth/get_strategies_performance', params, {
        arrayFormat: 'comma'
      })
    : null
}

export default function useAllPools(
  config: UseAllPoolsConfig = {}
): UseAllPools {
  const [filters, setFilters] = useState<AllPoolsFilters>({
    page: 1
  })

  const params: Partial<AllPoolsFilters> = { ...filters, ...config.filters }

  const {
    data,
    error,
    mutate: revalidate
  } = useSWR(() => getAllPoolsKey(params), fetcher)

  const onPage = (page) => {
    setFilters((filters) => ({
      ...filters,
      page
    }))
  }

  const isLoading = isReq(params) ? !data && !error : false
  const allPools = Array.isArray(data?.pools) ? data.pools : []

  const maxPages = data?.max_pages || 0

  return {
    isLoading,
    allPools,
    maxPages,
    filters,
    onPage,
    revalidate
  }
}
