import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { InfoIcon } from 'assets/icons'
import cx from 'classnames'
import BookButton from 'components/buttons/BookButton'
import CellImage from 'components/images/CellImage'
import PoolEconomicsTooltip from 'components/PoolEconomicsTooltip'
import Table from 'components/table/Table'
import TableCell from 'components/table/TableCell'
import TableRow from 'components/table/TableRow'
import { Link } from 'components/typography'
import useAssets from 'hooks/useAssets'
import useSavedSearches from 'hooks/useSavedSearches'
import { useAuth } from 'providers/AuthProvider'
import { useSpinner } from 'providers/SpinnerProvider'
import { TableCol } from 'types/components/table'
import { formatRows } from 'utils/assets'
import { getSearchesIds } from 'utils/searches'

const useStyles = makeStyles({
  btn: {
    marginRight: '1.25rem',
    whiteSpace: 'nowrap'
  },
  btnDisabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  name: {
    color: '#fff',
    fontWeight: 400
  },
  nameCol: {
    display: 'flex',
    alignItems: 'center'
  }
})

interface AssetsTableProps {
  farmId: string
}

const COLS: TableCol[] = [
  { title: 'Asset', name: 'asset', sortable: true },
  { title: 'TVL', name: 'tvlStaked', sortable: true },
  { title: 'APR (y)', name: 'aprYearly', sortable: true },
  { title: 'Farm', name: 'farm', sortable: true },
  { title: 'Rewards', name: 'rewardTokenA', sortable: true },
  { title: '', sortable: false }
]

export default function AssetsTable({ farmId }: AssetsTableProps) {
  const { isLogged } = useAuth()
  const classes = useStyles()
  const { assets, isLoading, filters, onSort } = useAssets({
    filters: {
      farm_ids: [farmId],
      num_per_page: 5,
      farms_tvl_staked_gte: 0
    }
  })

  useSpinner(isLoading)

  const rows = formatRows(assets)

  const { savedSearches, onSaveSearch, onRemoveSearch } = useSavedSearches({
    save_type: 'asset'
  })

  const savedIds = getSearchesIds(savedSearches, 'assetId')

  return (
    <Table
      cols={COLS}
      empty={!assets.length}
      sort={filters.sort}
      sortOrder={filters.sort_order}
      onSort={onSort}
    >
      {rows.map((row) => (
        <TableRow key={row.assetId} size="sm">
          <TableCell color="secondary">
            <div className={classes.nameCol}>
              {isLogged && (
                <BookButton
                  booked={savedIds.includes(row.assetId)}
                  onBook={() => onSaveSearch(row.assetId, 'asset')}
                  onUnBook={() => onRemoveSearch(row.assetId)}
                />
              )}

              <CellImage src={row.imageUrl} />

              <Link to={`/assets/${row.assetId}`} className={classes.name}>
                {row.asset}
              </Link>
            </div>
          </TableCell>
          <TableCell color="secondary">{row.tvlStaked}</TableCell>
          <TableCell color="secondary">{row.aprYearly}</TableCell>
          <TableCell color="secondary">{row.farm}</TableCell>
          <TableCell color="secondary">{row.rewards}</TableCell>
          <TableCell color="secondary" align="right" wrap="wrap">
            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              wrap="nowrap"
            >
              <Link
                to={row.investLink}
                native
                target="_blank"
                rel="noreferrer noopener"
                className={cx(classes.btn, {
                  [classes.btnDisabled]: !row.investLink
                })}
              >
                Invest
              </Link>
              <PoolEconomicsTooltip data={row}>
                <InfoIcon />
              </PoolEconomicsTooltip>
            </Grid>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
