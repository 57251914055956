import useBlockchainTypes from 'hooks/useBlockchainSelects'
import { formatPoolTypesToOptions } from 'utils/pools'

import Select, { SelectProps } from './Select'

export default function AssetBlockchainSelect(props: Partial<SelectProps>) {
  const poolsTypes = useBlockchainTypes()
  const options = formatPoolTypesToOptions(poolsTypes.types)
  return <Select options={options} {...props} />
}
