import { toast } from 'react-toastify'
import { fetcher } from 'services/api/common'
import useSWR from 'swr'

import { useSpinner } from '../providers/SpinnerProvider'
import { updatePool } from '../services/api/pools'
import { stringifyURL } from '../utils/query'

type OnPool = (
  poolId: string,
  updates: any,
  weights: any,
  onSuccess?: any
) => void

export interface UsePool {
  isLoading: boolean
  pool: any
  onUpdate: OnPool
}

interface UsePoolConfig {
  poolId?: string
}

function getKey(params) {
  return params.pool_id ? stringifyURL('/auth/get_user_pool', params) : null
}

export default function usePool(config: UsePoolConfig = {}): UsePool {
  const spinner = useSpinner()

  const params = {
    pool_id: config.poolId
  }

  const { data, error, mutate: revalidate } = useSWR(getKey(params), fetcher)

  const onUpdate: OnPool = async (poolId, updates, weights, onSuccess) => {
    try {
      spinner.open()
      await updatePool(poolId, updates, weights)

      revalidate?.()

      toast.success('Asset updated successfully')

      spinner.close()

      onSuccess?.()
    } catch (e) {
      spinner.close()
      console.error(e)
      toast.error('Oops error')
    }
  }

  const isLoading = config.poolId ? !data && !error : false
  const pool = data || {}

  return {
    isLoading,
    pool,
    onUpdate
  }
}
