import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'

import { device } from '../../utils/screen'

const useStyles = makeStyles({
  root: (props: any) => ({
    backgroundColor: '#373D66',
    height: 40,
    borderRadius: 15,
    color: '#fafafa',
    ...(props.width === 'full' && {
      display: 'grid',
      gridTemplateColumns: (props: any) => `repeat(${props.tabs}, 1fr)`
    }),
    ...(props.width === 'auto' && {
      display: 'flex',
      width: 'fit-content'
    }),

    [`@media ${device.tablet}`]: {
      height: 35
    }
  }),
  tab: (props) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0.25rem 0.75rem',
    fontSize: '0.875rem',
    minWidth: 50,

    ...(props.width === 'fill' && {
      width: '100%'
    }),
    ...(props.width === 'auto' && {
      width: 'fit-content'
    }),

    '&[data-active="true"]': {
      background: 'linear-gradient(110.29deg, #33B6FF -3.21%, #1A6B9F 105.5%)',
      borderRadius: 15
    },

    [`@media ${device.tablet}`]: {
      fontSize: '0.75rem',
      padding: '0.25rem 0.7rem'
    }
  })
})

interface Tab {
  name: string
  value: string
}

interface TabsProps {
  tabs: Tab[]
  value: string
  onChange: (value: string) => void
  className?: string
  tabClassName?: string
  width?: 'auto' | 'full'
}

export default function Tabs({
  tabs,
  value,
  onChange,
  className,
  tabClassName,
  width = 'full'
}: TabsProps) {
  const classes = useStyles({ tabs: tabs.length, width })
  return (
    <Grid container className={classNames(classes.root, className)}>
      {tabs.map((row, index) => (
        <Grid
          key={index}
          className={classNames(classes.tab, tabClassName)}
          data-active={value === row.value}
          onClick={() => onChange(row.value)}
        >
          {row.name}
        </Grid>
      ))}
    </Grid>
  )
}
