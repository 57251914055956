export const nFormatter = (num: number, fixed = 2): string => {
  let isNegative = false
  let formattedNumber
  if (num < 0) {
    isNegative = true
  }
  num = Math.abs(num)
  if (num >= 1000000000) {
    formattedNumber =
      (num / 1000000000).toFixed(fixed).replace(/\.0$/, '') + 'B'
  } else if (num >= 1000000) {
    formattedNumber = (num / 1000000).toFixed(fixed).replace(/\.0$/, '') + 'M'
  } else if (num >= 1000) {
    formattedNumber = (num / 1000).toFixed(fixed).replace(/\.0$/, '') + 'k'
  } else {
    formattedNumber = num.toFixed(fixed)
  }
  if (isNegative) {
    formattedNumber = '-' + formattedNumber
  }
  return formattedNumber
}

export function createMarkup(str: string) {
  return { __html: str }
}
