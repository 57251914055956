import Api from '../Api'

export async function getLeaderboards(url) {
  const response = await Api.get(url)
  return response.data
}

export async function voteLeaderboard(name) {
  const response = await Api.post('/auth/upvote_leaderboard', { name })
  return response.data
}

export async function downVoteLeaderboard(name) {
  const response = await Api.post('/auth/downvote_leaderboard', { name })
  return response.data
}
