import { OnPage } from 'components/table/TablePagination'
import { useState } from 'react'
import { fetcher } from 'services/api/common'
import useSWR from 'swr'
import { reverse } from 'utils/obj'
import { stringifyURL } from 'utils/query'

interface PnlFilters {
  date: string
}

function getKey(params) {
  return stringifyURL('/auth/get_pnl_stats', params)
}

export default function usePnlStats() {
  const [filters, onFilters] = useState<PnlFilters>({
    date: ''
  })

  const params = {
    ...filters
  }

  const {
    data: _data,
    error,
    mutate: revalidate
  } = useSWR(() => getKey(params), fetcher)

  const onPage: OnPage = (page) => {
    onFilters((filters) => ({
      ...filters,
      page
    }))
  }

  const onFilter = (key: string, value: number | string) => {
    onFilters((filters) => ({
      ...filters,
      [key]: value
    }))
  }

  const isLoading = !_data && !error
  const data = _data?.data || {}
  const maxPages = _data?.max_pages || 1
  const dates = reverse(_data?.available_dates || [])

  return {
    isLoading,
    maxPages,
    onPage,
    onFilter,
    filters,
    data,
    dates,
    revalidate
  }
}
