import { OnTableSort } from 'components/table/Table'
import { SortOrder } from 'components/table/TableHeadCell'
import { OnPage } from 'components/table/TablePagination'
import debounce from 'lodash.debounce'
import { useCallback, useState } from 'react'
import { getExchanges } from 'services/api/exchanges'
import useSWR from 'swr'
import {
  OnClearFilter,
  OnClearFilters,
  OnFilters,
  OnSearch
} from 'types/components/filters'
import { API_DATE_FORMAT_SECONDARY, format, getNow, subDays } from 'utils/date'

export interface ExchangesFilters {
  page: number
  per_page: number
  asset: string
  sort: string
  sort_order: SortOrder
  netRoiLast30d: number[]
  lpReturnHODLLast30d: number[]
  netPrincipalRoiLast30d: number[]
  impLossLast30d: number[]
  exchangeName: string[]
  blockchain: string[]
  liquidityUSD: number
  dateUpdated: string
}

interface UseExchanges {
  isLoading: boolean
  exchanges: any[]
  maxPages: number
  onPage: OnPage
  onSort: OnTableSort
  onSearch: OnSearch
  filters: ExchangesFilters
  onFilters: OnFilters<ExchangesFilters>
  onClearFilters: OnClearFilters
  onClearFilter: OnClearFilter<ExchangesFilters>
}

function getKey(params) {
  return '/auth/get_exchanges?' + JSON.stringify(params)
}

const dateUpdated = subDays(getNow(), 2)
const dateUpdatedFormatted = format(dateUpdated, API_DATE_FORMAT_SECONDARY)

const initialFilters: ExchangesFilters = {
  page: 1,
  per_page: 25,
  asset: '',
  sort: 'asset',
  sort_order: 'asc',
  exchangeName: [],
  blockchain: [],
  liquidityUSD: 0,
  netRoiLast30d: [0, 0],
  lpReturnHODLLast30d: [0, 0],
  netPrincipalRoiLast30d: [0, 0],
  impLossLast30d: [0, 0],
  dateUpdated: `{ gte: ${dateUpdatedFormatted} }`
}

export default function useExchanges(): UseExchanges {
  const [filters, setFilters] = useState<ExchangesFilters>(initialFilters)

  const params = {
    ...filters,
    sort_order: filters.sort_order === 'asc' ? 1 : 0
  }

  const { data, error } = useSWR(() => getKey(params), getExchanges)

  const onPage: OnPage = (page) => {
    setFilters((filters) => ({
      ...filters,
      page: page
    }))
  }

  const onSort: OnTableSort = (name, order) => {
    setFilters((filters) => ({
      ...filters,
      sort: name,
      sort_order: order
    }))
  }

  const onSearch: OnSearch = useCallback(
    debounce((search) => {
      setFilters((filters) => ({
        ...filters,
        page: 1,
        asset: search
      }))
    }, 400),
    []
  )

  const onFilters: OnFilters<ExchangesFilters> = (filters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...filters
    }))
  }

  const onClearFilters: OnClearFilters = () => {
    setFilters(initialFilters)
  }

  const onClearFilter: OnClearFilter<ExchangesFilters> = (key) => {
    setFilters((filters) => ({
      ...filters,
      [key]: initialFilters[key]
    }))
  }

  const isLoading = !data && !error
  const exchanges = data?.exchanges || []
  const maxPages = data?.max_pages || 1

  return {
    isLoading,
    exchanges,
    maxPages,
    filters,
    onPage,
    onSort,
    onSearch,
    onFilters,
    onClearFilters,
    onClearFilter
  }
}
