import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

export default function NoAccess() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography>
        {"You don't have access to premium features yet."}
      </Typography>
      <Typography>{'Please contact Multifarm'}</Typography>
    </Grid>
  )
}
