import Api from '../Api'

export async function addDomain(data) {
  const response = await Api.post('/auth/add_api_domain', data)
  return response.data
}

export async function deleteDomain(domain) {
  const response = await Api.get(`/auth/delete_api_domain?domain=${domain}`)
  return response.data
}
