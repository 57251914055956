import { FilterOptionsState } from '@material-ui/lab'

import { BlockchainSelectGroups } from '../../hooks/useBlockchainSelect'
import { BlockchainDropdownOption } from '../../interfaces/blockchain-dropdown-option'
import { Chain } from '../../services/types/globals'

/**
 * Strategies for blockchain dropdown behaviour.
 */
export abstract class BlockchainDropdownStrategy {
  constructor(
    protected props: {
      active: Chain[]
      groups: BlockchainSelectGroups
    }
  ) {
    const { groups } = props
    this.options = this.buildOptions(groups)
    this.selected = this.getOptionsFromChains()
  }

  /**
   * Available options.
   */
  public readonly options: BlockchainDropdownOption[]

  /**
   * Selected options.
   */
  public readonly selected: BlockchainDropdownOption[]

  /**
   * Returns the value that should be passed to the filter to get an empty result.
   * @returns Filter sequence.
   */
  public abstract getChainClearValue(): Chain[]

  /**
   * Processes selected options according to concrete strategy and returns value suitable for the blockchains filter.
   * @param selection - Options passed.
   * @returns Filter sequence.
   */
  public abstract getChainsFromSelection(
    selection?: BlockchainDropdownOption[]
  ): Chain[]

  /**
   * Returns title value to display on the dropdown component.
   * @returns string.
   */
  public abstract getTitle(): string

  /**
   * Returns options selection by group passed.
   * @param group - Group.
   * @returns option or options.
   */
  public getOptionsSelectionFromGroup(
    group: string
  ): BlockchainDropdownOption[] {
    if (!Array.isArray(this.selected)) {
      return []
    }
    const grouped = this.options.filter((item) => item.group === group)
    const selectedInGroup = this.selected.filter((item) => item.group === group)
    const excluded = this.selected.filter((item) => item.group !== group)
    return selectedInGroup.length === grouped.length
      ? excluded
      : [...grouped, ...excluded]
  }

  /**
   * Filters blockchain select options based on the passed search query.
   * @param options - Available select options.
   * @param state - Current filter state.
   * @returns Filtered options.
   */
  public filterOptions(
    options: BlockchainDropdownOption[],
    state: FilterOptionsState<BlockchainDropdownOption>
  ): BlockchainDropdownOption[] {
    if (!state.inputValue) {
      return options
    }
    const searchQuery = state.inputValue.toLowerCase()
    return options.filter(
      (option) =>
        option.name.toLowerCase().includes(searchQuery) ||
        option.group.toLowerCase().includes(searchQuery) ||
        option.name === 'ALL'
    )
  }

  /**
   * Transforms filtered blockchains into option / options to select.
   */
  protected abstract getOptionsFromChains(): BlockchainDropdownOption[]

  /**
   * Transforms API blockchains data into options.
   * @param groups - Blockchain select groups from API.
   * @returns Options.
   */
  protected buildOptions(
    groups: BlockchainSelectGroups
  ): BlockchainDropdownOption[] {
    return Object.entries(groups).reduce(
      (options: BlockchainDropdownOption[], [group, blockchains]) => [
        ...options,
        ...blockchains.map((blockchain) => ({ name: blockchain, group }))
      ],
      []
    )
  }
}
