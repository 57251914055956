import 'react-toastify/dist/ReactToastify.css'
import 'styles/keyframes.scss'

import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Footer from 'components/Footer'
import Header from 'components/Header'
import AuthRoute from 'components/routes/AuthRoute'
import AuthProvider from 'providers/AuthProvider'
import SpinnerProvider from 'providers/SpinnerProvider'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import routes from 'routes'
import { SWRConfig, SWRConfiguration } from 'swr'
import { device } from 'utils/screen'

import DialogsProvider from './providers/DialogsProvider'

const useStyles = makeStyles({
  app: {
    background: '#272d49',
    minHeight: '100vh'
  },
  body: {
    padding: '2.5rem',
    color: '#fff',

    [`@media ${device.tablet}`]: {
      padding: '1.875rem 1.25rem'
    }
  }
})

const swrConfig: SWRConfiguration = {
  revalidateOnFocus: false
}

export default function App() {
  const classes = useStyles()
  return (
    <>
      <BrowserRouter>
        <SWRConfig value={swrConfig}>
          <AuthProvider>
            <SpinnerProvider>
              <DialogsProvider>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  className={classes.app}
                >
                  <Grid container direction="column">
                    <Header />

                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.body}
                    >
                      <Switch>
                        {routes.map((route, index) =>
                          route.auth ? (
                            <AuthRoute path={route.path} exact key={index}>
                              <route.Component />
                            </AuthRoute>
                          ) : (
                            <Route path={route.path} exact key={index}>
                              <route.Component />
                            </Route>
                          )
                        )}
                        <Route path="/">
                          <Redirect to="/farms" />
                        </Route>
                      </Switch>
                    </Grid>
                  </Grid>

                  <Footer />
                </Grid>
              </DialogsProvider>
            </SpinnerProvider>
          </AuthProvider>
        </SWRConfig>
      </BrowserRouter>

      <ToastContainer theme="dark" />
    </>
  )
}
