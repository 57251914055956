import { toast } from 'react-toastify'
import { fetcher } from 'services/api/common'
import useSWR from 'swr'
import { stringifyURL } from 'utils/query'

import { editFieldNote } from '../../services/api/globalFields'

type OnEdit = (value: string, onSuccess?: () => void) => void

interface UseFieldNoteConfig {
  field?: string
}

interface UseFieldNote {
  data: string
  isLoading: boolean
  onEdit: OnEdit
}

function getKey(params: UseFieldNoteConfig) {
  return params.field ? stringifyURL(`/auth/get_field_note`, params) : null
}

export default function useFieldNote(
  config: UseFieldNoteConfig = {}
): UseFieldNote {
  const {
    data: _data,
    error,
    mutate: revalidate
  } = useSWR(() => getKey(config), fetcher)

  const onEdit: OnEdit = async (value, onSuccess) => {
    try {
      await editFieldNote(config.field as string, value)
      revalidate?.()
      onSuccess?.()
    } catch (e) {
      console.error(e)
      toast.error('Cannot edit notes')
    }
  }

  const isLoading = !_data && !error
  const data = _data?.value || ''

  return {
    isLoading,
    data,
    onEdit
  }
}
