import { BlockchainSelectGroups } from '../../hooks/useBlockchainSelect'
import { BlockchainDropdownOption } from '../../interfaces/blockchain-dropdown-option'
import { Chain } from '../../services/types/globals'
import { BlockchainDropdownStrategy } from './BlockchainDropdownStrategy'

/**
 * Strategies for blockchain dropdown multiple options behaviour.
 */
export class BlockchainDropdownMultipleStrategy extends BlockchainDropdownStrategy {
  /**
   * Returns the value that should be passed to the filter to get an empty result.
   * @returns Filter sequence.
   */
  public getChainClearValue(): Chain[] {
    return ['null']
  }

  /**
   * Processes selected options according to concrete strategy and returns value suitable for the blockchains filter.
   * @param selection - Options passed.
   * @returns Filter sequence.
   */
  public getChainsFromSelection(
    selection?: BlockchainDropdownOption[]
  ): Chain[] {
    let newValue: BlockchainDropdownOption[]
    if (!selection) {
      newValue =
        (this.selected as BlockchainDropdownOption[]).length ===
        this.options.length
          ? []
          : this.options
    } else {
      newValue = selection
    }

    return newValue.length === this.options.length
      ? ['']
      : !newValue.length
      ? ['null']
      : newValue.map((item: BlockchainDropdownOption) => item.name)
  }

  /**
   * Returns title value to display on the dropdown component.
   * @returns string.
   */
  public getTitle(): string {
    return this.selected.length !== this.options.length
      ? `Filtered by blockchain (${this.selected.length})`
      : 'Filter by blockchain'
  }

  /**
   * Transforms filtered blockchains into option / options to select.
   */
  protected getOptionsFromChains(): BlockchainDropdownOption[] {
    const { active } = this.props
    if (active.includes('')) {
      return this.options
    }
    return this.options.filter((item: BlockchainDropdownOption) =>
      active.includes(item.name)
    )
  }

  /**
   * Transforms API blockchains data into options.
   * @param groups - Blockchain select groups from API.
   * @returns Options.
   */
  protected buildOptions(
    groups: BlockchainSelectGroups
  ): BlockchainDropdownOption[] {
    return super.buildOptions(groups)
  }
}
