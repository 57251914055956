import Grid from '@material-ui/core/Grid'
import Tabs from 'components/tabs/Tabs'
import { PageTitle } from 'components/typography'
import usePersistState from 'hooks/ui/usePersistState'
import useAllPools from 'hooks/useAllPools'
import { useSpinner } from 'providers/SpinnerProvider'
import { useMemo, useState } from 'react'
import {
  getPoolsKey,
  parseStrategiesAssets,
  parseStrategiesCharts
} from 'utils/pools'

import AprLineChart, { AprLineChartProps } from './performance/AprLineChart'
import DailyRevenueBar from './performance/DailyRevenueBar'
import HoldingsAreaChart, {
  HoldingsAreaChartProps
} from './performance/HoldingsAreaChart'
import DailyEarningsBar from './strategies/DailyEarningsBar'
import StrategiesTable from './strategies/StrategiesTable'
import { useStrategiesStyles } from './strategies/styles'

const POOL_IDS_KEY = 'multifarm-pool-ids'

const TABS = [
  { value: 'APR(y)', name: 'APR(y)' },
  { value: 'Daily Revenue', name: 'Daily Revenue' }
]

export default function Performance() {
  const classes = useStrategiesStyles()
  const [chart, onChart] = useState('APR(y)')

  const [poolIds, setPoolIds] = usePersistState<string[]>(
    [],
    POOL_IDS_KEY,
    true
  )

  const allPools = useAllPools()

  const allPoolsByIds = useAllPools({
    filters: {
      poolIds,
      per_page: 9999
    }
  })

  useSpinner(allPools.isLoading || allPoolsByIds.isLoading)

  const isPoolsByIds = !!poolIds.length

  const poolsKey = getPoolsKey(allPools.allPools)
  const poolsByIdsKey = getPoolsKey(allPoolsByIds.allPools)

  const parsedAssets = useMemo(
    () => parseStrategiesAssets(allPools.allPools),
    [poolsKey]
  )

  const parsedCharts = useMemo(
    () => parseStrategiesCharts(allPools.allPools, isPoolsByIds),
    [poolsKey, isPoolsByIds]
  )

  const parsedChartsByIds = useMemo(
    () => parseStrategiesCharts(allPoolsByIds.allPools),
    [poolsByIdsKey]
  )

  const handleSelect = (poolId: string) => {
    setPoolIds((poolIds) =>
      poolIds.includes(poolId)
        ? poolIds.filter((id) => id !== poolId)
        : [...poolIds, poolId]
    )
  }

  const AprLineChartProps: AprLineChartProps = {
    data: isPoolsByIds ? parsedChartsByIds.aprChart : parsedCharts.aprChart,
    keys: isPoolsByIds ? parsedChartsByIds.chartKeys : parsedCharts.chartKeys
  }

  const DailyChartProps: AprLineChartProps = {
    data: isPoolsByIds ? parsedChartsByIds.dailyChart : parsedCharts.dailyChart,
    keys: isPoolsByIds ? parsedChartsByIds.chartKeys : parsedCharts.chartKeys
  }

  const HoldingsAreaChartProps: HoldingsAreaChartProps = {
    usdData: isPoolsByIds ? parsedChartsByIds.usdChart : parsedCharts.usdChart,
    ethData: isPoolsByIds ? parsedChartsByIds.ethChart : parsedCharts.ethChart,
    keys: isPoolsByIds ? parsedChartsByIds.chartKeys : parsedCharts.chartKeys,
    loading: isPoolsByIds ? allPoolsByIds.isLoading : allPools.isLoading
  }

  return (
    <Grid container direction="column">
      <Grid container className={classes.titleContainer}>
        <PageTitle>Strategies</PageTitle>

        <Tabs width="auto" tabs={TABS} value={chart} onChange={onChart} />
      </Grid>

      {chart === 'APR(y)' ? (
        <AprLineChart {...AprLineChartProps} />
      ) : (
        <DailyRevenueBar {...DailyChartProps} />
      )}

      <Grid container className={classes.strategiesTableContainer}>
        <StrategiesTable
          selectedPools={poolIds}
          onSelect={handleSelect}
          loading={allPools.isLoading}
          className={classes.table}
          page={allPools.filters.page}
          maxPages={allPools.maxPages}
          onPage={allPools.onPage}
          data={parsedAssets}
          revalidate={allPools.revalidate}
        />
      </Grid>

      <HoldingsAreaChart {...HoldingsAreaChartProps} />

      <DailyEarningsBar category="farm" />
    </Grid>
  )
}
