import MuiDialog from '@material-ui/core/Dialog'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import { PropsWithChildren, ReactNode } from 'react'
import { device } from 'utils/screen'

import { CloseIcon } from '../../assets/icons'

const SIZES = {
  sm: 360,
  md: 500,
  lg: 720,
  xl: 900,
  xxl: 1150
}

const useStyles = makeStyles({
  paper: (props: any) => ({
    borderRadius: 15,
    width: '100%',
    maxWidth: SIZES[props.size],
    height: props.height,
    background: '#303659',
    color: '#B2BDFF',
    boxShadow: '0px 8px 60px rgba(34, 39, 64, 0.4)',
    fontFamily: 'Montserrat',
    margin: '1rem',
    position: 'relative',

    [`@media ${device.mobileL}`]: {
      height: props.mobileHeight
        ? `${props.mobileHeight}`
        : props.height || 'auto'
    },

    ...(props.variant === 'secondary' && {
      fontFamily: 'Roboto',
      color: '#fafafa',
      background: 'linear-gradient(111.6deg, #303659 -2.51%, #292E4D 104.46%)'
    })
  }),
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)'
  },
  close: {
    position: 'absolute',
    right: 15,
    top: 15,
    cursor: 'pointer',
    zIndex: 1
  },
  title: (props: any) => ({
    fontSize: '1rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    padding: '2rem 1.5rem 0.5rem 1.5rem',
    color:
      props.titleColor === 'primary'
        ? '#fafafa'
        : props.titleColor === 'error'
        ? '#ff5733'
        : 'inherit'
  })
})

type TitleColor = 'primary' | 'error'

export type OnTitleComponent = (node: ReactNode) => void

export interface DialogTitleComponentProps {
  onTitleComponent: OnTitleComponent
}

export interface DialogBaseProps {
  open: boolean
  onClose?: () => void
}

export interface DialogProps extends DialogBaseProps {
  height?: number
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  className?: string
  mobileHeight?: string | boolean
  variant?: 'secondary'
  title?: string | ReactNode
  titleColor?: TitleColor
}

export default function Dialog({
  children,
  open,
  height,
  size,
  className,
  mobileHeight,
  variant,
  onClose,
  title,
  titleColor = 'primary'
}: PropsWithChildren<DialogProps>) {
  const classes = useStyles({ height, size, mobileHeight, variant, titleColor })
  return (
    <MuiDialog
      open={open}
      classes={{ paper: classNames(classes.paper, className) }}
      BackdropProps={{
        className: classes.backdrop
      }}
    >
      {variant === 'secondary' && (
        <>
          <CloseIcon className={classes.close} onClick={onClose} />
          <Typography className={classes.title}>{title}</Typography>
        </>
      )}

      {children}
    </MuiDialog>
  )
}
