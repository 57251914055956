import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Button'
import AddCustomAssetDialog from 'components/dialogs/AddCustomAssetDialog'
import Select from 'components/form/Select'
import { useEffect, useState } from 'react'

import EditPnlDialog from '../../../components/dialogs/EditPnlDialog'

const useStyles = makeStyles({
  select: {
    maxWidth: 250
  },
  selectContainer: {
    marginBottom: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginRight: '1.25rem',
      '&:last-child': {
        marginRight: 0
      }
    }
  }
})

interface DateSelectProps {
  dates: string[]
  filters: any
  onFilter: any
  addClosed?: boolean
  onEditStats?: any
  revalidate?: any
}

export default function DateSelect(props: DateSelectProps) {
  const classes = useStyles()
  const [addCustom, onAddCustom] = useState(false)
  const [addClosed, onAddClose] = useState(false)

  useEffect(() => {
    const date = new Date()

    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
    props.onFilter('date', `${months[date.getMonth()]} ${date.getFullYear()}`)
  }, [])

  return (
    <>
      <div className={classes.selectContainer}>
        <Select
          searchable={false}
          options={props.dates.map((d) => ({ value: d, label: d }))}
          placeholder="Month"
          className={classes.select}
          value={props.filters.date}
          onChange={(e) => props.onFilter('date', (e?.value || '') as string)}
        />

        <div className={classes.actions}>
          <Button
            size="sm"
            variant="contained-2"
            color="secondary"
            onClick={() => onAddCustom(true)}
          >
            Add Custom Asset
          </Button>
          {props.onEditStats && (
            <Button
              size="sm"
              variant="contained-2"
              color="secondary"
              onClick={() => props.onEditStats()}
            >
              Edit Stats
            </Button>
          )}
          {props.addClosed && (
            <Button
              size="sm"
              variant="contained-2"
              color="secondary"
              onClick={() => onAddClose(true)}
            >
              Add Closed Position
            </Button>
          )}
        </div>
      </div>

      <AddCustomAssetDialog
        open={addCustom}
        onClose={() => onAddCustom(false)}
      />
      <EditPnlDialog
        open={addClosed}
        onClose={() => onAddClose(false)}
        revalidate={props.revalidate}
      />
    </>
  )
}
