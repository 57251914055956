import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import useSaveSearch from 'hooks/useSaveSearch'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import Dialog from './Dialog'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.25rem',
    marginBottom: '2.5rem'
  },
  button: {
    marginRight: '1.25rem',

    '&:last-child': {
      marginRight: 0
    }
  }
})

interface ContentProps {
  data: any
  savedId: string
  onClose: () => void
}

function Content({ data, savedId, onClose }: ContentProps) {
  const classes = useStyles()

  const saveSearch = useSaveSearch({ id: savedId })

  const defaultValues = {
    title: data.title,
    text: data.text,
    name: data.name,
    website: data.website,
    vault: data.vault
  }

  const methods = useForm({
    defaultValues
  })

  useEffect(() => {
    if (saveSearch.savedSearch.saved_id && saveSearch.savedSearch.edits) {
      methods.reset({
        ...defaultValues,
        ...saveSearch.savedSearch.edits
      })
    }
  }, [saveSearch.savedSearch.saved_id])

  const handleSubmit = (values) => {
    saveSearch.onEditSearch(savedId, values, onClose)
  }

  return (
    <DialogBody>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <Grid container direction="column" className={classes.content}>
          <Input
            variant="secondary"
            placeholder="Title"
            label="Title"
            {...methods.register('title')}
          />
          <Input
            variant="secondary"
            placeholder="Website"
            label="Website"
            {...methods.register('website')}
          />
          <Input
            variant="secondary"
            placeholder="Vault"
            label="Vault"
            {...methods.register('vault')}
          />
          <Input
            variant="secondary"
            placeholder="Text"
            label="Text"
            {...methods.register('text')}
          />
        </Grid>

        <Grid container justifyContent="flex-end" alignItems="center">
          <Button
            variant="contained-2"
            size="sm"
            type="button"
            color="secondary"
            className={classes.button}
            onClick={onClose}
          >
            Reset
          </Button>
          <Button
            variant="contained-2"
            size="sm"
            type="submit"
            className={classes.button}
          >
            Save
          </Button>
        </Grid>
      </form>
    </DialogBody>
  )
}

interface EditInfoCardDialogProps {
  open: boolean
  onClose: () => void
}

export default function EditInfoCardDialog({
  onClose,
  open,
  ...props
}: EditInfoCardDialogProps & ContentProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Edit Values"
      variant="secondary"
      height={435}
      size="lg"
    >
      <Content {...props} onClose={onClose} />
    </Dialog>
  )
}
