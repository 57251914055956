import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { DeleteIcon, EditIcon } from 'assets/icons'
import Button from 'components/buttons/Button'
import IconButton from 'components/buttons/IconButton'
import AddProteusDescriptionDialog from 'components/dialogs/AddProteusDescriptionDialog'
import CellImage from 'components/images/CellImage'
import Table from 'components/table/Table'
import TableCell from 'components/table/TableCell'
import TableRow from 'components/table/TableRow'
import TooltipDropdown from 'components/TooltipDropdown'
import { Link, PageTitle } from 'components/typography'
import useProteusDescription from 'hooks/useProteusDescription'
import useProteusDescriptions from 'hooks/useProteusDescriptions'
import ProteusDescriptionProvider from 'providers/ProteusDescriptionProvider'
import { useState } from 'react'
import { TableCol } from 'types/components/table'

const useStyles = makeStyles({
  root: {
    marginBottom: '2.5rem'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1.25rem'
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '1.25rem'
  },
  editBtn: {
    '& svg': {
      width: 18
    }
  },
  actionsCell: {
    display: 'flex',
    alignItems: 'center'
  },
  rowTitle: {
    margin: '0 1.25rem',
    display: 'flex',
    alignItems: 'center'
  },
  text: {
    display: 'block',
    maxWidth: 300,
    width: 'fit-content',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
})

const COLS: TableCol[] = [
  { title: 'Title', key: 'title', width: 400 },
  { title: 'Link', key: 'link' },
  { title: 'Info', key: 'info' },
  { title: 'How To Use', key: 'howToUse' }
]

function isActive(row) {
  return row.active
}

export default function ProteusDescription() {
  const classes = useStyles()
  const proteusDescriptions = useProteusDescriptions()
  const proteusDescription = useProteusDescription()

  const [add, setAdd] = useState(false)
  const [edit, setEdit] = useState('')

  const Renderer = {
    title: (row) => (
      <Grid className={classes.actionsCell}>
        <IconButton
          className={classes.editBtn}
          onClick={() => setEdit(row['item_id'])}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={() =>
            proteusDescription.onRemove(row['item_id'], () =>
              proteusDescriptions.revalidate?.()
            )
          }
        >
          <DeleteIcon />
        </IconButton>

        <div className={classes.rowTitle}>
          {row['image'] && <CellImage src={row['image']} />}
          <span>{row['title']}</span>
        </div>
      </Grid>
    ),
    info: (row) => (
      <TooltipDropdown content={row['info']}>
        <span className={classes.text}>{row['info']}</span>
      </TooltipDropdown>
    ),
    howToUse: (row) => (
      <TooltipDropdown content={row['howToUse']}>
        <span className={classes.text}>{row['howToUse']}</span>
      </TooltipDropdown>
    ),
    link: (row) =>
      row.link ? (
        <Link to={row.link} native target="_blank" rel="noreferrer noopener">
          {row.link}
        </Link>
      ) : (
        'N/A'
      )
  }

  return (
    <ProteusDescriptionProvider revalidate={proteusDescriptions.revalidate}>
      <Grid container direction="column" className={classes.root}>
        <PageTitle className={classes.title}>
          <span>Proteus Description</span>

          <Button variant="contained-2" size="sm" onClick={() => setAdd(true)}>
            Add new Description
          </Button>
        </PageTitle>

        <Table
          cols={COLS}
          loading={proteusDescriptions.isLoading}
          empty={!proteusDescriptions.descriptions.length}
        >
          {proteusDescriptions.descriptions.filter(isActive).map((row) => (
            <TableRow key={row['item_id']}>
              {COLS.map((col) => (
                <TableCell key={col.key}>
                  {Renderer[col.key as string](row)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </Table>
      </Grid>

      <AddProteusDescriptionDialog
        open={add || !!edit}
        editId={edit}
        onClose={() => {
          setAdd(false)
          setEdit('')
        }}
      />
    </ProteusDescriptionProvider>
  )
}
