import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import { useForm } from 'react-hook-form'

import useAuthRegister from '../../hooks/useAuthRegister'
import { RegistrationData } from '../../interfaces/auth'
import Dialog from './Dialog'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  text: {
    marginBottom: '2rem',
    fontSize: '0.875rem'
  },
  input: {
    marginBottom: '1.875rem',

    '&:last-child': {
      marginBottom: 0
    }
  }
})

interface SignUpDialogProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export default function SignUpDialog({
  open,
  onClose,
  onSuccess
}: SignUpDialogProps) {
  const classes = useStyles()
  const { onRegister } = useAuthRegister()
  const form = useForm<RegistrationData>()

  const handleEmailSend = (data: RegistrationData) => {
    onRegister(data, onSuccess)
  }

  return (
    <Dialog
      open={open}
      size="sm"
      height={350}
      variant="secondary"
      mobileHeight={false}
      title="Sign Up"
      onClose={onClose}
    >
      <DialogBody>
        <Grid container direction="column">
          <Typography className={classes.text}>
            Enter the email address and we’ll send an email with instructions.
          </Typography>

          <Input
            className={classes.input}
            placeholder="Your Email"
            label="Email Address"
            variant="secondary"
            {...form.register('email')}
          />

          <Button
            color="secondary"
            size="sm"
            variant="contained-2"
            onClick={() => form.handleSubmit(handleEmailSend)()}
          >
            Send Instructions
          </Button>
        </Grid>
      </DialogBody>
    </Dialog>
  )
}
