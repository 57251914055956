import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { SearchIcon } from 'assets/icons'
import BottomPagination from 'components/BottomPagination'
import Button from 'components/buttons/Button'
import IconButton from 'components/buttons/IconButton'
import ExchangesFilterDrawer from 'components/drawers/ExchangesFilterDrawer'
import CircleCheckbox from 'components/form/CircleCheckbox'
import Input from 'components/form/Input'
import Table from 'components/table/Table'
import TableCell from 'components/table/TableCell'
import TableRow from 'components/table/TableRow'
import { PageTitle } from 'components/typography'
import useExchanges, { ExchangesFilters } from 'hooks/useExchanges'
import { useSpinner } from 'providers/SpinnerProvider'
import React, { useEffect, useMemo, useState } from 'react'
import { TableCol } from 'types/components/table'
import { parseMoney, parsePercent } from 'utils/api'
import { formatChartMoney } from 'utils/charts'
import { formatRows } from 'utils/exchanges'
import { device } from 'utils/screen'
import { TOOLTIP } from 'utils/tooltip'

import ActiveFilters from '../asset/ActiveFilters'
import ExchangeChart from './exchange/ExchangeChart'
import { useStrategiesStyles } from './strategies/styles'

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    alignItems: 'center',

    '& > button': {
      marginRight: '0.5rem'
    }
  },
  filtersContainer: {
    marginBottom: '2rem',

    [`@media ${device.tablet}`]: {
      flexDirection: 'column'
    }
  },
  input: {
    flex: 1,
    marginRight: '1.25rem',

    [`@media ${device.tablet}`]: {
      marginRight: 0,
      marginBottom: '1.25rem'
    }
  },
  charts: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: `
      "a b"
      "c d"
      "e f"
    `,
    gap: '1.25rem',
    marginBottom: '2.5rem',

    '& > div': {
      '&:nth-child(1)': { gridArea: 'a' },
      '&:nth-child(2)': { gridArea: 'b' },
      '&:nth-child(3)': { gridArea: 'c' },
      '&:nth-child(4)': { gridArea: 'd' },
      '&:nth-child(5)': { gridArea: 'e' },
      '&:nth-child(6)': { gridArea: 'f' }
    },

    [`@media ${device.tablet}`]: {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
        "a"
        "b"
        "c"
        "d"
        "e"
        "f"
    `
    }
  },
  highlight: {
    color: '#66C8FF',
    fontWeight: 800
  }
})

const COLS: TableCol[] = [
  {
    title: 'Asset',
    sortable: true,
    key: 'asset',
    name: 'asset',
    sticky: 0,
    width: 230
  },
  {
    title: 'Exchange',
    sortable: true,
    key: 'exchange',
    name: 'exchangeName',
    sticky: 230,
    width: 175
  },
  {
    title: 'Blockchain',
    sortable: true,
    key: 'blockchain',
    name: 'blockchain',
    sticky: 230 + 175,
    width: 175,
    border: 'right'
  },
  {
    title: 'Liquidity',
    sortable: true,
    key: 'liquidity',
    name: 'liquidityUSD'
  },
  {
    title: 'Equivalent APR',
    key: 'equivalentAPR',
    name: 'equivalentAPR',
    sortable: true,
    tooltip: TOOLTIP.EXCHANGE_EQ_APR,
    className: 'highlight',
    color: '#66C8FF'
  },
  {
    title: 'Trading Volume (24h)',
    sortable: true,
    key: 'tradingVolume',
    name: 'dailyVolumeUSD'
  },
  {
    title: 'Daily APR (Annualized)',
    key: 'dailyAPR',
    name: 'dailyAPR',
    sortable: true,
    tooltip: TOOLTIP.DAILY_APR_ANNUALIZED
  },
  {
    title: 'Reward APR',
    key: 'rewardApr',
    name: 'rewardApr'
  },
  { title: 'Fees Rate', key: 'feeRate' },
  { title: 'Fees Last 30d (USD)', key: 'fees30d' },
  { title: 'Fees Last 30d', key: 'fees30dPercent' },
  { title: 'Imp. Loss last 30d', key: 'impLossLast30d' },
  {
    title: 'Net ROI Last 30d',
    key: 'netRoiLast30d',
    name: 'netRoiLast30d',
    sortable: true,
    tooltip: TOOLTIP.EXCHANGE_NET_ROI
  },
  {
    title: 'Price of LP Token',
    key: 'lpPrice',
    tooltip: TOOLTIP.EXCHANGE_LP_PRICE
  }
]

export default function Exchange() {
  const classes = useStyles()
  const strategiesStyles = useStrategiesStyles()
  const [filtersDrawer, setFiltersDrawer] = useState(false)
  const [activeAssetAddress, setActiveAssetAddress] = useState('')
  const [activeAsset, onActiveAsset] = useState('')
  const [activeExchange, onActiveExchange] = useState('')

  const {
    exchanges,
    isLoading,
    onPage,
    onSearch,
    onSort,
    filters,
    maxPages,
    onFilters,
    onClearFilters,
    onClearFilter
  } = useExchanges()

  useSpinner(isLoading)

  const Renderer = useMemo(
    () => ({
      asset: (row) => (
        <div className={classes.actions}>
          <IconButton
            size="sm"
            onClick={() => {
              setActiveAssetAddress(row.assetAddress)
              onActiveAsset(row._asset)
              onActiveExchange(row._exchange)
            }}
          >
            <CircleCheckbox value={row.assetAddress === activeAssetAddress} />
          </IconButton>

          <span>{row.asset}</span>
        </div>
      ),
      exchange: (row) => row.exchange,
      blockchain: (row) => row.blockchain,
      liquidity: (row) => row.liquidity,
      tradingVolume: (row) => row.tradingVolume,
      fees30d: (row) => row.fees,
      fees30dPercent: (row) => row.feesPercent,
      impLossLast30d: (row) => row.impLossLast30d,
      netRoiLast30d: (row) => row.netRoiLast30d,
      equivalentAPR: (row) => row.equivalentAPR,
      lpPrice: (row) => row.lpPrice,
      netPrincipalRoiLast30d: (row) => row.netPrincipalRoiLast30d,
      lpReturnHODLLast30d: (row) => row.lpReturnHODLLast30d,
      dailyAPR: (row) => row.dailyAPR,
      rewardApr: (row) => row.rewardApr,
      feeRate: (row) => row.feeRate
    }),
    [activeAssetAddress, activeAsset, activeExchange]
  )

  const rows = formatRows(exchanges)

  useEffect(() => {
    if (!(activeAssetAddress && activeAsset && activeExchange) && rows[0]) {
      setActiveAssetAddress(rows[0].assetAddress)
      onActiveAsset(rows[0]._asset)
      onActiveExchange(rows[0]._exchange)
    }
  }, [activeAssetAddress, activeAsset, activeExchange, rows.length])

  const BaseChartProps = {
    exchange: activeExchange,
    asset: activeAsset,
    assetAddress: activeAssetAddress
  }

  return (
    <>
      <Grid container direction="column">
        <Grid className={strategiesStyles.title}>
          <PageTitle>
            {`Exchange: ${activeExchange}; Asset: ${activeAsset}`}
          </PageTitle>
        </Grid>

        <Grid container className={classes.charts}>
          <ExchangeChart
            yTickFormatter={(e) => parsePercent(e)}
            tooltipFormatter={(e) => parsePercent(e)}
            title="ROI(%)"
            tooltip={TOOLTIP.ROI}
            fields={[
              'netROILast30dHistorical',
              'impLoss30dHistorical',
              'feePercentageLast30dHistorical'
            ]}
            {...BaseChartProps}
          />
          <ExchangeChart
            yTickFormatter={(e) => parsePercent(e)}
            tooltipFormatter={(e) => parsePercent(e)}
            title="Daily APR (Annualized)"
            tooltip={TOOLTIP.DAILY_APR_ANNUALIZED}
            fields={['dailyAPRHistorical']}
            {...BaseChartProps}
          />
          <ExchangeChart
            yTickFormatter={(e) => formatChartMoney(e)}
            tooltipFormatter={(e) => parseMoney(e, 2, true)}
            title="Liquidity vs Trading Volume"
            fields={[
              'liquidityUSDHistorical',
              'dailyVolumeUSDHistorical',
              'dailyFeeHistorical'
            ]}
            ChartProps={{
              bar: true,
              stack: false
            }}
            {...BaseChartProps}
          />
        </Grid>

        <Grid container className={classes.filtersContainer}>
          <Input
            className={classes.input}
            placeholder="What would you like to search"
            prefix={<SearchIcon />}
            font="Roboto"
            onChange={(e) => onSearch(e.target.value)}
          />
          <Button
            size="sm"
            variant="contained-2"
            onClick={() => setFiltersDrawer(true)}
          >
            Apply filters
          </Button>
        </Grid>

        <ActiveFilters
          filters={filters}
          clearFilter={(key) => onClearFilter(key as keyof ExchangesFilters)}
          onClick={() => setFiltersDrawer(true)}
          exclude={['page', 'per_page', 'sort', 'sort_order', 'dateUpdated']}
        />

        <Table
          cols={COLS}
          loading={isLoading}
          empty={!exchanges.length}
          sort={filters.sort}
          sortOrder={filters.sort_order}
          onSort={onSort}
          ScrollArrowProps={{
            case: 'exchanges'
          }}
        >
          {rows.map((row, index) => (
            <TableRow key={index}>
              {COLS.map((col) => (
                <TableCell
                  key={col.key}
                  align={col.align}
                  wrap={col.wrap}
                  sticky={col.sticky}
                  border={col.border}
                  className={col.className && classes[col.className]}
                >
                  {Renderer[col.key as string]?.(row) || 'N/A'}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </Table>

        {!isLoading && !!exchanges.length && (
          <BottomPagination
            page={filters.page}
            count={maxPages}
            onChange={onPage}
          />
        )}
      </Grid>

      <ExchangesFilterDrawer
        open={filtersDrawer}
        onClose={() => setFiltersDrawer(false)}
        filters={filters}
        onApply={(filters) => onFilters(filters)}
        onReset={onClearFilters}
      />
    </>
  )
}
