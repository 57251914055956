import { useMemo } from 'react'
import { fetcher } from 'services/api/common'
import useSWR, { cache } from 'swr'

const key = '/get_coins_and_coin_groups'

export default function useGroups() {
  const { data, error } = useSWR(key, fetcher, {
    revalidateOnMount: !cache.has(key),
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  })

  const isSuccess = !!data

  return useMemo(
    () => ({
      isLoading: !data && !error,
      groups: data?.['coin_groups']?.['groups'] || {},
      coins: data?.['coins'] || {}
    }),
    [isSuccess]
  )
}
