import { Rule, Transaction } from 'interfaces/transactions'

import Api from '../Api'
import { getKey } from './common'

/**
 * Creates a rule.
 * @param rule
 * @returns Response.
 */
export function createRule(rule: Omit<Rule, 'id'>): Promise<any> {
  return Api.post('/auth/tx_labeling/add_rules', {
    address: rule.address,
    blockchain: rule.blockchain,
    tx_from: rule.tx_from,
    tx_to: rule.tx_to,
    labels: rule.labels
  })
}

/**
 * Edits a rule.
 * @param rule
 * @returns Response.
 */
export function editRule(rule: Omit<Rule, 'id'>): Promise<any> {
  return Api.post(
    getKey('/auth/tx_labeling/edit_rules', {
      address: rule.address,
      blockchain: rule.blockchain
    }),
    {
      tx_from: rule.tx_from,
      tx_to: rule.tx_to,
      labels: rule.labels
    }
  )
}

/**
 * Deletes a rule by the given id.
 * @param id
 * @returns Response
 */
export function deleteRule(id: string): Promise<any> {
  return Api.delete(
    getKey('/auth/tx_labeling/delete_rule', {
      id
    })
  )
}

/**
 * Edits transactions label..
 * @param rule
 * @returns Response.
 */
export function editTransaction(
  { id, blockchain }: Pick<Transaction, 'id' | 'blockchain'>,
  { labels }: Pick<Transaction, 'labels'>
): Promise<any> {
  return Api.post(
    getKey('/auth/tx_labeling/edit_transactions', {
      tx_id: id,
      blockchain
    }),
    { labels }
  )
}
