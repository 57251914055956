import { useSpinner } from 'providers/SpinnerProvider'
import { toast } from 'react-toastify'
import { addTransaction, editBond } from 'services/api/bonds'
import { fetcher } from 'services/api/common'
import useSWR from 'swr'
import { formatBondValues } from 'utils/bonds'
import { stringifyURL } from 'utils/query'

type OnAdd = (values: any, onSuccess?: () => void) => void
type OnAddTransaction = (values: any, onSuccess?: () => void) => void

interface UseBondTransactionConfig {
  hash?: string
}

interface UseBondTransaction {
  isLoading: boolean
  data: any
  onAdd: OnAdd
  onAddTransaction: OnAddTransaction
}

function getKey(params: UseBondTransactionConfig) {
  return params.hash
    ? stringifyURL('/auth/get_single_bond_transaction', params)
    : null
}

export default function useBondTransaction(
  config: UseBondTransactionConfig = {}
): UseBondTransaction {
  const spinner = useSpinner()

  const params = {
    hash: config.hash
  }

  const {
    data: _data,
    error,
    mutate: revalidate
  } = useSWR(() => getKey(params), fetcher)

  const onAdd: OnAdd = async (values, onSuccess) => {
    try {
      spinner.open()

      await editBond(formatBondValues(values))
      revalidate?.()
      onSuccess?.()

      toast.success('Bond successfully saved!')
      spinner.close()
    } catch (e) {
      spinner.close()
      toast.error('Oops error!')
      console.error(e)
    }
  }

  const onAddTransaction: OnAddTransaction = async (data, onSuccess) => {
    try {
      spinner.open()

      await addTransaction(data)
      onSuccess?.()

      toast.success('Transaction successfully saved!')
      spinner.close()
    } catch (e) {
      spinner.close()
      toast.error('Oops error!')
      console.error(e)
    }
  }

  const isLoading = !_data && !error
  const data = _data || {}

  return {
    isLoading,
    data,
    onAdd,
    onAddTransaction
  }
}
