import { useSpinner } from 'providers/SpinnerProvider'
import { toast } from 'react-toastify'
import { editSavedAsset } from 'services/api/assets'
import { getSavedSearch, removeSearch, saveSearch } from 'services/api/saved'
import useSWR from 'swr'
import { stringifyURL } from 'utils/query'

type OnSaveSearch = (id: string, type: string) => void
type OnRemoveSearch = (id: string) => void
type OnEditSearch = (id: string, data: any, onSuccess?: any) => void

export interface UseSaveSearch {
  onSaveSearch: OnSaveSearch
  onRemoveSearch: OnRemoveSearch
  onEditSearch: OnEditSearch
  isSearchLoading: boolean
  savedSearch: any
}

interface UseSaveSearchConfig {
  mutate?: any
  id?: string
}

function getKey(params) {
  return stringifyURL('/auth/get_single_search', params)
}

export default function useSaveSearch(
  config: UseSaveSearchConfig = {}
): UseSaveSearch {
  const spinner = useSpinner()

  const params = {
    saved_id: config.id
  }

  const {
    data,
    error,
    mutate: revalidate
  } = useSWR(() => (config.id ? getKey(params) : null), getSavedSearch)

  const onRemoveSearch: OnRemoveSearch = async (id) => {
    try {
      spinner.open()
      await removeSearch(id)
      toast.success('Successfully removed')
      spinner.close()
      config.mutate?.()
    } catch (e) {
      spinner.close()
      toast.error('Error removing search...')
      console.error(e)
    }
  }

  const onSaveSearch: OnSaveSearch = async (id, type) => {
    try {
      spinner.open()
      await saveSearch(id, type)
      toast.success('Successfully saved')
      spinner.close()
      config.mutate?.()
    } catch (e) {
      spinner.close()
      toast.error('Error saving search...')
      console.error(e)
    }
  }

  const onEditSearch: OnEditSearch = async (id, data, onSuccess) => {
    try {
      spinner.open()
      await editSavedAsset(id, data)
      toast.success('Successfully edited')
      spinner.close()
      revalidate?.()
      onSuccess?.()
    } catch (e) {
      spinner.close()
      toast.error('Error editing search...')
      console.error(e)
    }
  }

  const isSearchLoading = !data && !error
  const savedSearch = data || {}

  return {
    onEditSearch,
    onRemoveSearch,
    onSaveSearch,
    isSearchLoading,
    savedSearch
  }
}
