import { OnTableSort } from 'components/table/Table'
import { OnPage } from 'components/table/TablePagination'
import { useSpinner } from 'providers/SpinnerProvider'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { getPnl, removePnl } from 'services/api/pnl'
import useSWR from 'swr'
import { reverse } from 'utils/obj'

interface PnlFilters {
  page: number
  date: string
  is_sold?: boolean
  active?: boolean
  sort?: string
  all?: number
  sort_order?: 1 | 0
}

interface UsePnlConfig {
  revalidate?: any
  filters?: Partial<PnlFilters>
}

type OnRemove = (id: string, onSuccess?: any) => void

function getKey(params) {
  return '/auth/get_pnls?' + JSON.stringify(params)
}

export default function usePnl(config: UsePnlConfig = {}) {
  const spinner = useSpinner()

  const [filters, onFilters] = useState<PnlFilters>({
    page: 1,
    date: '',
    ...config.filters
  })

  const params = {
    per_page: 25,
    ...filters
  }

  const {
    data: _data,
    error,
    mutate: revalidate
  } = useSWR(() => getKey(params), getPnl)

  const { mutate: revalidateAll } = useSWR(() => getKey(params))

  const onPage: OnPage = (page) => {
    onFilters((filters) => ({
      ...filters,
      page: page
    }))
  }

  const onFilter = (key: string, value: number | string) => {
    onFilters((filters) => ({
      ...filters,
      [key]: value
    }))
  }

  const onSort: OnTableSort = (name, order) => {
    onFilters((filters) => ({
      ...filters,
      sort: name,
      sort_order: order === 'asc' ? 1 : 0
    }))
  }

  const onRemove: OnRemove = async (id) => {
    try {
      spinner.open()

      await removePnl(id)
      revalidateAll?.()

      toast.success('Row removed successfully')
      spinner.close()
    } catch (e) {
      spinner.close()
      toast.error('Oops error')
      console.error(e)
    }
  }

  const isLoading = !_data && !error
  const data = _data?.data || []
  const maxPages = _data?.max_pages || 1
  const dates = reverse(_data?.available_dates || [])

  return {
    isLoading,
    maxPages,
    onPage,
    onFilter,
    filters,
    data,
    dates,
    revalidate,
    onSort,
    onRemove
  }
}
