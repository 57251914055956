import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import useWallet from 'hooks/useWallet'
import React, { useState } from 'react'

import Dialog from './Dialog'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  input: {
    marginBottom: '1.25rem'
  }
})

interface AddTextWalletDialogProps {
  open: boolean
  onClose: () => void
}

export default function AddTextWalletDialog({
  open,
  onClose
}: AddTextWalletDialogProps) {
  const classes = useStyles()
  const { onAdd } = useWallet()

  const [wallet, setWallet] = useState('')

  const handleSuccess = () => {
    setWallet('')
    onClose()
  }

  const handleSubmit = () => {
    if (!wallet) {
      return
    }
    onAdd(wallet, handleSuccess)
  }

  return (
    <Dialog
      open={open}
      variant="secondary"
      size="sm"
      height={225}
      onClose={onClose}
      title="Connect your wallet"
    >
      <DialogBody>
        <Grid container direction="column">
          <Input
            variant="secondary"
            placeholder="Wallet ID"
            className={classes.input}
            value={wallet}
            onChange={(e) => setWallet(e.target.value)}
          />

          <Button
            variant="contained-2"
            color="secondary"
            size="sm"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Grid>
      </DialogBody>
    </Dialog>
  )
}
