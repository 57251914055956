import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Button'
import Checkbox from 'components/form/Checkbox'
import DatePicker from 'components/form/DatePicker'
import Input from 'components/form/Input'
import UserAllPoolsSelect from 'components/form/UserAllPoolsSelect'
import useSinglePnl from 'hooks/useSinglePnl'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { formatDataToForm } from 'utils/pnl'

import Dialog, { DialogBaseProps } from './Dialog'
import DialogActions from './DialogActions'
import DialogBody from './DialogBody'

interface ContentProps extends DialogBaseProps {
  poolId?: string
  revalidate?: any
}

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.25rem'
  },
  checkboxContainer: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  checkbox: {
    width: 'fit-content',
    paddingTop: '1.125rem'
  },
  checkboxInput: {
    marginRight: '1rem'
  }
})

interface PnlForm {
  current_value: string
  date: Date | undefined
  initial_investment: string
  is_sold: boolean
  is_partial_sell: boolean
  ignore_stats_calculation: boolean
  notes: string
  out: string
  poolId: string
  custom_selected: boolean | undefined
  active: boolean
}

function Content(props: ContentProps) {
  const classes = useStyles()
  const form = useForm<PnlForm>({
    defaultValues: {
      current_value: '',
      date: undefined,
      initial_investment: '',
      is_sold: false,
      is_partial_sell: false,
      ignore_stats_calculation: false,
      notes: '',
      out: '',
      poolId: '',
      custom_selected: undefined,
      active: true,
      ...(!props.poolId && {
        is_sold: true
      })
    }
  })

  const pnl = useSinglePnl({
    poolId: props.poolId,
    revalidate: props.revalidate
  })

  useEffect(() => {
    if (pnl.data.pool_id) {
      form.reset(formatDataToForm(pnl.data))
    }
  }, [pnl.data.pool_id])

  const handleSubmit = (values) => {
    pnl.onEdit(values, () => {
      props.onClose?.()
    })
  }

  return (
    <>
      <DialogBody>
        <Grid container className={classes.root}>
          <Input
            variant="secondary"
            label="Initial Investments"
            placeholder="Initial Investments"
            {...form.register('initial_investment')}
          />
          <div className={classes.checkboxContainer}>
            <Input
              variant="secondary"
              label="Out"
              placeholder="Out"
              className={classes.checkboxInput}
              {...form.register('out')}
            />
          </div>
          <Input
            variant="secondary"
            label="Notes"
            placeholder="Notes"
            {...form.register('notes')}
          />
          <Input
            disabled
            variant="secondary"
            label="Current Value"
            placeholder="Current Value"
            {...form.register('current_value')}
          />
          <Controller
            control={form.control}
            render={(e) => (
              <DatePicker
                label="Update Date"
                placeholder="Update Date"
                value={e.field.value as unknown as Date}
                onChange={(_e) => form.setValue(e.field.name, _e as any)}
              />
            )}
            name="date"
          />
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Controller
              control={form.control}
              render={(e) => {
                return (
                  <Checkbox
                    className={classes.checkbox}
                    value={e.field.value || false}
                    onChange={(_e) => form.setValue(e.field.name, _e)}
                    label="Sold"
                  />
                )
              }}
              name="is_sold"
            />
            <Controller
              control={form.control}
              render={(e) => {
                return (
                  <Checkbox
                    className={classes.checkbox}
                    value={e.field.value || false}
                    onChange={(_e) => form.setValue(e.field.name, _e)}
                    label="Partial sell"
                  />
                )
              }}
              name="is_partial_sell"
            />
            <Controller
              control={form.control}
              render={(e) => {
                return (
                  <Checkbox
                    className={classes.checkbox}
                    value={e.field.value || false}
                    onChange={(_e) => form.setValue(e.field.name, _e)}
                    label="Reset PnL"
                  />
                )
              }}
              name="ignore_stats_calculation"
            />
          </div>
          {!props.poolId && (
            <Controller
              control={form.control}
              render={(e) => (
                <UserAllPoolsSelect
                  label="Asset"
                  placeholder="Select asset"
                  variant="secondary"
                  value={e.field.value}
                  onChange={(_e) => {
                    form.setValue(e.field.name, (_e?.value || '') as string)
                    form.setValue('custom_selected', true)
                  }}
                />
              )}
              name="poolId"
            />
          )}
        </Grid>
      </DialogBody>

      <DialogActions>
        <Button
          size="sm"
          variant="contained-2"
          color="secondary"
          onClick={props.onClose}
        >
          Reset
        </Button>
        <Button
          size="sm"
          variant="contained-2"
          onClick={() => form.handleSubmit(handleSubmit)()}
        >
          Save
        </Button>
      </DialogActions>
    </>
  )
}

export default function EditPnlDialog(props: ContentProps) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      variant="secondary"
      title="Edit PnL"
      height={450}
      size="lg"
    >
      <Content {...props} />
    </Dialog>
  )
}
