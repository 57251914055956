import Grid from '@material-ui/core/Grid'
import ChartCard from 'components/cards/ChartCard'
import LineChart from 'components/charts/LineChart'
import HoldingGrowthTable from 'components/tables/HoldingsGrowthTable'
import Tabs from 'components/tabs/Tabs'
import ChartTooltip from 'components/tooltips/ChartTooltip'
import useHoldingsGrowth from 'hooks/useHoldingsGrowth'
import usePoolsByField from 'hooks/usePoolsByField'
import { memo, useMemo, useState } from 'react'
import { TableColWithKey } from 'types/components/table'
import { parseMoney } from 'utils/api'
import {
  formatChartDate,
  formatChartMoney,
  getChartId,
  renderTooltipOptions
} from 'utils/charts'
import { getRandomColors } from 'utils/colors'
import { CURRENCY, RANGE } from 'utils/filters'
import {
  getPoolsKey,
  parseRangeChart,
  parseStrategiesCharts
} from 'utils/pools'
import { TOOLTIP } from 'utils/tooltip'

import { CURRENCIES, RANGES } from '../performance/data'
import { useStrategiesStyles } from '../strategies/styles'

const COLORS = getRandomColors(20)
const TITLE = 'Value of Treasury Assets'

const COLS: TableColWithKey[] = [
  { title: '24h', align: 'center', key: 'daily_growth_change' },
  { title: '7d', align: 'center', key: 'weekly_growth_change' },
  { title: '14d', align: 'center', key: 'biweekly_growth_change' },
  { title: '30d', align: 'center', key: 'monthly_growth_change' },
  { title: '1y', align: 'center', key: 'yearly_growth_change' }
]

function AssetsTreasuryValueChart() {
  const classes = useStrategiesStyles()
  const [currency, setCurrency] = useState(CURRENCY._USD)
  const [range, setRange] = useState(RANGE._7d)

  const holdingsGrowth = useHoldingsGrowth()

  const poolsByField = usePoolsByField({
    filters: {
      field: 'all',
      total: 1
    }
  })

  const poolsKey = getPoolsKey(poolsByField.pools)

  const parsedChart = useMemo(
    () => parseStrategiesCharts(poolsByField.pools),
    [poolsKey]
  )

  const [rangeUsdChart, rangeEthChart] = useMemo(() => {
    return [
      parseRangeChart(range, parsedChart.usdChart),
      parseRangeChart(range, parsedChart.ethChart)
    ]
  }, [poolsKey, range])

  return (
    <ChartCard
      spacing={5}
      title={TITLE}
      tooltip={<ChartTooltip content={TOOLTIP.AREA_VALUE_TREASURY_ASSETS} />}
      titleComponent={
        <Tabs
          width="auto"
          tabs={CURRENCIES}
          value={currency}
          onChange={setCurrency}
        />
      }
      headerComponent={
        <Tabs width="auto" tabs={RANGES} value={range} onChange={setRange} />
      }
    >
      <Grid container className={classes.tableSpacing}>
        <LineChart
          area
          memo={[poolsKey, range].join('_')}
          loading={poolsByField.isLoading}
          noData={!rangeUsdChart.length}
          reverseLegends={false}
          id={getChartId(TITLE)}
          data={currency === CURRENCY._USD ? rangeUsdChart : rangeEthChart}
          legends={parsedChart.chartKeys}
          dataKey={['Total']}
          colors={COLORS}
          yTickFormatter={(tick) => formatChartMoney(tick, currency)}
          xTickFormatter={formatChartDate}
          tooltipOptions={renderTooltipOptions(
            parsedChart.chartKeys,
            false,
            COLORS,
            (e) => parseMoney(e, 2, true, currency)
          )}
        />
      </Grid>

      <HoldingGrowthTable data={holdingsGrowth.growth} columns={COLS} />
    </ChartCard>
  )
}

export default memo(AssetsTreasuryValueChart)
