import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useScrollTabs from 'hooks/ui/useScrollTabs'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { device } from 'utils/screen'
import { uuid } from 'utils/uuid'

const useStyles = makeStyles({
  tabsContainer: {
    marginBottom: '2rem',
    position: 'relative',

    [`@media ${device.mobileL}`]: {
      marginBottom: '1rem'
    }
  },
  tabs: {
    display: 'flex',
    overflowX: 'auto',
    borderBottom: '1px solid #41486E',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',

    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  tab: {
    padding: '0.625rem 1.25rem',
    cursor: 'pointer',
    position: 'relative',
    width: 175,
    minWidth: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    fontSize: '1rem',
    color: '#fff',
    textDecoration: 'none',

    '& svg': {
      marginRight: '0.5rem',
      minWidth: 20,
      minHeight: 20
    },

    '&[data-active="true"]': {
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        bottom: 0,
        left: 0,
        height: 4,
        backgroundColor: '#33B6FF',
        borderRadius: '3px 3px 0 0'
      }
    },

    [`@media ${device.mobileL}`]: {
      minWidth: 'fit-content',

      '& svg': {}
    }
  }
})

export default function PageTabs(props) {
  const [id] = useState(uuid())
  const classes = useStyles()

  const scrollTabs = useScrollTabs({ containerId: id })

  return (
    <Grid container className={classes.tabsContainer}>
      {scrollTabs.scroll && (
        <>
          {scrollTabs.renderLeftArrow()}
          {scrollTabs.renderRightArrow()}
        </>
      )}

      <Grid
        container
        alignItems="center"
        wrap="nowrap"
        className={classes.tabs}
        id={id}
        ref={scrollTabs.ref}
        onScroll={scrollTabs.onScroll}
      >
        {props.tabs.map((row, index) => (
          <Grid
            key={index}
            onClick={() => props.onClick?.(row)}
            className={classes.tab}
            data-active={props.active?.(row)}
            {...(props.asLink && {
              component: Link,
              to: props.to?.(row)
            })}
          >
            {props.render?.(row)}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
