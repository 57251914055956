import { formatISO, parseISO } from './date'

export function formatFormValues(values) {
  const data = {}

  Object.keys(values).forEach((key) => {
    if (key === 'date') {
      if (values[key]) {
        data[key] = formatISO(values[key])
      }
    } else if (key === 'custom_selected') {
      if (values['custom_selected'] !== undefined) {
        data[key] = values[key]
      }
    } else {
      data[key] = values[key]
    }
  })

  return data
}

export function formatDataToForm(data) {
  const values = {}

  Object.keys(data).forEach((key) => {
    if (key === 'date') {
      values[key] = parseISO(data[key])
    } else {
      values[key] = data[key]
    }
  })

  return values
}

export function formatStatsFormValues(values) {
  const data = {}

  Object.keys(values).forEach((key) => {
    if (key === 'date_updated') {
      if (values[key]) {
        data[key] = formatISO(values[key])
      }
    } else if (['stats_id', 'date_created'].includes(key)) {
      data[key] = values[key]
    } else {
      data[key] = isNaN(Number(values[key])) ? 0 : Number(values[key])
    }
  })

  return data
}

export function formatStatsDataToForm(data) {
  const values = {}

  Object.keys(data).forEach((key) => {
    if (key === 'date_updated') {
      values[key] = parseISO(data[key])
    } else {
      values[key] = data[key]
    }
  })

  return values
}
