import { stringifyURL } from 'utils/query'

import Api from '../Api'

export async function getNft(url: string) {
  const [reqUrl, reqBody] = url.split('?')
  const body = JSON.parse(reqBody)

  const { page, ...otherBody } = body

  const urlWithBodyParams = stringifyURL(reqUrl, { page })

  const response = await Api.post(urlWithBodyParams, otherBody)
  return response.data
}
