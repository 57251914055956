import { LinearProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { EditIcon } from 'assets/icons'
import classNames from 'classnames'
import IconButton from 'components/buttons/IconButton'
import EditStrategyDialog from 'components/dialogs/EditStrategyDialog'
import CircleCheckbox from 'components/form/CircleCheckbox'
import CellImage from 'components/images/CellImage'
import Table from 'components/table/Table'
import TableCell from 'components/table/TableCell'
import TablePagination, { OnPage } from 'components/table/TablePagination'
import TableRow from 'components/table/TableRow'
import React, { useMemo, useState } from 'react'
import { TableCol } from 'types/components/table'
import { parseMoney, parsePercent } from 'utils/api'
import { getRemoteAssetIcon, getRemoteFarmIcon } from 'utils/icons'
import {
  getFarmDisplayNameOrName,
  getStrategyDisplayNameOrAssetName
} from 'utils/pools'

const useStyles = makeStyles({
  table: {
    margin: '-1.25rem 0 -1.25rem 0'
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  iconsCell: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: '1rem',
      cursor: 'pointer',

      '&:last-child': {
        marginRight: 0
      }
    }
  },
  progressCell: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontWeight: 700
  },
  progress: {
    width: 250,
    height: 10,
    borderRadius: 10,
    marginRight: '1rem',
    backgroundColor: '#464F81',
    boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.04)'
  },
  progressBar: {
    background: 'linear-gradient(131.33deg, #33B6FF 10.83%, #1A6B9F 89.78%)',
    borderRadius: 10,
    boxShadow: '0px 4px 12px rgba(0, 35, 57, 0.25)'
  },
  iconBtn: {
    marginRight: '0.5rem'
  }
})

interface StrategiesTableProps {
  className?: string
  data: any[]
  page: number
  maxPages: number
  onPage: OnPage
  loading: boolean
  onSelect?: (poolId: string) => void
  selectedPools?: string[]
  revalidate?: any
}

const cellProps: any = {
  color: 'pool'
}

export default function StrategiesTable({
  className,
  data,
  page,
  maxPages,
  onPage,
  onSelect,
  loading,
  selectedPools,
  revalidate
}: StrategiesTableProps) {
  const classes = useStyles()
  const [editStrategy, setEditStrategy] = useState<any>(null)

  const COLS: TableCol[] = useMemo(
    () => [
      { title: '', key: 'actions' },
      { title: 'Asset', key: 'asset' },
      { title: 'Farm', key: 'farm' },
      { title: 'Blockchain', key: 'blockchain', minWidth: 125 },
      { title: 'APR (y)', key: 'aprY', minWidth: 125 },
      { title: 'Holdings', key: 'holdings', minWidth: 125 },
      { title: 'Monthly Earnings (USD)', key: 'monthlyEarning' },
      {
        title: <TablePagination page={page} count={maxPages} onPage={onPage} />,
        key: 'progress',
        align: 'right'
      }
    ],
    [page, maxPages]
  )

  const Renderer = {
    actions: (row) => (
      <Grid className={classes.iconsCell}>
        {onSelect && (
          <IconButton
            size="sm"
            className={classes.iconBtn}
            onClick={() => onSelect(row.poolId)}
          >
            <CircleCheckbox value={selectedPools?.includes(row.poolId)} />
          </IconButton>
        )}

        <IconButton size="sm" onClick={() => setEditStrategy(row)}>
          <EditIcon />
        </IconButton>
      </Grid>
    ),
    asset: (row) => (
      <div className={classes.nameCell}>
        <CellImage src={getRemoteAssetIcon(row)} />
        <span>{getStrategyDisplayNameOrAssetName(row) || 'N/A'}</span>
      </div>
    ),
    farm: (row) => (
      <div className={classes.nameCell}>
        <CellImage src={getRemoteFarmIcon(row)} />
        <span> {getFarmDisplayNameOrName(row) || 'N/A'}</span>
      </div>
    ),
    blockchain: (row) => row['blockchain'] || 'N/A',
    aprY: (row) => parsePercent(row['customAprYearly']),
    holdings: (row) => parseMoney(row['positionSizeUsd']),
    monthlyEarning: (row) => parseMoney(row['monthly_earnings']),
    progress: (row) => (
      <Grid className={classes.progressCell}>
        <LinearProgress
          classes={{ root: classes.progress, bar: classes.progressBar }}
          value={row['net_distribution'] || 0}
          variant="determinate"
        />
        <span>{parsePercent(row['net_distribution'] || 0)}</span>
      </Grid>
    )
  }

  return (
    <>
      <Table
        inCard
        cols={COLS}
        className={classNames(classes.table, className)}
        loading={loading}
        empty={!data.length}
      >
        {data.map((row, index) => (
          <TableRow key={index}>
            {COLS.map((col) => (
              <TableCell key={col.key} {...cellProps}>
                {Renderer[col.key as string](row)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Table>

      <EditStrategyDialog
        revalidate={revalidate}
        poolId={editStrategy?.poolId as string}
        open={!!editStrategy}
        onClose={() => setEditStrategy(null)}
      />
    </>
  )
}
