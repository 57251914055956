import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    display: 'block',
    cursor: 'pointer',
    marginLeft: '1rem',

    '&[data-active="true"]': {
      '& span:nth-child(2)': {
        opacity: 0
      },

      '& span:nth-child(1)': {
        transform: 'translateY(7px) rotate(45deg)'
      },
      '& span:nth-child(3)': {
        transform: ' translateY(-7px) rotate(-45deg)'
      }
    }
  },
  bar: {
    display: 'block',
    width: 25,
    height: 2,
    margin: '5px auto',
    '-webkit-transition': 'all 0.3s ease-in-out',
    transition: 'all 0.3s ease-in-out',
    backgroundColor: '#fafafa',
    borderRadius: '1rem'
  }
})

interface HamburgerProps {
  active: boolean
  onClick: any
}

export default function Hamburger({ active, onClick }: HamburgerProps) {
  const classes = useStyles()
  return (
    <div className={classes.root} data-active={active} onClick={onClick}>
      <span className={classes.bar} />
      <span className={classes.bar} />
      <span className={classes.bar} />
    </div>
  )
}
