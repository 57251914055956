import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { CopyIcon, InfoIcon } from 'assets/icons'
import classNames from 'classnames'
import Card from 'components/cards/Card'
import TooltipDropdown from 'components/TooltipDropdown'
import { toast } from 'react-toastify'
import { copyToClipboard } from 'utils/files'
import { device } from 'utils/screen'

import Title from './Title'
import { ContractsTooltip } from './Tooltip'

const useStyles = makeStyles({
  root: {
    width: '100%',

    [`@media ${device.tablet}`]: {
      width: 'calc(100% + 2.5rem)',
      overflowX: 'auto',
      margin: '0 -1.25rem',
      scrollbarWidth: 'none',
      '-ms-overflow-style': 'none',

      '&::-webkit-scrollbar': {
        display: 'none'
      }
    }
  },
  scrollContent: {
    width: '100%',
    height: '100%',

    [`@media ${device.tablet}`]: {
      width: 'fit-content',
      minWidth: '100%',
      padding: '0 1.25rem'
    }
  },
  table: {
    fontSize: '0.75rem',
    borderCollapse: 'separate',
    borderSpacing: '0 0.75rem',
    margin: '-0.75rem 0',
    whiteSpace: 'nowrap',

    '& tr': {
      '& td': {
        height: 20,
        padding: '0.25rem 0.5rem',
        backgroundColor: '#39406A',

        '&:first-child': {
          paddingLeft: '1.25rem',
          borderRadius: '1.25rem 0 0 1.25rem'
        },

        '&:last-child': {
          paddingRight: '1.25rem',
          borderRadius: '0 1.25rem 1.25rem 0'
        }
      }
    }
  },
  copyCell: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    '& svg': {
      marginLeft: '0.75rem',
      width: 12,
      height: 12,
      cursor: 'pointer'
    }
  },
  nameCell: {
    fontWeight: 700
  },
  text: {
    fontSize: '0.875rem'
  },
  tooltipIcon: {
    width: 14,
    height: 14,
    margin: '0 0.5rem'
  },
  content: {
    width: '100%',
    height: '100%',

    [`@media ${device.tablet}`]: {
      width: 'fit-content',
      minWidth: '100%',
      padding: '1.25rem'
    }
  }
})

type Token = {
  address: string
  name: string
}

interface ContractsProps {
  contracts: Token[]
}

export default function Contracts({ contracts }: ContractsProps) {
  const classes = useStyles()

  const onCopy = (hash: string) => {
    copyToClipboard(hash as string).then(() => {
      toast.success('Copied to clipboard')
    })
  }

  return (
    <div className={classNames(classes.root)}>
      <div className={classes.scrollContent}>
        <Card container direction="column" className={classes.content}>
          <Grid container direction="column">
            <Title>
              Contracts
              <TooltipDropdown content={<ContractsTooltip />}>
                <InfoIcon className={classes.tooltipIcon} />
              </TooltipDropdown>
            </Title>

            {!contracts.length ? (
              <Typography className={classes.text}>No data</Typography>
            ) : (
              <Grid container direction="column">
                <table className={classes.table}>
                  <tbody>
                    {contracts.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <span className={classes.nameCell}>{row.name}</span>
                        </td>
                        <td align="right">
                          <Grid className={classes.copyCell}>
                            <span>{row.address}</span>
                            <CopyIcon onClick={() => onCopy(row.address)} />
                          </Grid>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Grid>
            )}
          </Grid>
        </Card>
      </div>
    </div>
  )
}
