import { toast } from 'react-toastify'
import { fetcher } from 'services/api/common'
import { addCustom, removeCustom } from 'services/api/globalFields'
import useSWR from 'swr'

type OnAddCustom = (field: string, onSuccess?: any) => void
type OnRemoveCustom = (field: string, onSuccess?: any) => void

interface UseDefaultFields {
  isLoading: boolean
  defaultFields: string[]
  customFields: string[]
  strictFields: string[]
  onAddCustom: OnAddCustom
  onRemoveCustom: OnRemoveCustom
}

export default function useDefaultFields(): UseDefaultFields {
  const {
    data,
    error,
    mutate: revalidate
  } = useSWR('/auth/get_default_fields', fetcher)

  const isLoading = !data && !error
  const defaultFields = data?.edit_default_fields || []
  const customFields = data?.custom_user_fields || []
  const strictFields = data?.default_fields || []

  const onAddCustom: OnAddCustom = async (field, onSuccess) => {
    try {
      if (customFields.includes(field)) {
        toast.error('Field is already added')
        return
      }
      if ([...defaultFields, ...strictFields].includes(field)) {
        toast.error('This field cannot be added')
        return
      }
      await addCustom(field)
      revalidate?.()
      onSuccess?.()
    } catch (e) {
      toast.error('Oops error!')
      console.error(e)
    }
  }

  const onRemoveCustom: OnRemoveCustom = async (field, onSuccess) => {
    try {
      await removeCustom(field)
      revalidate?.()
      onSuccess?.()
    } catch (e) {
      toast.error('Oops error!')
      console.error(e)
    }
  }

  return {
    isLoading,
    defaultFields,
    customFields,
    strictFields,
    onAddCustom,
    onRemoveCustom
  }
}
