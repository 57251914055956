import { fetcher } from 'services/api/common'
import useSWR from 'swr'

import { stringifyURL } from '../utils/query'

export enum StrategyTypeEnum {
  ALL = 'all',
  PRODUCTIVE_ASSETS = 'productive_asset',
  NFTS = 'NFT'
}

interface PoolFilters {
  type: StrategyTypeEnum
}

interface UsePoolsNames {
  isLoading: boolean
  poolsNames: any[]
}

export function getPoolNamesKey(params: Partial<PoolFilters> = {}) {
  return stringifyURL('/auth/get_user_pool_list', params)
}

export default function usePoolsNames(
  filters: Partial<PoolFilters> = {}
): UsePoolsNames {
  const { data, error } = useSWR(() => getPoolNamesKey(filters), fetcher)

  const isLoading = !data && !error
  const poolsNames = data || []

  return {
    isLoading,
    poolsNames
  }
}
