import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Card from 'components/cards/Card'
import PieChart from 'components/charts/PieChart'
import { EmptyPlaceholder } from 'components/placeholders'
import { TableCol } from 'types/components/table'
import { parsePercent } from 'utils/api'
import { TOOLTIP } from 'utils/tooltip'

import ChartTable, { Cell, Row } from './ChartTable'

const useStyles = makeStyles({
  chartTable: {
    flex: 1,
    height: 'fit-content',
    paddingLeft: '2rem'
  },
  percent: {
    fontWeight: 700
  },
  colorCell: {}
})

const COLS: TableCol[] = [
  { title: '' },
  { title: 'Blockchain' },
  { title: '', align: 'right' }
]

interface BlockchainChartProps {
  data: any[]
}

export default function BlockchainChart({ data }: BlockchainChartProps) {
  const classes = useStyles()
  return (
    <Card container direction="row">
      {data.length ? (
        <>
          <PieChart
            customTooltip={TOOLTIP.PIE_BLOCKCHAIN}
            data={data}
            dataKey="value"
            tooltipTitle={(props) => props?.blockchain}
            tooltipText={(props) => parsePercent(props?.value)}
          />
          <Grid container className={classes.chartTable}>
            <ChartTable cols={COLS}>
              {data.map((row, index) => (
                <Row key={index}>
                  <Cell color={row.color}>
                    <span />
                  </Cell>
                  <Cell>{row.blockchain}</Cell>
                  <Cell align="right">
                    <span className={classes.percent}>
                      {parsePercent(row.value)}
                    </span>
                  </Cell>
                </Row>
              ))}
            </ChartTable>
          </Grid>
        </>
      ) : (
        <EmptyPlaceholder text="No Blockchain Data" />
      )}
    </Card>
  )
}
