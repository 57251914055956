import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { device } from 'utils/screen'

const useStyles = makeStyles({
  root: {
    maxWidth: 1024,

    '& h2': {
      fontSize: '3rem',
      marginBottom: '2rem'
    },

    '& h3': {
      fontSize: '2rem',
      marginBottom: '2rem'
    },

    '& h4': {
      fontSize: '1.5rem',
      marginBottom: '2rem'
    },

    '& h5': {
      fontSize: '1.15rem',
      marginBottom: '2rem'
    },

    '& p': {
      fontSize: '1rem',
      marginBottom: '2rem'
    },

    '& a': {
      color: '#fff'
    },

    [`@media ${device.tablet}`]: {
      '& h2': {
        fontSize: '1.5rem',
        marginBottom: '1rem'
      },

      '& h3': {
        fontSize: '1rem',
        marginBottom: '1rem'
      },

      '& h4': {
        fontSize: '0.75rem',
        marginBottom: '1rem'
      },

      '& h5': {
        fontSize: '0.75rem',
        marginBottom: '1rem'
      },

      '& p': {
        fontSize: '0.75rem',
        marginBottom: '1rem'
      },

      '& a': {
        color: '#fff'
      }
    }
  }
})

interface MarkdownProps {
  children: string
}

export default function Markdown({ children }: MarkdownProps) {
  const classes = useStyles()
  return (
    <Grid container direction="column" className={classes.root}>
      <ReactMarkdown rehypePlugins={[rehypeRaw as any]}>
        {children}
      </ReactMarkdown>
    </Grid>
  )
}
