import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Link } from 'components/typography'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '2rem 1rem',
    textTransform: 'uppercase',
    color: '#fff',
    cursor: 'pointer',

    '&[data-active="true"]': {
      color: '#B2BDFF'
    }
  }
})

interface MenuLinkProps {
  href?: string
  onClick: any
  title: string
  deps: string[]
  native?: boolean
}

export default function MenuLink({
  href,
  onClick,
  title,
  native,
  deps
}: MenuLinkProps) {
  const classes = useStyles()
  const location = useLocation()
  const active = deps.some((d) => location.pathname.includes(d))
  return (
    <Link
      to={href as string}
      native={native}
      className={classes.root}
      onClick={onClick}
    >
      <Grid className={classes.container} data-active={active}>
        {title}
      </Grid>
    </Link>
  )
}
