import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Card from 'components/cards/Card'
import { parseMoney, parsePercent } from 'utils/api'

import Title from './Title'

const useStyles = makeStyles({
  text: {
    fontSize: '0.75rem',
    marginBottom: '0.75rem',

    '&:last-child': {
      marginBottom: 0
    },

    '& span': {
      fontWeight: 700,

      '&[data-color="red"]': {
        color: '#ff5733',
        fontWeight: 400
      },
      '&[data-color="green"]': {
        color: '#00FF00',
        fontWeight: 400
      }
    }
  },
  pro: {
    borderRadius: '20px',
    background: '#525a71',
    padding: '2px 8px',
    cursor: 'pointer'
  }
})

interface LiquidityProps {
  asset: any
  isLogged: boolean
  onPro: any
}

export default function Liquidity({
  asset,
  isLogged = false,
  onPro
}: LiquidityProps) {
  const classes = useStyles()
  return (
    <Card direction="column">
      <Title>Liquidity</Title>

      <Typography className={classes.text}>
        <span>24h Trading Volume</span>{' '}
        {isLogged ? (
          parseMoney(asset.dexData?.dailyVolumeUSD)
        ) : (
          <span className={classes.pro} onClick={onPro}>
            PRO
          </span>
        )}
      </Typography>
      <Typography className={classes.text}>
        <span>Impermanent Loss Last 30d (annualized)</span>{' '}
        {isLogged ? (
          parsePercent(asset.dexData?.impLossLast30d)
        ) : (
          <span className={classes.pro} onClick={onPro}>
            PRO
          </span>
        )}
      </Typography>
      <Typography className={classes.text}>
        <span>TVL</span> {parseMoney(asset.tvlStaked)}{' '}
        <span data-color={asset.tvlChange24hValue > 0 ? 'green' : 'red'}>
          ({asset.tvlChange24h} - 24h)
        </span>
      </Typography>
    </Card>
  )
}
