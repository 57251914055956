import ScrollArrow from 'components/badge/ScrollArrow'
import { ReactNode } from 'react'

import useContainerScroll, { UseContainerScroll } from './useContainerScroll'

interface UseScrollTabsConfig {
  containerId: string
}

type RenderArrow = () => ReactNode | null

interface UseScrollTabs extends UseContainerScroll {
  renderLeftArrow: RenderArrow
  renderRightArrow: RenderArrow
}

export default function useScrollTabs(
  config: UseScrollTabsConfig
): UseScrollTabs {
  const containerScroll = useContainerScroll({
    id: config.containerId
  })

  const renderLeftArrow: RenderArrow = () =>
    containerScroll.leftScroll ? (
      <ScrollArrow side="left" onClick={() => containerScroll.scrollTo(-400)} />
    ) : null

  const renderRightArrow: RenderArrow = () =>
    containerScroll.rightScroll ? (
      <ScrollArrow side="right" onClick={() => containerScroll.scrollTo(400)} />
    ) : null

  return {
    renderLeftArrow,
    renderRightArrow,
    ...containerScroll
  }
}
