import Accordion from 'components/accordions/Accordion'
import Button from 'components/buttons/Button'
import AddRowDialog from 'components/dialogs/balanceSheet/AddRowDialog'
import { WarnDeleteRowDialog } from 'components/dialogs/WarnDeleteDialog'
import { BalanceSheetColumn, BalanceSheetRow } from 'interfaces/balance-sheet'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import BalanceSheetTable from './BalanceSheetTable'

type BalanceSheetAccordionProps = {
  isLoading: boolean
  items: BalanceSheetRow[]
  columns: BalanceSheetColumn[]
  title: string
  buttonTitle: string
  onUpdate: (items: BalanceSheetRow[]) => Promise<void>
}

export default function BalanceSheetAccordion({
  isLoading,
  items,
  title,
  buttonTitle,
  columns,
  onUpdate
}: BalanceSheetAccordionProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [editRow, setEditRow] = useState<BalanceSheetRow | undefined>(undefined)
  const [removeRowId, setRemoveRowId] = useState<string | null>(null)

  const openEditModal = (row: BalanceSheetRow) => {
    setEditRow(row)
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    setEditRow(undefined)
  }

  const handleRemoveRow = useCallback(async () => {
    await onUpdate(items.filter(({ product }) => product !== removeRowId))
    setRemoveRowId(null)
  }, [removeRowId])

  const handleCreateRow = useCallback(
    async (values: BalanceSheetRow) => {
      const hasDuplicates =
        items.some(({ product }) => product === values.product) &&
        values.product !== editRow?.product

      if (hasDuplicates) {
        toast.error('Product field should be unique.')
        return
      }

      if (!editRow) {
        await onUpdate(items.concat(values))
        setIsOpen(false)
        return
      }

      const newArray = [...items]
      const editRowIndex = items.findIndex((row) => row === editRow)
      newArray.splice(editRowIndex, 1, values)

      await onUpdate(newArray)
      setIsOpen(false)
      setEditRow(undefined)
    },
    [items, editRow]
  )

  return (
    <>
      <Accordion
        title={title}
        open
        onClick={() => void 0}
        arrow={false}
        headerComponent={
          <Button
            size="sm"
            variant="contained-2"
            onClick={() => setIsOpen(true)}
          >
            {buttonTitle}
          </Button>
        }
      >
        <BalanceSheetTable
          columns={columns}
          data={items}
          onEdit={openEditModal}
          onDelete={setRemoveRowId}
          isLoading={isLoading}
        />
      </Accordion>
      <AddRowDialog
        open={isOpen}
        onSubmit={handleCreateRow}
        row={editRow}
        onClose={closeModal}
      />
      <WarnDeleteRowDialog
        open={!!removeRowId}
        onClose={() => setRemoveRowId(null)}
        onConfirm={handleRemoveRow}
      />
    </>
  )
}
