import Grid from '@material-ui/core/Grid'
import PnlTable from 'components/tables/PnlTable'
import usePnl from 'hooks/usePnl'

import DateSelect from './DateSelect'

export default function Overview() {
  // TODO uncomment when  backend is ready
  //  const pnl = usePnl({ filters: { all: 1 } })
  const pnl = usePnl()
  if (pnl.isLoading) return <div>loading...</div>

  return (
    <Grid container direction="column">
      <DateSelect
        dates={pnl.dates}
        filters={pnl.filters}
        onFilter={pnl.onFilter}
        revalidate={pnl.revalidate}
      />
      <PnlTable
        filters={pnl.filters}
        maxPages={pnl.maxPages}
        onPage={pnl.onPage}
        data={pnl.data}
        onSort={pnl.onSort}
        revalidate={pnl.revalidate}
        onRemove={(id) => pnl.onRemove(id)}
      />
    </Grid>
  )
}
