import Api from 'services/Api'

export async function uploadImage(file) {
  const formData = new FormData()
  formData.append('file', file)

  const response = await Api.formData({
    method: 'POST',
    url: '/auth/cms/upload_image',
    data: formData
  })

  return response.data
}
