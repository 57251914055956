import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import useAuthPassword from '../../hooks/useAuthPassword'
import { PasswordData } from '../../interfaces/auth'
import { parseURL } from '../../utils/query'
import Button from '../buttons/Button'
import Input from '../form/Input'
import Dialog from './Dialog'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  text: {
    fontSize: '0.875rem',
    marginBottom: '2rem'
  },
  input: {
    marginBottom: '1.875rem',

    '&:last-child': {
      marginBottom: 0
    }
  },
  controls: {
    marginBottom: '3rem'
  }
})

export default function CreatePasswordDialog({ open, onClose, onLogin }) {
  const classes = useStyles()
  const form = useForm<PasswordData>()
  const locations = useLocation()
  const { onSetPassword } = useAuthPassword()

  const query = parseURL(locations.search).query

  const handleSuccess = (loggedIn) => {
    onClose()
    // opens login dialog if not logged in
    !loggedIn && onLogin()
  }

  const handleSave = (values: PasswordData) => {
    onSetPassword(values, query.pt as string, handleSuccess)
  }

  return (
    <Dialog
      open={open}
      size="sm"
      height={435}
      variant="secondary"
      mobileHeight={false}
      title="CREATE NEW password"
      onClose={onClose}
    >
      <DialogBody>
        <Grid container direction="column">
          <Typography className={classes.text}>
            Your new password must be different from previous used passwords.
          </Typography>

          <Grid container direction="column" className={classes.controls}>
            <Input
              variant="secondary"
              className={classes.input}
              placeholder="Password"
              type="password"
              label="Password"
              {...form.register('password')}
            />
            <Input
              variant="secondary"
              className={classes.input}
              placeholder="Confirm Password"
              type="password"
              label="Password"
              {...form.register('verified_password')}
            />
          </Grid>

          <Button
            color="secondary"
            variant="contained-2"
            size="sm"
            onClick={() => form.handleSubmit(handleSave)()}
          >
            Create Password
          </Button>
        </Grid>
      </DialogBody>
    </Dialog>
  )
}
