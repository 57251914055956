import { RegistrationData } from '../../interfaces/auth'
import Api from '../Api'

export async function setPassword(data, token) {
  const response = await Api.post(`/new_password?p=${token}`, data)
  return response.data
}

export async function login(data) {
  const response = await Api.post(`/login_web`, data)
  return response.data
}

export async function getToken(url) {
  const response = await Api.post(url, {})
  return response.data
}

export async function resetApiToken() {
  const response = await Api.post('/auth/reset_api_token', {})
  return response.data
}

export async function register(data: RegistrationData) {
  const response = await Api.post('/auth/register', data)
  return response.data
}
