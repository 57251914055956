import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { InfoIcon } from 'assets/icons'
import classNames from 'classnames'
import { ReactNode } from 'react'

import TooltipDropdown from '../TooltipDropdown'

interface ChartTooltipProps {
  content?: string | ReactNode
  className?: string
  bar?: boolean
  size?: 'sm'
  maxWidth?: number
}

const useStyles = makeStyles({
  root: (props: Pick<ChartTooltipProps, 'size' | 'bar'>) => ({
    margin: '0 0.75rem',
    color: '#B2BDFF',
    width: 16,
    height: 16,

    ...(props.bar && {
      margin: 0,
      position: 'absolute',
      top: 5,
      right: 5,
      zIndex: 111
    }),

    ...(props.size === 'sm' && {
      width: 12,
      height: 12,
      margin: '0 0.5rem'
    })
  }),
  text: {
    fontSize: '0.75rem',
    whiteSpace: 'pre-wrap'
  }
})

export default function ChartTooltip({
  content = 'To be explained...',
  className,
  bar,
  size,
  maxWidth
}: ChartTooltipProps) {
  const classes = useStyles({ bar, size })
  return (
    <TooltipDropdown
      maxWidth={maxWidth}
      content={
        <Typography className={classNames(classes.text, className)}>
          {content}
        </Typography>
      }
    >
      <InfoIcon className={classes.root} />
    </TooltipDropdown>
  )
}
