import { useMemo, useState } from 'react'
import { RANGE } from 'utils/filters'
import { parseRangeChart } from 'utils/pools'

export type IRangeChart<T> = {
  data: T[]
  initialRange: string
}

export default function useRangeChart<T extends any>({
  data,
  initialRange = RANGE._max
}: IRangeChart<T>) {
  const [range, setRange] = useState(initialRange)

  const rangeParsedData = useMemo(() => parseRangeChart(range, data), [range])

  return {
    range,
    setRange,
    rangeParsedData
  }
}
