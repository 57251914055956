import { OnPage } from 'components/table/TablePagination'
import { useState } from 'react'
import { fetcher } from 'services/api/common'
import useSWR from 'swr'
import { stringifyURL } from 'utils/query'

interface BondsTransactionsFilters {
  page: number
  category: string
  range: 'daily' | 'monthly'
}

interface UseBondsTransactionsConfig {
  filters?: Partial<BondsTransactionsFilters>
}

interface UseBondsTransactions {
  isLoading: boolean
  bonds: any[]
  filters: BondsTransactionsFilters
  maxPages: number
  totalIncome: number
  onPage: OnPage
  revalidate: any
}

function getKey(params) {
  return stringifyURL('/auth/get_bonds_transactions', params)
}

export default function useBondsTransactions(
  config: UseBondsTransactionsConfig = {}
): UseBondsTransactions {
  const [filters, setFilters] = useState<BondsTransactionsFilters>({
    page: 1,
    category: '',
    range: 'daily'
  })

  const params = {
    ...filters,
    ...config.filters
  }

  const {
    data,
    error,
    mutate: revalidate
  } = useSWR(() => getKey(params), fetcher)

  const onPage: OnPage = (page) => {
    setFilters((filters) => ({
      ...filters,
      page
    }))
  }

  const isLoading = !data && !error
  const bonds = data?.['bonds'] || []
  const maxPages = data?.max_pages || 1
  const totalIncome = data?.total_income

  return {
    isLoading,
    bonds,
    filters,
    maxPages,
    totalIncome,
    onPage,
    revalidate
  }
}
