import { fetcher } from 'services/api/common'
import useSWR, { cache } from 'swr'

interface UseExchangesSelect {
  isLoading: boolean
  data: {
    blockchains: string[]
    exchangeNames: string[]
  }
}

const key = '/auth/get_exchanges_select'

export default function useExchangesSelect(): UseExchangesSelect {
  const swr = useSWR(key, fetcher, {
    revalidateOnMount: !cache.has(key),
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  })

  const isLoading = !swr.data && !swr.error
  const data = swr.data || {}

  return {
    isLoading,
    data
  }
}
