import { useSpinner } from 'providers/SpinnerProvider'
import { useTriggersContext } from 'providers/TriggersProvider'
import { toast } from 'react-toastify'
import { addTrigger } from 'services/api/triggers'
import { formatValues } from 'utils/triggers'

type OnAdd = (values: any, onSuccess?: any) => void

interface UseTriggers {
  onAdd: OnAdd
}

export default function useTrigger(): UseTriggers {
  const spinner = useSpinner()
  const triggersContext = useTriggersContext()

  const onAdd: OnAdd = async (values, onSuccess) => {
    try {
      spinner.open()
      await addTrigger(formatValues(values))
      toast.success('Successfully added')
      triggersContext.revalidate?.()
      spinner.close()
      onSuccess?.()
    } catch (e) {
      spinner.close()
      toast.error('Oops error')
      console.error(e)
    }
  }

  return {
    onAdd
  }
}
