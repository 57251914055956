import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Tabs from 'components/tabs/Tabs'
import { PageTitle } from 'components/typography'
import useBlockchainDistributions from 'hooks/useBlockchainDistributions'
import useDistributionByField from 'hooks/useDistributionByField'
import useInvestmentsBalance from 'hooks/useInvestmentsBalance'
import useYieldTypeDistributions from 'hooks/useYieldTypeDistributions'
import { useSpinner } from 'providers/SpinnerProvider'
import { useState } from 'react'

import AssetsChart from './portfolio/AssetsChart'
import AssetsTreasuryValueChart from './portfolio/AssetsTreasuryValueChart'
import AssetsTypes from './portfolio/AssetsTypes'
import BlockchainChart from './portfolio/BlockchainChart'
import NetWorthBar from './portfolio/NetWorthBar'
import NftTypes from './portfolio/NftTypes'
import RiskChart from './portfolio/RiskChart'
import YieldTypeChart from './portfolio/YieldTypeChart'

const useStyles = makeStyles({
  chartsContainer: {
    marginBottom: '1.5rem'
  },
  title: {
    marginBottom: '1.25rem'
  },
  tabs: {
    marginBottom: '1.25rem'
  },
  charts: {
    display: 'grid',
    gap: '1.25rem',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridTemplateAreas: `
      "a a a a a a a b b b b b"
      "c c c c c c d d d d d d"
    `,

    '& > div': {
      '&:nth-child(1)': {
        gridArea: 'a'
      },
      '&:nth-child(2)': {
        gridArea: 'b'
      },
      '&:nth-child(3)': {
        gridArea: 'c'
      },
      '&:nth-child(4)': {
        gridArea: 'd'
      }
    }
  },
  chartTable: {
    flex: 1,
    paddingLeft: '2rem'
  },
  strategiesTableContainer: {
    marginBottom: '2.5rem'
  }
})

const TABS = [
  { value: 'ASSET', name: 'Asset' },
  { value: 'NFT', name: 'NFT' }
]

export default function Portfolio() {
  const classes = useStyles()

  const [tab, onTab] = useState('ASSET')

  const investmentsBalance = useInvestmentsBalance()
  const blockchainDistributions = useBlockchainDistributions()
  const yieldTypeDistributions = useYieldTypeDistributions()
  const riskDistribution = useDistributionByField({ field: 'riskLevel' })

  useSpinner(investmentsBalance.isLoading)

  return (
    <Grid container direction="column">
      <Grid container direction="column" className={classes.chartsContainer}>
        <PageTitle className={classes.title}>
          Current Investment Balance
        </PageTitle>

        <Grid container className={classes.tabs}>
          <Tabs width="auto" tabs={TABS} value={tab} onChange={onTab} />
        </Grid>

        <NetWorthBar
          tab={tab}
          investmentsBalance={investmentsBalance.investmentsBalance}
        />

        {tab === 'ASSET' ? (
          <Grid container className={classes.charts}>
            <AssetsChart
              data={investmentsBalance.investmentsBalance.top_assets || []}
            />
            <BlockchainChart data={blockchainDistributions.chartData} />
            <RiskChart data={riskDistribution.chartData} />
            <YieldTypeChart data={yieldTypeDistributions.chartData} />
          </Grid>
        ) : (
          <Grid container>
            <AssetsChart
              data={investmentsBalance.investmentsBalance.top_nfts || []}
            />
          </Grid>
        )}
      </Grid>

      {tab === 'ASSET' && <AssetsTreasuryValueChart />}

      {tab === 'ASSET' ? <AssetsTypes /> : <NftTypes />}
    </Grid>
  )
}
