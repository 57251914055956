import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import useProteusDescription from 'hooks/useProteusDescription'
import { useProteusDescriptionContext } from 'providers/ProteusDescriptionProvider'
import { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'

import Dialog, {
  DialogBaseProps,
  DialogTitleComponentProps,
  OnTitleComponent
} from './Dialog'
import DialogActions from './DialogActions'
import DialogBody from './DialogBody'
import DialogHeadIconButton, {
  DialogHeadIconButtonContainer
} from './DialogHeadIconButton'
import DialogTitleComponent from './DialogTitleComponent'
import UserIconsDialog from './UserIconsDialog'

const useStyles = makeStyles({
  input: {
    marginBottom: '1.25rem'
  }
})

interface ContentProps {
  editId: string
}

function Content(
  props: DialogBaseProps & ContentProps & DialogTitleComponentProps
) {
  const classes = useStyles()
  const proteusDescriptionContext = useProteusDescriptionContext()
  const proteusDescription = useProteusDescription({
    editId: props.editId
  })

  const [iconDialog, onIconDialog] = useState(false)

  const form = useForm()

  const [image] = useWatch({
    control: form.control,
    name: ['image']
  })

  const key = JSON.stringify(proteusDescription.description)

  useEffect(() => {
    if (proteusDescription.description.item_id) {
      form.reset({ ...proteusDescription.description })
    }
  }, [key])

  const handleSubmit = (values) => {
    proteusDescription.onAdd(values, () => {
      proteusDescriptionContext.revalidate?.()
      props.onClose?.()
    })
  }

  useEffect(() => {
    props.onTitleComponent(
      <DialogTitleComponent>
        <span>Add new Description</span>
        <DialogHeadIconButtonContainer>
          <DialogHeadIconButton
            tooltip="Upload Icon"
            onClick={() => onIconDialog(true)}
            icon={image}
          />
        </DialogHeadIconButtonContainer>
      </DialogTitleComponent>
    )
  }, [image])

  return (
    <>
      <DialogBody>
        <Input
          variant="secondary"
          label="Title"
          placeholder="Title"
          className={classes.input}
          {...form.register('title')}
        />
        <Input
          variant="secondary"
          label="Link"
          placeholder="Link"
          className={classes.input}
          {...form.register('link')}
        />
        <Input
          rows={10}
          variant="secondary"
          label="Info"
          placeholder="Info"
          className={classes.input}
          {...form.register('info')}
        />
        <Input
          rows={10}
          variant="secondary"
          label="How to Use"
          placeholder="How to use"
          className={classes.input}
          {...form.register('howToUse')}
        />
      </DialogBody>

      <DialogActions>
        <Button
          variant="contained-2"
          size="sm"
          color="secondary"
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained-2"
          size="sm"
          onClick={() => form.handleSubmit(handleSubmit)()}
        >
          Save
        </Button>
      </DialogActions>

      <UserIconsDialog
        open={iconDialog}
        onClose={() => onIconDialog(false)}
        onConfirm={(url) => {
          form.setValue('image', url)
          onIconDialog(false)
        }}
        initialUrl={image}
      />
    </>
  )
}

export default function AddProteusDescriptionDialog(
  props: DialogBaseProps & ContentProps
) {
  const [titleComponent, onTitleComponent] = useState()
  return (
    <Dialog
      open={props.open}
      variant="secondary"
      title={titleComponent}
      onClose={props.onClose}
      size="lg"
      height={800}
    >
      <Content
        {...props}
        onTitleComponent={onTitleComponent as OnTitleComponent}
      />
    </Dialog>
  )
}
