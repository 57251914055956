import { Grid, IconButton } from '@material-ui/core'
import { AddIcon } from 'assets/icons'
import Card from 'components/cards/Card'
import { AddAssetTokenDialog } from 'components/dialogs/AddAssetTokenDialog'
import { EditAssetTokenDialog } from 'components/dialogs/EditAssetTokenDialog'
import { Typography } from 'components/typography'
import { UsePool } from 'hooks/usePool'
import { getPoolNamesKey } from 'hooks/usePoolsNames'
import { AssetTokenEntity } from 'interfaces/asset-token'
import { useState } from 'react'
import { mutate as globalRevalidate } from 'swr'

import { useStrategiesStyles } from '../../strategies/styles'
import AssetToken from './AssetToken'

interface AssetDetailsProps {
  usePool: UsePool
}

export default function AssetDetails({ usePool }: AssetDetailsProps) {
  const pool = usePool.pool
  const classes = useStrategiesStyles()

  const [isAddTokenDialogOpen, switchAddTokenDialog] = useState<boolean>(false)
  const [assetToken, setAssetToken] = useState<AssetTokenEntity | null>(null)

  /**
   * Appends a new asset token to the asset details of the given pool.
   * Closes the add modal upon success.
   * @param data - New asset token entity
   */
  const addAssetToken = async (
    data: AssetTokenEntity,
    errorCallback: (
      property: 'asset' | 'websiteUrl' | 'contractUrl' | 'description',
      message: string
    ) => void
  ): Promise<void> => {
    const assetDetails: AssetTokenEntity[] = pool.assetDetails || []
    const assetNameConflict = assetDetails.some(
      (assetToken) => assetToken.asset == data.asset
    )
    if (assetNameConflict) {
      errorCallback('asset', "Asset's name must be unique.")
      return
    }
    usePool.onUpdate(pool.poolId, { assetDetails: [...assetDetails, data] }, {})
    switchAddTokenDialog(false)
  }

  /**
   * Updates the asset token by the given asset property.
   * @param asset - Asset Token's asset value.
   * @param data - Update data.
   */
  const editAssetToken = async (
    asset: string,
    data: AssetTokenEntity,
    errorCallback: (
      property: 'asset' | 'websiteUrl' | 'contractUrl' | 'description',
      message: string
    ) => void
  ): Promise<void> => {
    const assetDetails: AssetTokenEntity[] = pool.assetDetails || []
    if (asset !== data.asset) {
      const assetNameConflict = assetDetails.some(
        (assetToken) => assetToken.asset == data.asset
      )
      if (assetNameConflict) {
        errorCallback('asset', "Asset's name must be unique.")
        return
      }
    }
    usePool.onUpdate(
      pool.poolId,
      {
        assetDetails: assetDetails.map((assetToken) => {
          if (assetToken.asset !== asset) return assetToken
          return data
        })
      },
      {}
    )
    globalRevalidate?.(getPoolNamesKey())
    setAssetToken(null)
  }

  /**
   * Selects an assets token and triggers the edit form to open.
   * @param assetToken - Selcted asset token.
   */
  const onEditAssetToken = (assetToken: AssetTokenEntity): void => {
    setAssetToken(assetToken)
  }

  /**
   * Deletes the asset token by the given asset property.
   * @param asset
   */
  const onDeleteAssetToken = async (asset: string): Promise<void> => {
    const assetDetails: AssetTokenEntity[] = pool.assetDetails || []
    usePool.onUpdate(
      pool.poolId,
      {
        assetDetails: assetDetails.filter(
          (assetToken) => assetToken.asset !== asset
        )
      },
      {}
    )
  }

  return (
    <>
      <Card className={classes.assetDetails} title="Asset Details">
        <Grid className={classes.assetDetailsGrid}>
          <Grid className={classes.assetDetailsTitleContainer}>
            <Typography className={classes.assetDetailsTitle}>
              <span>Asset Details</span>
            </Typography>
            <IconButton
              className={classes.white}
              size="small"
              onClick={() => switchAddTokenDialog(!isAddTokenDialogOpen)}
            >
              <AddIcon />
            </IconButton>
          </Grid>

          <Grid className={classes.assetTokens}>
            {pool.assetDetails?.map((assetToken, index) => (
              <AssetToken
                key={`assetToken-${index}`}
                assetToken={assetToken}
                onEdit={onEditAssetToken}
                onDelete={onDeleteAssetToken}
              />
            ))}
          </Grid>
        </Grid>
      </Card>
      <AddAssetTokenDialog
        open={isAddTokenDialogOpen}
        onClose={() => switchAddTokenDialog(false)}
        onSubmit={addAssetToken}
      />
      {assetToken && (
        <EditAssetTokenDialog
          open={true}
          assetToken={assetToken}
          onClose={() => setAssetToken(null)}
          onSubmit={editAssetToken}
        />
      )}
    </>
  )
}
