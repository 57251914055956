import useSWR from 'swr'

import { getFarm } from '../services/api/farms'

interface UseFarmConfig {
  farmId?: string
}

interface UseFarm {
  isLoading: boolean
  farm: any
}

export default function useFarm(config: UseFarmConfig = {}): UseFarm {
  const { data, error } = useSWR(
    config.farmId ? `/get_farm_details/${config.farmId}` : null,
    getFarm
  )

  const isLoading = !data && !error
  const farm = data || {}

  return {
    isLoading,
    farm
  }
}
