import Api from '../Api'

export async function subscribeToEmail(
  type: 'waitlist' | 'newsletter',
  email: string,
  message?: string
) {
  const response = await Api.post('/subscribe', {
    type,
    email,
    message
  })
  return response.data
}
