import { toast } from 'react-toastify'
import { getToken, resetApiToken } from 'services/api/auth'
import useSWR from 'swr'

type OnReset = () => void

interface UseApiToken {
  isLoading: boolean
  apiToken: string
  onReset: OnReset
}

export default function useApiToken(): UseApiToken {
  const {
    data,
    error,
    mutate: revalidate
  } = useSWR('/auth/get_api_tokens', getToken)

  const onReset: OnReset = async () => {
    try {
      await resetApiToken()
      revalidate?.()
      toast.success('Token successfully updated')
    } catch (e) {
      console.error(e)
      toast.error('Oops error!')
    }
  }

  const isLoading = !data && !error
  const apiToken =
    data && Array.isArray(data)
      ? data.find((t) => !!t.active)?.token || undefined
      : undefined

  return {
    isLoading,
    apiToken,
    onReset
  }
}
