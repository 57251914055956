import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { InfoIcon } from 'assets/icons'
import BookButton from 'components/buttons/BookButton'
import Button from 'components/buttons/Button'
import PoolEconomicsTooltip from 'components/PoolEconomicsTooltip'
import Table from 'components/table/Table'
import TableCell from 'components/table/TableCell'
import TablePagination from 'components/table/TablePagination'
import TableRow from 'components/table/TableRow'
import { UseAssets } from 'hooks/useAssets'
import useSavedSearches from 'hooks/useSavedSearches'
import { useAuth } from 'providers/AuthProvider'
import React, { useMemo } from 'react'
import { TableCol } from 'types/components/table'
import { formatRows, getAssetsCols } from 'utils/assets'
import { device } from 'utils/screen'
import { getSearchesIds } from 'utils/searches'

import BottomPagination from '../BottomPagination'
import CellImage from '../images/CellImage'
import { Link } from '../typography'

const useStyles = makeStyles({
  btn: {
    marginRight: '1.25rem',
    whiteSpace: 'nowrap',

    [`@media ${device.tablet}`]: {
      height: 30,
      borderRadius: 10,
      fontSize: '0.75rem'
    }
  },
  nameCol: {
    display: 'flex',
    alignItems: 'center'
  },
  img: {
    width: 20,
    height: 20,
    marginRight: '0.5rem'
  }
})

interface AssetsTableProps
  extends Pick<
    UseAssets,
    'assets' | 'isLoading' | 'filters' | 'maxPages' | 'onPage' | 'onSort'
  > {}

export default function AssetsTable({
  assets,
  isLoading,
  filters,
  maxPages,
  onPage,
  onSort
}: AssetsTableProps) {
  const classes = useStyles()
  const { isLogged } = useAuth()

  const COLS: TableCol[] = useMemo(
    () =>
      getAssetsCols(
        <TablePagination page={filters.pg} count={maxPages} onPage={onPage} />
      ),
    [isLogged, filters.pg, maxPages]
  )

  const { savedSearches, onSaveSearch, onRemoveSearch } = useSavedSearches({
    save_type: 'asset'
  })
  const savedIds = getSearchesIds(savedSearches, 'assetId')

  const rows = formatRows(assets)

  return (
    <>
      <Table
        cols={COLS}
        loading={isLoading}
        empty={!rows.length}
        sort={filters.sort}
        sortOrder={filters.sort_order}
        onSort={onSort}
      >
        {rows.map((row) => (
          <TableRow key={row.assetId}>
            <TableCell>
              <div className={classes.nameCol}>
                {isLogged && (
                  <BookButton
                    booked={savedIds.includes(row.assetId)}
                    onBook={() => onSaveSearch(row.assetId, 'asset')}
                    onUnBook={() => onRemoveSearch(row.assetId)}
                  />
                )}

                <CellImage src={row.imageUrl} />

                <Link to={`/assets/${row.assetId}`}>
                  <span>{row.asset}</span>
                </Link>
              </div>
            </TableCell>
            <TableCell>{row.tvlStaked}</TableCell>
            <TableCell>{row.aprYearly}</TableCell>
            <TableCell>{row.aprDaily}</TableCell>
            <TableCell>{row.blockchain}</TableCell>
            <TableCell>{row.farm}</TableCell>
            <TableCell>{row.rewards}</TableCell>
            <TableCell align="right">
              <Grid
                container
                alignItems="center"
                justifyContent="flex-end"
                wrap="nowrap"
              >
                <a
                  href={row.investLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Button
                    variant="contained-2"
                    size="sm"
                    className={classes.btn}
                    disabled={!row.investLink}
                  >
                    Invest
                  </Button>
                </a>

                <PoolEconomicsTooltip data={row}>
                  <InfoIcon />
                </PoolEconomicsTooltip>
              </Grid>
            </TableCell>
          </TableRow>
        ))}
      </Table>

      {!isLoading && (
        <BottomPagination
          page={filters.pg}
          count={maxPages}
          onChange={onPage}
        />
      )}
    </>
  )
}
