import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import { TableCol } from 'types/components/table'

import { ArrowRightIcon } from '../../assets/icons'
import { device } from '../../utils/screen'
import ChartTooltip from '../tooltips/ChartTooltip'

export type SortOrder = 'desc' | 'asc'

interface TableHeadCellProps
  extends Pick<
    TableCol,
    | 'align'
    | 'minWidth'
    | 'sortable'
    | 'title'
    | 'name'
    | 'width'
    | 'wrap'
    | 'maxWidth'
    | 'tooltip'
    | 'sticky'
    | 'border'
  > {
  active?: boolean
  sortOrder?: SortOrder
  onClick?: any
  color?: string
}

const useStyles = makeStyles({
  root: (props: Partial<TableHeadCellProps>) => ({
    verticalAlign: 'bottom',
    padding: '0.5rem 0.5rem',
    tableLayout: 'fixed',
    width: props.width,
    minWidth: props.minWidth || props.width,
    maxWidth: props.maxWidth,
    fontSize: '1rem',

    ...(typeof props.sticky === 'number' && {
      position: 'sticky',
      left: props.sticky,
      backgroundColor: '#272d49'
    }),

    ...(props.border === 'right' && {
      borderRight: '1px solid #41486E'
    }),

    '&:first-child': {
      paddingLeft: '2.5rem'
    },
    '&:last-child': {
      paddingRight: '2.5rem'
    },

    [`@media ${device.tablet}`]: {
      fontSize: '0.875rem'
    }
  }),
  sortIcon: {
    transform: 'rotate(90deg)',
    margin: '0 0.5rem'
  },
  sortAsc: {
    transform: 'rotate(-90deg)'
  },
  content: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    whiteSpace: (props: any) => (props.wrap ? 'normal' : 'nowrap'),
    cursor: (props: any) => (props.sortable ? 'pointer' : 'default'),
    color: (props: any) =>
      props.color ? props.color : props.active ? '#66C8FF' : '#fff'
  }
})

export default function TableHeadCell({
  align = 'left',
  minWidth,
  maxWidth,
  title,
  sortable,
  active,
  sortOrder,
  onClick,
  width,
  wrap,
  sticky,
  tooltip,
  border,
  color
}: TableHeadCellProps) {
  const classes = useStyles({
    minWidth,
    maxWidth,
    sortable,
    active,
    width,
    wrap,
    sticky,
    border,
    color
  })
  return (
    <th align={align} className={classes.root}>
      <div className={classes.content} onClick={onClick}>
        {title}

        {tooltip && <ChartTooltip size="sm" content={tooltip} />}

        {sortable && active && (
          <ArrowRightIcon
            className={classNames(classes.sortIcon, {
              [classes.sortAsc]: sortOrder === 'asc'
            })}
          />
        )}
      </div>
    </th>
  )
}
