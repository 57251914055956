import ChartCard from 'components/cards/ChartCard'
import LineChart from 'components/charts/LineChart'
import Tabs from 'components/tabs/Tabs'
import ChartTooltip from 'components/tooltips/ChartTooltip'
import { useMemo, useState } from 'react'
import { parseMoney } from 'utils/api'
import {
  formatChartDate,
  formatChartMoney,
  getChartId,
  renderTooltipOptions
} from 'utils/charts'
import { getRandomColors } from 'utils/colors'
import { CURRENCY, RANGE } from 'utils/filters'
import { parseRangeChart } from 'utils/pools'
import { TOOLTIP } from 'utils/tooltip'

import { CURRENCIES, RANGES } from './data'

const COLORS_2 = getRandomColors(100)
const TITLE = 'Holdings'
const CHART_ID = getChartId(TITLE + Date.now())

export interface HoldingsAreaChartProps {
  usdData: any[]
  ethData: any[]
  keys: string[]
  loading: boolean
}

export default function HoldingsAreaChart({
  keys,
  usdData,
  ethData,
  loading
}: HoldingsAreaChartProps) {
  const [highlightsRange, setHighlightsRange] = useState(RANGE._7d)
  const [currency, setCurrency] = useState(CURRENCY._USD)

  const key = keys.join('-')

  const [rangeUsdData, rangeEthData] = useMemo(() => {
    return [
      parseRangeChart(highlightsRange, usdData),
      parseRangeChart(highlightsRange, ethData)
    ]
  }, [key, highlightsRange])

  const chartData = currency === CURRENCY._USD ? rangeUsdData : rangeEthData

  return (
    <ChartCard
      title={TITLE}
      spacing={10}
      tooltip={<ChartTooltip content={TOOLTIP.AREA_HOLDINGS} />}
      titleComponent={
        <Tabs
          width="auto"
          tabs={CURRENCIES}
          value={currency}
          onChange={setCurrency}
        />
      }
      headerComponent={
        <Tabs
          width="auto"
          tabs={RANGES}
          value={highlightsRange}
          onChange={setHighlightsRange}
        />
      }
    >
      <LineChart
        area
        memo={[key, highlightsRange].join('_')}
        id={CHART_ID}
        data={chartData}
        legends={keys}
        dataKey={keys}
        colors={COLORS_2}
        loading={loading}
        noData={!chartData.length}
        yTickFormatter={(tick) => formatChartMoney(tick, currency)}
        xTickFormatter={formatChartDate}
        tooltipOptions={renderTooltipOptions(
          keys,
          true,
          COLORS_2,
          (e) => parseMoney(e, 2, true, currency),
          'total'
        )}
      />
    </ChartCard>
  )
}
