import useMediaQuery from '@material-ui/core/useMediaQuery'
import { device } from 'utils/screen'

interface UseMediaQueries {
  isMobile: boolean
  isTablet: boolean
  isLaptop: boolean
  isLaptopM: boolean
  isDesktop: boolean
}

export default function useMediaQueries(): UseMediaQueries {
  const isMobile = useMediaQuery(device.mobileL)
  const isTablet = useMediaQuery(device.tablet)
  const isLaptop = useMediaQuery(device.laptop)
  const isLaptopM = useMediaQuery(device.laptopM)
  const isDesktop = useMediaQuery(device.desktop)
  return {
    isMobile,
    isTablet,
    isLaptop,
    isLaptopM,
    isDesktop
  }
}
