import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import ChartTooltip from 'components/tooltips/ChartTooltip'
import useBondTransaction from 'hooks/useBondTransaction'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { copyToClipboard } from 'utils/files'
import { BOOL_FILTERS } from 'utils/filters'
import { toStartCase } from 'utils/strings'

import Dialog, {
  DialogBaseProps,
  DialogTitleComponentProps,
  OnTitleComponent
} from './Dialog'
import DialogActions from './DialogActions'
import DialogBody from './DialogBody'
import DialogTitleComponent from './DialogTitleComponent'

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.25rem'
  },
  copyHash: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',

    '& svg': {
      width: 16,
      cursor: 'pointer',
      marginLeft: '0.75rem'
    }
  }
})

const FIELDS = [
  'active',
  'asset',
  'raw_value',
  'decimals',
  'bond_sender_addr',
  'bond_receiver_addr',
  'value_usd',
  'value_eth',
  'value_asset',
  'source',
  'partner',
  'category',
  'blockchain'
]

interface ContentProps extends DialogBaseProps {
  hash?: string
  revalidate: any
}

function Content(props: ContentProps & DialogTitleComponentProps) {
  const classes = useStyles()

  const bondTransaction = useBondTransaction({
    hash: props.hash
  })

  const form = useForm()

  const key = JSON.stringify(bondTransaction.data)

  useEffect(() => {
    form.reset({ ...bondTransaction.data })
  }, [key])

  const handleCopy = () => {
    copyToClipboard(props.hash as string).then(() => {
      toast.success('Copied')
    })
  }

  useEffect(
    () =>
      props.onTitleComponent(
        <DialogTitleComponent>
          {props.hash ? 'Edit Bond Transaction' : 'Add Bond Transaction'}
          {props.hash && (
            <ChartTooltip
              maxWidth={700}
              content={
                <Grid className={classes.copyHash}>
                  <span>{props.hash}</span>

                  <FileCopyIcon onClick={handleCopy} />
                </Grid>
              }
            />
          )}
        </DialogTitleComponent>
      ),
    [props.hash]
  )

  const handleSubmit = (values) => {
    bondTransaction.onAdd(values, () => {
      props.onClose?.()
      props.revalidate?.()
    })
  }

  const Renderer = {
    active: (row) => (
      <Controller
        key={row}
        name={row}
        control={form.control}
        render={(e) => (
          <Select
            clearable
            searchable={false}
            variant="secondary"
            options={BOOL_FILTERS}
            placeholder={toStartCase(row)}
            label={toStartCase(row)}
            value={e.field.value}
            onChange={(e) => form.setValue(row, e ? e.value : '')}
          />
        )}
      />
    ),
    default: (row) => (
      <Input
        key={row}
        variant="secondary"
        label={toStartCase(row)}
        placeholder={toStartCase(row)}
        {...form.register(row)}
      />
    )
  }

  return (
    <>
      <DialogBody>
        <Grid
          container
          component="form"
          id="add_edit_bond"
          onSubmit={form.handleSubmit(handleSubmit)}
          className={classes.root}
        >
          {FIELDS.map((row) =>
            Renderer[row] ? Renderer[row](row) : Renderer.default(row)
          )}
        </Grid>
      </DialogBody>

      <DialogActions>
        <Button
          size="sm"
          color="secondary"
          variant="contained-2"
          onClick={props.onClose}
        >
          Reset
        </Button>
        <Button
          size="sm"
          color="primary"
          variant="contained-2"
          type="submit"
          form="add_edit_bond"
        >
          Save
        </Button>
      </DialogActions>
    </>
  )
}

export default function AddBondDialog(props: ContentProps) {
  const [titleComponent, onTitleComponent] = useState()
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      variant="secondary"
      title={titleComponent}
      size="lg"
      height={500}
    >
      <Content
        {...props}
        onTitleComponent={onTitleComponent as OnTitleComponent}
      />
    </Dialog>
  )
}
