import { useSpinner } from 'providers/SpinnerProvider'
import { toast } from 'react-toastify'
import {
  addProteusDescription,
  removeProteusDescription
} from 'services/api/cms'
import { fetcher } from 'services/api/common'
import useSWR from 'swr'
import { stringifyURL } from 'utils/query'

type OnAdd = (data: any, onSuccess?: any) => void
type OnRemove = (id: string, onSuccess?: any) => void

interface UseProteusDescription {
  isLoading: boolean
  description: Record<string, any>
  revalidate: any
  onAdd: OnAdd
  onRemove: OnRemove
}

interface UseProteusDescriptionConfig {
  editId?: string
}

function getKey(params) {
  return params.item_id
    ? stringifyURL('/auth/cms/get_single_proteus_description', params)
    : null
}

export default function useProteusDescription(
  config: UseProteusDescriptionConfig = {}
): UseProteusDescription {
  const spinner = useSpinner()

  const params = {
    item_id: config.editId
  }

  const {
    data,
    error,
    mutate: revalidate
  } = useSWR(() => getKey(params), fetcher)

  const onAdd: OnAdd = async (data, onSuccess) => {
    try {
      spinner.open()
      const values = { ...data }

      if (!values.image) {
        delete values.image
      }

      await addProteusDescription(values)

      spinner.close()
      revalidate?.()
      toast.success('Successfully saved')
      onSuccess?.()
    } catch (e) {
      spinner.close()
      console.error(e)
      toast.error('Oops error')
    }
  }

  const onRemove: OnRemove = async (id, onSuccess) => {
    try {
      spinner.open()
      await removeProteusDescription(id)
      spinner.close()
      toast.success('Successfully removed')
      onSuccess?.()
    } catch (e) {
      spinner.close()
      console.error(e)
      toast.error('Oops error')
    }
  }

  const isLoading = !data && !error
  const description = data || {}

  return {
    isLoading,
    description,
    revalidate,
    onAdd,
    onRemove
  }
}
