import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { CBridgeIcon } from 'assets/icons'
import Badge from 'components/badge/Badge'
import { Link } from 'components/typography'
import { device } from 'utils/screen'

const useStyles = makeStyles({
  link: {
    marginBottom: '1.25rem'
  },
  root: {
    padding: '1rem',
    backgroundColor: '#ffffff',
    borderRadius: 15,

    '& svg': {
      width: 250,
      height: 30
    },

    [`@media ${device.tablet}`]: {
      flexDirection: 'column'
    }
  },
  title: {
    fontSize: '1.875rem',
    lineHeight: 1,
    color: '#000',

    '& span': {
      fontWeight: 700
    },

    [`@media ${device.tablet}`]: {
      fontSize: '1rem',
      margin: '1.25rem 0'
    }
  }
})

export default function CelerBridgeBanner() {
  const classes = useStyles()
  return (
    <Link
      native
      wrapper
      className={classes.link}
      target="_blank"
      rel="noreferrer noopener"
      to="https://cbridge.celer.network/#/transfer"
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.root}
      >
        <Badge>AD</Badge>

        <Typography className={classes.title}>
          Going cross-chain? <span>Use Celer cBridge</span>
        </Typography>

        <CBridgeIcon />
      </Grid>
    </Link>
  )
}
