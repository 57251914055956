import { useState } from 'react'
import { getNft } from 'services/api/nft'
import useSWR from 'swr'

import { OnPage } from '../components/table/TablePagination'

function getKey(params) {
  return `/auth/get_nfts?` + JSON.stringify(params)
}

export default function useNftStrategies() {
  const [filters, onFilters] = useState({
    page: 1
  })

  const params = {
    strategies_only: true,
    ...filters
  }
  const { data: _data, error } = useSWR(() => getKey(params), getNft)

  const onPage: OnPage = (page) => {
    onFilters((filters) => ({
      ...filters,
      page
    }))
  }

  const isLoading = !_data && !error
  const data = _data?.data || []
  const maxPages = _data?.max_pages || 1

  return {
    isLoading,
    maxPages,
    data,
    filters,
    onPage
  }
}
