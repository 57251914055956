import { makeStyles, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import useAuthAccount from 'hooks/useAuthAccount'
import { useForm } from 'react-hook-form'

import { PREMIUM_NO_ACCESS } from '../../config'
import Dialog from './Dialog'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  input: {
    marginBottom: '1.875rem',

    '&:last-child': {
      marginBottom: 0
    }
  },
  controls: {
    marginBottom: '3rem'
  },
  btn: {
    marginBottom: '1rem'
  },
  forgot: {
    fontSize: '0.75rem',
    color: '#66C8FF',
    fontWeight: 700,
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline'
    },

    '& button': {
      background: 'transparent',
      border: 0,
      color: 'inherit',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }
})

interface LoginDialogProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  onForgot: () => void
  onSignUp: () => void
}

export default function LoginDialog({
  open,
  onSuccess,
  onClose,
  onForgot,
  onSignUp
}: LoginDialogProps) {
  const classes = useStyles()
  const { onLogin } = useAuthAccount()
  const form = useForm()

  const handleSave = (values) => {
    onLogin(values, onSuccess)
  }

  return (
    <Dialog
      open={open}
      size="sm"
      height={400}
      variant="secondary"
      mobileHeight={false}
      title="Sign In"
      onClose={onClose}
    >
      <DialogBody>
        <Grid container direction="column">
          <Grid container direction="column" className={classes.controls}>
            <Input
              className={classes.input}
              placeholder="Your Email"
              label="Email Address"
              variant="secondary"
              {...form.register('email')}
            />
            <Input
              className={classes.input}
              placeholder="Your Password"
              type="password"
              label="Password"
              variant="secondary"
              {...form.register('password')}
            />
          </Grid>

          <Button
            color="secondary"
            size="sm"
            variant="contained-2"
            className={classes.btn}
            onClick={() => form.handleSubmit(handleSave)()}
          >
            Submit
          </Button>

          <Grid container justifyContent="space-between" alignItems="center">
            <Typography className={classes.forgot} onClick={onForgot}>
              Forgot password?
            </Typography>

            {!PREMIUM_NO_ACCESS && (
              <Typography className={classes.forgot} onClick={onSignUp}>
                Sign Up
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogBody>
    </Dialog>
  )
}
