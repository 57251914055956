import { PaginationRequestDTO } from 'interfaces/common.contracts'
import { Transaction, TransactionsResponse } from 'interfaces/transactions'
import { useState } from 'react'
import { fetcher, getKey } from 'services/api/common'
import useSWR, { mutate } from 'swr'

interface UseTransactionsParams extends PaginationRequestDTO {}

interface UseTransactionsResponse {
  isLoading: boolean
  transactions: Transaction[]
  page: number
  maxPages: number
  onPage: (page: number) => void
  revalidate: () => Promise<any>
}

export const useTransactions = (
  params?: UseTransactionsParams
): UseTransactionsResponse => {
  const [page, onPage] = useState<number>(params?.page ?? 1)

  const requestKey = getKey('/auth/tx_labeling/get_transactions', {
    page,
    per_page: 10
  })
  const { data, error } = useSWR<TransactionsResponse>(requestKey, fetcher)

  const isLoading = !data && !error

  return {
    isLoading,
    transactions: data?.data ?? [],
    maxPages: data?.max_pages ?? 0,
    page,
    onPage,
    revalidate: () => mutate(requestKey)
  }
}
