import makeStyles from '@material-ui/core/styles/makeStyles'
import Slider from 'components/sliders/Slider'
import { FarmsFilters } from 'hooks/useFarms'
import useFiltersDrawer from 'hooks/useFiltersDrawer'
import { FILTER_STEPS, RENDER_VALUE, TITLES_SECONDARY } from 'utils/filters'
import { device } from 'utils/screen'

import DrawerContent from './DrawerContent'
import {
  FiltersDrawer,
  FiltersDrawerActions,
  FiltersDrawerActionsProps,
  FiltersDrawerHead,
  FiltersDrawerHeadProps,
  FiltersDrawerProps,
  FiltersDrawerSection,
  FiltersDrawerTitle
} from './FiltersDrawer'

const useStyles = makeStyles({
  paper: {
    [`@media ${device.mobileL}`]: {
      maxHeight: '75vh',
      height: 325
    }
  }
})

interface ContentProps {
  filters: FarmsFilters
}

function Content({
  onClose,
  onReset,
  onApply,
  filters
}: FiltersDrawerHeadProps & FiltersDrawerActionsProps & ContentProps) {
  const { innerFilters, onChange } = useFiltersDrawer<typeof filters>({
    filters
  })

  const handleApply = () => {
    onApply(innerFilters)
    onClose()
  }

  const handleReset = () => {
    onReset()
    onClose()
  }

  return (
    <>
      <FiltersDrawerHead onClose={onClose} />

      <DrawerContent>
        <FiltersDrawerSection>
          <Slider
            min={0}
            max={11}
            step={1}
            defaultValue={FILTER_STEPS.findIndex(
              (e) => e === innerFilters.tvl_min
            )}
            valueFormat={(value) => FILTER_STEPS[value as number]}
            renderValueFormat={RENDER_VALUE['tvl_min']}
            labelComponent={
              <FiltersDrawerTitle>
                {TITLES_SECONDARY.tvl_min}
              </FiltersDrawerTitle>
            }
            onChange={(e) => onChange('tvl_min', e)}
          />
        </FiltersDrawerSection>
      </DrawerContent>

      <FiltersDrawerActions onApply={handleApply} onReset={handleReset} />
    </>
  )
}

export default function FarmsFiltersDrawer({
  open,
  ...props
}: FiltersDrawerProps &
  FiltersDrawerActionsProps &
  FiltersDrawerHeadProps &
  ContentProps) {
  const classes = useStyles()
  return (
    <FiltersDrawer
      open={open}
      onClose={props.onClose}
      className={classes.paper}
    >
      <Content {...props} />
    </FiltersDrawer>
  )
}
