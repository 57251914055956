import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { Link } from 'components/typography'
import { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    width: 160,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#B2BDFF',
    position: 'relative',
    cursor: 'pointer',

    '&::after': {
      content: "''",
      display: 'block',
      opacity: 0,
      width: '100%',
      height: 4,
      backgroundColor: '#D733FF',
      position: 'absolute',
      bottom: 0,
      left: 0,
      borderRadius: '3px 3px 0 0'
    },

    '&:hover': {
      color: '#fafafa'
    },

    '&[data-active="true"]': {
      color: '#fafafa',
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.2) -9.64%, rgba(255, 255, 255, 0) 138.09%)',

      '&::after': {
        opacity: 1
      }
    }
  },
  text: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: '0.75rem'
    }
  }
})

interface TabProps {
  href?: string
  title: string
  icon: ReactNode
  deps: string[]
  native?: boolean
  exclude?: string[]
  onClick?: any
}

export default function Tab({
  href,
  title,
  icon,
  deps,
  exclude,
  native,
  onClick
}: TabProps) {
  const classes = useStyles()
  const location = useLocation()

  const active =
    deps.some((d) => location.pathname.includes(d)) &&
    !exclude?.some((e) => location.pathname.includes(e))

  return (
    <Grid
      wrapper
      className={classes.root}
      component={Link}
      to={href as string}
      native={native}
      data-active={active}
      onClick={onClick}
    >
      <Typography className={classes.text}>
        {icon}
        {title}
      </Typography>
    </Grid>
  )
}
