import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { InfoIcon, SearchIcon } from 'assets/icons'
import Announcement from 'components/Announcement'
import BottomPagination from 'components/BottomPagination'
import BookButton from 'components/buttons/BookButton'
import Button from 'components/buttons/Button'
import FarmsFiltersDrawer from 'components/drawers/FarmsFiltersDrawer'
import Input from 'components/form/Input'
import Table from 'components/table/Table'
import TableCell from 'components/table/TableCell'
import TablePagination from 'components/table/TablePagination'
import TableRow from 'components/table/TableRow'
import TooltipDropdown from 'components/TooltipDropdown'
import { Link } from 'components/typography'
import useAdvFarms from 'hooks/useAdvFarms'
import { AssetsFilters } from 'hooks/useAssets'
import useFarms, { FarmsFilters } from 'hooks/useFarms'
import useSavedSearches from 'hooks/useSavedSearches'
import { useAuth } from 'providers/AuthProvider'
import { useSpinner } from 'providers/SpinnerProvider'
import React, { Fragment, useMemo, useState } from 'react'
import { TableCol } from 'types/components/table'
import { formatRows } from 'utils/farms'
import { device } from 'utils/screen'
import { getSearchesIds } from 'utils/searches'

import BlockchainsDropdown from '../components/blockchains-dropdown/BlockchainsDropdown'
import CellImage from '../components/images/CellImage'
import ActiveFilters from './asset/ActiveFilters'
import AssetsTable from './farms/AssetsTable'
import Chart from './farms/Chart'

const useStyles = makeStyles({
  blockchainsDropdown: {
    marginRight: '1.25rem'
  },
  input: {
    flex: 1,
    marginRight: '1.25rem',

    [`@media ${device.tablet}`]: {
      width: '100%',
      marginBottom: '1rem'
    }
  },
  btn: {
    marginRight: '1.25rem',
    whiteSpace: 'nowrap'
  },
  inputContainer: {
    marginBottom: '2rem',

    [`@media ${device.tablet}`]: {
      flexDirection: 'column'
    }
  },
  filtersBtn: {
    [`@media ${device.tablet}`]: {
      width: '100%'
    }
  },
  more: {
    padding: '0 0 1.25rem 2.5rem',
    zIndex: 1
  },
  tableRow: {
    '& > td': {
      padding: '0 0.75rem',

      '&:first-child': {
        paddingLeft: '1rem'
      },
      '&:last-child': {
        paddingRight: '1rem'
      }
    }
  },
  nameCol: {
    display: 'flex',
    alignItems: 'center'
  },
  ad: {
    width: 32,
    height: 32,
    borderRadius: 10,
    fontSize: '0.75rem',
    color: '#fafafa',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(110.29deg, #D733FF -3.21%, #66308B 105.5%)',
    fontWeight: 700,
    marginRight: '0.5rem'
  },
  linkCell: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content'
  },
  infoBtn: {
    margin: '0 0 0 0.75rem'
  },
  adTooltip: {
    maxWidth: 160
  },
  adTooltipText: {
    fontSize: '0.75rem',

    '& span': {
      fontWeight: 600
    }
  }
})

const AD_TOOLTIP = `<span>*Paid Advertisement.</span> Not financial advice. Multifarm is not responsible for the projects showcased here. DYOR and ape safu.`

function cellProps(row) {
  return {
    ...(row.isAd && { color: 'ad' })
  }
}

function rowProps(row) {
  return {
    ...(row.isAd && { id: `farms-ad-row-${row.farmName}` })
  }
}

export default function Farms() {
  const { isLogged } = useAuth()
  const [filterDrawer, setFilterDrawer] = useState(false)
  const [farmId, setFarmId] = useState(null)
  const classes = useStyles()
  const {
    farms,
    isLoading,
    maxPages,
    filters,
    onSearch,
    onSort,
    onPage,
    onFilter,
    onFilters,
    onClearFilter,
    onClearFilters
  } = useFarms({ syncFiltersUrl: true })
  const advFarms = useAdvFarms()

  useSpinner(isLoading)

  const { savedSearches, onSaveSearch, onRemoveSearch } = useSavedSearches({
    save_type: 'farm'
  })

  const savedIds = getSearchesIds(savedSearches, 'farmId')

  const COLS: TableCol[] = useMemo(
    () => [
      { title: 'Farm', name: 'farmName', sortable: true },
      { title: 'Blockchain', name: 'blockchain', sortable: true },
      { title: 'TVL', name: 'tvlStaked', sortable: true },
      { title: 'Change (24h)', name: 'tvlChange24hValue', sortable: true },
      { title: 'Date added', name: 'dateAdded', sortable: true },
      {
        title: (
          <TablePagination page={filters.pg} count={maxPages} onPage={onPage} />
        ),
        sortable: false,
        align: 'right'
      }
    ],
    [maxPages, filters.pg]
  )

  const rows = formatRows(farms)
  const advRows = formatRows(advFarms.advFarms, true)

  const renderRows = (rows: any[]) => {
    return (
      <>
        {rows.map((row, index) => (
          <Fragment key={row.farmId + index}>
            <TableRow
              action
              onClick={() =>
                setFarmId((farmId) =>
                  farmId === row.farmId ? null : row.farmId
                )
              }
              {...rowProps(row)}
            >
              <TableCell {...cellProps(row)}>
                <div className={classes.nameCol}>
                  {row.isAd && <Grid className={classes.ad}>AD</Grid>}

                  {isLogged && !row.isAd && (
                    <BookButton
                      booked={savedIds.includes(row.farmId)}
                      onBook={() => onSaveSearch(row.farmId, 'farm')}
                      onUnBook={() => onRemoveSearch(row.farmId)}
                    />
                  )}

                  <CellImage src={row.farmIconUrl} />

                  <span>{row.farmName}</span>
                </div>
              </TableCell>
              <TableCell {...cellProps(row)}>{row.blockchain}</TableCell>
              <TableCell {...cellProps(row)}>{row.tvlStaked}</TableCell>
              <TableCell {...cellProps(row)}>{row.tvlChange24hValue}</TableCell>
              <TableCell {...cellProps(row)}>{row.dateAdded}</TableCell>
              <TableCell {...cellProps(row)} align="right">
                <div className={classes.linkCell}>
                  <Link to={`/farms/${row.farmId}`}>Farm page</Link>

                  {row.isAd && (
                    <TooltipDropdown
                      content={
                        <div className={classes.adTooltip}>
                          <Typography
                            className={classes.adTooltipText}
                            dangerouslySetInnerHTML={{ __html: AD_TOOLTIP }}
                          />
                        </div>
                      }
                    >
                      <InfoIcon className={classes.infoBtn} />
                    </TooltipDropdown>
                  )}
                </div>
              </TableCell>
            </TableRow>

            {farmId === row.farmId && (
              <TableRow className={classes.tableRow}>
                <TableCell colSpan={6}>
                  <Grid container direction="column">
                    <AssetsTable key={farmId} farmId={row.farmId} />

                    <div className={classes.more}>
                      <Link to={`/farms/${row.farmId}`} color="secondary">
                        More
                      </Link>
                    </div>
                  </Grid>
                </TableCell>
              </TableRow>
            )}
          </Fragment>
        ))}
      </>
    )
  }

  return (
    <>
      <Grid container direction="column">
        <Announcement />
        <Chart blockchain={filters.blockchain} />
        <Grid container className={classes.inputContainer}>
          <BlockchainsDropdown
            active={filters.blockchain}
            onChange={(chains) => onFilter('blockchain', chains)}
            className={classes.blockchainsDropdown}
          />
          <Input
            className={classes.input}
            prefix={<SearchIcon />}
            placeholder="Type Farm"
            font="Roboto"
            defaultValue={filters.search}
            onChange={(e) => onSearch(e.target.value)}
          />

          <Button
            variant="contained-2"
            color="secondary"
            size="sm"
            onClick={() => setFilterDrawer(true)}
            className={classes.filtersBtn}
          >
            Advanced Search
          </Button>
        </Grid>
        <ActiveFilters
          titlesVariant="secondary"
          filters={filters}
          clearFilter={(key) => onClearFilter(key as keyof FarmsFilters)}
          onClick={() => setFilterDrawer(true)}
          exclude={['pg', 'sort', 'sort_order', 'search', 'blockchain']}
        />
        <Table
          cols={COLS}
          loading={isLoading}
          empty={!farms.length}
          sort={filters.sort}
          sortOrder={filters.sort_order}
          onSort={onSort}
        >
          {renderRows(advRows)}
          {renderRows(rows)}
        </Table>
        {!isLoading && (
          <BottomPagination
            page={filters.pg}
            count={maxPages}
            onChange={onPage}
          />
        )}
      </Grid>

      <FarmsFiltersDrawer
        open={filterDrawer}
        onClose={() => setFilterDrawer(false)}
        filters={filters}
        onApply={(filters) => onFilters(filters as AssetsFilters)}
        onReset={onClearFilters}
      />
    </>
  )
}
