import Api from '../Api'

export async function getAssets(url) {
  const response = await Api.get(url)
  return response.data
}

export async function getAsset(url) {
  const response = await Api.get(url)
  return response.data
}

export async function editSavedAsset(id, data) {
  const response = await Api.post('/auth/edit_saved_search_info', {
    saved_id: id,
    edits: data
  })
  return response.data
}

export async function addCustomAsset(data) {
  const response = await Api.post('/auth/cms/add_custom_asset', data)
  return response.data
}
