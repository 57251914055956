import ChartCard from 'components/cards/ChartCard'
import LineChart from 'components/charts/LineChart'
import Tabs from 'components/tabs/Tabs'
import ChartTooltip from 'components/tooltips/ChartTooltip'
import { useMemo, useState } from 'react'
import { parsePercent } from 'utils/api'
import { formatChartDate, getChartId, renderTooltipOptions } from 'utils/charts'
import { getRandomColors } from 'utils/colors'
import { RANGE } from 'utils/filters'
import { parseRangeChart } from 'utils/pools'
import { TOOLTIP } from 'utils/tooltip'

import { RANGES } from '../performance/data'

const APR_COLOR = getRandomColors(1)
const TITLE = 'Asset APR (y)'
const CHART_ID = getChartId(TITLE + Date.now())

interface AprSingleLineChartProps {
  poolId?: string
  loading: boolean
  data: any[]
}

export default function AprSingleLineChart({
  poolId,
  data,
  loading
}: AprSingleLineChartProps) {
  const [range, setRange] = useState(RANGE._7d)

  const rangeChart = useMemo(
    () => parseRangeChart(range, data),
    [poolId, range]
  )

  return (
    <ChartCard
      title={TITLE}
      tooltip={<ChartTooltip content={TOOLTIP.AREA_SINGLE_HOLDINGS} />}
      headerComponent={
        <Tabs width="auto" tabs={RANGES} value={range} onChange={setRange} />
      }
    >
      <LineChart
        memo={[range, poolId].join('_')}
        loading={loading}
        noData={!rangeChart.length}
        data={rangeChart}
        id={CHART_ID}
        dataKey={['value']}
        colors={APR_COLOR}
        yTickFormatter={(tick) => parsePercent(tick)}
        xTickFormatter={formatChartDate}
        tooltipOptions={renderTooltipOptions(
          ['value'],
          false,
          APR_COLOR,
          parsePercent
        )}
      />
    </ChartCard>
  )
}
