import Grid from '@material-ui/core/Grid'
import IncomesPerPartnerTable from 'components/tables/IncomesPerPartnerTable'
import { PageTitle } from 'components/typography'

import DailyVolumeLine from './proteus/DailyVolumeLine'
import LiquidityPerChainPie from './proteus/LiquidityPerChainPie'
import { useStrategiesStyles } from './strategies/styles'

export default function Proteus() {
  const classes = useStrategiesStyles()
  return (
    <Grid container direction="column">
      <PageTitle className={classes.title}>Proteus</PageTitle>

      <Grid container className={classes.card}>
        <IncomesPerPartnerTable />
      </Grid>

      <Grid container className={classes.card}>
        <DailyVolumeLine />
      </Grid>

      <Grid container className={classes.card}>
        <LiquidityPerChainPie />
      </Grid>
    </Grid>
  )
}
