import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Button'
import BlockchainSelect from 'components/form/BlockchainSelect'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import useCustomStrategy from 'hooks/useCustomStrategy'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Option } from 'types/components/form'

import AddAPRDialog from './AddAPRDialog'
import AddACustomFieldDialog from './AddCustomFieldDialog'
import Dialog, { DialogBaseProps } from './Dialog'
import DialogActions from './DialogActions'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.25rem'
  }
})

const TYPE_OPTIONS: Option[] = [
  { label: 'NFT', value: 'NFT' },
  { label: 'Pool', value: 'productive_asset' }
]

interface ContentProps extends DialogBaseProps {}

function Content(props: ContentProps) {
  const classes = useStyles()
  const customAsset = useCustomStrategy()
  const form = useForm()

  const [addFieldDialog, setAddFieldDialog] = useState(false)
  const [addAPRDialog, setAddAPRDialog] = useState(false)
  const [customAPRs, setCustomAPRs] = useState({})
  const [customFields, setCustomFields] = useState({})

  const handleSubmit = (values) => {
    customAsset.onAdd(
      { ...values, ...customFields, custom_aprs: customAPRs },
      props.onClose
    )
  }

  const toggleAPRModal = () => {
    setAddAPRDialog(!addAPRDialog)
  }

  const toggleFieldModal = () => {
    setAddFieldDialog(!addFieldDialog)
  }

  const addAPR = (data) => {
    const aprs: any = { ...customAPRs }
    aprs[data?.name] = data.value
    setCustomAPRs(aprs)
  }

  const addFields = (data) => {
    const fields: any = { ...customFields }
    fields[data?.name] = data.value
    setCustomFields(fields)
  }

  return (
    <>
      <DialogBody>
        <Grid
          container
          id="add-custom-asset"
          component="form"
          onSubmit={form.handleSubmit(handleSubmit)}
          className={classes.root}
        >
          <Input
            variant="secondary"
            label="Project"
            placeholder="Project"
            {...form.register('farm')}
          />
          <Input
            variant="secondary"
            label="Asset"
            placeholder="Asset"
            {...form.register('asset')}
          />
          <Input
            variant="secondary"
            label="Size"
            placeholder="0"
            type="float"
            {...form.register('size')}
          />
          <Input
            variant="secondary"
            label="Size USD"
            placeholder="0"
            type="float"
            {...form.register('positionSizeUsd')}
          />
          <Input
            variant="secondary"
            label="Size ETH"
            placeholder="0"
            type="float"
            {...form.register('positionSizeEth')}
          />
          <Controller
            control={form.control}
            render={(args) => (
              <Select
                options={TYPE_OPTIONS}
                variant="secondary"
                label="Type"
                placeholder="Type"
                value={args.field.value}
                onChange={(e) => form.setValue(args.field.name, e?.value || '')}
              />
            )}
            name="type"
          />
          <Input
            variant="secondary"
            label="Wallet"
            placeholder="0x123"
            {...form.register('user')}
          />
          <Controller
            name="blockchain"
            control={form.control}
            render={(e) => (
              <BlockchainSelect
                value={e.field.value}
                onChange={(_e) => form.setValue(e.field.name, _e?.value || '')}
                variant="secondary"
                label="Blockchain"
                tooltip="Blockchain the transactions will be sent on"
              />
            )}
          />
        </Grid>
      </DialogBody>

      <DialogActions>
        <Button
          size="sm"
          variant="contained-2"
          color="primary"
          onClick={toggleFieldModal}
        >
          Add field
        </Button>
        <Button
          size="sm"
          variant="contained-2"
          color="primary"
          onClick={toggleAPRModal}
        >
          Add APR
        </Button>
        <Button
          size="sm"
          variant="contained-2"
          color="secondary"
          onClick={props.onClose}
        >
          Reset
        </Button>
        <Button
          size="sm"
          variant="contained-2"
          color="primary"
          type="submit"
          form="add-custom-asset"
        >
          Save
        </Button>
      </DialogActions>

      <AddACustomFieldDialog
        open={addFieldDialog}
        onClose={toggleFieldModal}
        onApply={(field) => {
          addFields(field)
        }}
      />
      <AddAPRDialog
        open={addAPRDialog}
        onClose={toggleAPRModal}
        onApply={(value) => addAPR(value)}
      />
    </>
  )
}

export default function AddCustomStrategyDialog(props: ContentProps) {
  return (
    <Dialog
      variant="secondary"
      height={435}
      title="Add Custom Strategy"
      size="lg"
      open={props.open}
      onClose={props.onClose}
    >
      <Content {...props} />
    </Dialog>
  )
}
