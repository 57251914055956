import { getWallets } from 'services/api/wallet'
import useSWR from 'swr'

interface UseWallets {
  isLoading: boolean
  wallets: any[]
  revalidate: any
}

export default function useWallets(): UseWallets {
  const {
    data,
    error,
    mutate: revalidate
  } = useSWR('/auth/get_wallets', getWallets)

  const isLoading = !data && !error
  const wallets = Array.isArray(data) ? data : []

  return {
    isLoading,
    wallets,
    revalidate
  }
}
