import { toast } from 'react-toastify'
import { fetcher, getKey } from 'services/api/common'
import useSWR from 'swr'

import { useSpinner } from '../providers/SpinnerProvider'
import { updateNftOverview } from '../services/api/nftOverview'

export type OnUpdateNft = (name: string, updates: any, onSuccess?: any) => void

export interface UseNftOverview {
  isLoading: boolean
  data: UseNftOverviewDataItem[]
  onUpdate: OnUpdateNft
}

export interface UseNftOverviewDataItem {
  name: string
  display_name: string
  active: number
  image_url: string
}

interface UseNftOverviewConfig {
  inactive?: number
}

export default function useNftOverview(
  config: UseNftOverviewConfig = {}
): UseNftOverview {
  const spinner = useSpinner()

  const params = {
    ...config
  }

  const {
    data: body,
    error,
    mutate: revalidate
  } = useSWR(() => getKey('/auth/get_nfts_overview', params), fetcher)

  const onUpdate: OnUpdateNft = async (name, updates, onSuccess) => {
    try {
      spinner.open()
      await updateNftOverview(name, updates)

      revalidate?.()

      toast.success('Asset updated successfully')

      spinner.close()

      onSuccess?.()
    } catch (e) {
      spinner.close()
      console.error(e)
      toast.error('Updating NFT failed')
    }
  }

  const isLoading = !body && !error

  const { data = [] } = body || {}

  return {
    isLoading,
    data,
    onUpdate
  }
}
