import {
  ClickAwayListener,
  Grid,
  InputBase,
  Paper,
  Popper
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Autocomplete, FilterOptionsState } from '@material-ui/lab'
import classNames from 'classnames'
import { useCallback, useMemo, useState } from 'react'

import { CaretDown } from '../../assets/icons'
import useBlockchainSelect from '../../hooks/useBlockchainSelect'
import { BlockchainDropdownOption } from '../../interfaces/blockchain-dropdown-option'
import { BlockchainDropdownMultipleStrategy } from '../../models/blockchain-dropdown-strategies/BlockchainDropdownMultipleStrategy'
import { BlockchainDropdownSingleStrategy } from '../../models/blockchain-dropdown-strategies/BlockchainDropdownSingleStrategy'
import { BlockchainDropdownStrategy } from '../../models/blockchain-dropdown-strategies/BlockchainDropdownStrategy'
import { Chain } from '../../services/types/globals'
import Button from '../buttons/Button'
import Checkbox from '../form/Checkbox'

const useStyles = makeStyles({
  root: {
    width: 330,
    height: 40,
    borderRadius: 15,
    padding: '0 1.25rem',
    backgroundColor: 'rgb(48, 54, 89)',
    cursor: 'pointer',

    '& p': {
      fontWeight: 700
    }
  },
  title: {
    border: 'none',
    height: '100%',
    padding: 0,
    flexGrow: 1,
    color: 'rgb(250, 250, 250)',
    fontSize: '1rem',
    fontFamily: 'Roboto',
    outline: 'none',
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',

    '& > svg': {
      width: 12,
      height: 8,
      marginRight: '0.75rem',

      '&:last-child': {
        marginRight: 0
      }
    }
  },
  popup: {
    width: 330
  },
  popupArea: {
    marginTop: 16,
    backgroundColor: '#373D66',
    borderRadius: 15,
    border: '1px solid #41486E',
    boxShadow: '0px 8px 60px rgba(34, 39, 64, 0.4)'
  },
  paper: {
    background: 'none',
    boxShadow: 'none'
  },
  options: {
    width: '100%',
    margin: '16px 0'
  },
  option: {
    height: 32
  },
  group: ({ multiple }: any) => ({
    color: '#fafafa',
    cursor: multiple && 'pointer',
    width: '100%',
    background: 'rgba(51, 184, 255, 0.25)',
    textAlign: 'center',
    '&:hover': multiple && {
      background: 'rgba(51, 184, 255, 0.15)'
    }
  }),
  groupHidden: {
    display: 'none'
  },
  search: {
    width: '100%',
    '& input': {
      color: '#fafafa',
      background: '#272d49',
      borderRadius: 15,
      height: 40,
      padding: '0px 16px'
    }
  },
  autocomplete: {
    width: '100%',
    margin: '0 16px'
  },
  buttons: {
    margin: 16
  },
  checkbox: {
    '& .MuiFormControlLabel-root': {
      '& .MuiCheckbox-root': {
        color: '#fafafa'
      }
    }
  }
})

interface BlockchainsDropdownProps {
  // List of active blockchains
  active: Chain[]
  onChange?: (chains: Chain[]) => void
  className?: string
  multiple?: boolean
}

export default function BlockchainsDropdown({
  className,
  active,
  onChange,
  multiple
}: BlockchainsDropdownProps) {
  const classes = useStyles({ multiple })

  const { groups } = useBlockchainSelect()

  const strategy: BlockchainDropdownStrategy = useMemo(() => {
    const context = { groups, active }
    if (multiple) {
      return new BlockchainDropdownMultipleStrategy(context)
    }
    return new BlockchainDropdownSingleStrategy(context)
  }, [groups, active, multiple])

  const [anchorEl, setAnchorEl] = useState(null)
  const [inputValue, setInputValue] = useState('')

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const clear = () => {
    if (!onChange) {
      return
    }
    onChange(strategy.getChainClearValue())
  }

  const handleChanges = (changes?, reason?) => {
    if (!onChange) {
      return
    }
    if (reason && !['select-option', 'remove-option'].includes(reason)) {
      return
    }
    const payload = strategy.getChainsFromSelection(changes)
    onChange(payload)
  }

  const handleGroupClick = (group) => {
    handleChanges(strategy.getOptionsSelectionFromGroup(group))
  }

  const handleInputChange = (event, value, reason) => {
    if (reason !== 'input') {
      return
    }
    setInputValue(value)
  }

  const filterOptions = (
    options: BlockchainDropdownOption[],
    state: FilterOptionsState<BlockchainDropdownOption>
  ) => {
    return strategy.filterOptions(options, state) as BlockchainDropdownOption[]
  }

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={classNames(classes.root, className)}
        onClick={handleClick}
      >
        <div className={classes.title}>{strategy.getTitle()}</div>
        <div className={classes.icon}>
          <CaretDown />
        </div>
      </Grid>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={classes.popup}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className={classes.popupArea}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              className={classes.buttons}
            >
              {multiple && (
                <Button
                  variant="outlined"
                  size="sm"
                  onClick={() => {
                    setInputValue('')
                    clear()
                  }}
                >
                  Clear
                </Button>
              )}
              <Button
                onClick={() => {
                  setInputValue('')
                  handleChanges()
                }}
                variant="outlined"
                size="sm"
              >
                {multiple ? 'Toggle All' : 'Select All'}
              </Button>
            </Grid>
            <Autocomplete
              open
              multiple
              inputValue={inputValue}
              onInputChange={handleInputChange}
              className={classes.autocomplete}
              clearOnBlur={false}
              filterOptions={filterOptions}
              value={strategy.selected}
              onChange={(event, newValue, reason) => {
                handleChanges(newValue, reason)
              }}
              renderInput={({ InputProps, inputProps }) => (
                <InputBase
                  className={classes.search}
                  ref={InputProps.ref}
                  inputProps={inputProps}
                  autoFocus
                  placeholder="Search for blockchains..."
                  onKeyDown={(e) =>
                    e.key === 'Backspace' && e.stopPropagation()
                  }
                />
              )}
              renderOption={(option, { selected }) => (
                <div key={option.name} className={classes.option}>
                  <Checkbox
                    className={classes.checkbox}
                    value={selected}
                    onChange={() => void 0}
                    label={
                      <span onClick={(e) => e.stopPropagation()}>
                        {option.name}
                      </span>
                    }
                  />
                </div>
              )}
              renderGroup={({ group, children }) => (
                <div key={group}>
                  <div
                    onClick={() => multiple && handleGroupClick(group)}
                    className={classNames(
                      classes.group,
                      group === 'ALL' && classes.groupHidden
                    )}
                  >
                    {group}
                  </div>
                  {children}
                </div>
              )}
              groupBy={(option) => option.group}
              getOptionLabel={(option) => option.name}
              options={strategy.options}
              PopperComponent={useCallback(
                ({ children }) => (
                  <div className={classes.options}>{children}</div>
                ),
                []
              )}
              PaperComponent={useCallback(
                ({ children }) => (
                  <Paper className={classes.paper}>{children}</Paper>
                ),
                []
              )}
            />
          </Grid>
        </ClickAwayListener>
      </Popper>
    </>
  )
}
