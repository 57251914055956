import makeStyles from '@material-ui/core/styles/makeStyles'
import CheckIcon from '@material-ui/icons/Check'

const useStyles = makeStyles({
  root: {
    width: 16,
    height: 16,
    borderRadius: 9999,
    border: '1px solid #B2BDFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      width: 10,
      height: 10
    },

    '&[data-active="true"]': {
      border: 0,
      background:
        'linear-gradient(110.29deg, rgb(51, 182, 255) -3.21%, rgb(26, 107, 159) 105.5%)'
    }
  }
})

interface CircleCheckboxProps {
  value?: boolean
}

export default function CircleCheckbox({ value }: CircleCheckboxProps) {
  const classes = useStyles()
  return (
    <div className={classes.root} data-active={value}>
      {value && <CheckIcon />}
    </div>
  )
}
