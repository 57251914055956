import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { PropsWithChildren } from 'react'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1.5rem',

    '& > button': {
      marginRight: '1.25rem',

      '&:last-child': {
        marginRight: 0
      }
    }
  }
})

export default function DialogActions({ children }: PropsWithChildren<{}>) {
  const classes = useStyles()
  return (
    <Grid container className={classes.root}>
      {children}
    </Grid>
  )
}
