import makeStyles from '@material-ui/core/styles/makeStyles'
import { ReactNode } from 'react'

import CellImage from '../images/CellImage'
import TooltipDropdown from '../TooltipDropdown'

const useStyles = makeStyles({
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '0.75rem',
    position: 'relative',

    '&:last-child': {
      margin: 0
    }
  },
  icon: {
    width: 40,
    height: 40,
    backgroundColor: '#272d49',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 9999,
    cursor: 'pointer',
    position: 'relative'
  },
  cellIcon: {
    margin: 0
  }
})

interface DialogHeadIconButtonProps {
  tooltip?: string
  onClick: () => void
  icon: string
}

export default function DialogHeadIconButton(props: DialogHeadIconButtonProps) {
  const classes = useStyles()
  return (
    <span className={classes.iconContainer}>
      <TooltipDropdown content={props.tooltip}>
        <span className={classes.icon} onClick={props.onClick}>
          <CellImage
            placeholder="?"
            className={classes.cellIcon}
            key={props.icon}
            src={props.icon}
          />
        </span>
      </TooltipDropdown>
    </span>
  )
}

const useContainerStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: '-0.75rem 0.75rem'
  }
})

interface DialogHeadIconButtonContainerProps {
  children: ReactNode
}

export function DialogHeadIconButtonContainer(
  props: DialogHeadIconButtonContainerProps
) {
  const classes = useContainerStyles()
  return <span className={classes.root}>{props.children}</span>
}
