import { toast } from 'react-toastify'
import { subscribeToEmail } from 'services/api/email'

import { useSpinner } from '../providers/SpinnerProvider'

type OnSubscribe = (
  type: 'waitlist' | 'newsletter',
  email: string,
  message?: string,
  onSuccess?: any
) => void

interface UseSubscribeEmail {
  onSubscribe: OnSubscribe
}

export default function useSubscribeEmail(): UseSubscribeEmail {
  const spinner = useSpinner()

  const onSubscribe: OnSubscribe = async (type, email, message, onSuccess) => {
    try {
      spinner.open()
      await subscribeToEmail(type, email, message)
      toast.success('Thank you!')
      spinner.close()
      onSuccess?.()
    } catch (e) {
      spinner.close()
      console.error(e)
      toast.error('Oops error!')
    }
  }
  return {
    onSubscribe
  }
}
