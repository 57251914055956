import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

export default function Research() {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Typography>Soon...</Typography>
    </Grid>
  )
}
