import debounce from 'lodash.debounce'
import { UIEvent, useCallback, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { animateScroll } from 'react-scroll'

type OnScroll = (e: UIEvent) => void
type ScrollTo = (to: number) => void

export interface UseContainerScroll {
  scroll: boolean
  ref: any
  leftScroll: boolean
  rightScroll: boolean
  isScrolled: boolean
  onScroll: OnScroll
  scrollTo: ScrollTo
}

interface UseContainerScrollConfig {
  id: string
}

export default function useContainerScroll(
  config: UseContainerScrollConfig
): UseContainerScroll {
  const resizeDetector = useResizeDetector()
  const [leftScroll, setLeftScroll] = useState(false)
  const [rightScroll, setRightScroll] = useState(true)

  const scroll = resizeDetector?.width
    ? resizeDetector.ref.current?.scrollWidth > resizeDetector.width
    : false

  const scrollTo: ScrollTo = (to) => {
    animateScroll.scrollMore(to, {
      containerId: config.id,
      horizontal: true,
      duration: 400
    })
  }

  const onScroll: OnScroll = useCallback(
    debounce((e: any) => {
      setLeftScroll(e.target.scrollLeft !== 0)
      setRightScroll(
        e.target.scrollWidth > e.target.offsetWidth + e.target.scrollLeft
      )
    }, 400),
    []
  )

  const isScrolled = leftScroll

  return {
    ref: resizeDetector.ref,
    scroll,
    isScrolled,
    leftScroll,
    rightScroll,
    onScroll,
    scrollTo
  }
}
