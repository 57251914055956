import makeStyles from '@material-ui/core/styles/makeStyles'
import { ReactNode } from 'react'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center'
  }
})

interface DialogTitleComponentProps {
  children: ReactNode
}

export default function DialogTitleComponent(props: DialogTitleComponentProps) {
  const classes = useStyles()
  return <span className={classes.root}>{props.children}</span>
}
