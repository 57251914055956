import { fetcher } from 'services/api/common'
import useSWR from 'swr'

interface UseRole {
  isLoading: boolean
  isSuccess: boolean
  noRole: boolean
  pages: string[]
  data: any
}

export default function useRole(): UseRole {
  const { data: _data, error } = useSWR(
    '/auth/get_user_role_and_pages',
    fetcher
  )

  const isSuccess = !!_data
  const isLoading = !_data && !error
  const data = _data || {}
  const pages = _data?.pages || []
  const noRole = _data ? _data?.error === 'this user has no role set!' : true

  return {
    isSuccess,
    isLoading,
    data,
    pages,
    noRole
  }
}
