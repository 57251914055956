import { getPools } from 'services/api/pools'
import useSWR from 'swr'

interface UsePools {
  isLoading: boolean
  pools: any[]
}

export function getPoolsKey() {
  return '/auth/get_user_pools'
}

export default function usePools(): UsePools {
  const { data, error } = useSWR(() => getPoolsKey(), getPools)

  const isLoading = !data && !error
  const pools = Array.isArray(data) ? data : []

  return {
    isLoading,
    pools
  }
}
