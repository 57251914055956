import trim from 'lodash.trim'
import { nFormatter } from 'types/utils'

import { parsePercent } from './api'
import { DATE_FORMAT_PRIMARY, format } from './date'
import { getRemoteFarmIcon } from './icons'

export function formatRows(data: any[], ad?: boolean) {
  try {
    return data.map((row) => ({
      farmId: row.farmId,
      farmName: row.farmName,
      blockchain: row.blockchain,
      tvlStaked: `$${nFormatter(row.tvlStaked)}`,
      tvlChange24hValue: parsePercent(row.tvlChange24hValue),
      dateAdded: format(new Date(row.dateAdded), DATE_FORMAT_PRIMARY),
      isAd: ad ? row.adSpot : false,
      farmIconUrl: getRemoteFarmIcon(row)
    }))
  } catch (e) {
    console.error(e)
    return []
  }
}

export function formatChartData(farm: any, assets: any[]) {
  return {
    tvlStaked: farm.tvlStaked ? `$${nFormatter(farm.tvlStaked)}` : 'N/A',
    tvlChange24h: farm.tvlChange24h || 'N/A',
    blockchain: farm.blockchain || 'N/A',
    reward: assets?.[0]?.rewardTokenA
      ? trim(assets?.[0]?.rewardTokenA) || 'N/A'
      : 'N/A',
    markedCap: 'N/A',
    stakingLink: assets?.[0]?.stakingLink
  }
}

export function parseChartData(data: any[]) {
  if (!data) {
    return []
  }
  return data.map((row) => ({ ...row, value: row.value || 0 }))
}
