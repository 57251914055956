import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { SearchIcon } from 'assets/icons'
import Button from 'components/buttons/Button'
import AssetsFiltersDrawer from 'components/drawers/AssetsFiltersDrawer'
import Input from 'components/form/Input'
import AssetsTable from 'components/tables/AssetsTable'
import useAssets, { AssetsFilters } from 'hooks/useAssets'
import { useSpinner } from 'providers/SpinnerProvider'
import React, { useState } from 'react'
import { device } from 'utils/screen'

import BlockchainsDropdown from '../components/blockchains-dropdown/BlockchainsDropdown'
import ActiveFilters from './asset/ActiveFilters'

const useStyles = makeStyles({
  blockchainsDropdown: {
    marginRight: '1.25rem'
  },
  input: {
    flex: 1,
    marginRight: '1.25rem',

    [`@media ${device.tablet}`]: {
      width: '100%',
      marginBottom: '1rem'
    }
  },
  inputContainer: {
    marginBottom: '2rem',

    [`@media ${device.tablet}`]: {
      flexDirection: 'column'
    }
  },
  filtersBtn: {
    [`@media ${device.tablet}`]: {
      width: '100%'
    }
  },
  cardsContainer: {
    paddingTop: '1.5rem'
  }
})

export default function Assets() {
  const [showFilters, setShowFilters] = useState(false)
  const classes = useStyles()
  const {
    assets,
    maxPages,
    isLoading,
    filters,
    onFilter,
    onFilters,
    onSearch,
    onSort,
    onClearFilter,
    onClearFilters,
    onPage
  } = useAssets({ syncFiltersUrl: true })

  useSpinner(isLoading)

  return (
    <>
      <Grid container direction="column">
        <Grid container className={classes.inputContainer}>
          {/* TODO: Clear and Toggle all options are not visible on the Assets page */}
          <BlockchainsDropdown
            active={filters.blockchain}
            onChange={(chains) => onFilter('blockchain', chains)}
            className={classes.blockchainsDropdown}
            multiple
          />
          <Input
            className={classes.input}
            prefix={<SearchIcon />}
            placeholder="Type Asset"
            font="Roboto"
            defaultValue={filters.search}
            onChange={(e) => onSearch(e.target.value)}
          />

          <Button
            variant="contained-2"
            color="secondary"
            size="sm"
            onClick={() => setShowFilters(true)}
            className={classes.filtersBtn}
          >
            Advanced Search
          </Button>
        </Grid>

        <ActiveFilters
          filters={filters}
          clearFilter={(key) => onClearFilter(key as keyof AssetsFilters)}
          onClick={() => setShowFilters(true)}
          exclude={[
            'pg',
            'sort',
            'sort_order',
            'search',
            'blockchain',
            'num_per_page'
          ]}
        />

        <AssetsTable
          isLoading={isLoading}
          assets={assets}
          maxPages={maxPages}
          filters={filters}
          onPage={onPage}
          onSort={onSort}
        />
      </Grid>

      <AssetsFiltersDrawer
        open={showFilters}
        onClose={() => setShowFilters(false)}
        filters={filters}
        onApply={(filters) => onFilters(filters as AssetsFilters)}
        onReset={onClearFilters}
      />
    </>
  )
}
