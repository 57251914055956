import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { InfoIcon } from 'assets/icons'
import BookButton from 'components/buttons/BookButton'
import Button from 'components/buttons/Button'
import PoolEconomicsTooltip from 'components/PoolEconomicsTooltip'
import Table from 'components/table/Table'
import TableCell from 'components/table/TableCell'
import TableRow from 'components/table/TableRow'
import useTrendingAssets from 'hooks/useTrendingAssets'
import useSavedSearches from 'hooks/useSavedSearches'
import { useAuth } from 'providers/AuthProvider'
import React, { useMemo } from 'react'
import { TableCol } from 'types/components/table'
import { formatRows } from 'utils/assets'
import { device } from 'utils/screen'
import { getSearchesIds } from 'utils/searches'

import CellImage from '../../../components/images/CellImage'
import { Link } from '../../../components/typography'

const useStyles = makeStyles({
  btn: {
    marginRight: '1.25rem',
    whiteSpace: 'nowrap',

    [`@media ${device.tablet}`]: {
      height: 30,
      borderRadius: 10,
      fontSize: '0.75rem'
    }
  },
  nameCol: {
    display: 'flex',
    alignItems: 'center'
  },
  img: {
    width: 20,
    height: 20,
    marginRight: '0.5rem'
  },
  info: {
    marginBottom: '2rem'
  }
})

export default function TrendingAssetsTable() {
  const classes = useStyles()
  const { isLogged } = useAuth()

  const COLS: TableCol[] = useMemo(
    () => [
      { title: 'Asset' },
      { title: 'Farm' },
      { title: 'TVL Staked' },
      { title: 'APR (y))' },
      { title: 'Blockchain' },
    ],
    []
  )

  const { savedSearches, onSaveSearch, onRemoveSearch } = useSavedSearches({
    save_type: 'asset'
  })
  const savedIds = getSearchesIds(savedSearches, 'assetId')

  const { assets } = useTrendingAssets()

  const rows = formatRows(assets)

  return (
    <>
      <div className={classes.info}>Assets that have been growing in TVL for 7d, with positive TVL growth and with at least 1M in TVL</div>
      <Table
        cols={COLS}
        loading={false}
        empty={!rows.length}
      >
        {rows.map((row) => (
          <TableRow key={row.assetId}>
            <TableCell>
              <div className={classes.nameCol}>
                {isLogged && (
                  <BookButton
                    booked={savedIds.includes(row.assetId)}
                    onBook={() => onSaveSearch(row.assetId, 'asset')}
                    onUnBook={() => onRemoveSearch(row.assetId)}
                  />
                )}

                <CellImage src={row.imageUrl} />

                <Link to={`/assets/${row.assetId}`}>
                  {row.asset}
                </Link>
              </div>
            </TableCell>
            <TableCell>{row.farm}</TableCell>
            <TableCell>{row.tvlStaked}</TableCell>
            <TableCell>{row.aprYearly}</TableCell>
            <TableCell>{row.blockchain}</TableCell>
            <TableCell>{row.tvlGrowth}</TableCell>
            <TableCell align="right">
              <Grid
                container
                alignItems="center"
                justifyContent="flex-end"
                wrap="nowrap"
              >
                <a
                  href={row.investLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Button
                    variant="contained-2"
                    size="sm"
                    className={classes.btn}
                    disabled={!row.investLink}
                  >
                    Invest
                  </Button>
                </a>

                <PoolEconomicsTooltip data={row}>
                  <InfoIcon />
                </PoolEconomicsTooltip>
              </Grid>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </>
  )
}
