export function formatValues(values: any) {
  const formData = {}

  Object.keys(values).forEach((key) => {
    if (key === 'tvl') {
      formData['selected_trigger'] = [
        { key, comparator: 'GTE', value: values[key] }
      ]
    } else {
      formData[key] = values[key]
    }
  })

  return formData
}
