import Chip from 'components/chips/Chip'
import FiltersFarmsDialog from 'components/dialogs/FiltersFarmsDialog'
import Slider from 'components/sliders/Slider'
import { AssetsFilters } from 'hooks/useAssets'
import useFiltersDrawer from 'hooks/useFiltersDrawer'
import useGlobals from 'hooks/useGlobals'
import useGroups from 'hooks/useGroups'
import { useState } from 'react'
import { FILTER_STEPS, RENDER_VALUE } from 'utils/filters'
import { groupsToOptions } from 'utils/groups'

import DrawerContent from './DrawerContent'
import {
  FiltersDrawer,
  FiltersDrawerActions,
  FiltersDrawerActionsProps,
  FiltersDrawerExchangesSelect,
  FiltersDrawerHead,
  FiltersDrawerHeadProps,
  FiltersDrawerProps,
  FiltersDrawerSection,
  FiltersDrawerTitle,
  FiltersDrawerTitleAction
} from './FiltersDrawer'

interface AssetsFiltersDrawerContentProps {
  filters: AssetsFilters
}

function AssetsFiltersDrawerContent({
  onClose,
  onApply,
  onReset,
  filters
}: FiltersDrawerHeadProps &
  FiltersDrawerActionsProps &
  AssetsFiltersDrawerContentProps) {
  const [farmsDialog, setFarmsDialog] = useState(false)
  const { groups } = useGroups()
  const { yieldTypes, farmsIdsToNames } = useGlobals()
  const groupsOptions = groupsToOptions(groups)

  const {
    innerFilters,
    onChange,
    onFarmsChange,
    onGroupChange,
    onGroupsChange,
    onArrayUpdate
  } = useFiltersDrawer<typeof filters>({
    filters
  })

  const handleApply = () => {
    onApply(innerFilters)
    onClose()
  }

  const handleReset = () => {
    onReset()
    onClose()
  }

  return (
    <>
      <FiltersDrawerHead onClose={onClose} />

      <DrawerContent>
        <FiltersDrawerSection>
          <Slider
            min={0}
            max={11}
            step={1}
            defaultValue={FILTER_STEPS.findIndex(
              (e) => e === innerFilters.tvl_min
            )}
            valueFormat={(value) => FILTER_STEPS[value as number]}
            renderValueFormat={RENDER_VALUE['tvl_min']}
            labelComponent={
              <FiltersDrawerTitle>Liquidity higher than</FiltersDrawerTitle>
            }
            onChange={(e) => onChange('tvl_min', e)}
          />
        </FiltersDrawerSection>

        <FiltersDrawerSection>
          <Slider
            min={0}
            max={11}
            step={1}
            defaultValue={FILTER_STEPS.findIndex(
              (e) => e === innerFilters.farms_tvl_staked_gte
            )}
            valueFormat={(value) => FILTER_STEPS[value as number]}
            renderValueFormat={RENDER_VALUE['farms_tvl_staked_gte']}
            labelComponent={
              <FiltersDrawerTitle>
                Farm Liquidity higher than
              </FiltersDrawerTitle>
            }
            onChange={(e) => onChange('farms_tvl_staked_gte', e)}
          />
        </FiltersDrawerSection>

        <FiltersDrawerSection>
          <Slider
            min={0}
            max={100}
            step={10}
            defaultValue={innerFilters.apr_yearly_min}
            renderValueFormat={RENDER_VALUE['apr_yearly_min']}
            labelComponent={
              <FiltersDrawerTitle>APR (y) higher than</FiltersDrawerTitle>
            }
            onChange={(e) => onChange('apr_yearly_min', e)}
          />
        </FiltersDrawerSection>

        <FiltersDrawerSection>
          <Slider
            min={0}
            max={100}
            step={10}
            defaultValue={innerFilters.tvl_change_min}
            renderValueFormat={RENDER_VALUE['tvl_change_min']}
            labelComponent={
              <FiltersDrawerTitle>
                TVL Change (24h) higher than
              </FiltersDrawerTitle>
            }
            onChange={(e) => onChange('tvl_change_min', e)}
          />
        </FiltersDrawerSection>

        <FiltersDrawerSection title="Yield Type" chips>
          {yieldTypes.map((type) => (
            <Chip
              key={type}
              onClick={() => onArrayUpdate('yield_types', type)}
              active={innerFilters.yield_types?.includes(type)}
            >
              {type}
            </Chip>
          ))}
        </FiltersDrawerSection>

        <FiltersDrawerSection title="Coin Single Group" chips>
          {groupsOptions.map((group) => (
            <Chip
              key={group.value}
              onClick={() => onGroupChange(group.value)}
              active={innerFilters.coin_group === group.value}
            >
              {group.label}
            </Chip>
          ))}
        </FiltersDrawerSection>

        <FiltersDrawerSection
          title="Coin Pair Groups"
          subtitle="Note: Select only one group to make the same pair"
        >
          {groupsOptions.map((group) => (
            <Chip
              key={group.value}
              onClick={() => onGroupsChange(group.value)}
              active={!!innerFilters.coin_groups?.includes(group.value)}
            >
              {group.label}
            </Chip>
          ))}
        </FiltersDrawerSection>

        <FiltersDrawerExchangesSelect
          value={innerFilters.exchanges}
          onChange={(exchange) => onArrayUpdate('exchanges', exchange)}
        />

        <FiltersDrawerSection
          title="Farms"
          titleAction={
            <FiltersDrawerTitleAction onClick={() => setFarmsDialog(true)}>
              Select
            </FiltersDrawerTitleAction>
          }
          chips
        >
          {innerFilters.farm_ids?.map((farmId) => (
            <Chip key={farmId} active onRemove={() => onFarmsChange(farmId)}>
              {farmsIdsToNames[farmId]}
            </Chip>
          ))}
        </FiltersDrawerSection>

        <FiltersDrawerSection>
          <Slider
            min={0}
            max={5}
            step={0.5}
            defaultValue={innerFilters.deposit_fee_lte}
            renderValueFormat={RENDER_VALUE['deposit_fee_lte']}
            labelComponent={
              <FiltersDrawerTitle>Deposit Fee lower than</FiltersDrawerTitle>
            }
            onChange={(e) => onChange('deposit_fee_lte', e)}
          />
        </FiltersDrawerSection>

        <FiltersDrawerSection>
          <Slider
            min={0}
            max={5}
            step={0.5}
            defaultValue={innerFilters.withdrawal_fee_lte}
            renderValueFormat={RENDER_VALUE['withdrawal_fee_lte']}
            labelComponent={
              <FiltersDrawerTitle>Withdrawal Fee lower than</FiltersDrawerTitle>
            }
            onChange={(e) => onChange('withdrawal_fee_lte', e)}
          />
        </FiltersDrawerSection>
      </DrawerContent>

      <FiltersDrawerActions onApply={handleApply} onReset={handleReset} />

      <FiltersFarmsDialog
        open={farmsDialog}
        farms={innerFilters.farm_ids}
        onChange={(farmId) => onFarmsChange(farmId)}
        onClose={() => setFarmsDialog(false)}
      />
    </>
  )
}

export default function AssetsFiltersDrawer({
  open,
  ...props
}: FiltersDrawerProps &
  FiltersDrawerActionsProps &
  FiltersDrawerHeadProps &
  AssetsFiltersDrawerContentProps) {
  return (
    <FiltersDrawer open={open} onClose={props.onClose}>
      <AssetsFiltersDrawerContent {...props} />
    </FiltersDrawer>
  )
}
