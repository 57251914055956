import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CellImage from 'components/images/CellImage'
import { LoadingPlaceholder } from 'components/placeholders'
import Table from 'components/table/Table'
import TableCell from 'components/table/TableCell'
import TableRow from 'components/table/TableRow'
import TooltipDropdown from 'components/TooltipDropdown'
import { Link } from 'components/typography'
import useAssets, { AssetsFilters } from 'hooks/useAssets'
import { TableCol } from 'types/components/table'
import { formatApr } from 'utils/assets'
import { getRemoteAssetIcon } from 'utils/icons'
import { stringifyQuery } from 'utils/query'

import { device } from '../../utils/screen'

const COLS: TableCol[] = [
  { title: '', name: 'asset' },
  { title: '', name: 'farm' },
  { title: '', name: 'apr' }
]

const useStyles = makeStyles({
  imgCell: {
    display: 'flex',
    alignItems: 'center'
  },
  name: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  more: {
    fontSize: '0.875rem',
    color: '#D733FF',
    fontWeight: 700
  },
  root: {
    [`@media ${device.tablet}`]: {
      marginBottom: '1.25rem'
    }
  }
})

interface TopVaultsTableProps {
  filters: Partial<AssetsFilters>
}

export default function TopVaultsTable({ filters }: TopVaultsTableProps) {
  const classes = useStyles()
  const assets = useAssets({
    filters: {
      ...filters,
      num_per_page: 5
    }
  })

  return (
    <Grid container direction="column" className={classes.root}>
      <Table
        loading={assets.isLoading}
        empty={!assets.assets.length}
        cols={COLS}
        head={false}
      >
        {assets.assets.map((row) => (
          <TableRow key={row.assetId} size="sm">
            <TableCell maxWidth={150} color="secondary">
              <div className={classes.imgCell}>
                <CellImage src={getRemoteAssetIcon(row)} />

                <TooltipDropdown content={row.asset}>
                  <span className={classes.name}>
                    <Link to={`/assets/${row.assetId}`}>{row.asset}</Link>
                  </span>
                </TooltipDropdown>
              </div>
            </TableCell>
            <TableCell color="secondary">{row.farm}</TableCell>
            <TableCell color="secondary">
              {formatApr(row.aprYearly).aprValue}
            </TableCell>
          </TableRow>
        ))}
        {assets.isLoading && <LoadingPlaceholder />}
      </Table>

      <Link to={stringifyQuery('/assets', filters)} className={classes.more}>
        More
      </Link>
    </Grid>
  )
}
