import ChartCard from 'components/cards/ChartCard'
import LineChart from 'components/charts/LineChart'
import Tabs from 'components/tabs/Tabs'
import { useState } from 'react'
import { parseMoney } from 'utils/api'
import {
  formatChartDate,
  formatChartMoney,
  getChartId,
  renderTooltipOptions
} from 'utils/charts'
import { getRandomColors } from 'utils/colors'
import { RANGE } from 'utils/filters'

import { RANGES_THIRD } from './data'

const COLORS = getRandomColors(20)

interface DailyRevenueBarProps {
  data: any[]
  keys: string[]
}

export default function DailyRevenueBar(props: DailyRevenueBarProps) {
  const [range, onRange] = useState(RANGE._7d)
  return (
    <ChartCard
      title="Daily Revenue"
      headerComponent={
        <Tabs
          width="auto"
          tabs={RANGES_THIRD}
          value={range}
          onChange={onRange}
        />
      }
    >
      <LineChart
        bar
        stack={false}
        id={getChartId('Daily Revenue')}
        data={props.data}
        legends={props.keys}
        dataKey={props.keys}
        colors={COLORS}
        xTickFormatter={formatChartDate}
        yTickFormatter={(tick) => formatChartMoney(tick)}
        tooltipOptions={renderTooltipOptions(props.keys, false, COLORS, (e) =>
          parseMoney(e, 2, true)
        )}
      />
    </ChartCard>
  )
}
