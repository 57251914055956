import Spacing from 'components/common/Spacing'
import useGlobals from 'hooks/useGlobals'
import { useSearch } from 'hooks/useSearch'
import { searchHighlight } from 'utils/strings'

import Dialog from './Dialog'
import DialogBody from './DialogBody'
import DialogList from './DialogList'
import DialogListItem from './DialogListItem'
import DialogSearch from './DialogSearch'

interface ContentProps {
  farms: any[]
  onChange: (farm: string) => void
  onClose: () => void
}

function Content({ farms, onChange }: ContentProps) {
  const { farmsOptions } = useGlobals()
  const { search, onSearch } = useSearch()

  const searchedFarms = farmsOptions.filter(
    (farm) => !!farm.name?.match(new RegExp(search, 'gi'))
  )

  return (
    <>
      <DialogSearch onSearch={onSearch} />

      <DialogBody>
        <DialogList>
          {searchedFarms.map((farm) => (
            <DialogListItem
              name={searchHighlight(farm.name, search)}
              key={farm.farmId}
              active={farms.includes(farm.farmId)}
              onClick={() => onChange(farm.farmId)}
            />
          ))}
        </DialogList>
      </DialogBody>

      <Spacing />
    </>
  )
}

interface FiltersFarmsDialogProps {
  open: boolean
}

export default function FiltersFarmsDialog({
  open,
  ...props
}: FiltersFarmsDialogProps & ContentProps) {
  return (
    <Dialog
      open={open}
      height={600}
      mobileHeight="70vh"
      variant="secondary"
      title="Apply Farms"
      onClose={props.onClose}
    >
      <Content {...props} />
    </Dialog>
  )
}
