import { useSpinner } from 'providers/SpinnerProvider'
import { toast } from 'react-toastify'
import { downVoteLeaderboard, voteLeaderboard } from 'services/api/leaderboards'

type OnVote = (name: string) => void
type OnDownVote = (name: string) => void

export interface UseLeaderboard {
  onVote: OnVote
  onDownVote: OnDownVote
}

interface UseLeaderboardConfig {
  mutate?: any
}

export default function useLeaderboard(
  config: UseLeaderboardConfig = {}
): UseLeaderboard {
  const { close, open } = useSpinner()

  const onVote: OnVote = async (name) => {
    try {
      open()
      const response = await voteLeaderboard(name)
      toast.success(response)
      config.mutate?.()
      close()
    } catch (e) {
      console.error(e)
      toast.error('Something went wrong')
    }
  }

  const onDownVote: OnDownVote = async (name) => {
    try {
      open()
      const response = await downVoteLeaderboard(name)
      toast.success(response)
      config.mutate?.()
      close()
    } catch (e) {
      toast.error('Something went wrong')
    }
  }

  return {
    onDownVote,
    onVote
  }
}
