import ChartCard from 'components/cards/ChartCard'
import LineChart from 'components/charts/LineChart'
import Tabs from 'components/tabs/Tabs'
import ChartTooltip from 'components/tooltips/ChartTooltip'
import { useMemo, useState } from 'react'
import { parseMoney } from 'utils/api'
import {
  formatChartDate,
  formatChartMoney,
  getChartId,
  renderTooltipOptions
} from 'utils/charts'
import { getRandomColors } from 'utils/colors'
import { CURRENCY, RANGE } from 'utils/filters'
import { parseRangeChart } from 'utils/pools'
import { TOOLTIP } from 'utils/tooltip'

import { CURRENCIES, RANGES } from '../performance/data'

const HIGHLIGHT_COLOR = getRandomColors(1)
const TITLE = 'Holdings'
const CHART_ID = getChartId(TITLE + Date.now())

interface HoldingsSingleLineChartProps {
  usdData: any[]
  ethData: any[]
  loading: boolean
  poolId?: string
  poolAsset?: string
}

export default function HoldingsSingleLineChart({
  loading,
  poolId,
  usdData,
  ethData
}: HoldingsSingleLineChartProps) {
  const [currency, setCurrency] = useState(CURRENCY._USD)
  const [range, setRange] = useState(RANGE._7d)

  const [parseUsdChart, parseEthChart] = useMemo(() => {
    return [parseRangeChart(range, usdData), parseRangeChart(range, ethData)]
  }, [range, poolId])

  return (
    <ChartCard
      title={TITLE}
      tooltip={<ChartTooltip content={TOOLTIP.AREA_SINGLE_HOLDINGS} />}
      spacing={10}
      titleComponent={
        <Tabs
          width="auto"
          tabs={CURRENCIES}
          value={currency}
          onChange={setCurrency}
        />
      }
      headerComponent={
        <Tabs width="auto" tabs={RANGES} value={range} onChange={setRange} />
      }
    >
      <LineChart
        area
        memo={[poolId, range].join('_')}
        loading={loading}
        noData={!parseUsdChart.length}
        id={CHART_ID}
        data={currency === CURRENCY._USD ? parseUsdChart : parseEthChart}
        dataKey={['value']}
        colors={HIGHLIGHT_COLOR}
        yTickFormatter={(tick) => formatChartMoney(tick, currency)}
        xTickFormatter={formatChartDate}
        tooltipOptions={renderTooltipOptions(
          ['value'],
          false,
          HIGHLIGHT_COLOR,
          (e) => parseMoney(e, 2, true, currency)
        )}
      />
    </ChartCard>
  )
}
