import { fetcher } from 'services/api/common'
import useSWR from 'swr'

export default function useAllUserPools() {
  const { data: _data, error } = useSWR('/auth/get_all_user_pools', fetcher)

  const isLoading = !_data && !error
  const data = _data || []

  return {
    data,
    isLoading
  }
}
