import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { Cell, Pie, PieChart as RePierChart, Tooltip } from 'recharts'

import ChartTooltip from '../tooltips/ChartTooltip'

const useStyles = makeStyles({
  tooltip: {
    background: 'linear-gradient(107.39deg, #474F7E 3.48%, #343B61 97.52%)',
    padding: '0.5rem 0.75rem',
    borderRadius: 10,

    '& p': {
      fontSize: '0.75rem',

      '& span': {
        fontWeight: 700
      }
    }
  },
  root: {
    width: 200,
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  }
})

interface PieChartProps {
  data: any[]
  dataKey: string
  tooltipTitle: (props: any) => string
  tooltipText: (props: any) => string
  customTooltip?: string
}

export default function PieChart({
  data,
  dataKey,
  tooltipTitle,
  tooltipText,
  customTooltip
}: PieChartProps) {
  const classes = useStyles()
  return (
    <Grid className={classes.root}>
      {customTooltip && <ChartTooltip bar content={customTooltip} />}

      <RePierChart width={200} height={200}>
        <Pie
          dataKey={dataKey}
          data={data}
          outerRadius={95}
          innerRadius={55}
          paddingAngle={0}
          stroke="0"
        >
          {data.map((row, index) => (
            <Cell key={index} fill={row.color} />
          ))}
        </Pie>

        <Tooltip
          cursor={{ stroke: '#6B6BB2', strokeOpacity: 1 }}
          content={(props) => {
            return (
              <Grid className={classes.tooltip}>
                <Typography>
                  {tooltipTitle(props.payload?.[0]?.payload)}
                </Typography>
                <Typography>
                  <span>{tooltipText(props.payload?.[0]?.payload)}</span>
                </Typography>
              </Grid>
            )
          }}
        />
      </RePierChart>
    </Grid>
  )
}
