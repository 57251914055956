import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BOOL_FILTERS } from 'utils/filters'
import { toStartCase } from 'utils/strings'

import { OnUpdateNft, UseNftOverviewDataItem } from '../../hooks/useNftOverview'
import Dialog from './Dialog'
import DialogBody from './DialogBody'
import DialogTitleComponent from './DialogTitleComponent'

const useStyles = makeStyles({
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.25rem'
  },
  button: {
    marginRight: '1.25rem',

    '&:last-child': {
      marginRight: 0
    }
  },
  inputContainer: {
    position: 'relative',

    '&:hover': {
      '& svg': {
        visibility: 'visible'
      }
    }
  },
  actions: {
    padding: '1.25rem'
  }
})

interface ContentProps {
  nft: UseNftOverviewDataItem | null
  onUpdate: OnUpdateNft
  onClose: () => void
  revalidate?: any
}

const EDITABLE_FIELDS: string[] = ['active', 'image_url']

function Content({ nft, onUpdate, onClose, revalidate }: ContentProps) {
  const classes = useStyles()

  const methods = useForm<UseNftOverviewDataItem>()

  useEffect(() => {
    methods.reset({
      active: nft?.active,
      image_url: nft?.image_url
    })
  }, [nft])

  const handleSubmit = ({ ...values }) => {
    nft &&
      onUpdate(nft.name, values, () => {
        revalidate?.()
        onClose()
      })
  }

  return (
    <>
      <DialogBody>
        <form onSubmit={methods.handleSubmit(handleSubmit)} id="edit-nft-view">
          <Grid container direction="column" className={classes.content}>
            {EDITABLE_FIELDS.map((field, index) => (
              <Grid
                container
                alignItems="center"
                key={index}
                className={classes.inputContainer}
              >
                {field === 'active' ? (
                  <Controller
                    control={methods.control}
                    render={({ field: { value, name } }) => (
                      <Select
                        searchable={false}
                        variant="secondary"
                        options={BOOL_FILTERS}
                        placeholder={toStartCase(field)}
                        label={toStartCase(field)}
                        value={Boolean(value)}
                        onChange={(e) =>
                          methods.setValue(name, e?.value ? 1 : 0)
                        }
                      />
                    )}
                    name={field}
                  />
                ) : (
                  <Input
                    variant="secondary"
                    placeholder={toStartCase(field)}
                    label={toStartCase(field)}
                    {...methods.register(field as any)}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </form>
      </DialogBody>

      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        className={classes.actions}
      >
        <Button
          variant="contained-2"
          size="sm"
          type="button"
          color="secondary"
          className={classes.button}
          onClick={onClose}
        >
          Reset
        </Button>
        <Button
          variant="contained-2"
          size="sm"
          type="submit"
          form="edit-nft-view"
          className={classes.button}
        >
          Save
        </Button>
      </Grid>
    </>
  )
}

interface EditNftViewDialogProps {
  open: boolean
  onClose: () => void
}

export default function EditNftViewDialog({
  onClose,
  open,
  ...props
}: EditNftViewDialogProps & Partial<ContentProps>) {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        title={
          <DialogTitleComponent>
            <span>Edit Values</span>
          </DialogTitleComponent>
        }
        variant="secondary"
        height={550}
        size="lg"
      >
        <Content {...(props as ContentProps)} onClose={onClose} />
      </Dialog>
    </>
  )
}
