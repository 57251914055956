import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import { ChangeEvent, forwardRef, ReactNode } from 'react'

import Label from './Label'

const useStyles = makeStyles({
  inputContainer: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  input: (props: any) => ({
    width: '100%',
    outline: 'none',
    border: 'none',
    fontSize: '1rem',
    padding: props.prefix ? '0 1.25rem 0 3.5rem' : '0 1.25rem',
    backgroundColor: '#303659',
    borderRadius: 15,
    color: '#fafafa',
    fontFamily: props.font || 'Montserrat',
    resize: 'none',

    '&:disabled': {
      opacity: 0.5
    },

    ...(props.variant === 'secondary' && {
      fontFamily: 'Roboto',
      borderRadius: 15,
      fontSize: '0.875rem',
      backgroundColor: '#373D66',
      border: '1px solid rgba(255,255,255,0.4)'
    }),

    ...(!props.rows && {
      height: 40
    }),

    ...(props.rows && {
      minHeight: 40,
      padding: '0.5rem 1.25rem'
    }),

    ...(props.error && {
      border: '1px solid red'
    })
  }),
  prefix: {
    position: 'absolute',
    left: '1.25rem',
    height: 18,
    width: 18,

    '& svg': {
      width: 18,
      height: 18
    }
  }
})

export interface InputProps {
  type?: string
  prefix?: ReactNode
  placeholder?: string
  className?: string
  inputClassName?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  label?: string
  font?: 'Roboto'
  variant?: 'secondary'
  value?: string
  rows?: number
  disabled?: boolean
  error?: string
  defaultValue?: string
  labelComponent?: ReactNode
  tooltip?: string
}

const Input = forwardRef(
  (
    {
      type = 'text',
      prefix,
      placeholder,
      className,
      inputClassName,
      onChange,
      value,
      label,
      variant,
      font,
      rows,
      disabled,
      error,
      defaultValue,
      labelComponent,
      tooltip,
      ...props
    }: InputProps,
    ref
  ) => {
    const classes = useStyles({ prefix: !!prefix, variant, font, rows, error })

    const rootProps = {
      ref: ref as any,
      type: type,
      placeholder: placeholder,
      className: classNames(classes.input, inputClassName),
      onChange: onChange,
      value: value,
      defaultValue,
      disabled
    }

    return (
      <Grid container direction="column" className={className}>
        {label && (
          <Label tooltip={tooltip} component={labelComponent} error={error}>
            {`${label}${error ? `: ${error}` : ''}`}
          </Label>
        )}

        <div className={classes.inputContainer}>
          {!!prefix && <span className={classes.prefix}>{prefix}</span>}
          {rows ? (
            <textarea rows={rows} {...rootProps} {...(props as any)} />
          ) : (
            <input {...rootProps} {...props} />
          )}
        </div>
      </Grid>
    )
  }
)

export default Input
