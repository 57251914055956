import Accordion from 'components/accordions/Accordion'
import usePoolsByField from 'hooks/usePoolsByField'
import { useState } from 'react'

import StrategiesTable from '../strategies/StrategiesTable'

export default function InactiveAssets() {
  const [open, onOpen] = useState(false)

  const poolsByField = usePoolsByField({
    filters: {
      field: 'active',
      value: 'false'
    }
  })

  return (
    <Accordion
      title="Deactivated Assets"
      open={open}
      onClick={() => onOpen(!open)}
    >
      <StrategiesTable
        data={poolsByField.pools}
        page={poolsByField.filters.page}
        maxPages={poolsByField.maxPages}
        onPage={poolsByField.onPage}
        loading={poolsByField.isLoading}
      />
    </Accordion>
  )
}
