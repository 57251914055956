import Grid, { GridProps } from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'

const useStyles = makeStyles({
  root: {
    padding: '1.25rem',
    background: 'linear-gradient(111.6deg, #303659 -2.51%, #2A2F4D 104.46%)',
    borderRadius: 15
  }
})

export default function Card({ ...props }: GridProps) {
  const classes = useStyles()
  return (
    <Grid
      container
      {...props}
      className={classNames(classes.root, props.className)}
    ></Grid>
  )
}
