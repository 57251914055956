import { fetcher } from 'services/api/common'
import useSWR from 'swr'

interface UseAdvFarms {
  isLoading: boolean
  advFarms: any[]
}

export default function useAdvFarms(): UseAdvFarms {
  const { data, error } = useSWR('/get_adspot_farms', fetcher)

  const isLoading = !data && !error
  const advFarms = data || []

  return {
    isLoading,
    advFarms
  }
}
