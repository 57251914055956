// import { toast } from 'react-toastify'
// import { editProteusDescription } from 'services/api/cms'
import { fetcher } from 'services/api/common'
import useSWR from 'swr'

// type OnEdit = (data: any) => void
//
interface UseProteusDescriptions {
  isLoading: boolean
  descriptions: any[]
  revalidate: any
  // onEdit: OnEdit
}

export default function useProteusDescriptions(): UseProteusDescriptions {
  const {
    data,
    error,
    mutate: revalidate
  } = useSWR('/auth/cms/get_all_proteus_description', fetcher)
  //
  // const onEdit: OnEdit = async (data) => {
  //   try {
  //     await editProteusDescription(data)
  //     toast.success('Successfully saved')
  //     revalidate?.()
  //   } catch (e) {
  //     console.error(e)
  //     toast.error('Oops error')
  //   }
  // }
  //
  const isLoading = !data && !error
  const descriptions = data || []

  return {
    isLoading,
    descriptions,
    revalidate
    // onEdit
  }
}
