import { fetcher } from 'services/api/common'
import useSWR from 'swr'

interface UseTriggers {
  isLoading: boolean
  triggers: any[]
  revalidate: any
}

function getKey() {
  return '/auth/get_triggers'
}

export default function useTriggers(): UseTriggers {
  const { data, error, mutate: revalidate } = useSWR(() => getKey(), fetcher)

  const isLoading = !data && !error
  const triggers = Array.isArray(data?.triggers) ? data?.triggers : []

  return {
    isLoading,
    triggers,
    revalidate
  }
}
