import { useState } from 'react'
import { toast } from 'react-toastify'
import { uploadImage } from 'services/api/files'

type OnSuccess = (url: string) => void
type OnUpload = (file: File, onSuccess?: OnSuccess) => void

interface UseUploadFile {
  onUpload: OnUpload
  isUploadLoading: boolean
}

export default function useUploadFile(): UseUploadFile {
  const [isUploadLoading, setUploadLoading] = useState(false)

  const onUpload: OnUpload = async (file, onSuccess) => {
    try {
      setUploadLoading(true)

      const response = await uploadImage(file)
      onSuccess?.(response.url)

      setUploadLoading(false)
    } catch (e) {
      setUploadLoading(false)
      toast.error('Oops error!')
      console.error(e)
    }
  }

  return {
    onUpload,
    isUploadLoading
  }
}
