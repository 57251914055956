import { Grid, makeStyles } from '@material-ui/core'
import { EditIcon } from 'assets/icons'
import Accordion from 'components/accordions/Accordion'
import TransactionDialog from 'components/dialogs/transactions/TransactionDialog'
import Table from 'components/table/Table'
import TablePagination from 'components/table/TablePagination'
import TableRow from 'components/table/TableRow'
import { useTransactions } from 'hooks/useTransactions'
import { Token, Transaction, TransactionsColumn } from 'interfaces/transactions'
import { useSpinner } from 'providers/SpinnerProvider'
import { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { editTransaction } from 'services/api/transactions'
import { TableCol } from 'types/components/table'
import { shortenAddress } from 'utils/web3.utils'

import IconButton from '../../../components/buttons/IconButton'
import TableCell from '../../../components/table/TableCell'

const useStyles = makeStyles({
  actionsCell: {
    width: '100px'
  },
  iconsCell: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: '1rem',
      cursor: 'pointer',

      '&:last-child': {
        marginRight: 0
      }
    }
  },
  iconBtn: {
    marginRight: '0.5rem'
  },
  table: {
    margin: '-1.25rem 0 -1.25rem 0'
  }
})

const COLUMNS: TransactionsColumn[] = [
  {
    title: 'Address',
    key: 'address',
    align: 'center',
    render: (item) => shortenAddress(item),
    width: '10%'
  },
  {
    title: 'Blockchain',
    key: 'blockchain',
    align: 'center',
    render: (item) => item,
    width: '10%'
  },
  {
    title: 'Labels',
    key: 'labels',
    align: 'center',
    render: (labels: string[]) => labels.join(', '),
    width: '20%'
  },
  {
    title: 'ID',
    key: 'tx_id',
    align: 'center',
    render: (item) => shortenAddress(item),
    width: '10%'
  },
  {
    title: 'From',
    key: 'tx_from',
    align: 'center',
    render: (item) => shortenAddress(item),
    width: '10%'
  },
  {
    title: 'To',
    key: 'tx_to',
    align: 'center',
    render: (item) => shortenAddress(item),
    width: '10%'
  },
  {
    title: 'Value',
    key: 'tx_value',
    align: 'center',
    render: (item) => item,
    width: '10%'
  },
  {
    title: 'Token',
    key: 'tx_token',
    align: 'center',
    render: (item: Token) => item.symbol,
    width: '10%'
  },
  {
    title: 'Block',
    key: 'block',
    align: 'center',
    render: (item) => item,
    width: '10%'
  },
  {
    title: 'Timestamp',
    key: 'timestamp',
    align: 'center',
    render: (item) => item,
    width: '10%'
  }
]

export const Transactions: React.FC = () => {
  const [isFormOpen, openForm] = useState<boolean>(false)
  const [transaction, setTransaction] = useState<Transaction | undefined>(
    undefined
  )

  const classes = useStyles()
  const { open: startSpinner, close: stopSpinner } = useSpinner(false)
  const { transactions, isLoading, maxPages, page, onPage, revalidate } =
    useTransactions({
      page: 1
    })

  const HEADER_COLUMNS: TableCol[] = useMemo(
    () => [
      ...COLUMNS,
      {
        title: <TablePagination page={page} count={maxPages} onPage={onPage} />,
        align: 'right'
      }
    ],
    [page, maxPages, onPage]
  )

  /**
   * Closes the modal and unsets the rule in state.
   */
  const closeModal = () => {
    openForm(false)
    setTransaction(undefined)
  }

  /**
   * Opens a transaction for edditing.
   * @param transacton
   */
  const onEdit = (transaction: Transaction) => {
    setTransaction(transaction)
    openForm(true)
  }

  /**
   * Callback that handles Rule create or update
   */
  const handleTransaction = useCallback(
    async (values: Pick<Transaction, 'labels'>) => {
      if (!transaction) return
      startSpinner()
      try {
        await editTransaction(
          { id: transaction.tx_id, blockchain: transaction.blockchain },
          values
        )
        revalidate()
      } catch (error) {
        toast.error('Editing transaction failed.')
      }
      closeModal()
      stopSpinner()
    },
    [transaction]
  )

  return (
    <>
      <Accordion title="Transactions" open onClick={() => void 0} arrow={false}>
        <Table
          inCard
          className={classes.table}
          cols={HEADER_COLUMNS}
          loading={isLoading}
          empty={!transactions.length}
        >
          {transactions.map((transaction: Transaction, index: number) => (
            <TableRow key={`transaction-${index}`}>
              {COLUMNS.map((column) => (
                <TableCell key={column.key} align={column.align}>
                  {column.render && column.key
                    ? column.render(transaction[column.key])
                    : transaction[column.key] || 'N/A'}
                </TableCell>
              ))}
              <TableCell className={classes.actionsCell}>
                <Grid className={classes.iconsCell}>
                  <IconButton
                    size="sm"
                    onClick={() => {
                      onEdit(transaction)
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Accordion>
      <TransactionDialog
        open={isFormOpen}
        onSubmit={(data: Pick<Transaction, 'labels'>) => {
          handleTransaction(data)
        }}
        transaction={transaction}
        onClose={closeModal}
      />
    </>
  )
}
