import { createContext, PropsWithChildren, useContext } from 'react'

interface ProteusDescriptionContextProps {
  revalidate: any
}

const ProteusDescriptionContext = createContext<ProteusDescriptionContextProps>(
  {
    revalidate: () => {}
  }
)

export default function ProteusDescriptionProvider({
  children,
  ...props
}: PropsWithChildren<ProteusDescriptionContextProps>) {
  return (
    <ProteusDescriptionContext.Provider value={props}>
      {children}
    </ProteusDescriptionContext.Provider>
  )
}

export function useProteusDescriptionContext() {
  return useContext(ProteusDescriptionContext)
}
