import trim from 'lodash.trim'
import { useMemo } from 'react'
import { getGlobals } from 'services/api/globals'
import {
  AnnouncementResponse,
  AnnouncementUpdatedResponse,
  Chain,
  GlobalsDataResponse
} from 'services/types/globals'
import useSWR, { cache } from 'swr'

import { farmIdsToNames } from '../utils/globals'

interface UseGlobals {
  data: GlobalsDataResponse | {}
  announcement: AnnouncementResponse
  announcementUpdate: AnnouncementUpdatedResponse
  chains: Chain[]
  exchangesTypes: any[]
  farmsOptions: any[]
  yieldTypes: any[]
  farmsIdsToNames: any
}

const key = '/get_global_multi_selects'

export default function useGlobals(): UseGlobals {
  const { data } = useSWR(key, getGlobals, {
    revalidateOnMount: !cache.has(key),
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  })

  const isSuccess = !!data

  return useMemo(
    () => ({
      data: data?.data || {},
      announcement: data?.announcement || '',
      announcementUpdate: data?.announcementUpdated || '',
      chains: data?.data?.chains_available || [],
      farmsOptions: data?.data?.farms || [],
      farmsIdsToNames: farmIdsToNames(data?.data?.farms || []),
      exchangesTypes:
        data?.data?.exchange_select?.filter((a) => !!trim(a)) || [],
      yieldTypes: data?.data?.yield_type_select?.filter((a) => !!trim(a)) || []
    }),
    [isSuccess]
  )
}
