import { BalanceResponse, BalanceSheetRow } from 'interfaces/balance-sheet'
import { fetcher } from 'services/api/common'
import useSWR, { mutate } from 'swr'
import { stringifyURL } from 'utils/query'

interface UseBalanceSheetConfig {
  year?: number
}

interface QueryParams extends UseBalanceSheetConfig {}

interface UseBalanceSheet {
  isLoading: boolean
  expenses: BalanceSheetRow[]
  revenues: BalanceSheetRow[]
  balanceSheet: any
  revalidate: () => Promise<any>
}

function getKey(params: QueryParams) {
  return stringifyURL('/auth/balance_sheet', params)
}

export function useBalanceSheet(
  config: UseBalanceSheetConfig = {}
): UseBalanceSheet {
  if (!config.year) {
    config.year = new Date().getFullYear()
  }

  const requestKey = getKey(config)
  const { data, error } = useSWR<BalanceResponse>(requestKey, fetcher)

  const isLoading = !data && !error
  const [balanceSheet] = data?.data || []
  const { expenses = [], revenues = [] } = balanceSheet || {}

  return {
    isLoading,
    expenses,
    revenues,
    balanceSheet,
    revalidate: () => mutate(requestKey)
  }
}
