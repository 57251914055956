import useSWR from 'swr'

import { useAuth } from '../providers/AuthProvider'
import { getSavedSearches } from '../services/api/saved'
import { stringifyURL } from '../utils/query'
import useSaveSearch, { UseSaveSearch } from './useSaveSearch'

function getKey(params) {
  return stringifyURL('/auth/get_saved_searches', params)
}

interface UseSavedSearchesConfig {
  save_type?: 'farm' | 'asset'
  onlyIds?: boolean
}

export interface UseSavedSearches extends UseSaveSearch {
  isLoading: boolean
  savedSearches: any[]
}

export default function useSavedSearches(
  config: UseSavedSearchesConfig = {}
): UseSavedSearches {
  const { isLogged } = useAuth()

  const params = {
    save_type: config.save_type
  }

  const { data, error, mutate } = useSWR(
    () => (isLogged && config.save_type ? getKey(params) : null),
    getSavedSearches
  )

  const savedSearch = useSaveSearch({ mutate })

  const isLoading = !data && !error
  const savedSearches = data || []
  return {
    isLoading,
    savedSearches,
    ...savedSearch
  }
}
