import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { usePagination } from '@material-ui/lab/Pagination'
import { ArrowRightIcon } from 'assets/icons'
import classNames from 'classnames'
import { useState } from 'react'

import { device } from '../utils/screen'

const useStyles = makeStyles({
  root: {
    paddingTop: 40,
    color: '#B2BDFF',

    [`@media ${device.tablet}`]: {
      flexDirection: 'column'
    }
  },
  button: {
    padding: 7,
    margin: 7,
    display: 'flex',
    background: 'transparent',
    color: '#B2BDFF',

    '&:hover': {
      color: '#66C8FF'
    },

    '&:disabled': {
      color: '#BEBEBE',
      pointerEvents: 'none'
    }
  },
  btnPrev: {
    '& svg': {
      transform: 'rotate(180deg)'
    }
  },
  active: {
    color: '#fff',
    fontWeight: 700
  },
  container: {
    display: 'flex',
    alignItems: 'center',

    [`@media ${device.tablet}`]: {
      marginBottom: 10
    }
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    marginLeft: 20,

    [`@media ${device.tablet}`]: {
      marginLeft: 0
    }
  },
  input: {
    width: 44,
    height: 40,
    borderRadius: 10,
    background: 'transparent',
    border: '1px solid #B2BDFF',
    outline: 'none',
    margin: '0 16px',
    color: '#fff',
    padding: '0 10px'
  },
  goBtn: {
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    color: '#66C8FF',
    fontWeight: 700,

    '&:disabled': {
      color: '#BEBEBE',
      pointerEvents: 'none'
    },

    '& svg': {
      marginLeft: 5
    }
  }
})

export default function BottomPagination({ page, count, onChange }: any) {
  const classes = useStyles()
  const [manualPage, setManualPage] = useState('')
  const { items } = usePagination({ count, page })

  const handleGo = () => {
    manualPage &&
      !isNaN(+manualPage) &&
      !(+manualPage > count) &&
      !(+manualPage <= 0) &&
      onChange(+manualPage)
  }

  const curPage = page

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid className={classes.container}>
        {items.map(({ page, type, selected, ...item }, index) => {
          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            return <span key={index}>...</span>
          }
          if (type === 'page') {
            return (
              <button
                className={classNames(classes.button, {
                  [classes.active]: selected
                })}
                key={index}
                type="button"
                onClick={() => onChange(page)}
              >
                {page}
              </button>
            )
          }
          if (type === 'next') {
            return (
              <button
                key={index}
                className={classes.button}
                disabled={curPage === count}
                onClick={() => onChange(curPage + 1)}
              >
                <ArrowRightIcon />
              </button>
            )
          }
          if (type === 'previous') {
            return (
              <button
                key={index}
                className={classNames(classes.btnPrev, classes.button)}
                disabled={curPage === 1}
                onClick={() => onChange(curPage - 1)}
              >
                <ArrowRightIcon />
              </button>
            )
          }
          return (
            <button key={index} type="button" {...item}>
              {type}
            </button>
          )
        })}
      </Grid>

      <Grid className={classes.inputContainer}>
        <span>Go to page</span>

        <input
          type="text"
          className={classes.input}
          value={manualPage}
          onChange={(e) => setManualPage(e.target.value)}
        />

        <button
          className={classes.goBtn}
          onClick={handleGo}
          disabled={page === 1}
        >
          <span>Go</span>
          <ArrowRightIcon />
        </button>
      </Grid>
    </Grid>
  )
}
