import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStrategiesStyles = makeStyles({
  tabs: {
    marginBottom: '1.25rem'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1.25rem'
  },
  titleActions: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    marginBottom: '2rem'
  },
  cellActions: {
    display: 'flex',
    alignItems: 'center'
  },
  subtitle: {
    fontWeight: 700,
    marginRight: '1.25rem',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center'
  },
  chartTitle: {
    marginBottom: '1.5rem'
  },
  chartTitleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  chart: {
    marginBottom: '1.5rem'
  },
  table: {
    marginBottom: '1.5rem'
  },
  cards: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '1.25rem',
    marginBottom: '2rem'
  },
  cartTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '0.75rem',

    '& svg': {
      width: 25,
      cursor: 'pointer',

      '&[data-icon="edit"]': {
        width: 17.5
      }
    }
  },
  cardTitle: {
    marginRight: 0,
    textTransform: 'uppercase',

    '& span': {
      color: '#B2BDFF'
    }
  },
  cardText: {
    fontSize: '0.875rem'
  },
  selectStrategy: {
    maxWidth: 600,
    marginBottom: '1.25rem'
  },
  strategiesTableContainer: {
    margin: '2.5rem 0'
  },
  card: {
    marginBottom: '1.25rem'
  },
  pieTitle: {
    fontSize: '0.875rem',
    fontWeight: 600,
    padding: '0 0 0 0.625rem'
  },
  pieTable: {
    padding: '0 0 0 1.25rem'
  },
  tableSpacing: {
    marginBottom: '1.25rem'
  },
  white: {
    color: 'white'
  },
  spaceRight: {
    marginRight: '10px'
  },
  assetDetails: {
    margin: '2rem 0'
  },
  assetDetailsGrid: {
    width: '100%'
  },
  assetDetailsTitle: {
    marginRight: '20px'
  },
  assetDetailsTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.25rem'
  },
  assetTokens: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '1.25rem'
  },
  assetToken: {
    border: '1px solid #363c64'
  },
  urls: {
    cursor: 'pointer',
    color: '#2a99da'
  }
})
