import {
  AssetsIcon,
  ExchangeIcon,
  FarmsIcon,
  LeaderboardIcon,
  ResearchIcon,
  TrendingIcon,
  TriggersIcon
} from 'assets/icons'
import TrendingAssetsTable from 'pages/premium/trending/TopAssetsTable'
import useRole from 'hooks/useRole'
import Assets from 'pages/premium/Assets'
import BalanceSheet from 'pages/premium/BalanceSheet'
import Cms from 'pages/premium/Cms'
import EditDashboard from 'pages/premium/EditDashboard'
import Exchange from 'pages/premium/Exchange'
import Farms from 'pages/premium/Farms'
import Labels from 'pages/premium/Labels'
import Leaderboard from 'pages/premium/Leaderboard'
import OtherIncome from 'pages/premium/OtherIncome'
import Performance from 'pages/premium/Performance'
import Pnl from 'pages/premium/Pnl'
import Portfolio from 'pages/premium/Portfolio'
import Proteus from 'pages/premium/Proteus'
import Research from 'pages/premium/Research'
import StrategiesDetailed from 'pages/premium/StrategiesDetailed'
import { TransactionsPage } from 'pages/premium/TransactionsPage'
import Triggers from 'pages/premium/Triggers'
import Wallets from 'pages/premium/Wallets'
import { useSpinner } from 'providers/SpinnerProvider'
import { ReactNode } from 'react'

interface Tab {
  title: string
  key: string
  path: string
  regex?: string
  icon: ReactNode
  Component: () => JSX.Element
}

export const TABS: Tab[] = [
  {
    title: 'Saved Farms',
    key: 'saved_farms',
    icon: <FarmsIcon />,
    path: 'farms',
    Component: Farms
  },
  {
    title: 'Saved Assets',
    key: 'saved_assets',
    icon: <AssetsIcon />,
    path: 'assets',
    Component: Assets
  },
  {
    title: 'Wallets',
    key: 'wallets',
    icon: <AssetsIcon />,
    path: 'wallets',
    Component: Wallets
  },
  {
    title: 'Portfolio Allocation',
    key: 'portfolio_allocation',
    icon: <AssetsIcon />,
    path: 'portfolio',
    Component: Portfolio
  },
  {
    title: 'Strategies Performance',
    key: 'strategies_performance',
    icon: <AssetsIcon />,
    path: 'performance',
    Component: Performance
  },
  {
    title: 'Strategies Detailed',
    key: 'strategies_detailed',
    icon: <AssetsIcon />,
    path: 'details',
    regex: 'details/:id?',
    Component: StrategiesDetailed
  },
  {
    title: 'Balance Sheet',
    key: 'balance_sheet',
    icon: <AssetsIcon />,
    path: 'balance_sheet',
    regex: 'balance_sheet/:id?',
    Component: BalanceSheet
  },
  {
    title: 'Edit Yield Dashboard',
    key: 'edit_dashboard',
    icon: <AssetsIcon />,
    path: 'dashboard',
    regex: 'dashboard/:id?',
    Component: EditDashboard
  },
  {
    title: 'PnL',
    key: 'pnl',
    icon: <AssetsIcon />,
    path: 'pnl',
    Component: Pnl
  },
  {
    title: 'Other Revenue',
    key: 'other_revenue',
    icon: <AssetsIcon />,
    path: 'other-revenue',
    Component: OtherIncome
  },
  {
    title: 'Protocol Trading Data',
    key: 'protocol_trading_data',
    icon: <AssetsIcon />,
    path: 'proteus',
    Component: Proteus
  },
  {
    title: 'CMS',
    key: 'cms',
    icon: <AssetsIcon />,
    path: 'cms',
    Component: Cms
  },
  {
    title: 'Labels',
    key: 'labels',
    icon: <AssetsIcon />,
    path: 'labels',
    Component: Labels
  },
  {
    title: 'Triggers',
    key: 'triggers',
    icon: <TriggersIcon />,
    path: 'triggers',
    Component: Triggers
  },
  {
    title: 'Transactions',
    key: 'transactions',
    icon: <AssetsIcon />,
    path: 'transactions',
    Component: TransactionsPage
  },
  {
    title: 'Exchange Data',
    key: 'exchange_data',
    icon: <ExchangeIcon />,
    path: 'exchange',
    Component: Exchange
  },
  {
    title: 'Trending Pools',
    key: 'trending_pools',
    icon: <TrendingIcon />,
    path: 'trending',
    Component: TrendingAssetsTable
  },
  {
    title: 'Research & Alpha',
    key: 'research_alpha',
    icon: <ResearchIcon />,
    path: 'research',
    Component: Research
  },
  {
    title: 'Leaderboard',
    key: 'leaderboard',
    icon: <LeaderboardIcon />,
    path: 'leaderboard',
    Component: Leaderboard
  }
]

interface UsePremiumPages {
  pages: Tab[]
}

export default function usePremiumPages(): UsePremiumPages {
  const role = useRole()

  useSpinner(role.isLoading)

  const pages = role.isLoading
    ? []
    : role.noRole
      ? []
      : TABS.filter((tab) => role.pages.includes(tab.key))

  return {
    pages
  }
}
