import { useSpinner } from 'providers/SpinnerProvider'
import { toast } from 'react-toastify'
import { addCustomAsset } from 'services/api/assets'
import { formatCustomAssetValues } from 'utils/assets'

type OnAdd = (data: any, onSuccess?: any) => void

interface UseCustomAsset {
  onAdd: OnAdd
}

export default function useCustomAsset(): UseCustomAsset {
  const spinner = useSpinner()

  const onAdd: OnAdd = async (data, onSuccess) => {
    try {
      spinner.open()

      await addCustomAsset(formatCustomAssetValues(data))
      toast.success('Successfully saved. Asset will appear soon.')

      onSuccess?.()
      spinner.close()
    } catch (e) {
      spinner.close()
      console.error(e)
      toast.error('Oops error!')
    }
  }

  return {
    onAdd
  }
}
