import { Grid, makeStyles } from '@material-ui/core'
import { DeleteIcon, EditIcon } from 'assets/icons'
import Accordion from 'components/accordions/Accordion'
import Button from 'components/buttons/Button'
import RuleDialog, {
  RuleForm
} from 'components/dialogs/transactions/RuleDialog'
import Table from 'components/table/Table'
import TablePagination from 'components/table/TablePagination'
import TableRow from 'components/table/TableRow'
import { useGlobalMultiSelects } from 'hooks/useGlobalMultiSelects'
import { useRules } from 'hooks/useRules'
import { Rule, TransactionsColumn } from 'interfaces/transactions'
import { useSpinner } from 'providers/SpinnerProvider'
import { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { createRule, deleteRule, editRule } from 'services/api/transactions'
import { TableCol } from 'types/components/table'

import IconButton from '../../../components/buttons/IconButton'
import TableCell from '../../../components/table/TableCell'

const useStyles = makeStyles({
  actionsCell: {
    width: '100px'
  },
  iconsCell: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: '1rem',
      cursor: 'pointer',

      '&:last-child': {
        marginRight: 0
      }
    }
  },
  iconBtn: {
    marginRight: '0.5rem'
  },
  table: {
    margin: '-1.25rem 0 -1.25rem 0'
  }
})

const COLUMNS: TransactionsColumn[] = [
  {
    title: 'Address',
    key: 'address',
    align: 'center',
    render: (item) => item,
    width: '20%'
  },
  {
    title: 'Blockchain',
    key: 'blockchain',
    align: 'center',
    render: (item) => item,
    width: '20%'
  },
  {
    title: 'Tx From',
    key: 'tx_from',
    align: 'center',
    render: (item) => item,
    width: '20%'
  },
  {
    title: 'Tx To',
    key: 'tx_to',
    align: 'center',
    render: (item) => item,
    width: '20%'
  },
  {
    title: 'Labels',
    key: 'labels',
    align: 'center',
    render: (labels: string[]) => labels.join(', '),
    width: '20%'
  }
]

export const TransactionRules: React.FC = () => {
  const [isFormOpen, openForm] = useState<boolean>(false)
  const [rule, setRule] = useState<Rule | undefined>(undefined)

  const classes = useStyles()
  const { open: startSpinner, close: stopSpinner } = useSpinner(false)
  const { availableChains } = useGlobalMultiSelects()
  const { rules, isLoading, maxPages, page, onPage, revalidate } = useRules({
    page: 1
  })

  const HEADER_COLUMNS: TableCol[] = useMemo(
    () => [
      ...COLUMNS,
      {
        title: <TablePagination page={page} count={maxPages} onPage={onPage} />,
        align: 'right'
      }
    ],
    [page, maxPages, onPage]
  )

  /**
   * Closes the modal and unsets the rule in state.
   */
  const closeModal = () => {
    openForm(false)
    setRule(undefined)
  }

  /**
   * Creates or edits a rule.
   * @param data - Rule data.
   * @returns Response.
   */
  const createOrEditRule = async (data: Omit<Rule, 'id'>): Promise<any> => {
    if (rule) {
      return editRule(data)
    }
    return createRule(data)
  }

  /**
   * Callback that handles Rule create or update
   */
  const handleRule = useCallback(
    async (values: RuleForm) => {
      startSpinner()
      try {
        const labels = [values.label1]
        if (values.label2) {
          labels.push(values.label2)
        }
        await createOrEditRule({ ...rule, ...values, labels })
        revalidate()
      } catch (error) {
        toast.error('Manipulating rule failed.')
      }
      closeModal()
      stopSpinner()
    },
    [rule]
  )

  /**
   * Deletes the rule by the given id.
   * @param ruleId
   */
  const onDelete = async (ruleId: string): Promise<void> => {
    startSpinner()
    try {
      await deleteRule(ruleId)
      revalidate()
    } catch (error) {
      toast.error('Deleting rule failed.')
    }
    stopSpinner()
  }

  /**
   * Opens a rule for edditing.
   * @param rule
   */
  const onEdit = (rule: Rule) => {
    setRule(rule)
    openForm(true)
  }

  return (
    <>
      <Accordion
        title="Rules"
        open
        onClick={() => void 0}
        arrow={false}
        headerComponent={
          <Button
            size="sm"
            variant="contained-2"
            onClick={() => {
              if (!availableChains.length) {
                return
              }
              openForm(true)
            }}
          >
            Add a Rule
          </Button>
        }
      >
        <Table
          inCard
          className={classes.table}
          cols={HEADER_COLUMNS}
          loading={isLoading}
          empty={!rules.length}
        >
          {rules.map((rule: Rule, index: number) => (
            <TableRow key={`rule-${index}`}>
              {COLUMNS.map((column) => (
                <TableCell key={column.key} align={column.align}>
                  {column.render && column.key
                    ? column.render(rule[column.key])
                    : rule[column.key] || 'N/A'}
                </TableCell>
              ))}
              <TableCell className={classes.actionsCell}>
                <Grid className={classes.iconsCell}>
                  <IconButton
                    size="sm"
                    onClick={() => {
                      onEdit(rule)
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="sm"
                    onClick={() => {
                      onDelete(rule.id)
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Accordion>
      <RuleDialog
        open={isFormOpen}
        onSubmit={handleRule}
        rule={rule}
        onClose={closeModal}
        availableChains={availableChains}
      />
    </>
  )
}
