import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'

import Button from '../buttons/Button'
import Dialog from './Dialog'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  actions: {
    paddingTop: '1.25rem',

    '& button': {
      '&:first-child': {
        marginRight: '1.25rem'
      }
    }
  }
})

interface WarnDeleteRowDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export function WarnDeleteRowDialog({
  onClose,
  open,
  onConfirm
}: WarnDeleteRowDialogProps) {
  const classes = useStyles()
  return (
    <Dialog
      height={200}
      open={open}
      onClose={onClose}
      title="A friendly check"
      variant="secondary"
    >
      <DialogBody>
        <Typography>Are you sure that you want to delete this row?</Typography>

        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          className={classes.actions}
        >
          <Button variant="contained-2" size="sm" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained-2"
            size="sm"
            color="secondary"
            onClick={() => {
              onConfirm()
              onClose()
            }}
          >
            Delete
          </Button>
        </Grid>
      </DialogBody>
    </Dialog>
  )
}
