import { useSpinner } from 'providers/SpinnerProvider'
import { toast } from 'react-toastify'
import Api from 'services/Api'
import { formatStatsFormValues } from 'utils/pnl'

export default function usePnlStatSingle() {
  const spinner = useSpinner()

  const onEdit = async (values, onSuccess) => {
    try {
      spinner.open()
      await Api.post(
        '/auth/cms/edit_save_pnl_stats',
        formatStatsFormValues(values)
      )
      toast.success('Successfully saved!')
      onSuccess?.()
      spinner.close()
    } catch (e) {
      spinner.close()
      console.error(e)
      toast.error('Oops error!')
    }
  }

  return {
    onEdit
  }
}
