import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import { AssetTokenEntity } from 'interfaces/asset-token'
import { useForm } from 'react-hook-form'

import Dialog, { DialogBaseProps } from './Dialog'
import DialogActions from './DialogActions'
import DialogBody from './DialogBody'

interface ContentProps extends DialogBaseProps {
  onSubmit: (
    asset: string,
    data: AssetTokenEntity,
    onError: (
      property: 'asset' | 'websiteUrl' | 'contractUrl' | 'description',
      message: string
    ) => void
  ) => void
  assetToken: AssetTokenEntity
}

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.25rem'
  }
})

function Content(props: ContentProps) {
  const classes = useStyles()
  const form = useForm<AssetTokenEntity>({
    defaultValues: {
      asset: props.assetToken.asset,
      description: props.assetToken.description || '',
      websiteUrl: props.assetToken.websiteUrl || '',
      contractUrl: props.assetToken.contractUrl || ''
    }
  })

  /**
   * Submits the form.
   * @param values - Form values
   */
  const handleSubmit = (values: AssetTokenEntity): void => {
    props.onSubmit(props.assetToken.asset, values, onError)
  }

  /**
   * On error callback.
   * @param property - Property's name.
   * @param message - Error message
   */
  const onError = (
    property: 'asset' | 'websiteUrl' | 'contractUrl' | 'description',
    message: string
  ): void => {
    form.setError(property, { type: 'validate', message })
  }

  return (
    <>
      <DialogBody>
        <Grid container className={classes.root}>
          <Input
            variant="secondary"
            label="Name *"
            placeholder="Token's name"
            {...form.register('asset', {
              required: 'Required.'
            })}
            error={form.formState.errors.asset?.message}
          />
          <Input
            variant="secondary"
            label="Website URL"
            placeholder="Website URL"
            {...form.register('websiteUrl')}
          />
          <Input
            variant="secondary"
            label="Contract URL"
            placeholder="Contract URL"
            {...form.register('contractUrl')}
          />
          <Input
            variant="secondary"
            label="Description"
            rows={3}
            placeholder="Token's description"
            {...form.register('description')}
          />
        </Grid>
      </DialogBody>

      <DialogActions>
        <Button
          size="sm"
          variant="contained-2"
          color="secondary"
          onClick={props.onClose}
        >
          Reset
        </Button>
        <Button
          size="sm"
          variant="contained-2"
          onClick={() => form.handleSubmit(handleSubmit)()}
        >
          Save
        </Button>
      </DialogActions>
    </>
  )
}

export function EditAssetTokenDialog(props: ContentProps) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      variant="secondary"
      title="Edit Asset Token"
      height={400}
      size="lg"
    >
      <Content {...props} />
    </Dialog>
  )
}
