import ChartCard from 'components/cards/ChartCard'
import LineChart from 'components/charts/LineChart'
import Tabs from 'components/tabs/Tabs'
import ChartTooltip from 'components/tooltips/ChartTooltip'
import useTopExchanges from 'hooks/useTopExchanges'
import { useMemo, useState } from 'react'
import { parseMoney } from 'utils/api'
import {
  addTotalKey,
  addTotalLegend,
  formatChartDate,
  formatChartMoney,
  getChartId,
  renderTooltipOptions
} from 'utils/charts'
import { getRandomColors } from 'utils/colors'
import { CURRENCY, RANGE } from 'utils/filters'
import { parseRangeChart } from 'utils/pools'
import { getExchangesKey, parseExchangesChart } from 'utils/proteus'
import { TOOLTIP } from 'utils/tooltip'

import { CURRENCIES, RANGES } from '../performance/data'

const COLORS = getRandomColors(20)

export default function DailyVolumeLine() {
  const [range, onRange] = useState(RANGE._7d)
  const [currency, onCurrency] = useState(CURRENCY._USD)

  const topExchanges = useTopExchanges()

  const key = getExchangesKey(topExchanges.exchanges)

  const chart = useMemo(
    () => parseExchangesChart(topExchanges.exchanges),
    [key]
  )

  const [rangeUsd, rangeEth] = useMemo(
    () => [
      parseRangeChart(range, chart.usd),
      parseRangeChart(range, chart.eth)
    ],
    [key, range, currency]
  )

  const chartData = currency === CURRENCY._USD ? rangeUsd : rangeEth

  return (
    <ChartCard
      title="Total Daily Volume"
      tooltip={<ChartTooltip content={TOOLTIP.LINE_DAILY_VOLUME} />}
      titleComponent={
        <Tabs
          width="auto"
          tabs={CURRENCIES}
          value={currency}
          onChange={onCurrency}
        />
      }
      headerComponent={
        <Tabs width="auto" tabs={RANGES} value={range} onChange={onRange} />
      }
    >
      <LineChart
        area
        memo={[key, range, currency].join('_')}
        id={getChartId('Total Daily Volume')}
        data={chartData}
        dataKey={['total']}
        colors={COLORS}
        loading={topExchanges.isLoading}
        noData={!chartData.length}
        reverseLegends={false}
        legends={addTotalLegend(chart.keys)}
        xTickFormatter={formatChartDate}
        yTickFormatter={(tick) => formatChartMoney(tick)}
        tooltipOptions={renderTooltipOptions(
          addTotalKey(chart.keys),
          false,
          COLORS,
          (e) => parseMoney(e, 2, true, currency)
        )}
      />
    </ChartCard>
  )
}
