import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Accordion from 'components/accordions/Accordion'
import Button from 'components/buttons/Button'
import AddCustomStrategyDialog from 'components/dialogs/AddCustomStrategyDialog'
import NftHoldingsTable from 'components/tables/NftHoldingsTable'
import NftStrategies from 'components/tables/NftStrategies'
import { useState } from 'react'

import NftView from '../../../components/tables/NftView'

const Components = {
  'NFT Holdings': NftHoldingsTable,
  'NFT Strategies': NftStrategies,
  'NFT View': NftView
}

const useStyles = makeStyles({
  button: {
    width: '180px',
    marginBottom: '20px',
    borderRadius: '15px'
  }
})

export default function NftTypes() {
  const classes = useStyles()

  const [active, onActive] = useState('')
  const [addDialog, setAddDialog] = useState(false)

  const Component = Components[active] || (() => null)

  return (
    <Grid container direction="column">
      <Button
        size="sm"
        className={classes.button}
        variant="contained-2"
        onClick={() => setAddDialog(true)}
      >
        Add NFT Strategy
      </Button>

      {['NFT Strategies', 'NFT Holdings', 'NFT View'].map((e) => (
        <Accordion
          key={e}
          title={e}
          open={active === e}
          onClick={() => onActive(active === e ? '' : e)}
        >
          <Component />
        </Accordion>
      ))}

      <AddCustomStrategyDialog
        open={addDialog}
        onClose={() => setAddDialog(false)}
      />
    </Grid>
  )
}
