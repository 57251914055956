import { createContext, PropsWithChildren, useContext } from 'react'

interface CmsPartnersContextProps {
  revalidate: any
}

const CmsPartnersContext = createContext<CmsPartnersContextProps>({
  revalidate: () => {}
})

export default function CmsPartnersProvider({
  children,
  ...props
}: PropsWithChildren<CmsPartnersContextProps>) {
  return (
    <CmsPartnersContext.Provider value={props}>
      {children}
    </CmsPartnersContext.Provider>
  )
}

export function useCmsPartnersContext() {
  return useContext(CmsPartnersContext)
}
