import Grid from '@material-ui/core/Grid'
import SelectStrategy from 'components/form/SelectStrategy'
import usePool from 'hooks/usePool'
import { useSpinner } from 'providers/SpinnerProvider'
import { useHistory, useParams } from 'react-router-dom'

import DashboardCards from './editDashboard/DashboardCards'
import InfoCard from './strategies/InfoCard'
import { useStrategiesStyles } from './strategies/styles'

interface Params {
  id?: string
}

export default function EditDashboard() {
  const classes = useStrategiesStyles()
  const history = useHistory()
  const params = useParams<Params>()

  const pool = usePool({ poolId: params.id })

  useSpinner(pool.isLoading)

  const handleChange = (id: string) => {
    history.push(`/premium/dashboard/${id}`)
  }

  return (
    <Grid container direction="column">
      <SelectStrategy
        value={params.id as string}
        className={classes.selectStrategy}
        onChange={(e) => handleChange(e?.value || '')}
      />
      <InfoCard pool={pool.pool} />
      <DashboardCards pool={pool.pool} />
    </Grid>
  )
}
