import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { DeleteIcon, EditIcon, InfoIcon, NotificationsIcon } from 'assets/icons'
import BookButton from 'components/buttons/BookButton'
import Button from 'components/buttons/Button'
import IconButton from 'components/buttons/IconButton'
import CellImage from 'components/images/CellImage'
import Table from 'components/table/Table'
import TableCell from 'components/table/TableCell'
import TablePagination from 'components/table/TablePagination'
import TableRow from 'components/table/TableRow'
import { UseSavedSearches } from 'hooks/useSavedSearches'
import { useMemo } from 'react'
import { TableCol } from 'types/components/table'
import { formatRows } from 'utils/assets'

const useStyles = makeStyles({
  assetCell: {
    '& svg': {
      marginRight: '0.5rem'
    }
  },
  iconsCell: {
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',

    '& a': {
      marginRight: '1.25rem'
    }
  },
  bookmarkCell: {
    display: 'flex',
    alignItems: 'center'
  },
  iconBtn: {
    '& svg': {
      width: 18,
      height: 18
    }
  }
})

interface SavedAssetsTableProps
  extends Pick<UseSavedSearches, 'savedSearches' | 'onRemoveSearch'> {
  onEdit: (id: string) => void
}

export default function SavedAssetsTable({
  savedSearches,
  onRemoveSearch,
  onEdit
}: SavedAssetsTableProps) {
  const classes = useStyles()
  const rows = formatRows(savedSearches)

  const COLS: TableCol[] = useMemo(
    () => [
      {
        title: (
          <Grid
            container
            alignItems="center"
            className={classes.assetCell}
            wrap="nowrap"
          >
            <DeleteIcon />
            <span>Asset</span>
          </Grid>
        )
      },
      { title: 'Farm' },
      { title: 'TVL Staked' },
      { title: 'APR (d)' },
      { title: 'APR (y)' },
      { title: 'Blockchain' },
      { title: 'Rewards' },
      {
        title: <TablePagination page={1} count={1} onPage={() => {}} />,
        align: 'right'
      }
    ],
    []
  )

  return (
    <Table cols={COLS} empty={!savedSearches.length}>
      {rows.map((row, index) => (
        <TableRow key={index}>
          <TableCell>
            <div className={classes.bookmarkCell}>
              <BookButton
                booked
                onUnBook={() => onRemoveSearch(row.assetId)}
                onBook={() => {}}
              />

              <CellImage src={row.imageUrl} />

              <span>{row.asset}</span>
            </div>
          </TableCell>
          <TableCell>{row.farmName || row.farm}</TableCell>
          <TableCell>{row.tvlStaked}</TableCell>
          <TableCell>{row.aprDaily}</TableCell>
          <TableCell>{row.aprYearly}</TableCell>
          <TableCell>{row.blockchain}</TableCell>
          <TableCell>{row.rewards}</TableCell>
          <TableCell align="right">
            <Grid className={classes.iconsCell}>
              <a
                href={row.investLink}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Button
                  variant="contained-2"
                  size="sm"
                  disabled={!row.investLink}
                >
                  Invest
                </Button>
              </a>

              <IconButton className={classes.iconBtn}>
                <InfoIcon />
              </IconButton>
              <IconButton
                className={classes.iconBtn}
                onClick={() => onEdit(row.assetId)}
              >
                <EditIcon />
              </IconButton>
              <IconButton className={classes.iconBtn}>
                <NotificationsIcon />
              </IconButton>
            </Grid>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
