export function dataToOptions(data): any[] {
  try {
    return data.map((row) => ({
      label: row,
      value: row
    }))
  } catch (e) {
    console.error(e)
    return []
  }
}

export function farmIdsToNames(farms: any[]) {
  const obj = {}

  farms.forEach((row) => {
    obj[row.farmId] = row.name
  })

  return obj
}

/**
 * Checks is value empty, null or undefined.
 * If value is array, checks if the length of the array is greater than zero.
 * @param value - Value to be checked.
 * @returns Is value empty boolean.
 */
export const isEmpty = (value: any): boolean => {
  if (
    value === null ||
    value === undefined ||
    value === '' ||
    value === 'null' ||
    value === 'undefined'
  ) {
    return true
  }

  // Validate the lenght of array.
  if (Array.isArray(value)) {
    return value.length === 0
  }

  return false
}
