import sortBy from 'lodash.sortby'
import { ChainsResponse } from 'services/types/chains'

import { reverse } from './obj'

export function formatChainsKey(chains: ChainsResponse) {
  const max: any[] = []

  Object.keys(chains).forEach((key) => {
    max.push({ key, max: chains[key]?.[0]?.value })
  })

  return reverse(sortBy(max, ['max']).map((row) => row.key))
}
