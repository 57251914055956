const ERROR_KEYS = {
  REQUIRED: 'REQUIRED'
}

const MESSAGES = {
  [ERROR_KEYS.REQUIRED]: 'This field is required'
}

export function requiredMsg() {
  return ERROR_KEYS.REQUIRED
}

export function getErrorMsg(errors: any, field: string) {
  return MESSAGES[errors?.[field]?.message]
}
