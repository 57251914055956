import { createContext, PropsWithChildren, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { setLogoutWorkaround } from '../services/Api'
import LocalStorage from '../utils/LocalStorage'

interface AuthContextProps {
  isLogged: boolean
  onLogout: () => void
  onLogin: (token: string) => void
}

const AuthContext = createContext({
  isLogged: false,
  onLogout: () => {},
  onLogin: () => {}
} as AuthContextProps)

export default function AuthProvider({ children }: PropsWithChildren<{}>) {
  const [isLogged, setLogged] = useState(!!LocalStorage.get('access_token'))
  const history = useHistory()

  const onLogout = () => {
    setLogged(false)
    LocalStorage.remove('access_token')
    history.push('/farms')
  }

  setLogoutWorkaround(onLogout)

  const onLogin = (token) => {
    setLogged(true)
    LocalStorage.set('access_token', token)
    history.push('/account')
  }

  return (
    <AuthContext.Provider value={{ isLogged, onLogout, onLogin }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
