import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import ScrollArrow, { ScrollArrowProps } from 'components/badge/ScrollArrow'
import { EmptyPlaceholder, LoadingPlaceholder } from 'components/placeholders'
import useContainerScroll from 'hooks/ui/useContainerScroll'
import { PropsWithChildren, useState } from 'react'
import { TableCol } from 'types/components/table'
import { uuid } from 'utils/uuid'

import TableHeadCell, { SortOrder } from './TableHeadCell'

export type OnTableSort = (name: string, order: SortOrder) => void

interface TableProps {
  cols: TableCol[]
  loading?: boolean
  empty?: boolean
  sort?: string
  sortOrder?: SortOrder
  onSort?: OnTableSort
  className?: string
  head?: boolean
  inCard?: boolean
  ScrollArrowProps?: Partial<ScrollArrowProps>
}

const useStyles = makeStyles({
  rootWrapper: {
    width: '100%',
    position: 'relative'
  },
  root: {
    minWidth: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 1.25rem',
    tableLayout: 'fixed'
  },
  tableContainer: (props: Partial<TableProps>) => ({
    padding: '0 0rem',
    width: 'fit-content',
    minWidth: '100%',

    ...(props.inCard && {
      padding: '0 0rem'
    })
  }),
  container: (props: Partial<TableProps>) => ({
    maxWidth: 'calc(100% + 0rem)',
    minWidth: 'calc(100% + 0rem)',
    width: 'auto',
    overflowY: 'auto',
    margin: '-1.25rem 0rem',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',

    '&::-webkit-scrollbar': {
      display: 'none'
    },

    ...(props.inCard && {
      maxWidth: 'calc(100% + 0rem)',
      minWidth: 'calc(100% + 0rem)',
      margin: '-1.25rem 0rem'
    })
  }),
  placeholder: {
    padding: '1.25rem 0'
  }
})

export default function Table({
  children,
  cols,
  loading,
  empty,
  sort,
  sortOrder,
  onSort,
  className,
  head,
  inCard,
  ScrollArrowProps
}: PropsWithChildren<TableProps>) {
  const [id] = useState(uuid())
  const classes = useStyles({ inCard })
  const containerScroll = useContainerScroll({ id })

  const handleSort = (row: TableCol) => {
    if (onSort) {
      onSort(
        row.name as string,
        row.name === sort ? (sortOrder === 'desc' ? 'asc' : 'desc') : 'desc'
      )
    }
  }

  return (
    <div className={classes.rootWrapper}>
      {containerScroll.scroll && (
        <>
          <ScrollArrow
            side="left"
            condition={containerScroll.leftScroll}
            onClick={() => containerScroll.scrollTo(-400)}
            {...ScrollArrowProps}
          />
          <ScrollArrow
            side="right"
            condition={containerScroll.rightScroll}
            onClick={() => containerScroll.scrollTo(400)}
            {...ScrollArrowProps}
          />
        </>
      )}

      <Grid
        container
        id={id}
        ref={containerScroll.ref}
        onScroll={containerScroll.onScroll}
        direction="column"
        className={classNames(classes.container, className)}
      >
        <div className={classes.tableContainer}>
          <table className={classes.root}>
            {head !== false && (
              <thead>
                <tr>
                  {cols.map((row, index) => (
                    <TableHeadCell
                      key={index}
                      width={row.width}
                      align={row.align}
                      maxWidth={row.maxWidth}
                      minWidth={row.minWidth}
                      title={row.title}
                      sortable={row.sortable}
                      sticky={row.sticky}
                      active={row.name && sort ? row.name === sort : false}
                      color={row.color}
                      sortOrder={sortOrder}
                      onClick={() => row.sortable && handleSort(row)}
                      wrap={row.wrap}
                      tooltip={row.tooltip}
                      border={row.border}
                    />
                  ))}
                </tr>
              </thead>
            )}

            <tbody>{children}</tbody>
          </table>
        </div>
      </Grid>

      {loading && <LoadingPlaceholder height={250} />}

      {empty && !loading && (
        <EmptyPlaceholder height={250} className={classes.placeholder} />
      )}
    </div>
  )
}
