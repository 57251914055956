import { getAssets } from 'services/api/assets'
import useSWR from 'swr'

function getKey() {
  return '/api/v2/trending/assets'
}


export default function useTrendingAssets() {

  const { data, error } = useSWR(() => getKey(), getAssets)

  const assets = data?.data || []

  return {
    assets,
  }
}
