import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import BookButton from 'components/buttons/BookButton'
import Button from 'components/buttons/Button'
import ReportErrorDialog from 'components/dialogs/ReportErrorDialog'
import HoldingGrowthTable from 'components/tables/HoldingsGrowthTable'
import { Link } from 'components/typography'
import useAsset from 'hooks/useAsset'
import useMediaQueries from 'hooks/useMediaQueries'
import useSavedSearches from 'hooks/useSavedSearches'
import { useAuth } from 'providers/AuthProvider'
import { useDialogsProvider } from 'providers/DialogsProvider'
import { useSpinner } from 'providers/SpinnerProvider'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TableColWithKey } from 'types/components/table'
import { nFormatter } from 'types/utils'
import { parseMoney, parsePercent } from 'utils/api'
import { mergeArrays } from 'utils/array'
import { renderTooltipOptions } from 'utils/charts'
import { getRandomColors } from 'utils/colors'
import { CURRENCY } from 'utils/filters'
import { reverse } from 'utils/obj'
import { device } from 'utils/screen'
import { getSearchesIds } from 'utils/searches'

import Apr from './asset/Apr'
import AssetChart from './asset/AssetChart'
import AssetLineChart from './asset/AssetLineChart'
import Contracts from './asset/Contracts'
import Fees from './asset/Fees'
import Info from './asset/Info'
import Liquidity from './asset/Liquidity'

const GRID_LAYOUT_DEFAULT = `
        "a a a a a b b b b b"
        "c c c c d d d d d d"
        "f f f f e e e e g g"
        "f f f f e e e e h h"
`

const RESPONSIVE_GRID_LAYOUT = `
        "a"
        "b"
        "c"
        "j"
        "e"
        "g"
        "f"
        "d"
        "h"
        "i"
`

const useStyles = makeStyles({
  content: {
    display: 'grid',
    gridTemplateAreas: GRID_LAYOUT_DEFAULT,
    gridTemplateColumns: 'repeat(10, 1fr)',
    gap: '1.25rem',

    '& > div': {
      minHeight: 0,

      '&:nth-child(1)': {
        gridArea: 'a'
      },
      '&:nth-child(2)': {
        gridArea: 'b'
      },
      '&:nth-child(3)': {
        gridArea: 'c'
      },
      '&:nth-child(4)': {
        gridArea: 'd'
      },
      '&:nth-child(5)': {
        gridArea: 'e'
      },
      '&:nth-child(6)': {
        gridArea: 'f'
      },
      '&:nth-child(7)': {
        gridArea: 'g'
      },
      '&:nth-child(8)': {
        gridArea: 'h'
      },
      '&:nth-child(9)': {
        gridArea: 'i'
      },
      '&:nth-child(10)': {
        gridArea: 'j'
      },
      '&:nth-child(11)': {
        gridArea: 'k'
      },
      '&:nth-child(12)': {
        gridArea: 'l'
      }
    },

    [`@media ${device.laptop}`]: {
      gridTemplateAreas: () => RESPONSIVE_GRID_LAYOUT,
      gridTemplateColumns: '1fr'
    }
  },
  titleContainer: {
    margin: '-1.25rem 0 1.25rem 0',

    [`@media ${device.tablet}`]: {
      margin: '-0.75rem 0 1rem 0',
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  title: {
    fontSize: '1.875rem',
    display: 'flex',
    alignItems: 'center',

    [`@media ${device.tablet}`]: {
      fontSize: '1.25rem',
      marginBottom: '0.5rem'
    }
  },
  book: {
    margin: '0 1rem'
  },
  error: {
    color: '#D733FF',
    fontWeight: 700,
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline'
    },

    [`@media ${device.tablet}`]: {
      fontSize: '0.875rem',
      marginBottom: '0.5rem'
    }
  },
  investButton: {
    width: '100%'
  },
  assetChartBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem'
  }
})

const COLS: TableColWithKey[] = [
  { title: '1d', align: 'center', key: '1d' },
  { title: '7d', align: 'center', key: '7d' },
  { title: '14d', align: 'center', key: '14d' },
  { title: '30d', align: 'center', key: '30d' },
  { title: '365d', align: 'center', key: '365d' }
]

export default function Asset() {
  const params = useParams<any>()
  const { isLogged } = useAuth()
  const dialogsProvider = useDialogsProvider()
  const [reportDialog, setReportDialog] = useState(false)

  const { isTablet } = useMediaQueries()

  const { asset, isLoading } = useAsset({ id: params.assetId })
  useSpinner(isLoading)

  const classes = useStyles({
    yieldType: asset.yieldType
  })

  const { savedSearches, onRemoveSearch, onSaveSearch } = useSavedSearches({
    save_type: 'asset'
  })
  const savedIds = getSearchesIds(savedSearches, 'assetId')

  const [aprChartData, aprChartKeys] = useMemo(() => {
    const data = reverse(asset.totalAPRYearlyHistory || asset.aprHistory || [])
    const assetFeeAprHistory = asset.feeAPRHistory || asset.feeAprHistory || []
    const baseRewardAprHistory = asset.baseRewardAPRHistories || {}
    const chartData = data.map(({ date, value: aprHistory }) => {
      const chartTick = {
        date,
        aprHistory,
        feeAprHistory: assetFeeAprHistory?.find((item) => item.date === date)
          ?.value
      }

      Object.keys(baseRewardAprHistory).forEach((token) => {
        chartTick[`Reward APR (${token})`] = baseRewardAprHistory[token].find(
          (item) => item.date === date
        )?.value
      })
      return chartTick
    })
    const chartKeys = chartData.length
      ? Object.keys(chartData[0]).filter((key) => key != 'date')
      : []
    return [chartData, chartKeys]
  }, [asset])

  if (isLoading) {
    return null
  }

  return (
    <>
      <Grid container direction="column">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.titleContainer}
        >
          {isTablet ? (
            <>
              <Typography className={classes.title}>
                <Link to={`/farms/${asset.farmId}`}>{asset.farm}</Link>
              </Typography>
              <Typography className={classes.title}>
                {asset.asset || asset.assetId}

                {isLogged && (
                  <BookButton
                    booked={savedIds.includes(asset.assetId)}
                    onBook={() => onSaveSearch(asset.assetId, 'asset')}
                    onUnBook={() => onRemoveSearch(asset.assetId)}
                    className={classes.book}
                  />
                )}
              </Typography>
            </>
          ) : (
            <Typography className={classes.title}>
              <Link to={`/farms/${asset.farmId}`}>{asset.farm}</Link>:{' '}
              {asset.asset || asset.assetId}
              {isLogged && (
                <BookButton
                  booked={savedIds.includes(asset.assetId)}
                  onBook={() => onSaveSearch(asset.assetId, 'asset')}
                  onUnBook={() => onRemoveSearch(asset.assetId)}
                  className={classes.book}
                />
              )}
            </Typography>
          )}

          <Typography
            className={classes.error}
            onClick={() => setReportDialog(true)}
          >
            Report an error
          </Typography>
        </Grid>

        <Grid container direction="column" className={classes.content}>
          <div className={classes.assetChartBlock}>
            <AssetChart
              title="Asset TVL"
              data={reverse(asset.tvlStakedHistory || [])}
              yTickFormatter={(tick) => nFormatter(tick)}
              tooltipOptions={renderTooltipOptions(
                ['value'],
                false,
                getRandomColors(1),
                (e) => parseMoney(e, 2, true, CURRENCY._USD)
              )}
              hasRange
            />
            {asset.statistics?.tvlStability && (
              <HoldingGrowthTable
                data={asset.statistics.tvlStability}
                columns={COLS}
              />
            )}
          </div>
          <div className={classes.assetChartBlock}>
            <AssetLineChart
              title="Asset APR (y)"
              keys={aprChartData.length ? ['aprHistory'] : []}
              tooltipKeys={aprChartKeys}
              data={aprChartData}
              yTickFormatter={(tick) => parsePercent(tick)}
              hasRange
            />
            {asset.statistics?.aprStability && (
              <HoldingGrowthTable
                data={asset.statistics.aprStability}
                columns={COLS}
              />
            )}
          </div>

          {false && asset.yieldType === '3,3' && (
            <>
              <AssetChart
                title="Staked Token Holders"
                data={reverse(asset.tokenAHoldersHistory || [])}
                yTickFormatter={(tick) => nFormatter(tick)}
              />
              <AssetChart
                title="Token Price"
                data={reverse(asset.tokenAPriceHistory || [])}
                yTickFormatter={(tick) => parseMoney(tick)}
              />
            </>
          )}

          <Info asset={asset} />
          <Contracts
            contracts={mergeArrays(asset.token_info, asset.reward_token_info)}
          />
          <Liquidity
            asset={asset}
            isLogged={isLogged}
            onPro={() => dialogsProvider.onLogin(true)}
          />
          <Apr asset={asset} />
          <Fees asset={asset} />
          <div>
            <a
              href={asset.links?.staking || null}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="contained-2"
                className={classes.investButton}
                disabled={!asset.links?.staking}
              >
                Invest
              </Button>
            </a>
          </div>
        </Grid>
      </Grid>

      <ReportErrorDialog
        open={reportDialog}
        onClose={() => setReportDialog(false)}
        id={asset.assetId}
        title="Report an Error (For this asset)"
        titleColor="error"
        idKey="assetId"
      />
    </>
  )
}
