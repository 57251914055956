import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { PropsWithChildren } from 'react'

const useStyles = makeStyles({
  root: {
    width: 32,
    height: 32,
    borderRadius: 10,
    fontSize: '0.75rem',
    color: '#fafafa',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(110.29deg, #D733FF -3.21%, #66308B 105.5%)',
    fontWeight: 700,
    marginRight: '0.5rem'
  }
})

export default function Badge({ children }: PropsWithChildren<any>) {
  const classes = useStyles()
  return <Grid className={classes.root}>{children}</Grid>
}
