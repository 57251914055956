import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { CaretDown, CloseIcon } from 'assets/icons'
import classNames from 'classnames'
import Button from 'components/buttons/Button'
import Dialog from 'components/dialogs/Dialog'
import DialogBody from 'components/dialogs/DialogBody'
import DialogList from 'components/dialogs/DialogList'
import DialogListItem from 'components/dialogs/DialogListItem'
import DialogSearch from 'components/dialogs/DialogSearch'
import DrawerActions from 'components/drawers/DrawerActions'
import { EmptyPlaceholder } from 'components/placeholders'
import { useSearch } from 'hooks/useSearch'
import { ChangeEvent, useEffect, useState } from 'react'
import { Option } from 'types/components/form'
import { replaceUnterminatedChars, searchHighlight } from 'utils/strings'

import ChartTooltip from '../tooltips/ChartTooltip'
import Label from './Label'

const useStyles = makeStyles({
  root: {},
  input: (props: any) => ({
    height: 40,
    borderRadius: 15,
    padding: '0 1.25rem',
    backgroundColor: '#373D66',
    cursor: 'pointer',

    '& p': {
      fontWeight: 700
    },

    ...(props.variant === 'secondary' && {
      fontFamily: 'Roboto',
      borderRadius: 15,
      backgroundColor: '#373D66',
      border: '1px solid rgba(255,255,255,0.4)',

      '& p': {
        fontSize: '0.875rem',
        fontWeight: 400,

        '&[data-type="placeholder"]': {
          color: 'rgba(250, 250, 250, 0.4)'
        }
      }
    }),

    ...(props.error && {
      border: '1px solid red'
    })
  }),
  inputComponent: {
    background: 'transparent',
    border: 'none',
    height: '100%',
    padding: 0,
    flexGrow: 1,
    color: 'rgb(250, 250, 250)',
    fontSize: '0.875rem',
    fontFamily: 'Roboto',
    outline: 'none',

    '&:disabled': {
      cursor: 'pointer'
    }
  },
  inputActions: {
    display: 'flex',
    alignItems: 'center',

    '& > svg': {
      width: 12,
      height: 8,
      marginRight: '0.75rem',

      '&:last-child': {
        marginRight: 0
      }
    }
  },
  label: {
    fontSize: '0.875rem',
    color: '#fafafa',
    marginBottom: '0.5rem',
    display: 'flex',
    alignItems: 'center'
  }
})

export interface SelectProps {
  className?: string
  inputClassName?: string
  options: Option[]
  placeholder?: string
  onChange?: (option: Option | null) => void
  value?: Option | string | boolean
  searchable?: boolean
  clearable?: boolean
  label?: string
  error?: string
  variant?: 'secondary'
  tooltip?: string
  editable?: boolean
}

const getValueLabel = (
  value: string | boolean | Option | null | undefined,
  options: Option[]
): string => {
  if (value === null || value === undefined) {
    return ''
  }

  if (typeof value === 'boolean' || typeof value === 'string') {
    const option = options.find((item) => item.value === value)
    return option?.label || value.toString()
  }

  return value.label
}

export default function Select({
  className,
  inputClassName,
  options,
  placeholder = 'Select',
  value,
  onChange,
  searchable = true,
  label,
  error,
  variant,
  clearable,
  tooltip,
  editable = false
}: SelectProps) {
  const classes = useStyles({ variant, error })
  const [open, setOpen] = useState(false)
  const { search, onSearch } = useSearch()
  const [innerValue, setInnerValue] = useState<
    string | boolean | Option | undefined
  >(value)

  useEffect(() => {
    if (value !== innerValue) {
      setInnerValue(value)
    }
  }, [value])

  const handleSave = () => {
    onChange?.(innerValue as Option)
    setOpen(false)
  }

  const handleCancel = () => {
    setInnerValue(value)
    setOpen(false)
  }

  const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) {
      return
    }
    const newValue: Option = { value: target.value, label: target.value }
    onChange(newValue)
  }

  const valueLabel: string = getValueLabel(value, options)

  const searched = options.filter(
    (option) =>
      !!option.label?.match(new RegExp(replaceUnterminatedChars(search), 'gi'))
  )

  return (
    <>
      <Grid
        container
        direction="column"
        className={classNames(classes.root, className)}
      >
        {label && (
          <Label error={error}>
            {`${label}${error ? `: ${error}` : ''}`}
            {tooltip && <ChartTooltip content={tooltip} />}
          </Label>
        )}

        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          className={classNames(classes.input, inputClassName)}
          onClick={() => setOpen(true)}
        >
          <input
            className={classes.inputComponent}
            value={valueLabel}
            placeholder={placeholder}
            onChange={handleInputChange}
            onClick={(e) => {
              e.stopPropagation()
            }}
            disabled={!editable}
          />

          <div className={classes.inputActions}>
            {clearable && !!valueLabel && (
              <CloseIcon
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onChange?.(null)
                }}
              />
            )}

            <CaretDown />
          </div>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        height={500}
        mobileHeight="70vh"
        variant="secondary"
        title="Select"
        onClose={() => setOpen(false)}
      >
        {searchable && <DialogSearch onSearch={onSearch} />}

        <DialogBody>
          {searched.length ? (
            <DialogList>
              {searched.map((option, index) => (
                <DialogListItem
                  name={searchHighlight(option.label, search)}
                  key={index}
                  onClick={() => setInnerValue(option)}
                  active={
                    innerValue
                      ? ['string', 'boolean'].includes(typeof innerValue)
                        ? option.value === innerValue
                        : option.value === (innerValue as Option).value
                      : false
                  }
                />
              ))}
            </DialogList>
          ) : (
            <EmptyPlaceholder />
          )}
        </DialogBody>

        <DrawerActions>
          <Button
            variant="contained-2"
            color="secondary"
            size="sm"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button variant="contained-2" size="sm" onClick={handleSave}>
            Apply
          </Button>
        </DrawerActions>
      </Dialog>
    </>
  )
}
