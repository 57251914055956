import Api from '../Api'

export async function getFarms(url) {
  const response = await Api.get(url)
  return response.data
}

export async function getFarm(url) {
  const response = await Api.get(url)
  return response.data
}
