/**
 * Merges two arrays that are both possibly undefined.
 * @param array1
 * @param array2
 * @returns Merged array.
 */
export const mergeArrays = (array1?: any[], array2?: any[]): any[] => {
  if (array1 && array2) return [...array1, ...array2]
  if (array1) return array1
  if (array2) return array2
  return []
}
