import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { TableCol } from 'types/components/table'

const BG_COLORS = {
  secondary: '#39406A',
  pool: '#343A60',
  ad: '#354E7B'
}

interface TableCellProps
  extends Pick<
    TableCol,
    'align' | 'minWidth' | 'maxWidth' | 'wrap' | 'border'
  > {
  colSpan?: number
  color?: 'secondary' | 'ad' | 'pool'
  className?: string
  sticky?: number
}

const useStyles = makeStyles({
  root: (props: Partial<TableCellProps>) => ({
    backgroundColor: BG_COLORS[props.color as string] || '#303659',
    minWidth: props.minWidth,
    maxWidth: props.maxWidth,
    whiteSpace: (props.wrap || 'nowrap') as any,

    ...(typeof props.sticky === 'number' && {
      position: 'sticky',
      left: props.sticky
    }),

    ...(props.border === 'right' && {
      borderRight: '1px solid #41486E'
    })
  })
})

export default function TableCell({
  children,
  align = 'left',
  minWidth,
  maxWidth,
  wrap,
  colSpan,
  color,
  className,
  sticky,
  border
}: PropsWithChildren<TableCellProps>) {
  const classes = useStyles({ minWidth, maxWidth, wrap, color, sticky, border })
  return (
    <td
      className={classNames(classes.root, className)}
      align={align}
      colSpan={colSpan}
    >
      {children}
    </td>
  )
}
