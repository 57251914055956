import { useCmsPartnersContext } from 'providers/CmsPartnersProvider'
import { useSpinner } from 'providers/SpinnerProvider'
import { toast } from 'react-toastify'
import { addPartner, removePartner } from 'services/api/cms'
import { fetcher } from 'services/api/common'
import useSWR from 'swr'
import { stringifyURL } from 'utils/query'

type OnAdd = (data: any, onSuccess?: any) => void
type OnRemove = (id: string, onSuccess?: any) => void

interface UseCmsPartnerProps {
  onAdd: OnAdd
  onRemove: OnRemove
  isLoading: boolean
  partner: any
}

interface UseCmsPartnerConfig {
  id?: string
  revalidate?: any
}

function getKey(params) {
  return params.item_id
    ? stringifyURL('/auth/cms/get_single_partnership', params)
    : null
}

export default function useCmsPartner(
  config: UseCmsPartnerConfig = {}
): UseCmsPartnerProps {
  const spinner = useSpinner()
  const cmsPartnersContext = useCmsPartnersContext()

  const params = {
    item_id: config.id
  }

  const {
    data,
    error,
    mutate: revalidate
  } = useSWR(() => getKey(params), fetcher)

  const onAdd: OnAdd = async (data, onSuccess) => {
    try {
      spinner.open()

      const values = { ...data }

      if (!values.image) {
        delete values.image
      }

      await addPartner(values)

      toast.success('Partner successfully added')
      cmsPartnersContext.revalidate?.()
      revalidate?.()
      onSuccess?.()

      spinner.close()
    } catch (e) {
      spinner.close()
      console.error(e)
      toast.error('Oops error!')
    }
  }

  const onRemove: OnRemove = async (id, onSuccess) => {
    try {
      spinner.open()

      await removePartner(id)
      toast.success('Partner successfully removed')

      cmsPartnersContext.revalidate?.()
      config.revalidate?.()
      onSuccess?.()

      spinner.close()
    } catch (e) {
      spinner.close()
      toast.error('Oops error!')
    }
  }

  const isLoading = !data && !error
  const partner = data || {}

  return {
    onAdd,
    onRemove,
    isLoading,
    partner
  }
}
