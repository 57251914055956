import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'

import { device } from '../../utils/screen'

const useStyles = makeStyles({
  root: (props: any) => ({
    width: '100%',
    height: 60,
    minHeight: 60,
    cursor: props.action ? 'pointer' : 'default',

    '& td': {
      fontSize: '0.875rem',
      padding: '0.5rem 0.5rem',

      '&:first-child': {
        borderTopLeftRadius: '1rem',
        borderBottomLeftRadius: '1rem',
        paddingLeft: '2.5rem'
      },
      '&:last-child': {
        borderTopRightRadius: '1rem',
        borderBottomRightRadius: '1rem',
        paddingRight: '2.5rem'
      },

      [`@media ${device.tablet}`]: {
        fontSize: '0.75rem'
      }
    },

    [`@media ${device.tablet}`]: {
      height: 40,
      minHeight: 40
    },

    ...(props.size === 'sm' && {
      height: 40,
      minHeight: 40,

      '& td': {
        fontSize: '0.875rem',
        padding: '0.5rem 0.5rem',

        '&:first-child': {
          borderTopLeftRadius: '0.75rem',
          borderBottomLeftRadius: '0.75rem',
          paddingLeft: '1.75rem'
        },
        '&:last-child': {
          borderTopRightRadius: '0.75rem',
          borderBottomRightRadius: '0.75rem',
          paddingRight: '1.75rem'
        },

        [`@media ${device.tablet}`]: {
          fontSize: '0.75rem'
        }
      },

      [`@media ${device.tablet}`]: {
        height: 35,
        minHeight: 35
      }
    })
  })
})

interface TableRowProps {
  id?: string
  size?: 'sm'
  onClick?: any
  action?: boolean
  className?: string
}

export default function TableRow({
  children,
  size,
  onClick,
  action,
  className,
  id
}: PropsWithChildren<TableRowProps>) {
  const classes = useStyles({ size, action })
  return (
    <tr
      id={id}
      className={classNames(classes.root, className)}
      onClick={onClick}
    >
      {children}
    </tr>
  )
}
