import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useReportError from 'hooks/useReportError'
import { useState } from 'react'

import Button from '../buttons/Button'
import Input from '../form/Input'
import Dialog, { DialogProps } from './Dialog'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  close: {
    position: 'absolute',
    right: 15,
    top: 15,
    cursor: 'pointer',
    zIndex: 1
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    padding: '2rem 1.5rem 0.5rem 1.5rem',
    color: '#ff5733'
  },
  input: {
    marginBottom: '1.25rem'
  }
})

interface ReportErrorDialogProps {
  open: boolean
  onClose: () => void
  id: string
  idKey: string
}

export default function ReportErrorDialog({
  open,
  onClose,
  id,
  idKey,
  title = 'Report an Error',
  titleColor
}: ReportErrorDialogProps & Pick<DialogProps, 'title' | 'titleColor'>) {
  const classes = useStyles()
  const [message, setMessage] = useState('')

  const { onReportError } = useReportError()

  const handleSubmit = () => {
    onReportError({ [idKey]: id, message }, onClose)
  }

  return (
    <Dialog
      open={open}
      variant="secondary"
      title={title}
      onClose={onClose}
      height={295}
      mobileHeight={false}
      titleColor={titleColor}
    >
      <DialogBody>
        <Grid container direction="column">
          <Input
            placeholder="Type here..."
            variant="secondary"
            className={classes.input}
            rows={6}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <Button
            variant="contained-2"
            color="secondary"
            size="sm"
            onClick={handleSubmit}
          >
            Send
          </Button>
        </Grid>
      </DialogBody>
    </Dialog>
  )
}
