import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Button from 'components/buttons/Button'
import { EmptyPlaceholder } from 'components/placeholders'
import TooltipDropdown from 'components/TooltipDropdown'
import useUploadFile from 'hooks/useUploadFile'
import useUsersIcons from 'hooks/useUserIcons'
import { useSpinner } from 'providers/SpinnerProvider'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import Dialog from './Dialog'
import DialogActions from './DialogActions'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '1.25rem'
  },
  removeBtn: {
    width: 20,
    height: 20,
    position: 'absolute',
    color: '#FF441B',
    top: 8,
    right: 8,
    opacity: 0.75
  },
  iconContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem 0',
    cursor: 'pointer',
    borderRadius: 8,
    position: 'relative',

    '& svg[data-remove]': {
      visibility: 'hidden'
    },

    '&:hover, &[data-active="true"]': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)'
    },

    '&:hover': {
      '& svg[data-remove]': {
        visibility: 'visible'
      }
    },

    '& img': {
      width: 75,
      height: 75,
      objectFit: 'contain'
    }
  }
})

interface UserIconsDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: (url: string) => void
  initialUrl: string
  title?: string
}

function Content(props: UserIconsDialogProps) {
  const classes = useStyles()
  const uploadFile = useUploadFile()
  const usersIcons = useUsersIcons()

  const [innerUrl, setInnerUrl] = useState(props.initialUrl || '')

  useSpinner(usersIcons.isLoading || uploadFile.isUploadLoading)

  const onDrop = useCallback((files) => {
    uploadFile.onUpload(files[0], (url) => {
      usersIcons.onAdd(url)
    })
  }, [])

  const dropzone = useDropzone({
    onDrop,
    accept: ['image/svg+xml', 'image/png'],
    multiple: false
  })

  return (
    <>
      <DialogBody>
        {!usersIcons.icons.length ? (
          <EmptyPlaceholder text="No icons yet. Upload new." />
        ) : (
          <Grid container className={classes.content}>
            {usersIcons.icons.map((row) => (
              <Grid
                key={row}
                data-active={row === innerUrl}
                className={classes.iconContainer}
                onClick={() => setInnerUrl(row)}
              >
                <TooltipDropdown content="Remove">
                  <DeleteOutlineIcon
                    data-remove={1}
                    className={classes.removeBtn}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      usersIcons.onRemove(row)
                    }}
                  />
                </TooltipDropdown>

                <img src={row} alt="" />
              </Grid>
            ))}
          </Grid>
        )}
      </DialogBody>

      <DialogActions>
        <Button
          variant="contained-2"
          size="sm"
          color="secondary"
          onClick={() => props.onConfirm('')}
        >
          Reset
        </Button>

        <Button
          variant="contained-2"
          size="sm"
          color="primary"
          {...dropzone.getRootProps()}
        >
          <input {...dropzone.getInputProps()} />
          Upload
        </Button>

        <Button
          variant="contained-2"
          size="sm"
          color="primary"
          onClick={() => props.onConfirm(innerUrl)}
        >
          Confirm
        </Button>
      </DialogActions>
    </>
  )
}

export default function UserIconsDialog(props: UserIconsDialogProps) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={props.title || 'Icons Library'}
      variant="secondary"
      height={500}
    >
      <Content {...props} />
    </Dialog>
  )
}
