import Grid from '@material-ui/core/Grid'
import usePremiumPages from 'hooks/ui/usePremiumPages'
import useDefaultFields from 'hooks/useDefaultFields'
import { Redirect, Route, Switch } from 'react-router-dom'

import Tabs from './premium/Tabs'

export default function Premium() {
  useDefaultFields()
  const premiumPages = usePremiumPages()

  return (
    <Grid container direction="column">
      <Tabs />

      <Switch>
        {premiumPages.pages.map((row, index) => (
          <Route exact path={`/premium/${row.regex || row.path}`} key={index}>
            <row.Component />
          </Route>
        ))}
        {premiumPages.pages[0] && (
          <Redirect to={`/premium/${premiumPages.pages[0].path}`} />
        )}
      </Switch>
    </Grid>
  )
}
