import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useState } from 'react'

import Button from '../buttons/Button'
import Input from '../form/Input'
import Dialog from './Dialog'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  input: {
    marginBottom: '1.25rem'
  }
})

interface AddAPRDialogProps {
  open: boolean
  onClose: () => void
  onApply: (value: any) => void
}

export default function AddAPRDialog({
  open,
  onClose,
  onApply
}: AddAPRDialogProps) {
  const classes = useStyles()
  const [name, setName] = useState('')
  const [value, setValue] = useState('')

  const saveData = () => {
    onApply({ name, value: +value })

    setValue('')
    setName('')
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setValue('')
        onClose()
      }}
      title="Add APR"
      variant="secondary"
      height={320}
      size="sm"
    >
      <DialogBody>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="secondary"
          label="APR Name"
          placeholder="Custom APR"
          className={classes.input}
        />

        <Input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          variant="secondary"
          label="APR Value"
          placeholder="1.0"
          className={classes.input}
        />

        <Grid container justifyContent="flex-end">
          <Button variant="contained-2" size="sm" onClick={saveData}>
            Save APR
          </Button>
        </Grid>
      </DialogBody>
    </Dialog>
  )
}
