import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from 'components/cards/Card'
import PieChart from 'components/charts/PieChart'
import useDistributionPerPartner from 'hooks/useDistributionPerPartner'
import { useMemo } from 'react'
import { TableCol } from 'types/components/table'
import { formatBlockchain, parseMoney, parsePercent } from 'utils/api'
import { getRandomColors } from 'utils/colors'
import { getPartnersKey, parsePartnersPieChart } from 'utils/proteus'

import ChartTable, { Cell, Row } from '../portfolio/ChartTable'
import { useStrategiesStyles } from '../strategies/styles'

const COLORS = getRandomColors(20)

const COLS: TableCol[] = [
  { title: '' },
  { title: '' },
  { title: '' },
  { title: '' }
]

export default function LiquidityPerChainPie() {
  const classes = useStrategiesStyles()
  const distributionPerPartner = useDistributionPerPartner()

  const key = getPartnersKey(distributionPerPartner.keys)

  const chart = useMemo(
    () =>
      parsePartnersPieChart(
        distributionPerPartner.percentage,
        COLORS,
        distributionPerPartner.values
      ),
    [key]
  )

  return (
    <Card container direction="row" alignItems="center" justifyContent="center">
      <PieChart
        data={chart.chart}
        dataKey="value"
        tooltipTitle={(payload) => payload?.key}
        tooltipText={(payload) => parsePercent(payload?.value)}
      />

      <Grid className={classes.pieTable}>
        <Typography className={classes.pieTitle}>
          gOHM Liquidity per Chain
        </Typography>
        <ChartTable cols={COLS} head={false}>
          {chart.chart.map((row, index) => (
            <Row key={index}>
              <Cell color={row.color}>
                <span />
              </Cell>
              <Cell>{formatBlockchain(row.key)}</Cell>
              <Cell>{parseMoney(row.tvl, 2, true)}</Cell>
              <Cell align="right">
                <span>{parsePercent(row.value)}</span>
              </Cell>
            </Row>
          ))}
        </ChartTable>
      </Grid>
    </Card>
  )
}
