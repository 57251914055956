import { EditIcon } from 'assets/icons'
import IconButton from 'components/buttons/IconButton'
import ChartCard from 'components/cards/ChartCard'
import EditPnlDialog from 'components/dialogs/EditPnlDialog'
import InfoList, { InfoListItem } from 'components/lists/InfoList'
import { useState } from 'react'
import { parseMoney } from 'utils/api'

interface PnlProps {
  pool: any
  pnl: any
}

export default function Pnl(props: PnlProps) {
  const [edit, onEdit] = useState()
  return (
    <>
      <ChartCard
        title="PnL"
        titleComponent={
          <IconButton size="sm" onClick={() => onEdit(props.pool.poolId)}>
            <EditIcon />
          </IconButton>
        }
      >
        <InfoList>
          <InfoListItem
            title="Initial Investment"
            value={parseMoney(props.pnl.initial_investment)}
          />
          <InfoListItem
            title="Current Value"
            value={parseMoney(props.pnl.current_value)}
          />
          <InfoListItem
            title="Out"
            value={props.pnl.is_sold ? 'Sold' : parseMoney(props.pnl.out)}
          />
          <InfoListItem
            title="P/L"
            value={parseMoney(props.pnl.profit_or_loss)}
          />
          <InfoListItem title="Notes" value={props.pnl.notes || 'N/A'} />
        </InfoList>
      </ChartCard>
      <EditPnlDialog
        open={!!edit}
        poolId={props.pool.poolId}
        onClose={() => onEdit(undefined)}
      />
    </>
  )
}
