import { parseURL } from 'utils/query'

import Api from '../Api'

export async function getSavedSearches(strUrl) {
  const { url, query } = parseURL(strUrl)
  const response = await Api.post(url, query)
  return response.data
}

export async function getSavedSearch(strUrl) {
  const { url, query } = parseURL(strUrl)
  const response = await Api.post(url, query)
  return response.data
}

export async function removeSearch(id) {
  const response = await Api.post('/auth/remove_saved_search', {
    saved_id: id
  })
  return response.data
}

export async function saveSearch(id, type) {
  const response = await Api.post('/auth/save_search', {
    saved_id: id,
    save_type: type
  })
  return response.data
}
