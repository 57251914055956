import Grid from '@material-ui/core/Grid'

import TooltipDropdown from '../components/TooltipDropdown'
import { parseMoney } from './api'
import { CHART_TOOLTIP_DATE_PRIMARY, DATE_CHART_FORMAT, format } from './date'
import { reverse } from './obj'
import { capitalize, loweralize } from './strings'

export function renderGradients(
  bar: boolean,
  chartId: string,
  dataKey: string[],
  colors: string[]
) {
  return (
    <defs>
      {dataKey.map((key, index) => (
        <linearGradient
          key={index}
          id={`${chartId}-color-${index}`}
          x1="0"
          y1="0"
          x2="0"
          y2="1"
        >
          <stop offset="5%" stopColor={colors[index]} stopOpacity={0.8} />
          <stop
            offset="95%"
            stopColor={colors[index]}
            stopOpacity={bar ? 0.2 : 0}
          />
        </linearGradient>
      ))}
    </defs>
  )
}

export function formatChartDate(date) {
  return date && date !== 'auto'
    ? format(new Date(date), DATE_CHART_FORMAT)
    : 'N/A'
}

type DateParse = (date: string) => Date

export function formatChartTooltipDate(date, dateParse?: DateParse) {
  return date
    ? format(
        dateParse ? dateParse(date) : new Date(date),
        CHART_TOOLTIP_DATE_PRIMARY
      )
    : 'N/A'
}

export function renderTooltipOptions(
  keys: Array<string | [string, string]>,
  revert: boolean,
  colors: string[],
  format: (e: number) => string,
  separateTotal?: string,
  dateParse?: DateParse
) {
  const _colors = colors.slice(0, keys.length)
  const __colors = revert ? reverse(_colors) : _colors
  return [
    ...(revert ? reverse(keys) : keys).map((key, index) => (payload) => {
      const [dataKey, label] = Array.isArray(key) ? key : [key, key]
      return (
        <>
          <span
            data-color={1}
            style={{
              backgroundColor: __colors[index]
            }}
          />
          <span>{`${formatTooltipKey(label)}: ${format(
            payload[dataKey]
          )}`}</span>
        </>
      )
    }),
    (payload) => (
      <span data-footer={1}>
        {separateTotal && (
          <span>{`${formatTooltipKey(separateTotal)}: ${format(
            payload[separateTotal]
          )}`}</span>
        )}
        <span>{`${formatChartTooltipDate(payload.date, dateParse)}`}</span>
      </span>
    )
  ]
}

export function renderLegends(legends, revert, colors, classes) {
  const _colors = colors.slice(0, legends.length)
  const __colors = revert ? reverse(_colors) : _colors
  return (
    <Grid container direction="column">
      {(revert ? reverse(legends) : legends).map((key, index) => (
        <Grid
          container
          alignItems="center"
          className={classes.legend}
          key={index}
        >
          <span data-legend={1} style={{ backgroundColor: __colors[index] }} />

          <TooltipDropdown content={formatTooltipKey(key)}>
            <p>{formatTooltipKey(key)}</p>
          </TooltipDropdown>
        </Grid>
      ))}
    </Grid>
  )
}

export function getChartId(title) {
  return title.replace(/\s/g, '-').toLowerCase()
}

export function addTotalLegend(legends) {
  return ['total', ...legends]
}

export function addTotalKey(keys) {
  return ['total', ...keys]
}

const TOOLTIP_KEYS = {
  currentHistorical: 'Current',
  runway2dot5kHistorical: '2.5K',
  runway5kHistorical: '5K',
  runway7dot5kHistorical: '7.5K',
  treasuryOhmDaiPOL: 'SLP Treasury',
  treasuryOhmFraxPOL: 'Market SLP',
  impLoss30dHistorical: 'Imp. Loss Last 30d',
  netROILast30dHistorical: 'Net ROI Last 30d',
  feeLast30dHistorical: 'Fees Last 30d',
  feePercentageLast30dHistorical: 'Fees Last 30d (%)',
  pairPriceSTD30dHistorical: 'Standard Deviation Last 30d (Token A vs Token B)',
  lpPriceSTD30dHistorical: 'Standard Deviation Last 30d (LP Price vs USD)',
  netPrincipalROILast30dHistorical: 'Net Principal ROI Last 30d (against USD)',
  lpingVsHODLHistorical: 'LPing Return vs HODL in the last 30d',
  liquidityUSDHistorical: 'Liquidity',
  dailyVolumeUSDHistorical: 'Trading Volume',
  dailyFeeHistorical: 'Fees',
  lpPriceHistorical: 'LP',
  LPingPrice30dHistorical: 'LP',
  LPingPrice7dHistorical: 'LP',
  HODLPriceHistorical: 'HODL',
  HODLPrice30dHistorical: 'HODL',
  HODLPrice7dHistorical: 'HODL',
  dailyAPRHistorical: 'Daily APR',
  aprHistory: 'Total APR',
  feeAprHistory: 'Fee APR',
  rewardAprHistory: 'Reward APR'
}

export function formatTooltipKey(key) {
  return (
    TOOLTIP_KEYS[key] ||
    (['total', 'value', 'other'].includes(key) ? capitalize(key) : key)
  )
}

export function formatChartMoney(field: number | string, currency?: string) {
  return parseMoney(field, 0, true, currency)
}

const DAO_DEFINED = {
  sFDT: 'Fiat DAO',
  Klima: 'Klima DAO'
}

export function formatDaoKey(key) {
  return DAO_DEFINED[key] || key.replace(/(Dao|dao)/g, 'DAO')
}

export function formatWKey(key) {
  return key
    .replace(/(WFTM|WETH|WAVAX)/, (e) => loweralize(e))
    .replace('wFTM-gOHM', 'gOHM-FTM')
}

export function formatSushiswapKey(key) {
  return key.replace(/(Sushiswap|sushiswap)/, 'SushiSwap')
}

export function calcTickWidth(tick, formatter) {
  return `${formatter(tick)}`.length * 10
}

export function calcAxisWidth(
  data: Record<string, string | number>[],
  formatter: any
) {
  return Math.max(
    calcTickWidth(
      Math.max(
        ...data.map(({ date: _date, ...row }) =>
          Math.max(...Object.values(row as Record<string, number>))
        )
      ),
      formatter
    ),
    60
  )
}
