import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { InfoIcon } from 'assets/icons'
import classNames from 'classnames'
import Card from 'components/cards/Card'
import TooltipDropdown from 'components/TooltipDropdown'
import { parsePercent } from 'utils/api'
import { device } from 'utils/screen'

import Title from './Title'

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',

    [`@media ${device.laptopL}`]: {
      gridTemplateColumns: '1fr'
    }
  },
  text: {
    fontSize: '0.75rem',
    fontWeight: 400,
    marginBottom: '0.75rem',

    '& span': {
      fontWeight: 700
    }
  },
  tooltipIcon: {
    width: 14,
    height: 14,
    margin: '0 0.5rem'
  }
})

interface FeesProps {
  asset: any
}

export default function Fees({ asset }: FeesProps) {
  const classes = useStyles()
  return (
    <Card direction="column">
      <Title>
        Fees
        <TooltipDropdown content="">
          <InfoIcon className={classes.tooltipIcon} />
        </TooltipDropdown>
      </Title>

      <Typography className={classNames(classes.text)}>
        <span>Deposit Fee</span> {parsePercent(asset.depositFee)}
      </Typography>
      <Typography className={classNames(classes.text)}>
        <span>Withdrawal Fee</span> {parsePercent(asset.withdrawFee)}
      </Typography>
    </Card>
  )
}
