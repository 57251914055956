import Api from '../Api'

export async function addUserIcon(url: string) {
  const response = await Api.post('/auth/add_user_image', {
    imageUrl: url
  })
  return response.data
}

export async function removeUserIcon(url: string) {
  const response = await Api.post('/auth/delete_user_image', {
    imageUrl: url
  })
  return response.data
}
