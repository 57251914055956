import Slider from 'components/sliders/Slider'
import { ExchangesFilters } from 'hooks/useExchanges'
import useFiltersDrawer from 'hooks/useFiltersDrawer'
import { FILTER_STEPS, RENDER_VALUE, renderPercentDouble } from 'utils/filters'
import { TOOLTIP } from 'utils/tooltip'

import useExchangesSelect from '../../hooks/useExchangesSelect'
import { DrawerBaseProps } from './Drawer'
import DrawerContent from './DrawerContent'
import {
  FiltersDrawer,
  FiltersDrawerActions,
  FiltersDrawerActionsProps,
  FiltersDrawerBlockchainSelect,
  FiltersDrawerExchangesSelect,
  FiltersDrawerHead,
  FiltersDrawerSection,
  FiltersDrawerTitle
} from './FiltersDrawer'

interface ContentProps extends DrawerBaseProps, FiltersDrawerActionsProps {
  filters: ExchangesFilters
}

const SLIDERS = [
  {
    key: 'lpReturnHODLLast30d',
    title: 'LPing Return vs HODL<br />in the last 30d between',
    tooltip: TOOLTIP.EXCHANGE_LP_VS_HODL,
    range: [0, 100],
    step: 5
  },
  {
    key: 'netPrincipalRoiLast30d',
    title: 'Net Principal ROI Last 30d<br />(against USD) between',
    tooltip: TOOLTIP.EXCHANGE_NET_PRINCIPAL_ROI,
    range: [0, 100],
    step: 5
  },
  {
    key: 'netRoiLast30d',
    title: 'Net ROI Last 30d<br />between',
    tooltip: TOOLTIP.EXCHANGE_NET_ROI,
    range: [0, 100],
    step: 5
  },
  {
    key: 'impLossLast30d',
    title: 'Imp. Loss last 30d<br />between',
    range: [0, 20],
    step: 1
  }
]

function Content(props: ContentProps) {
  const { innerFilters, onChange, onArrayUpdate } = useFiltersDrawer<
    typeof props.filters
  >({
    filters: props.filters
  })

  const exchangesSelect = useExchangesSelect()

  const handleApply = () => {
    props.onApply(innerFilters)
    props.onClose()
  }

  const handleReset = () => {
    props.onReset()
    props.onClose()
  }

  return (
    <>
      <FiltersDrawerHead onClose={props.onClose} />

      <DrawerContent>
        {SLIDERS.map((row) => (
          <FiltersDrawerSection key={row.key}>
            <Slider
              min={row.range[0]}
              max={row.range[1]}
              step={row.step}
              defaultValue={innerFilters[row.key]}
              renderValueFormat={renderPercentDouble}
              labelComponent={
                <FiltersDrawerTitle html={row.title} tooltip={row.tooltip} />
              }
              onChange={(e) => onChange(row.key, e as number[])}
            />
          </FiltersDrawerSection>
        ))}

        <FiltersDrawerExchangesSelect
          customOptions={exchangesSelect.data.exchangeNames}
          value={innerFilters.exchangeName}
          onChange={(exchange) => onArrayUpdate('exchangeName', exchange)}
        />

        <FiltersDrawerBlockchainSelect
          customOptions={exchangesSelect.data.blockchains}
          value={innerFilters.blockchain}
          onChange={(blockchain) => onArrayUpdate('blockchain', blockchain)}
        />

        <FiltersDrawerSection>
          <Slider
            min={0}
            max={11}
            step={1}
            defaultValue={FILTER_STEPS.findIndex(
              (e) => e === innerFilters.liquidityUSD
            )}
            valueFormat={(value) => FILTER_STEPS[value as number]}
            renderValueFormat={RENDER_VALUE['liquidityUSD']}
            labelComponent={
              <FiltersDrawerTitle>Liquidity higher than</FiltersDrawerTitle>
            }
            onChange={(e) => onChange('liquidityUSD', e)}
          />
        </FiltersDrawerSection>
      </DrawerContent>

      <FiltersDrawerActions onApply={handleApply} onReset={handleReset} />
    </>
  )
}

export default function ExchangesFilterDrawer(props: ContentProps) {
  return (
    <FiltersDrawer open={props.open} onClose={props.onClose}>
      <Content {...props} />
    </FiltersDrawer>
  )
}
