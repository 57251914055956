import Grid from '@material-ui/core/Grid'
import SelectStrategy from 'components/form/SelectStrategy'
import usePool from 'hooks/usePool'
import { StrategyTypeEnum } from 'hooks/usePoolsNames'
import { useSpinner } from 'providers/SpinnerProvider'
import { useState } from 'react'

import InfoCard from '../strategies/InfoCard'
import PremiumSections from '../strategies/PremiumSections'
import { useStrategiesStyles } from '../strategies/styles'

export default function NFTStrategiesDetailed() {
  const classes = useStrategiesStyles()
  const [poolId, setPoolId] = useState<string>('')

  const pool = usePool({ poolId })
  useSpinner(pool.isLoading)

  const handleChange = (id: string) => {
    setPoolId(id)
  }

  return (
    <>
      <Grid container direction="column">
        <SelectStrategy
          value={pool.pool.poolId as string}
          className={classes.selectStrategy}
          onChange={(e) => handleChange(e?.value || '')}
          strategyType={StrategyTypeEnum.NFTS}
        />
        <InfoCard pool={pool.pool} />
        <PremiumSections pool={pool.pool} />
      </Grid>
    </>
  )
}
