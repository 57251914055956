import Spacing from 'components/common/Spacing'
import useChains from 'hooks/useChains'
import { useSearch } from 'hooks/useSearch'
import { searchHighlight } from 'utils/strings'

import Dialog, { DialogBaseProps } from './Dialog'
import DialogBody from './DialogBody'
import DialogList from './DialogList'
import DialogListItem from './DialogListItem'
import DialogSearch from './DialogSearch'

interface ContentProps extends DialogBaseProps {
  value: string[]
  onChange: (blockchain: string) => void
  customOptions?: string[]
}

function Content(props: ContentProps) {
  const chains = useChains()
  const { search, onSearch } = useSearch()
  const options = props.customOptions || chains.chainsKeys
  return (
    <>
      <DialogSearch onSearch={onSearch} />

      <DialogBody>
        <DialogList>
          {options.map((key) => (
            <DialogListItem
              name={searchHighlight(key, search)}
              key={key}
              active={props.value.includes(key)}
              onClick={() => props.onChange(key)}
            />
          ))}
        </DialogList>
      </DialogBody>

      <Spacing />
    </>
  )
}

export default function FiltersBlockchainDialog(props: ContentProps) {
  return (
    <Dialog
      open={props.open}
      height={600}
      mobileHeight="70vh"
      variant="secondary"
      title="Apply Blockchain"
      onClose={props.onClose}
    >
      <Content {...props} />
    </Dialog>
  )
}
