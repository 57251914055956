import { Dispatch, SetStateAction, useState } from 'react'
import LocalStorage from 'utils/LocalStorage'

export default function usePersistState<T>(
  initialValue: T,
  persistKey: string,
  json?: boolean
): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState(() =>
    LocalStorage.get(persistKey)
      ? json
        ? JSON.parse(LocalStorage.get(persistKey) as string)
        : LocalStorage.get(persistKey)
      : initialValue
  )

  LocalStorage.set(persistKey, json ? JSON.stringify(state) : state)

  return [state, setState]
}
