import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { InfoIcon } from 'assets/icons'
import { parseBoolean, parsePercent } from 'utils/api'

const useStyles = makeStyles({
  root: {
    fontSize: '0.875rem',
    marginBottom: '0.75rem',

    '&:last-child': {
      marginBottom: 0
    },

    '& span': {
      fontWeight: 700
    }
  },
  title: {
    fontSize: '1rem',
    marginBottom: '1rem',
    textTransform: 'uppercase',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    height: 'fit-content'
  },
  infoIcon: {
    color: '#FFD24D',
    marginRight: '0.5rem'
  }
})

const DATA = [
  {
    title: 'Deposit Fee',
    text: 'Fee paid when depositing/staking the assets.'
  },
  { title: 'Withdrawal Fee', text: 'Fee paid when withdrawing the assets.' },
  {
    title: 'Harvest Lockup',
    text: 'The harvest lockup locks users&apos;farming rewards for xx hours/days and xx % of the rewards.'
  },
  {
    title: 'Transfer Tax',
    text: 'Each transfer of the native token must pay a xx% transfer tax.'
  },
  {
    title: 'Anti Whale',
    text: 'Any transfer of more than xx % of the total supply of the native token will be rejected.'
  }
]

export function EconomicsTooltip() {
  const classes = useStyles()
  return (
    <Grid container direction="column">
      {DATA.map((row, index) => (
        <Typography key={index} className={classes.root}>
          <span>{row.title}</span>: {row.text}
        </Typography>
      ))}
    </Grid>
  )
}

export function EconomicsInfoTooltip({ asset }) {
  const classes = useStyles()
  return (
    <Grid container direction="column">
      <Typography className={classes.root}>
        <span>Transfer Tax</span>: {parseBoolean(asset.transferTax)}
      </Typography>
      <Typography className={classes.root}>
        <span>Transfer Tax Info</span>: {asset.transferTaxInfo || 'N/A'}
      </Typography>
      <Typography className={classes.root}>
        <span>Anti Whale</span>: {parsePercent(asset.antiWhale)}
      </Typography>
      <Typography className={classes.root}>
        <span>Other Info</span>: {asset.otherInfo || 'N/A'}
      </Typography>
    </Grid>
  )
}

export function EconomicsHarvestTooltip({ asset }) {
  const classes = useStyles()
  return (
    <Grid container direction="column">
      <Typography className={classes.root}>
        {asset.harvestLockupInfo || 'N/A'}
      </Typography>
    </Grid>
  )
}

const CONTRACTS_DATA = [
  {
    title: 'This is for information purposes only,',
    text: "please do your own research on the contacts, we don't take any liability for the contracts. Always double check and do your own research."
  },
  {
    title: "We don't take accountability for lost funds",
    text: 'and there maybe errors in those smart contracts since we are getting them from third parties.'
  }
]

export function ContractsTooltip() {
  const classes = useStyles()
  return (
    <Grid container direction="column">
      <Typography className={classes.title}>
        <InfoIcon className={classes.infoIcon} />
        Important!
      </Typography>

      {CONTRACTS_DATA.map((row, index) => (
        <Typography className={classes.root} key={index}>
          <span>{row.title}</span> {row.text}
        </Typography>
      ))}
    </Grid>
  )
}
