import { createContext, PropsWithChildren, useContext } from 'react'

interface TriggersContextProps {
  revalidate: any
}

export const TriggersContext = createContext<TriggersContextProps>({
  revalidate: () => {}
})

export default function TriggersProvider({
  children,
  ...props
}: PropsWithChildren<TriggersContextProps>) {
  return (
    <TriggersContext.Provider value={props}>
      {children}
    </TriggersContext.Provider>
  )
}

export function useTriggersContext() {
  return useContext(TriggersContext)
}
