import { nFormatter } from 'types/utils'

import { Option } from '../types/components/form'
import { parseMoney } from './api'
import { getNow, subDate } from './date'
import { omit, omitEmpty } from './obj'

export const TITLES = {
  tvl_min: 'Liquidity higher than',
  liquidityUSD: 'Liquidity higher than',
  farms_tvl_staked_gte: 'Farm Liquidity higher than',
  apr_yearly_min: 'APR (y) higher than',
  tvl_change_min: 'TVL Change (24h) higher than',
  coin_group: 'Coin Single Group',
  coin_groups: 'Coin Pair Groups',
  yield_types: 'Yield Type',
  exchanges: 'Exchange',
  exchangeName: 'Exchange',
  blockchain: 'Blockchain',
  farm_ids: 'Farms',
  harvest_lockup_bool: 'Harvest Lockup',
  transfer_tax_bool: 'Transfer Tax',
  deposit_fee_lte: 'Deposit Fee lower than',
  withdrawal_fee_lte: 'Withdrawal Fee lower than',
  pairPriceStd30d: 'Standard Deviation Last 30d (Token A vs Token B)',
  lpPriceStd30d: 'Standard Deviation Last 30d (LP Price vs USD)',
  netRoiLast30d: 'Net ROI Last 30d',
  lpReturnHODLLast30d: 'LPing Return vs HODL in the last 30d',
  netPrincipalRoiLast30d: 'Net Principal ROI Last 30d (against USD)',
  impLossLast30d: 'Imp. Loss last 30d'
}

export const TITLES_SECONDARY = {
  ...TITLES,
  tvl_min: 'TVL higher than'
}

export function checkGroups(groups) {
  return groups.length === 1 ? [...groups, ...groups] : groups
}

const renderPercent = (value) => value + '%'
const renderMoney = (value) => '$' + nFormatter(value, 0)
const renderBoolean = (value) => (`${value}` === 'true' ? 'Yes' : 'No')
const renderArray = (value) => value.join(', ')
const renderGroups = (value, groups) =>
  checkGroups(value)
    .map((group) => groups[group]?.display_name || '')
    .join(', ')
const renderGroup = (value, groups) => groups[value]?.display_name || ''
const renderFarms = (value, idsToNames) =>
  value.map((farm) => idsToNames[farm]).join(', ')
export const renderPercentDouble = (value) =>
  (value as number[]).filter(Boolean).length
    ? `${(value as number[]).map((e) => nFormatter(e, 1)).join(' - ')} (%)`
    : ''
export const renderStepsDouble = (value) =>
  (value as number[]).map((e) => parseMoney(e, 0, true)).join(' - ')

export const RENDER_VALUE = {
  withdrawal_fee_lte: renderPercent,
  deposit_fee_lte: renderPercent,
  apr_yearly_min: renderPercent,
  tvl_change_min: renderPercent,
  tvl_min: renderMoney,
  liquidityUSD: renderMoney,
  farms_tvl_staked_gte: renderMoney,
  transfer_tax_bool: renderBoolean,
  harvest_lockup_bool: renderBoolean,
  yield_types: renderArray,
  exchanges: renderArray,
  exchangeName: renderArray,
  blockchain: renderArray,
  coin_group: renderGroup,
  coin_groups: renderGroups,
  farm_ids: renderFarms,
  pairPriceStd30d: renderPercentDouble,
  lpPriceStd30d: renderPercentDouble,
  netRoiLast30d: renderPercentDouble,
  lpReturnHODLLast30d: renderPercentDouble,
  netPrincipalRoiLast30d: renderPercentDouble,
  impLossLast30d: renderPercentDouble
}

export const STATUS_FILTERS = [
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Inactive',
    value: 'inactive'
  },
  {
    label: 'Disabled Under Review',
    value: 'disabled_under_review'
  },
  {
    label: 'Permanently Disabled',
    value: 'permanently_disabled'
  },
  {
    label: 'Temporarily Disabled',
    value: 'temporarily_disabled'
  }
]

const RISK = [
  'Risk Score 1',
  'Risk Score 2',
  'Risk Score 3',
  'Risk Score 4',
  'Risk Score 5',
  'Risk Score 6',
  'Risk Score 7',
  'Risk Score 8',
  'Risk Score 9',
  'Risk Score 10'
]

export const RISKS = RISK.map((risk) => ({
  label: risk,
  value: risk
}))

export const BOOL_FILTERS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
]

// 0, 10k, 50k, 100k, 500k, 1m, 5m, 10m, 50m, 100m, 500m 1b
export const FILTER_STEPS = [
  0, 10000, 50000, 100000, 500000, 1000000, 5000000, 10000000, 50000000,
  100000000, 500000000, 1000000000
]

const FILTER_STEP = 500000

export function decrease(prevState) {
  const curIndex = FILTER_STEPS.indexOf(prevState)
  if (curIndex !== -1 && typeof FILTER_STEPS[curIndex - 1] === 'number') {
    return FILTER_STEPS[curIndex - 1]
  }
  return prevState === 0 ? 0 : prevState - FILTER_STEP
}

export function increase(prevState) {
  const curIndex = FILTER_STEPS.indexOf(prevState)
  if (curIndex !== -1 && FILTER_STEPS[curIndex + 1]) {
    return FILTER_STEPS[curIndex + 1]
  }
  return prevState + FILTER_STEP
}

export function increasePercent(prevState) {
  return prevState + 10
}

export function decreasePercent(prevState) {
  return prevState - 10 <= 0 ? 0 : prevState - 10
}

export const RANGE = {
  _24h: '24h',
  _7d: '7d',
  _14d: '14d',
  _30d: '30d',
  _90d: '90d',
  _180d: '180d',
  _1y: '1y',
  _max: 'max'
}

export const RANGE_API = {
  '24h': '1d',
  '7d': '7d',
  '14d': '14d',
  '30d': '30d',
  '90d': '90d',
  '180d': '180d',
  '1y': '1y',
  max: 'max'
}

export function getRangeDate(range: string): Date {
  switch (range) {
    case RANGE._7d:
      return subDate(getNow(), { weeks: 1 })
    case RANGE._30d:
      return subDate(getNow(), { months: 1 })
    default:
      return getNow()
  }
}

export const CURRENCY = {
  _USD: 'USD',
  _ETH: 'ETH'
}

export const MONTH: Option[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
].map((e) => ({
  label: e,
  value: e
}))

export const BOOLEAN_FIELDS = ['harvest_lockup_bool', 'transfer_tax_bool']

export const ARRAY_FIELDS = [
  'farm_ids',
  'coin_groups',
  'blockchain',
  'exchanges',
  'yield_types'
]

export function formatValuesStepsDouble(value: number | number[]): number[] {
  return (value as number[]).map((e) => FILTER_STEPS[e])
}

export function getStepsDefaultValueDouble(value: number[]): number[] {
  return value.map((e) => FILTER_STEPS.findIndex((_e) => _e === e))
}

export interface FilterGTLT {
  lt: number
  gt: number
}

export interface FilterGT {
  gt: number
}

export interface FilterEQ {
  eq: number | string
}

export interface FilterIN {
  in: string[]
}

export function parseStepsDoubleValue(value: FilterGTLT): number[] {
  return [value.gt, value.lt]
}

export function GTLTFilter(value: number[]): FilterGTLT {
  return {
    gt: value[0],
    lt: value[1]
  }
}

export function GTFilter(value: number): FilterGT {
  return {
    gt: value
  }
}

export function EQFilter(value: number | string): FilterEQ {
  return {
    eq: value
  }
}

export function INFilter(value: string[]): FilterIN {
  return {
    in: value
  }
}

export function GTLTFilterEmpty(e: number[]): boolean {
  return !!e.filter(Boolean).length
}

export function GTFilterEmpty(e: number): boolean {
  return !!e
}

export function EQFilterEmpty(e: number | string): boolean {
  return !!e
}

export function INFilterEmpty(e: string[]): boolean {
  return !!e.length
}

export const QUERY_KEYS: Record<string, any> = {
  pairPriceStd30d: {
    valid: GTLTFilterEmpty,
    format: GTLTFilter
  },
  lpPriceStd30d: {
    valid: GTLTFilterEmpty,
    format: GTLTFilter
  },
  netRoiLast30d: {
    valid: GTLTFilterEmpty,
    format: GTLTFilter
  },
  lpReturnHODLLast30d: {
    valid: GTLTFilterEmpty,
    format: GTLTFilter
  },
  netPrincipalRoiLast30d: {
    valid: GTLTFilterEmpty,
    format: GTLTFilter
  },
  impLossLast30d: {
    valid: GTLTFilterEmpty,
    format: GTLTFilter
  },
  exchangeName: {
    valid: INFilterEmpty,
    format: INFilter
  },
  blockchain: {
    valid: INFilterEmpty,
    format: INFilter
  },
  liquidityUSD: {
    valid: GTFilter,
    format: GTFilter
  }
}

export function formatQueryFilters(
  obj: Record<string, any>,
  keys: string[]
): Record<string, any> {
  const filtersObj = {}

  keys.forEach((key) => {
    if (obj[key] !== undefined) {
      if (QUERY_KEYS[key] && QUERY_KEYS[key].valid(obj[key])) {
        filtersObj[key] = QUERY_KEYS[key].format(obj[key])
      } else {
        filtersObj[key] = obj[key]
      }
    }
  })

  return filtersObj
}

export function formatQueryParams(
  url: string,
  innerFiltersKeys: string[] = []
): string {
  try {
    const split = url.split('?')
    const base = split[0]
    const query: any = omitEmpty(JSON.parse(split[1]), ['sort_order'])

    const otherQuery = omit(query, innerFiltersKeys)

    const queryRequiredForApiStructure = {
      ...otherQuery
    }

    queryRequiredForApiStructure.filters = formatQueryFilters(
      query,
      innerFiltersKeys
    )

    if (!Object.keys(queryRequiredForApiStructure.filters).length) {
      delete queryRequiredForApiStructure.filters
    }

    const parseQuery = Object.entries(queryRequiredForApiStructure)
      .map((e) =>
        e[0] === 'filters' ? `${e[0]}=${JSON.stringify(e[1])}` : e.join('=')
      )
      .join('&')

    return `${base}?${parseQuery}`
  } catch (e) {
    console.error(e)
    return ''
  }
}
