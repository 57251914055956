import PageTabs from 'components/tabs/PageTabs'
import usePremiumPages from 'hooks/ui/usePremiumPages'
import { useLocation } from 'react-router-dom'

import NoAccess from './NoAccess'

export default function Tabs() {
  const location = useLocation()
  const premiumPages = usePremiumPages()

  if (!premiumPages.pages.length) {
    return <NoAccess />
  }

  return (
    <PageTabs
      asLink
      tabs={premiumPages.pages}
      to={(row) => `/premium/${row.path}`}
      active={(row) => location.pathname.includes(row.path)}
      render={(row) => (
        <>
          {row.icon}
          {row.title}
        </>
      )}
    />
  )
}
