import { DATE_FORMAT_PRIMARY, format } from './date'

export function formatRows(data: any[]) {
  try {
    return data.map((row) => ({
      name: row.leaderboard_name || '-',
      vote: typeof row.vote === 'number' ? row.vote : '-',
      description: row.description || '-',
      blockchain: row.blockchain || '-',
      category: row.category || '-',
      followers: row.twitter_followers || '-',
      change24h: row.change24h || '-',
      change7d: row.change7d || '-',
      createdDate: row.createdDate
        ? format(new Date(row.createdDate), DATE_FORMAT_PRIMARY)
        : '-'
    }))
  } catch (e) {
    console.error(e)
    return []
  }
}
