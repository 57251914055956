import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { parseMoney } from 'utils/api'

const useStyles = makeStyles({
  chartsTitle: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '1.25rem',
    marginBottom: '2rem'
  },
  chartTitle: {
    fontSize: '1rem',

    '& span': {
      fontWeight: 700,
      fontSize: '1.25rem'
    }
  }
})

export default function NetWorthBar({ investmentsBalance, tab }) {
  const classes = useStyles()
  const isNft = tab === 'NFT'
  return (
    <Grid container className={classes.chartsTitle}>
      <Typography className={classes.chartTitle}>
        Net Worth:{' '}
        <span>
          {parseMoney(
            isNft
              ? investmentsBalance?.portfolio?.net_worth_nfts
              : investmentsBalance?.portfolio?.net_worth,
            2,
            true
          )}
        </span>
      </Typography>
      <Typography className={classes.chartTitle}>
        Estimated Daily Income:{' '}
        <span>
          {parseMoney(
            isNft
              ? investmentsBalance?.daily_income_nft
              : investmentsBalance?.daily_income,
            2,
            true
          )}
        </span>
      </Typography>
      <Typography className={classes.chartTitle}>
        Estimated Monthly Income:{' '}
        <span>{parseMoney(investmentsBalance?.monthly_income, 2, true)}</span>
      </Typography>
    </Grid>
  )
}
