import { Dispatch, SetStateAction, useState } from 'react'

interface UseFiltersDrawerConfig<T> {
  filters: T
  // onApply: (filters: T) => void
}

interface UseFiltersDrawer<T> {
  innerFilters: T
  onChange: (name: string, value: any) => void
  onArrayUpdate: (name: string, value: any) => void
  onChangeState: Dispatch<SetStateAction<T>>
  onGroupChange: (group: any) => void
  onGroupsChange: (group: any) => void
  onFarmsChange: (farm: any) => void
}

export default function useFiltersDrawer<T>({
  filters
}: UseFiltersDrawerConfig<T>): UseFiltersDrawer<T> {
  const [innerFilters, setInnerFilters] = useState<T>(filters)

  // !!! Auto apply feature was added when got a request to remove "apply" button
  // !!! then got a request to revert "apply" button, so comment out auto apply feature

  // const jsonKey = JSON.stringify(innerFilters)

  // const applyFilters = useCallback(
  //   debounce((filters: T) => {
  //     onApply(filters)
  //   }, 1000),
  //   []
  // )

  // useEffect(() => {
  //   if (JSON.stringify(filters) !== jsonKey) {
  //     applyFilters(innerFilters)
  //   }
  // }, [jsonKey])

  const onChange = (name, value) => {
    setInnerFilters((filters) => ({
      ...filters,
      [name]: value
    }))
  }

  const onArrayUpdate = (name, value) => {
    setInnerFilters((filters) => ({
      ...filters,
      [name]: filters[name].includes(value)
        ? filters[name].filter((v) => v !== value)
        : [...filters[name], value]
    }))
  }

  const onGroupChange = (group) => {
    setInnerFilters((filters: any) => ({
      ...filters,
      coin_group: filters.coin_group === group ? null : group,
      coin_groups: []
    }))
  }

  const onGroupsChange = (group) => {
    setInnerFilters((filters: any) => {
      return {
        ...filters,
        coin_group: '',
        coin_groups: filters.coin_groups.includes(group)
          ? filters.coin_groups.filter((g) => g !== group)
          : filters.coin_groups.length === 2
          ? filters.coin_groups
          : [...filters.coin_groups, group]
      }
    })
  }

  const onFarmsChange = (farmId) => {
    setInnerFilters((filters: any) => ({
      ...filters,
      farm_ids: filters.farm_ids.includes(farmId)
        ? filters.farm_ids.filter((f) => f !== farmId)
        : [...filters.farm_ids, farmId]
    }))
  }

  const onChangeState = setInnerFilters

  return {
    innerFilters,
    onChangeState,
    onChange,
    onArrayUpdate,
    onGroupChange,
    onGroupsChange,
    onFarmsChange
  }
}
