import LoadingSpinner from 'components/progress/LoadingSpinner'
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react'

interface SpinnerContextProps {
  visible: boolean
  open: () => void
  close: () => void
}

const SpinnerContext = createContext({
  visible: false,
  open: () => {},
  close: () => {}
} as SpinnerContextProps)

export default function SpinnerProvider({ children }: PropsWithChildren<{}>) {
  const [visible, setVisible] = useState(false)

  const open = () => {
    setVisible(true)
  }

  const close = () => {
    setVisible(false)
  }

  return (
    <SpinnerContext.Provider value={{ visible, open, close }}>
      <>
        {visible && <LoadingSpinner />}
        {children}
      </>
    </SpinnerContext.Provider>
  )
}

export function useSpinner(loading?: boolean) {
  const methods = useContext(SpinnerContext)

  useEffect(() => {
    if (typeof loading === 'boolean') {
      if (loading) {
        methods.open()
      } else {
        methods.close()
      }
    }
  }, [loading])

  return methods
}
