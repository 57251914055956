import { useState } from 'react'
import { fetcher } from 'services/api/common'
import useSWR from 'swr'

import { OnPage } from '../components/table/TablePagination'
import { stringifyURL } from '../utils/query'

interface CustomStrategiesFilters {
  page: number
  per_page: number
}

interface UseCustomStrategies {
  isLoading: boolean
  strategies: any[]
  maxPages: number
  onPage: OnPage
  filters: CustomStrategiesFilters
  revalidate: any
}

function getKey(params: Partial<CustomStrategiesFilters>) {
  return stringifyURL('/auth/get_custom_strategies', params)
}

export default function useCustomStrategies(): UseCustomStrategies {
  const [filters, setFilters] = useState<CustomStrategiesFilters>({
    page: 1,
    per_page: 5
  })

  const params = {
    ...filters
  }

  const {
    data,
    error,
    mutate: revalidate
  } = useSWR(() => getKey(params), fetcher)

  const onPage: OnPage = (page) => {
    setFilters((filters) => ({
      ...filters,
      page
    }))
  }

  const isLoading = !data && !error
  const strategies = data?.pools || []
  const maxPages = data?.max_pages

  return {
    isLoading,
    strategies,
    maxPages,
    filters,
    onPage,
    revalidate
  }
}
