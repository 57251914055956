import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { CaretDown, DeleteIcon } from 'assets/icons'
import classNames from 'classnames'
import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import useApiDomain from 'hooks/useApiDomain'
import useApiDomains from 'hooks/useApiDomains'
import useApiToken from 'hooks/useApiToken'
import { useState } from 'react'
import { device } from 'utils/screen'

const useStyles = makeStyles({
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    color: '#fff'
  },
  subtitle: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: '#fff'
  },
  titleContainer: {
    marginBottom: '1.25rem'
  },
  chartContainer: {
    background: 'linear-gradient(111.6deg, #303659 -2.51%, #292E4D 104.46%)',
    padding: '1.25rem',
    borderRadius: 15,
    marginBottom: '3rem'
  },
  apiKeyText: {
    fontWeight: 400
  },
  actionBtn: {
    backgroundColor: 'transparent',
    border: 0,
    fontSize: '1rem',
    fontWeight: 700,
    color: '#66C8FF',
    padding: 0
  },
  apiKeyTitle: {
    marginBottom: '1.25rem'
  },
  apiKeyBtn: {
    marginBottom: '2rem'
  },
  quickStart: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    padding: '0 1.25rem',
    background: 'linear-gradient(107.39deg, #474F7E 3.48%, #343B61 97.52%)',
    width: 'fit-content',
    borderRadius: 15,
    cursor: 'pointer',

    '& span': {
      marginRight: '0.5rem'
    },

    '& svg': {
      transform: 'rotate(270deg)',
      marginLeft: '2rem'
    },

    [`@media ${device.tablet}`]: {
      fontSize: '0.75rem',

      '& svg': {
        marginLeft: '0.5rem'
      }
    }
  },
  chartText: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: '#B2BDFF',
    marginBottom: '0.5rem'
  },
  chartInfo: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: '#fff',
    marginBottom: '0.5rem',

    '& span': {
      fontWeight: 700
    }
  },
  chartLegends: {
    marginBottom: '2rem'
  },
  chartLegend: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    marginRight: '2rem',

    '& span': {
      width: 60,
      height: 1,
      display: 'block',
      margin: '0 1rem'
    },

    '&[data-type="quota"]': {
      '& span': {
        backgroundColor: '#FFC700'
      }
    },
    '&[data-type="points"]': {
      '& span': {
        backgroundColor: '#FF441B'
      }
    },
    '&[data-type="requests"]': {
      '& span': {
        backgroundColor: '#24A2E9'
      }
    }
  },
  domainsList: {
    marginBottom: '2rem',
    maxWidth: 400
  },
  domainInput: {
    flex: 1,
    marginRight: '0.75rem'
  },
  domainRow: {
    marginBottom: '1rem'
  },
  domainBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 75,

    '& svg': {
      marginRight: 0
    }
  }
})

export default function Api() {
  const classes = useStyles()
  const apiToken = useApiToken()
  const apiDomains = useApiDomains()
  const apiDomain = useApiDomain({ revalidate: apiDomains.revalidate })

  const [newDomain, setDomain] = useState('')

  const handleAdd = () => {
    apiDomain.onAdd(newDomain, () => {
      setDomain('')
    })
  }

  return (
    <>
      <Grid container direction="column" className={classes.titleContainer}>
        <Typography className={classes.title}>Dashboard</Typography>
        <Typography className={classes.subtitle}>
          Here you can see and edit API access.
        </Typography>
      </Grid>

      <div>
        <Typography className={classNames(classes.title, classes.apiKeyTitle)}>
          API Key:{' '}
          <span className={classes.apiKeyText}>
            {apiToken.isLoading ? '-' : apiToken.apiToken || 'N/A'}
          </span>
        </Typography>

        <button
          className={classNames(classes.actionBtn, classes.apiKeyBtn)}
          onClick={() => apiToken.onReset()}
        >
          Generate New API Key
        </button>

        <Grid container direction="column" className={classes.titleContainer}>
          <Typography className={classes.title}>Allowed URLs</Typography>
        </Grid>

        <Grid container direction="column" className={classes.domainsList}>
          {apiDomains.domains.map((domain, index) => (
            <Grid
              className={classes.domainRow}
              container
              alignItems="center"
              wrap="nowrap"
              key={index}
            >
              <Input
                disabled
                variant="secondary"
                placeholder="Domain URL"
                className={classes.domainInput}
                value={domain.domain}
              />
              <Button
                variant="contained-2"
                size="sm"
                color="secondary"
                onClick={() => apiDomain.onDelete(domain.domain)}
                className={classes.domainBtn}
              >
                <DeleteIcon />
              </Button>
            </Grid>
          ))}

          <Grid container alignItems="center" wrap="nowrap">
            <Input
              variant="secondary"
              placeholder="Domain URL"
              className={classes.domainInput}
              value={newDomain}
              onChange={(e) => setDomain(e.target.value)}
            />
            <Button
              variant="contained-2"
              size="sm"
              onClick={handleAdd}
              className={classes.domainBtn}
            >
              Add
            </Button>
          </Grid>
        </Grid>

      </div>
    </>
  )
}
