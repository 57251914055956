import { Grid, IconButton } from '@material-ui/core'
import { DeleteIcon, EditIcon } from 'assets/icons'
import Card from 'components/cards/Card'
import { Typography } from 'components/typography'
import { AssetTokenEntity } from 'interfaces/asset-token'

import { useStrategiesStyles } from '../../strategies/styles'

interface AssetTokenProps {
  assetToken: AssetTokenEntity
  onEdit: (assetToken: AssetTokenEntity) => void
  onDelete: (asset: string) => void
}

export default function AssetToken({
  assetToken,
  onEdit,
  onDelete
}: AssetTokenProps) {
  const classes = useStrategiesStyles()

  return (
    <>
      <Card className={classes.assetToken} title={assetToken.asset}>
        <Grid className={classes.assetDetailsGrid}>
          <Grid className={classes.titleContainer}>
            <Typography className={classes.assetDetailsTitle}>
              <span>{assetToken.asset}</span>
            </Typography>
            <div>
              <IconButton
                className={`${classes.white} ${classes.spaceRight}`}
                size="small"
                onClick={() => onEdit(assetToken)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                className={classes.white}
                size="small"
                onClick={() => onDelete(assetToken.asset)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </Grid>
          <div>{assetToken.description}</div>
          <br />
          <div>
            Website URL:{' '}
            <a
              className={classes.urls}
              href={assetToken.websiteUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              {assetToken.websiteUrl || '/'}
            </a>
          </div>
          <div>
            Contract URL:{' '}
            <a
              className={classes.urls}
              href={assetToken.contractUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              {assetToken.contractUrl || '/'}
            </a>
          </div>
        </Grid>
      </Card>
    </>
  )
}
