import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { API_SECRET, API_URL } from 'config'
import LocalStorage from 'utils/LocalStorage'

let LOGOUT_WORKAROUND: any = null

export function setLogoutWorkaround(workaround: any) {
  LOGOUT_WORKAROUND = workaround
}

class Api {
  private readonly instance: AxiosInstance

  constructor() {
    this.instance = axios.create({
      baseURL: `${API_URL}/${API_SECRET}`
    })

    this.instance.interceptors.request.use((req) => {
      if (LocalStorage.get('access_token')) {
        req.headers['Authorization'] = `Bearer ${LocalStorage.get(
          'access_token'
        )}`
      }
      return req
    })

    this.instance.interceptors.response.use(
      (res) => {
        return res
      },
      (error) => {
        if (error.response) {
          if (
            error.response.status === 401 ||
            (error.response.status === 422 &&
              ['Not enough segments', 'Signature verification failed'].includes(
                error.response.data.msg
              ))
          ) {
            console.log('LOGOUT 401')
            LOGOUT_WORKAROUND?.()
          }
        }
        throw error
      }
    )
  }

  getInstance() {
    return this.instance
  }

  get(url: string) {
    return this.instance.get(url)
  }

  post(url: string, data: any) {
    return this.instance.post(url, data)
  }

  delete(url: string) {
    return this.instance.delete(url)
  }

  formData(config: AxiosRequestConfig) {
    return this.instance({
      ...config,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}

export default new Api()
