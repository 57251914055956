import { useSpinner } from 'providers/SpinnerProvider'
import { toast } from 'react-toastify'
import { fetcher } from 'services/api/common'
import { addUserIcon, removeUserIcon } from 'services/api/userIcons'
import useSWR from 'swr'

type OnAdd = (url: string) => void
type OnRemove = (url: string) => void

interface UseUsersIcons {
  isLoading: boolean
  icons: string[]
  onAdd: OnAdd
  onRemove: OnRemove
}

export default function useUsersIcons(): UseUsersIcons {
  const {
    data,
    error,
    mutate: revalidate
  } = useSWR('/auth/get_user_images', fetcher)

  const spinner = useSpinner()

  const onAdd: OnAdd = async (url) => {
    try {
      spinner.open()

      await addUserIcon(url)
      revalidate?.()
      toast.success('Icon uploaded to library')

      spinner.close()
    } catch (e) {
      spinner.close()
      toast.error('Oops error')
      console.error(e)
    }
  }

  const onRemove: OnRemove = async (url) => {
    try {
      spinner.open()

      await removeUserIcon(url)
      revalidate?.()
      toast.success('Icon removed from library')

      spinner.close()
    } catch (e) {
      spinner.close()
      toast.error('Oops error')
      console.error(e)
    }
  }

  const isLoading = !data && !error
  const icons = data?.images || []

  return {
    isLoading,
    icons,
    onAdd,
    onRemove
  }
}
