import { yupResolver } from '@hookform/resolvers/yup'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import useSubscribeEmail from 'hooks/useSubscribeEmail'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { getErrorMsg, requiredMsg } from 'utils/form'
import * as yup from 'yup'

import Checkbox from '../form/Checkbox'
import { Link } from '../typography'
import Dialog from './Dialog'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  dialogText: {
    marginBottom: '1.25rem',
    fontSize: '0.875rem'
  },
  starIcon: {
    marginBottom: '1rem'
  },
  btn: {
    width: '100%'
  },
  input: {
    marginBottom: '1.25rem',

    '&:last-child': {
      marginBottom: '0.75rem'
    }
  },
  checkbox: {
    marginBottom: '0.75rem'
  }
})

interface LoginAlertDialogProps {
  open: boolean
  onClose: () => void
}

const validationSchema = yup.object().shape({
  email: yup.string().required(requiredMsg()),
  privacy: yup.bool().oneOf([true], requiredMsg())
})

export default function LoginAlertDialog({
  open,
  onClose
}: LoginAlertDialogProps) {
  const classes = useStyles()
  const subscribeEmail = useSubscribeEmail()

  const form = useForm({
    defaultValues: {
      privacy: false,
      email: '',
      message: ''
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema)
  })

  const handleSubmit = (values) => {
    if (values.privacy) {
      subscribeEmail.onSubscribe(
        'waitlist',
        values.email,
        values.message,
        onClose
      )
    }
  }

  return (
    <Dialog
      open={open}
      size="sm"
      mobileHeight={false}
      variant="secondary"
      onClose={onClose}
      title="Join the Waitlist"
      height={450}
    >
      <DialogBody>
        <Grid
          container
          direction="column"
          component="form"
          onSubmit={form.handleSubmit(handleSubmit)}
        >
          <Typography className={classes.dialogText}>
            Sign up to be the first to access Multifarm Pro
          </Typography>

          <Grid container direction="column">
            <Input
              className={classes.input}
              variant="secondary"
              label="Email Address"
              placeholder="Your Email"
              error={getErrorMsg(form.formState.errors, 'email')}
              {...form.register('email')}
            />
            <Input
              rows={3}
              className={classes.input}
              variant="secondary"
              label="Message (not mandatory)"
              placeholder="Your Message"
              {...form.register('message')}
            />
          </Grid>

          <Controller
            control={form.control}
            render={({ field: { value, name } }) => (
              <Checkbox
                error={getErrorMsg(form.formState.errors, name)}
                value={value}
                onChange={(value) =>
                  form.setValue(name, value, { shouldValidate: true })
                }
                label={
                  <span>
                    I have read and agree to the{' '}
                    <Link to="/privacy" onClick={onClose}>
                      Privacy Policy
                    </Link>
                  </span>
                }
                className={classes.checkbox}
              />
            )}
            name="privacy"
          />

          <Button
            variant="contained-2"
            color="secondary"
            size="sm"
            type="submit"
            className={classes.btn}
          >
            Join the Waitlist
          </Button>
        </Grid>
      </DialogBody>
    </Dialog>
  )
}
