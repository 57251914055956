import { fetcher } from 'services/api/common'
import useSWR from 'swr'
import { stringifyURL } from 'utils/query'

import { parseDistribution } from '../utils/pools'

interface UseDistributionByFieldConfig {
  field?: string
}

interface UseDistributionByField {
  isLoading: boolean
  distribution: {}
  chartData: any[]
}

function getKey(params) {
  return params.field
    ? stringifyURL('/auth/get_distributions_by_field', params)
    : null
}

export default function useDistributionByField(
  config: UseDistributionByFieldConfig = {}
): UseDistributionByField {
  const params = {
    field: config.field
  }
  const { data, error } = useSWR(() => getKey(params), fetcher)

  const isLoading = config.field ? !data && !error : false
  const distribution = data || {}
  const chartData = parseDistribution(distribution)

  return {
    isLoading,
    distribution,
    chartData
  }
}
