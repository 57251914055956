import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { EditIcon } from 'assets/icons'
import classNames from 'classnames'
import IconButton from 'components/buttons/IconButton'
import Card from 'components/cards/Card'
import EditStrategyDialog from 'components/dialogs/EditStrategyDialog'
import InfoList, { InfoListItem } from 'components/lists/InfoList'
import { Link } from 'components/typography'
import { useState } from 'react'
import { parseMoney, parsePercent } from 'utils/api'
import { RISKS } from 'utils/filters'
import { getStrategyName } from 'utils/pools'

import { useStrategiesStyles } from './styles'

const useStyles = makeStyles({
  root: {
    marginBottom: '1.25rem'
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.25rem'
  },
  editIcon: {
    margin: '0 1rem'
  },
  name: {
    margin: '0 0.5rem'
  }
})

interface InfoCardProps {
  pool: any
}

export default function InfoCard({ pool }: InfoCardProps) {
  const classes = useStyles()
  const [editDialog, setEditDialog] = useState(false)
  const strategiesClasses = useStrategiesStyles()

  const renderLink = (link) =>
    link ? (
      <Link to={link} native target="_blank" rel="noopener noreferrer">
        {link}
      </Link>
    ) : (
      'N/A'
    )

  return (
    <>
      <Card direction="column" className={classes.root}>
        <Grid container className={strategiesClasses.cartTitleContainer}>
          <Typography
            className={classNames(
              strategiesClasses.subtitle,
              strategiesClasses.cardTitle
            )}
          >
            OVERVIEW {getStrategyName(pool)}
            {pool.poolId && (
              <IconButton
                size="sm"
                className={classes.editIcon}
                onClick={() => setEditDialog(true)}
              >
                <EditIcon />
              </IconButton>
            )}
          </Typography>
        </Grid>

        <Grid container className={classes.content}>
          <InfoList>
            <InfoListItem
              title="Blockchain"
              value={pool['blockchain'] || 'N/A'}
            />
            <InfoListItem title="TVL" value={parseMoney(pool['tvlStaked'])} />
            <InfoListItem
              title="Deposited into Strategy"
              value={parseMoney(pool['positionSizeUsd'])}
            />
            <InfoListItem
              title="APR"
              value={parsePercent(pool['customAprYearly'])}
            />
            <InfoListItem title="Website" value={renderLink(pool['website'])} />
          </InfoList>
          <InfoList>
            <InfoListItem
              title="Yield-Type"
              value={pool['yieldType'] || 'N/A'}
            />
            <InfoListItem
              title="Rewards Token"
              value={pool['rewardsToken'] || 'N/A'}
            />
            <InfoListItem
              title="Risk"
              value={
                pool['riskLevel']
                  ? RISKS.find((r) => r.value === pool['riskLevel'])?.label ||
                    'N/A'
                  : 'N/A'
              }
            />
            <InfoListItem
              title="Audit Link"
              value={renderLink(pool['auditLink'])}
            />
            <InfoListItem title="Wallet" value={pool['wallet']} />
          </InfoList>
        </Grid>
      </Card>

      <EditStrategyDialog
        open={editDialog}
        onClose={() => setEditDialog(false)}
        poolId={pool.poolId}
      />
    </>
  )
}
