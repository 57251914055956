import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import { forwardRef, ReactNode } from 'react'

import TooltipDropdown from '../TooltipDropdown'

interface IconButtonProps {
  className?: string
  onClick?: any
  size?: 'xs' | 'sm' | 'md'
  children?: ReactNode
  type?: 'submit' | 'button'
  tooltip?: string
}

const useStyles = makeStyles({
  root: (props: Partial<IconButtonProps>) => ({
    width: 35,
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 9999,
    color: 'inherit',
    backgroundColor: 'transparent',

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)'
    },

    ...(props.size === 'xs' && {
      width: 20,
      height: 20,
      padding: 0,

      '& svg': {
        height: 15
      }
    }),

    ...(props.size === 'sm' && {
      width: 27.5,
      height: 27.5,
      padding: 0,

      '& svg': {
        height: 18,

        '&[data-icon="CheckIcon"]': {
          height: 12
        }
      }
    })
  })
})

const IconButton = forwardRef(
  (
    {
      children,
      className,
      onClick,
      size,
      type = 'button',
      tooltip,
      ...props
    }: IconButtonProps,
    ref
  ) => {
    const classes = useStyles({ size })
    const Component = (
      <button
        ref={ref as any}
        type={type}
        className={classNames(classes.root, className)}
        onClick={onClick}
        {...props}
      >
        {children}
      </button>
    )

    if (tooltip) {
      return <TooltipDropdown content={tooltip}>{Component}</TooltipDropdown>
    }

    return Component
  }
)

export default IconButton
