import { fetcher } from 'services/api/common'
import useSWR from 'swr'

import { TOOLTIP } from '../utils/tooltip'

export const POOLS_TYPES_TITLES = {
  claimable: 'Claimable',
  wallet: 'Wallet / Holdings',
  debt: 'Debt',
  nft: 'NFT',
  collateral: 'Collateral',
  productive_asset: 'Productive Assets / Strategies'
}

export const POOLS_TYPES_TOOLTIPS = {
  claimable: TOOLTIP.TABLE_CLAIMABLE,
  wallet: TOOLTIP.TABLE_WALLET,
  debt: TOOLTIP.TABLE_DEBT,
  nft: TOOLTIP.TABLE_NFT,
  collateral: TOOLTIP.TABLE_COLLATERAL,
  productive_asset: TOOLTIP.TABLE_PRODUCTIVE_ASSETS
}

export interface UsePoolsTypes {
  isLoading: boolean
  types: string[]
}

export default function usePoolsTypes(): UsePoolsTypes {
  const { data, error } = useSWR('/auth/get_strategy_types', fetcher)

  const isLoading = !data && !error
  const types = data?.strategy_types || []

  return {
    isLoading,
    types
  }
}
