import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import SettingsIcon from '@material-ui/icons/Settings'
import { CheckIcon, EditIcon } from 'assets/icons'
import ScrollArrow from 'components/badge/ScrollArrow'
import Button from 'components/buttons/Button'
import IconButton from 'components/buttons/IconButton'
import AddFieldDialog from 'components/dialogs/AddFieldDialog'
import FieldNoteDialog from 'components/dialogs/FieldNoteDialog'
import FieldWeightDialog from 'components/dialogs/FieldWeightDialog'
import TablePagination from 'components/table/TablePagination'
import ChartTooltip from 'components/tooltips/ChartTooltip'
import { Link, PageTitle } from 'components/typography'
import useContainerScroll from 'hooks/ui/useContainerScroll'
import useFieldNote from 'hooks/ui/useFieldNote'
import useDefaultFields from 'hooks/useDefaultFields'
import usePool from 'hooks/usePool'
import usePoolsByField from 'hooks/usePoolsByField'
import { useSpinner } from 'providers/SpinnerProvider'
import { KeyboardEvent, useEffect, useRef, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { useResizeDetector } from 'react-resize-detector'
import { TableCol } from 'types/components/table'
import { parseMoney } from 'utils/api'
import { isKeyEnter } from 'utils/events'
import { getAssetName, isAssetStrategy } from 'utils/pools'
import { toCamelCase, toStartCase } from 'utils/strings'

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2rem'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',

    '& > button': {
      marginLeft: '1.25rem'
    }
  },
  tableContainer: {
    width: '100%',
    maxWidth: '100%',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',

    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  tableScrollContainer: {
    width: '100%',
    maxWidth: '100%',
    position: 'relative',
    marginBottom: '2.5rem',

    '&:last-child': {
      marginBottom: 0
    }
  },
  table: {
    minWidth: '100%',
    width: 'auto',
    tableLayout: 'fixed',
    borderCollapse: 'separate',
    borderSpacing: 0,

    '& th, & td': {
      fontWeight: 400,
      maxWidth: 400,
      padding: '1rem 1.25rem 1rem 1.25rem',
      borderBottom: '1px solid #41486E',
      borderRight: '1px solid #41486E',
      position: 'relative',
      backgroundClip: 'padding-box',

      '&[data-edit="true"]': {},

      '& > div': {}
    },

    '& th': {
      fontWeight: 700,
      borderTop: '1px solid #41486E',

      '&:first-child': {
        borderLeft: '1px solid #41486E'
      }
    },

    '& td': {
      padding: 0,

      '&:first-child': {
        borderLeft: '1px solid #41486E'
      }
    }
  },
  editBtn: {
    position: 'absolute',
    right: '0.625rem',
    visibility: 'hidden',
    display: 'flex',
    alignItems: 'center',

    '& button': {
      marginRight: '0.25rem',

      '&:last-child': {
        marginRight: 0
      }
    }
  },
  checkIcon: {
    position: 'absolute',
    right: '0.625rem'
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '1rem 4rem 1rem 1.25rem',

    '&:hover': {
      '& $editBtn': {
        visibility: 'visible'
      }
    }
  },
  inputContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    width: '100%',
    height: '100%',
    padding: '1rem 2.5rem 1rem 1.25rem',
    backgroundColor: 'transparent',
    color: '#ffffff',
    fontSize: '1rem',
    border: '2px solid #ffffff',
    outline: 'none'
  },
  cellActions: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap'
  },
  tooltipActions: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',

    '& button': {
      marginLeft: '0.5rem'
    }
  }
})

const BASE_COLS: TableCol[] = [
  {
    key: 'asset',
    title: 'Asset',
    width: 300,
    sticky: 0,
    render: (row) =>
      isAssetStrategy(row) ? (
        <Link to={`/premium/details/${row.poolId}`} target="_blank">
          {getAssetName(row)}
        </Link>
      ) : (
        <span>{getAssetName(row)}</span>
      )
  },
  {
    key: 'holdings',
    title: 'Holdings',
    width: 150,
    sticky: 300,
    render: (row) => parseMoney(row['positionSizeUsd'])
  }
]

const FIELDS_CONFIG: Record<string, Partial<TableCol>> = {
  additionalInfo: {
    width: 300
  },
  ohmBacking: {
    width: 250
  },
  olympusCategory: {
    width: 250
  }
}

type OnEdit = (
  poolId: string,
  values: Record<string, string>,
  onSuccess?: () => void
) => void

interface WeightState {
  open: boolean
  field: string
  poolId: string
  value: number
}

export default function Labels() {
  const classes = useStyles()
  const customFields = useDefaultFields()
  const pool = usePool()

  const poolsByField = usePoolsByField({
    filters: {
      field: 'active',
      value: 'true',
      per_page: 20
    }
  })

  useSpinner(poolsByField.isLoading)

  const containerScroll = useContainerScroll({ id: 'TABLE_LABELS' })

  const [addDialog, onAddDialog] = useState(false)
  const [noteDialog, onNoteDialog] = useState(false)
  const [noteToEdit, onNoteToEdit] = useState('')
  const [weightDialogProps, onWeightDialogProps] = useState({
    open: false,
    field: '',
    poolId: '',
    value: 1
  })

  const handleEdit: OnEdit = (poolId, values, onSuccess) => {
    pool.onUpdate(poolId, values, undefined, async () => {
      await poolsByField.revalidate?.()
      onSuccess?.()
    })
  }

  const Title = (title: string) => (
    <Grid container className={classes.titleContainer}>
      <PageTitle>{title}</PageTitle>

      <div className={classes.actions}>
        <TablePagination
          count={poolsByField.maxPages}
          page={poolsByField.filters.page}
          onPage={poolsByField.onPage}
        />
        <Button
          size="sm"
          variant="contained-2"
          onClick={() => onAddDialog(true)}
        >
          Add
        </Button>
      </div>
    </Grid>
  )

  const ScrollArrows = (provider) =>
    provider.scroll && (
      <>
        <ScrollArrow
          size="lg"
          side="left"
          case="labels"
          condition={provider.leftScroll}
          onClick={() => provider.scrollTo(-400)}
        />
        <ScrollArrow
          size="lg"
          side="right"
          condition={provider.rightScroll}
          onClick={() => provider.scrollTo(400)}
        />
      </>
    )

  const THead = () => (
    <thead>
      <tr>
        {BASE_COLS.map((col) => (
          <th align="left" key={col.key} style={getColStyles(col)}>
            {col.title}
          </th>
        ))}
        {customFields.customFields.map((key) => (
          <HeadCell
            key={key}
            _key={key}
            onEdit={(field) => {
              onNoteDialog(true)
              onNoteToEdit(field)
            }}
          />
        ))}
      </tr>
    </thead>
  )

  const TBody = (provider, rowHandler) => (
    <tbody>
      {!provider.length && (
        <tr>
          <td style={getColStyles({ width: 200, sticky: 0 })}>
            <div className={classes.content}>No Data</div>
          </td>
        </tr>
      )}

      {provider.map((row) => (
        <tr key={row.poolId}>
          {BASE_COLS.map((col) => (
            <td key={col.key} style={getColStyles(col)}>
              <div
                data-sticky={typeof col.sticky === 'number'}
                className={classes.content}
              >
                {col.render?.(row)}
              </div>
            </td>
          ))}
          {customFields.customFields.map((key) => (
            <EditableCell
              row={rowHandler(row)}
              key={key}
              _key={key}
              onEdit={handleEdit}
              onWeight={onWeightDialogProps}
            />
          ))}
        </tr>
      ))}
    </tbody>
  )

  return (
    <>
      <Grid container direction="column">
        {Title('Labels')}

        <div className={classes.tableScrollContainer}>
          {ScrollArrows(containerScroll)}

          <div
            id="TABLE_LABELS"
            onScroll={containerScroll.onScroll}
            className={classes.tableContainer}
            ref={containerScroll.ref}
          >
            <table className={classes.table}>
              {THead()}
              {TBody(poolsByField.pools, (row) => row)}
            </table>
          </div>
        </div>
      </Grid>

      <AddFieldDialog
        open={addDialog}
        onClose={() => onAddDialog(false)}
        onApply={(field) => customFields.onAddCustom(toCamelCase(field))}
      />

      <FieldNoteDialog
        field={noteToEdit}
        open={noteDialog}
        onClose={() => onNoteDialog(false)}
      />

      <FieldWeightDialog
        field={weightDialogProps.field}
        value={weightDialogProps.value}
        poolId={weightDialogProps.poolId}
        revalidate={poolsByField.revalidate}
        open={weightDialogProps.open}
        onClose={() =>
          onWeightDialogProps((props) => ({
            ...props,
            open: false
          }))
        }
      />
    </>
  )
}

interface HeadCellProps {
  _key: string
  onEdit: (field: string) => void
}

function HeadCell(props: HeadCellProps) {
  const classes = useStyles()
  const fieldNote = useFieldNote({ field: props._key })
  return (
    <th
      align="left"
      key={props._key}
      style={getColStyles(FIELDS_CONFIG[props._key])}
    >
      <div className={classes.cellActions}>
        {toStartCase(props._key)}
        <ChartTooltip
          content={
            <span className={classes.tooltipActions}>
              <span>{fieldNote.data || 'N/A'}</span>
              <IconButton size="xs" onClick={() => props.onEdit(props._key)}>
                <EditIcon />
              </IconButton>
            </span>
          }
        />
      </div>
    </th>
  )
}

interface EditableCellProps {
  _key: string
  row: any
  onEdit: OnEdit
  onWeight: (state: WeightState) => void
}

function EditableCell(props: EditableCellProps) {
  const classes = useStyles()
  const resizeDetector = useResizeDetector()
  const ref = useRef<HTMLInputElement>()
  const [edit, onEdit] = useState(false)
  const [value, onValue] = useState<any>()

  useEffect(() => {
    if (edit && ref.current) {
      ref.current?.focus()
    }
  }, [edit])

  const handleEdit = () => {
    if (value !== props.row[props._key]) {
      props.onEdit(
        props.row?.poolId,
        {
          [props._key]: value
        },
        () => {
          onEdit(false)
        }
      )
    } else {
      onEdit(false)
    }
  }

  const handleKeyPress = (e: KeyboardEvent) => {
    if (isKeyEnter(e)) {
      handleEdit()
    }
  }

  return (
    <td
      ref={resizeDetector.ref}
      key={props._key}
      style={getColStyles(FIELDS_CONFIG[props._key]) as any}
      data-edit={edit}
    >
      <OutsideClickHandler
        onOutsideClick={() => onEdit(false)}
        disabled={!edit}
      >
        {edit ? (
          <div
            className={classes.inputContainer}
            style={getDivStyle(resizeDetector.height)}
          >
            <input
              ref={ref as any}
              type="text"
              value={value || ''}
              className={classes.input}
              onChange={(e) => onValue(e.target.value)}
              onKeyPress={handleKeyPress}
              style={getDivStyle(resizeDetector.height)}
            />

            <IconButton
              size="xs"
              className={classes.checkIcon}
              onClick={handleEdit}
            >
              <CheckIcon />
            </IconButton>
          </div>
        ) : (
          <div
            className={classes.content}
            style={getDivStyle(resizeDetector.height)}
          >
            {props.row?.[props._key] || '-'}

            <div className={classes.editBtn}>
              <IconButton
                size="xs"
                onClick={() => {
                  onEdit(true)
                  onValue(props.row?.[props._key])
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="xs"
                tooltip={`Weight: ${
                  props.row?.['weights']?.[props._key] || '1'
                }`}
                onClick={() =>
                  props.onWeight({
                    open: true,
                    field: props._key,
                    value: props.row?.['weights']?.[props._key] || 1,
                    poolId: props.row?.poolId
                  })
                }
              >
                <SettingsIcon />
              </IconButton>
            </div>
          </div>
        )}
      </OutsideClickHandler>
    </td>
  )
}

function getColStyles(col: Partial<TableCol>): any {
  return {
    minWidth: col?.width,
    width: col?.width,

    ...(typeof col?.sticky === 'number' && {
      position: 'sticky',
      zIndex: 1,
      left: col.sticky,
      backgroundColor: '#272d49'
    })
  }
}

function getDivStyle(height: number | undefined) {
  return {
    minHeight: height
  }
}
