import Grid from '@material-ui/core/Grid'
import EditSavedAssetDialog from 'components/dialogs/EditSavedAssetDialog'
import { PageTitle } from 'components/typography'
import useSavedSearches from 'hooks/useSavedSearches'
import { useSpinner } from 'providers/SpinnerProvider'
import { useState } from 'react'

import SavedAssetsTable from './assets/SavedAssetsTable'
import { useStrategiesStyles } from './strategies/styles'

export default function Assets() {
  const classes = useStrategiesStyles()
  const [editSaveId, setEditSaveId] = useState<string | null>(null)

  const savedSearches = useSavedSearches({ save_type: 'asset' })

  useSpinner(savedSearches.isLoading)

  return (
    <>
      <Grid container className={classes.titleContainer}>
        <PageTitle>Saved Assets</PageTitle>
      </Grid>

      <SavedAssetsTable
        savedSearches={savedSearches.savedSearches}
        onRemoveSearch={savedSearches.onRemoveSearch}
        onEdit={setEditSaveId}
      />

      <EditSavedAssetDialog
        savedId={editSaveId as string}
        open={!!editSaveId}
        onClose={() => setEditSaveId(null)}
      />
    </>
  )
}
