import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from 'components/buttons/Button'
import Input from 'components/form/Input'

import Dialog from './Dialog'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  text: {
    marginBottom: '2rem',
    fontSize: '0.875rem'
  },
  input: {
    marginBottom: '1.875rem',

    '&:last-child': {
      marginBottom: 0
    }
  }
})

export default function ResetPasswordDialog({ open, onClose }) {
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      size="sm"
      height={350}
      variant="secondary"
      mobileHeight={false}
      title="Reset Password"
      onClose={onClose}
    >
      <DialogBody>
        <Grid container direction="column">
          <Typography className={classes.text}>
            Enter the email address associated with your account and we’ll send
            an email with instructions to reset your password.
          </Typography>

          <Input
            className={classes.input}
            placeholder="Your Email"
            label="Email Address"
            variant="secondary"
          />

          <Button color="secondary" size="sm" variant="contained-2">
            Send Instructions
          </Button>
        </Grid>
      </DialogBody>
    </Dialog>
  )
}
