import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { parseQuery, stringifyQuery } from 'utils/query'

interface UseStateUrlSyncConfig {
  disabled?: boolean
  modifier?: any
}

export default function useStateUrlSync<T>(
  initialState: T,
  config: UseStateUrlSyncConfig = {}
): [T, Dispatch<SetStateAction<T>>] {
  const history = useHistory()
  const location = useLocation()

  const [state, setState] = useState(() => {
    return config.disabled
      ? initialState
      : location.search
      ? { ...initialState, ...(parseQuery(location.search) as T) }
      : initialState
  })

  const stateKey = config.disabled ? '' : JSON.stringify(state)

  useEffect(() => {
    if (!config.disabled) {
      history.replace(
        stringifyQuery(
          location.pathname,
          config.modifier ? config.modifier(state) : state
        )
      )
    }
  }, [stateKey])

  return [state, setState]
}
