import { useState } from 'react'
import { fetcher } from 'services/api/common'
import useSWR from 'swr'

import { OnPage } from '../components/table/TablePagination'
import { stringifyURL } from '../utils/query'
import usePoolsTypes, { UsePoolsTypes } from './usePoolsTypes'

type OnType = (type: string | null) => void

interface PoolsByTypeFilters {
  type: string | null
  page: number
}

interface UsePoolsByType {
  isLoading: boolean
  poolsByTypes: any[]
  maxPages: number
  onType: OnType
  onPage: OnPage
  filters: PoolsByTypeFilters
  revalidate: any
}

function getKey(params: PoolsByTypeFilters) {
  return stringifyURL('/auth/get_strategies', params)
}

export default function usePoolsByType(): UsePoolsByType &
  Pick<UsePoolsTypes, 'types'> {
  const [filters, setFilters] = useState<PoolsByTypeFilters>({
    type: null,
    page: 1
  })

  const params = {
    ...filters
  }

  const {
    data,
    error,
    mutate: revalidate
  } = useSWR(() => (params.type ? getKey(params) : null), fetcher)

  const poolsTypes = usePoolsTypes()

  const onType: OnType = (type) => {
    setFilters((filters) => ({
      ...filters,
      page: 1,
      type
    }))
  }

  const onPage: OnPage = (page) => {
    setFilters((filters) => ({
      ...filters,
      page
    }))
  }

  const isLoading = !!params.type && !data && !error
  const poolsByTypes = data?.pools || []
  const maxPages = data?.max_pages || 0

  return {
    onPage,
    onType,
    revalidate,
    filters,
    isLoading,
    poolsByTypes,
    maxPages,
    types: poolsTypes.types
  }
}
