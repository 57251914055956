import { createContext, PropsWithChildren, useContext, useState } from 'react'

type OnDialog = (state: boolean) => void

interface DialogsProviderProps {
  login: boolean
  onLogin: OnDialog
}

const DialogsProviderContext = createContext<DialogsProviderProps>({
  login: false,
  onLogin: () => {}
})

export default function DialogsProvider({ children }: PropsWithChildren<{}>) {
  const [login, onLogin] = useState(false)
  return (
    <DialogsProviderContext.Provider value={{ login, onLogin }}>
      {children}
    </DialogsProviderContext.Provider>
  )
}

export function useDialogsProvider() {
  return useContext(DialogsProviderContext)
}
