import PageTabs from 'components/tabs/PageTabs'
import { useState } from 'react'

import ClosedPosition from './pnl/ClosedPosition'
import InactivePosition from './pnl/InactivePosition'
// import History from './pnl/History'
import OpenPosition from './pnl/OpenPosition'
import Overview from './pnl/Overview'
import Stats from './pnl/Stats'

const TABS = [
  'Overview',
  'Open Positions',
  'Closed Positions',
  'Inactive Positions',
  'Stats'
  // 'History'
]

const Components = {
  Overview: Overview,
  'Closed Positions': ClosedPosition,
  'Open Positions': OpenPosition,
  'Inactive Positions': InactivePosition,
  Stats: Stats
  // History: History
}

export default function Pnl() {
  const [tab, onTab] = useState(TABS[0])
  const Component = Components[tab]
  return (
    <div>
      <PageTabs
        tabs={TABS}
        active={(row) => row === tab}
        onClick={(row) => onTab(row)}
        render={(row) => row}
      />
      <Component />
    </div>
  )
}
