import { yupResolver } from '@hookform/resolvers/yup'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import { Rule } from 'interfaces/transactions'
import { Controller, useForm, useFormState } from 'react-hook-form'
import { getErrorMsg, requiredMsg } from 'utils/form'
import * as yup from 'yup'

import Dialog, { DialogBaseProps } from '../Dialog'
import DialogActions from '../DialogActions'
import DialogBody from '../DialogBody'

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.25rem'
  }
})

const validationSchema = yup.object().shape({
  address: yup.string().required(requiredMsg()),
  blockchain: yup.string().required(requiredMsg()),
  tx_from: yup.string().required(requiredMsg()),
  tx_to: yup.string().required(requiredMsg()),
  label1: yup.string().required(requiredMsg())
})

export interface RuleForm extends Omit<Rule, 'id' | 'labels'> {
  label1: string
  label2: string
}

const defaultValues: RuleForm = {
  address: '',
  blockchain: '',
  tx_from: '',
  tx_to: '',
  label1: '',
  label2: ''
}

function Content({ rule, onSubmit, availableChains }: ContentProps) {
  const classes = useStyles()

  const form = useForm<RuleForm>({
    defaultValues: rule
      ? { ...rule, label1: rule?.labels[0], label2: rule?.labels[1] }
      : defaultValues,
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema)
  })

  const { errors } = useFormState({
    control: form.control
  })

  /**
   * Submits the form
   * @param values - Form values
   */
  const handleSubmit = (values: RuleForm): void => {
    onSubmit(values)
  }

  return (
    <>
      <DialogBody>
        <Grid container id="add-custom-row" className={classes.root}>
          <Input
            variant="secondary"
            label="Address"
            placeholder="Address"
            error={getErrorMsg(errors, 'address')}
            {...form.register('address')}
          />
          <Controller
            control={form.control}
            render={({ field: { value, name } }) => (
              <Select
                clearable
                searchable={false}
                variant="secondary"
                options={availableChains.map((chain) => ({
                  value: chain,
                  label: chain
                }))}
                placeholder="Blockchain"
                label="Blockchain"
                value={value}
                onChange={(e) =>
                  form.setValue(name, e ? (e.value as string) : '')
                }
                error={getErrorMsg(errors, 'blockchain')}
              />
            )}
            name="blockchain"
          />
          <Input
            variant="secondary"
            label="TX From address"
            placeholder="Use .* to handle include every address"
            error={getErrorMsg(errors, 'tx_from')}
            {...form.register('tx_from')}
          />
          <Input
            variant="secondary"
            label="TX To address"
            placeholder="Use .* to handle include every address"
            error={getErrorMsg(errors, 'tx_to')}
            {...form.register('tx_to')}
          />
          <Input
            variant="secondary"
            label="Label 1"
            placeholder="Label 1"
            error={getErrorMsg(errors, 'label1')}
            {...form.register('label1')}
          />
          <Input
            variant="secondary"
            label="Label 2"
            placeholder="Label 2"
            error={getErrorMsg(errors, 'label2')}
            {...form.register('label2')}
          />
        </Grid>
      </DialogBody>

      <DialogActions>
        <Button
          size="sm"
          variant="contained-2"
          color="primary"
          form="add-custom-row"
          onClick={() => form.handleSubmit(handleSubmit)()}
        >
          Save
        </Button>
      </DialogActions>
    </>
  )
}

interface ContentProps extends DialogBaseProps {
  onSubmit: (data: RuleForm) => void
  rule?: Rule
  availableChains: string[]
}

export default function RuleDialog(props: ContentProps) {
  return (
    <Dialog
      variant="secondary"
      height={420}
      title="Manipulate a Rule"
      size="lg"
      open={props.open}
      onClose={props.onClose}
    >
      <Content {...props} />
    </Dialog>
  )
}
