import { nFormatter } from '../types/utils'
import { CURRENCY } from './filters'
import { capitalize } from './strings'

export function parseBoolean(field) {
  return typeof field === 'boolean' ? (field ? 'Yes' : 'No') : 'N/A'
}

export function parsePercent(
  field: string | number,
  fixed = 2,
  short?: boolean
) {
  const value = Number(field)

  if (field === null || field === undefined || Number.isNaN(value)) {
    return 'N/A'
  }

  if (short) {
    return `${nFormatter(value, fixed)}%`
  }

  return value || value.toString() === '0'
    ? `${Number(value).toFixed(fixed)}%`
    : 'N/A'
}

export function addCurrency(num: string, currency: string) {
  const symbol = currency === CURRENCY._USD ? '$' : 'ETH'
  return currency === CURRENCY._USD ? `${symbol}${num}` : `${num} ${symbol}`
}

export function parseMoney(field, fixed = 2, zero?, currency = CURRENCY._USD) {
  const format = `${nFormatter(field, fixed)}`

  return field && !isNaN(Number(field))
    ? addCurrency(format, currency)
    : zero
    ? addCurrency(`${(0).toFixed(fixed)}`, currency)
    : 'N/A'
}

const BLOCKCHAIN = {
  eth: 'Ethereum',
  avax: 'Avalanche'
}

export function formatBlockchain(blockchain) {
  return blockchain
    ? BLOCKCHAIN[blockchain.toLowerCase()] || capitalize(blockchain)
    : ''
}

export function formatPartner(id: string): string {
  return id.split('_')[0]
}
