import ChartCard from 'components/cards/ChartCard'
import LineChart, { LineChartProps } from 'components/charts/LineChart'
import Tabs from 'components/tabs/Tabs'
import ChartTooltip from 'components/tooltips/ChartTooltip'
import useExchangeAllCharts from 'hooks/useExchangeAllCharts'
import { useMemo, useState } from 'react'
import { formatChartDate, getChartId, renderTooltipOptions } from 'utils/charts'
import { getRandomColors } from 'utils/colors'
import { API_DATE_FORMAT_SECONDARY, format, getNow } from 'utils/date'
import { parseExchangeChart } from 'utils/exchanges'
import { getRangeDate, RANGE } from 'utils/filters'

import { RANGES_THIRD } from '../performance/data'

interface ExchangeChartProps extends Pick<LineChartProps, 'yTickFormatter'> {
  title: string
  ChartProps?: Partial<LineChartProps>
  tooltip?: string
  exchange: string
  asset: string
  assetAddress: string
  fields: string[]
  tooltipFormatter?: any
  rangeFields?: {
    7: string[]
    30: string[]
  }
}

const COLORS = getRandomColors(20)

export default function ExchangeChart(props: ExchangeChartProps) {
  const [range, onRange] = useState(RANGE._7d)

  const fields = props.rangeFields
    ? props.rangeFields[range === RANGE._7d ? 7 : 30]
    : props.fields

  const exchangeAllCharts = useExchangeAllCharts({
    assetAddress: props.assetAddress,
    fields,
    from: format(getRangeDate(range), API_DATE_FORMAT_SECONDARY),
    to: format(getNow(), API_DATE_FORMAT_SECONDARY)
  })

  const memo = [
    ...props.fields,
    ...Object.keys(exchangeAllCharts.data),
    props.exchange,
    props.asset,
    props.assetAddress
  ].join('_')

  const parsedChart = useMemo(
    () => parseExchangeChart(exchangeAllCharts.data, fields),
    [memo]
  )

  return (
    <ChartCard
      title={props.title}
      tooltip={props.tooltip && <ChartTooltip content={props.tooltip} />}
      headerComponent={
        <Tabs
          width="auto"
          tabs={RANGES_THIRD}
          value={range}
          onChange={onRange}
        />
      }
    >
      <LineChart
        id={getChartId(props.title)}
        noData={!parsedChart.data.length}
        loading={exchangeAllCharts.isLoading}
        memo={memo}
        data={parsedChart.data}
        dataKey={parsedChart.keys}
        colors={COLORS}
        yTickFormatter={props.yTickFormatter}
        xTickFormatter={formatChartDate}
        tooltipOptions={renderTooltipOptions(
          parsedChart.keys,
          true,
          COLORS,
          (e) => props.tooltipFormatter?.(e)
        )}
        {...props.ChartProps}
      />
    </ChartCard>
  )
}
