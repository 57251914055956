import Grid from '@material-ui/core/Grid'
import PnlTable from 'components/tables/PnlTable'
import usePnl from 'hooks/usePnl'

import DateSelect from './DateSelect'

export default function ClosedPosition() {
  const pnl = usePnl({
    filters: {
      is_sold: true
    }
  })

  if (pnl.isLoading) return <div>loading...</div>

  return (
    <Grid container direction="column">
      <DateSelect
        addClosed
        dates={pnl.dates}
        filters={pnl.filters}
        onFilter={pnl.onFilter}
        revalidate={pnl.revalidate}
      />
      <PnlTable
        filters={pnl.filters}
        onSort={pnl.onSort}
        maxPages={pnl.maxPages}
        onPage={pnl.onPage}
        data={pnl.data}
        revalidate={pnl.revalidate}
        onRemove={(id) => pnl.onRemove(id)}
      />
    </Grid>
  )
}
