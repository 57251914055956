import camelCase from 'lodash.camelcase'
import startCase from 'lodash.startcase'

export function searchHighlight(string, search) {
  return string.replace(
    new RegExp(replaceUnterminatedChars(search), 'gi'),
    (match) => `<span class="search-highlight">${match}</span>`
  )
}

export function replaceUnterminatedChars(str: string) {
  return str.replaceAll('[', '')
}

export function toCamelCase(str: string) {
  return camelCase(str)
}

export function toStartCase(str: string) {
  return startCase(str)
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function loweralize(string) {
  return (
    string.toUpperCase().charAt(0).toLowerCase() + string.toUpperCase().slice(1)
  )
}
