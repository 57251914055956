import { formatQueryParams } from 'utils/filters'

import Api from '../Api'

export async function getPnl(url): Promise<any> {
  const response = await Api.get(formatQueryParams(url, ['is_sold', 'active']))
  return response.data
}

export async function removePnl(id: string) {
  const response = await Api.post('/auth/cms/delete_pnl', {
    pool_id: id
  })
  return response.data
}
