import { useSpinner } from 'providers/SpinnerProvider'
import { toast } from 'react-toastify'
import Api from 'services/Api'
import { fetcher } from 'services/api/common'
import useSWR from 'swr'
import { omit } from 'utils/obj'
import { stringifyURL } from 'utils/query'

import { formatFormValues } from '../utils/pnl'

function getKey(params) {
  return params.poolId ? stringifyURL('/auth/get_pnl', params) : null
}

export default function useSinglePnl(config) {
  const spinner = useSpinner()
  const params = {
    poolId: config.poolId
  }
  const {
    data: _data,
    error,
    mutate: revalidate
  } = useSWR(() => getKey(params), fetcher)

  const onEdit = async (data, onSuccess) => {
    try {
      spinner.open()
      const posted = await Api.post('/auth/cms/edit_save_pnl', {
        poolId: data.pool_id,
        ...omit(formatFormValues(data), ['pool_id'])
      })
      posted.data.success
        ? toast.success('Updated successfully')
        : toast.error(posted.data.error)

      revalidate?.()
      config.revalidate?.()
      spinner.close()
      onSuccess?.()
    } catch (e) {
      toast.error('Oops error')
      console.error(e)
      spinner.close()
    }
  }

  const data = _data || {}
  const isLoading = !_data && !error

  return {
    data,
    isLoading,
    onEdit
  }
}
