import { useSpinner } from 'providers/SpinnerProvider'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { register } from 'services/api/auth'

import { RegistrationData } from '../interfaces/auth'

interface UseAuthRegister {
  onRegister: (data: RegistrationData, onSuccess?: () => void) => void
}

export default function useAuthRegister(): UseAuthRegister {
  const spinner = useSpinner()
  const history = useHistory()

  const onRegister = async (data: RegistrationData, onSuccess?: () => void) => {
    try {
      spinner.open()
      const response = await register(data)
      spinner.close()
      toast.success(response)
      history.push('/register-success')
      onSuccess?.()
    } catch (e: any) {
      spinner.close()
      toast.error(e.response || 'Something went wrong')
      console.error(e)
    }
  }

  return {
    onRegister
  }
}
