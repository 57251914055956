import Api from '../Api'

export async function addPartner(data) {
  const response = await Api.post('/auth/cms/add_edit_partnership', data)
  return response.data
}

export async function removePartner(id: string) {
  const response = await Api.post('/auth/cms/delete_partnership', {
    item_id: id
  })
  return response.data
}

export async function addProteusDescription(data) {
  const response = await Api.post(
    '/auth/cms/add_edit_proteus_description',
    data
  )
  return response.data
}

export async function removeProteusDescription(id: string) {
  const response = await Api.post('/auth/cms/delete_proteus_description', {
    item_id: id
  })
  return response.data
}

export async function addDaoGohmLeaderboard(data) {
  const response = await Api.post('/auth/cms/add_edit_dao_gohm_item', data)
  return response.data
}

export async function removeDaoGohmLeaderboard(id: string) {
  const response = await Api.post('/auth/cms/delete_dao_gohm_item', {
    item_id: id
  })
  return response.data
}

export async function createCustomNft(body: any) {
  const response = await Api.post('/auth/cms/create_custom_pool', body)
  return response.data
}
