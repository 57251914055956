import { Option } from 'types/components/form'
import { nFormatter } from 'types/utils'

import { parseMoney, parsePercent } from './api'
import { API_DATE_FORMAT_SECONDARY, parseDate } from './date'
import * as FiltersUtils from './filters'
import { numberFormatter, objFormat } from './obj'

export function formatRows(data: any[]) {
  try {
    return data.map((row) => ({
      id: row.id,
      assetAddress: row.assetAddress,
      _asset: row.asset,
      _exchange: row.exchangeName,
      asset: row.asset || 'N/A',
      exchange: row.exchangeName || 'N/A',
      blockchain: row.blockchain || 'N/A',
      liquidity:
        row.liquidityUSD && !isNaN(row.liquidityUSD)
          ? `$${nFormatter(row.liquidityUSD, 2)}`
          : 'N/A',
      tradingVolume: row.dailyVolumeUSD
        ? `$${nFormatter(row.dailyVolumeUSD, 2)}`
        : 'N/A',
      feesPercent: parsePercent(row['feePercentageLast30d']),
      fees: parseMoney(row['feeLast30d'], 2, true),
      ilLast: parsePercent(row.impermanent_loss),
      netRoi: parsePercent(row.net_roi),
      predictedFee: parsePercent(row.fee_predict),
      predictedIl: parsePercent(row.il_predict),
      predictedNetRoi: parsePercent(row.net_roi_predict),
      impLossLast30d: parsePercent(row['impLossLast30d']),
      netRoiLast30d: parsePercent(row['netRoiLast30d']),
      equivalentAPR: parsePercent(row['equivalentAPR']),
      pairPriceStd30d: parsePercent(row['pairPriceStd30d']),
      lpPrice: parseMoney(row['lpPrice']),
      lpPriceStd30d: parsePercent(row['lpPriceStd30d']),
      netPrincipalRoiLast30d: parsePercent(row['netPrincipalRoiLast30d']),
      lpReturnHODLLast30d: parsePercent(row['lpReturnHODLLast30d']),
      dailyAPR: parsePercent(row['dailyAPR']),
      rewardApr: parsePercent(row['rewardApr']),
      feeRate: parsePercent(row['feeRatePercentage'])
    }))
  } catch (e) {
    console.error(e)
    return []
  }
}

const innerFiltersKeys = [
  'pairPriceStd30d',
  'lpPriceStd30d',
  'netRoiLast30d',
  'lpReturnHODLLast30d',
  'netPrincipalRoiLast30d',
  'impLossLast30d',
  'blockchain',
  'exchangeName',
  'liquidityUSD',
  'dateUpdated'
]

export function formatQueryParams(url: string): string {
  return FiltersUtils.formatQueryParams(url, innerFiltersKeys)
}

export function parseExchangeChart(
  data: Record<string, Record<string, string>>,
  keys: string[]
) {
  try {
    if (Object.keys(data).length) {
      const dataKeys: any[] = Object.keys(data)

      const initRow = keys.reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: 0
        }),
        {}
      )

      const chart = dataKeys.reduce((acc, cur) => {
        return [
          ...acc,
          {
            date: parseDate(cur, API_DATE_FORMAT_SECONDARY),
            ...initRow,
            ...objFormat<string, number>(data[cur], numberFormatter)
          }
        ]
      }, [])

      return {
        keys,
        data: chart
      }
    }

    return {
      keys: [],
      data: []
    }
  } catch (e) {
    console.error(e)
    return {
      keys: [],
      data: []
    }
  }
}

export function blockchainsToOptions(blockchains: string[]): Option[] {
  return (
    blockchains?.map((e) => ({
      label: e,
      value: e
    })) || []
  )
}
