import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { CheckIcon, EditIcon } from 'assets/icons'
import classNames from 'classnames'
import IconButton from 'components/buttons/IconButton'
import Card from 'components/cards/Card'
import EditInfoCardDialog from 'components/dialogs/EditInfoCard'
import Textarea from 'components/form/Textarea'
import { Link } from 'components/typography'
import { useEffect, useState } from 'react'
import { urlDomain } from 'utils/query'

interface InfoCardProps {
  title: string
  text: string
  name: string
  website: string
  vault: string
  onEdit: (name: string, newText: string, onSuccess: any) => void
  poolId: string
}

const useStyles = makeStyles({
  info: {
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center'
  },
  subtitle: {
    fontWeight: 700,
    paddingRight: '0.25rem'
  },
  cardTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '0.75rem',

    '& svg': {
      width: 25,
      cursor: 'pointer',

      '&[data-icon="edit"]': {
        width: 17.5
      }
    }
  },
  cardTitle: {
    marginRight: 0,
    textTransform: 'uppercase',

    '& span': {
      color: '#B2BDFF'
    }
  },
  cardText: {
    fontSize: '0.875rem'
  }
})

export default function InfoCard({
  title,
  text,
  onEdit,
  name,
  website,
  poolId,
  vault
}: InfoCardProps) {
  const [edit, setEdit] = useState(false)
  const [editText, setEditText] = useState('')

  const classes = useStyles()

  useEffect(() => {
    setEditText('')
  }, [poolId])

  useEffect(() => {
    if (text !== editText && text !== 'N/A') {
      setEditText(text)
    }
  }, [text])

  const handleSave = () => {
    onEdit(name, editText, () => {
      setEdit(false)
    })
  }

  return (
    <Card direction="column">
      <Grid container className={classes.cardTitleContainer}>
        <Typography className={classNames(classes.subtitle, classes.cardTitle)}>
          {title}
        </Typography>

        {edit ? (
          <IconButton size="sm" onClick={handleSave}>
            <CheckIcon data-icon="CheckIcon" />
          </IconButton>
        ) : (
          <IconButton size="sm" onClick={() => setEdit(true)}>
            <EditIcon />
          </IconButton>
        )}
      </Grid>

      <Typography className={classes.info}>
        <span className={classes.subtitle}>Website: </span>
        {website ? (
          <Link native target="_blank" rel="noreferrer noopener" to={website}>
            {urlDomain(website)}
          </Link>
        ) : (
          ' '
        )}
      </Typography>

      <Typography className={classes.info}>
        <span className={classes.subtitle}>Vault address: </span> {vault}
      </Typography>

      {edit ? (
        <Textarea
          value={editText}
          onChange={(e) => setEditText(e.target.value)}
          placeholder="Write notes..."
        />
      ) : (
        <Typography className={classes.cardText}>
          <span>{text}</span>
        </Typography>
      )}

      <EditInfoCardDialog
        savedId={title as string}
        open={!!edit}
        data={{ title, text, name, website, vault }}
        onClose={() => {
          setEdit(false)
        }}
      />
    </Card>
  )
}
