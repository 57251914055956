import { fetcher } from 'services/api/common'
import useSWR from 'swr'

interface UseInvestmentsBalance {
  isLoading: boolean
  investmentsBalance: any
}

export default function useInvestmentsBalance(): UseInvestmentsBalance {
  const { data, error } = useSWR('/auth/get_investment_balance', fetcher)

  const isLoading = !data && !error
  const investmentsBalance = data || {}

  return {
    isLoading,
    investmentsBalance
  }
}
