import { yupResolver } from '@hookform/resolvers/yup'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import { Transaction } from 'interfaces/transactions'
import { useForm, useFormState } from 'react-hook-form'
import { getErrorMsg, requiredMsg } from 'utils/form'
import * as yup from 'yup'

import Dialog, { DialogBaseProps } from '../Dialog'
import DialogActions from '../DialogActions'
import DialogBody from '../DialogBody'

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.25rem'
  }
})

const validationSchema = yup.object().shape({
  label1: yup.string().required(requiredMsg())
})

export interface TransactionForm {
  label1: string
  label2: string
}

const defaultValues: TransactionForm = {
  label1: '',
  label2: ''
}

function Content({ transaction, onSubmit }: ContentProps) {
  const classes = useStyles()

  const form = useForm<TransactionForm>({
    defaultValues: transaction
      ? {
          label1: transaction.labels[0],
          label2: transaction.labels[1]
        }
      : defaultValues,
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema)
  })

  const { errors } = useFormState({
    control: form.control
  })

  /**
   * Submits the form
   * @param values - Form values
   */
  const handleSubmit = (values: TransactionForm): void => {
    const labels = [values.label1]
    if (values.label2) {
      labels.push(values.label2)
    }
    onSubmit({ labels })
  }

  return (
    <>
      <DialogBody>
        <Grid container id="add-custom-row" className={classes.root}>
          <Input
            variant="secondary"
            label="Address"
            disabled={true}
            value={transaction?.address || ''}
          />
          <Input
            variant="secondary"
            label="Blockchain"
            disabled={true}
            value={transaction?.blockchain || ''}
          />
          <Input
            variant="secondary"
            label="Label 1"
            placeholder="Label 1"
            error={getErrorMsg(errors, 'label1')}
            {...form.register('label1')}
          />
          <Input
            variant="secondary"
            label="Label 2"
            placeholder="Label 2"
            error={getErrorMsg(errors, 'label2')}
            {...form.register('label2')}
          />
          <Input
            variant="secondary"
            label="TX ID"
            disabled={true}
            value={transaction?.tx_id || ''}
          />
          <Input
            variant="secondary"
            label="TX From address"
            disabled={true}
            value={transaction?.tx_from || ''}
          />
          <Input
            variant="secondary"
            label="TX To address"
            disabled={true}
            value={transaction?.tx_to || ''}
          />
          <Input
            variant="secondary"
            label="TX Value"
            disabled={true}
            value={transaction?.tx_value + ''}
          />
          <Input
            variant="secondary"
            label="TX Token"
            disabled={true}
            value={transaction?.tx_token.symbol || ''}
          />
          <Input
            variant="secondary"
            label="Block"
            disabled={true}
            value={transaction?.block + ''}
          />
          <Input
            variant="secondary"
            label="Timestamp"
            disabled={true}
            value={transaction?.timestamp + ''}
          />
        </Grid>
      </DialogBody>

      <DialogActions>
        <Button
          size="sm"
          variant="contained-2"
          color="primary"
          form="add-custom-row"
          onClick={() => form.handleSubmit(handleSubmit)()}
        >
          Save
        </Button>
      </DialogActions>
    </>
  )
}

interface ContentProps extends DialogBaseProps {
  onSubmit: (data: { labels: string[] }) => void
  transaction?: Transaction
}

export default function TransactionDialog(props: ContentProps) {
  return (
    <Dialog
      variant="secondary"
      height={550}
      title="Edit Tranasction's label"
      size="lg"
      open={props.open}
      onClose={props.onClose}
    >
      <Content {...props} />
    </Dialog>
  )
}
