import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { device } from 'utils/screen'

const FAILED_IMAGES: string[] = []

const useStyles = makeStyles({
  root: {
    width: 20,
    height: 20,
    marginRight: '0.5rem',
    visibility: 'hidden',

    '&[data-loaded="true"]': {
      visibility: 'visible'
    },

    [`@media ${device.tablet}`]: {
      width: 14,
      height: 14
    }
  }
})

interface CellImageProps {
  src: string
  className?: string
  placeholder?: JSX.Element | string
}

export default function CellImage({
  src,
  className,
  placeholder
}: CellImageProps): JSX.Element | null {
  const classes = useStyles()
  const [success, setSuccess] = useState(false)
  const [failed, setFailed] = useState(FAILED_IMAGES.includes(src))

  useEffect(() => {
    if (src && failed && !FAILED_IMAGES.includes(src)) {
      setFailed(false)
      setSuccess(false)
    }
  }, [src])

  if (failed || !src) {
    return <>{placeholder}</> || null
  }

  return (
    <img
      data-loaded={success}
      className={classNames(classes.root, className)}
      src={src}
      onLoad={() => setSuccess(true)}
      onError={() => {
        FAILED_IMAGES.push(src)
        setFailed(true)
      }}
      alt=""
    />
  )
}
