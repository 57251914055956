import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import useFieldNote from 'hooks/ui/useFieldNote'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { toStartCase } from 'utils/strings'

import Dialog from './Dialog'
import DialogActions from './DialogActions'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  input: {
    marginBottom: '1.25rem'
  }
})

interface ContentProps {
  open: boolean
  onClose: () => void
  label: string
  value?: number
  onConfirm: (value: number) => void
}

function Content(props: ContentProps) {
  const classes = useStyles()
  const [value, setValue] = useState(`${props.value || 1}`)
  const [note, setNote] = useState('')

  const fieldNote = useFieldNote({ field: props.label })

  useEffect(() => {
    if (fieldNote.data && fieldNote.data !== note) {
      setNote(fieldNote.data)
    }
  }, [fieldNote.data])

  const handleConfirm = async () => {
    if (isNaN(Number(value))) {
      toast.error('Weight is not a number')
      return
    }

    if (note !== fieldNote.data) {
      await fieldNote.onEdit(note)
    }

    props.onConfirm(Number(value))
    props.onClose()
  }

  const handleReset = () => {
    props.onConfirm(1)
    props.onClose()
  }

  return (
    <>
      <DialogBody>
        <Input
          label="Weight"
          variant="secondary"
          placeholder="Weight"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className={classes.input}
        />
        <Input
          rows={7}
          label="Notes"
          variant="secondary"
          placeholder="Notes"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </DialogBody>

      <DialogActions>
        <Button
          variant="contained-2"
          size="sm"
          color="secondary"
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button
          variant="contained-2"
          size="sm"
          color="primary"
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </>
  )
}

export default function LabelWeightDialog(props: ContentProps) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={`Update Label (${toStartCase(props.label)})`}
      variant="secondary"
      height={450}
    >
      <Content {...props} />
    </Dialog>
  )
}
