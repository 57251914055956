import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import useFieldNote from 'hooks/ui/useFieldNote'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { toStartCase } from '../../utils/strings'
import Dialog, { DialogBaseProps } from './Dialog'
import DialogActions from './DialogActions'
import DialogBody from './DialogBody'

interface ContentProps extends DialogBaseProps {
  field: string
}

function Content(props: ContentProps) {
  const fieldNote = useFieldNote({ field: props.field })

  const [value, onValue] = useState(fieldNote.data)

  useEffect(() => {
    if (fieldNote.data !== value) {
      onValue(fieldNote.data)
    }
  }, [fieldNote.data])

  const handleSubmit = () => {
    if (value !== fieldNote.data) {
      fieldNote.onEdit(value, () => {
        toast.success('Note updated')
        props.onClose?.()
      })
    }
  }

  return (
    <>
      <DialogBody>
        <Input
          rows={7}
          variant="secondary"
          placeholder="Note"
          label="Note"
          value={value}
          onChange={(e) => onValue(e.target.value)}
        />
      </DialogBody>

      <DialogActions>
        <Button
          size="sm"
          variant="contained-2"
          color="secondary"
          onClick={props.onClose}
        >
          Reset
        </Button>
        <Button size="sm" variant="contained-2" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </>
  )
}

export default function FieldNoteDialog(props: ContentProps) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={`Edit Note (${toStartCase(props.field)})`}
      variant="secondary"
      height={375}
    >
      <Content {...props} />
    </Dialog>
  )
}
