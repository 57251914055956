import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Button'
import AddBondTransactionDialog from 'components/dialogs/AddBondTransactionDialog'
import BondCategoriesSelect from 'components/form/BondCategoriesSelect'
import { PageTitle } from 'components/typography'
import { useState } from 'react'

import OtherIncomeTable from './portfolio/OtherIncomeTable'
import DailyEarningsBar from './strategies/DailyEarningsBar'
import { useStrategiesStyles } from './strategies/styles'

const useStyles = makeStyles({
  select: {
    width: '100%',
    maxWidth: 300,
    marginBottom: '1.75rem'
  }
})

const SUM_CATEGORY = {
  'Olympus Pro': 'Olympus Pro',
  'Own Bonding': 'Bonds',
  'Revenue Share Agreements': 'Subdao Agreement',
  Bribes: 'Bribing'
}

export default function OtherIncome() {
  const classes = useStyles()
  const strategiesStyles = useStrategiesStyles()
  const [own, setOwn] = useState('Olympus Pro')
  const [addDialog, onAddDialog] = useState(false)

  return (
    <>
      <Grid container direction="column">
        <Grid container className={strategiesStyles.titleContainer}>
          <PageTitle>Other Revenue</PageTitle>

          <Button
            size="sm"
            variant="contained-2"
            onClick={() => onAddDialog(true)}
          >
            Import Transactions
          </Button>
        </Grid>

        <BondCategoriesSelect
          value={own}
          onChange={setOwn}
          className={classes.select}
        />

        <Grid container direction="column" className={strategiesStyles.card}>
          <OtherIncomeTable category={own} />
        </Grid>

        <DailyEarningsBar category={SUM_CATEGORY[own]} />
      </Grid>

      <AddBondTransactionDialog
        open={addDialog}
        onClose={() => onAddDialog(false)}
      />
    </>
  )
}
