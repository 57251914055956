import { fetcher } from 'services/api/common'
import useSWR from 'swr'

interface UseDaoGohmLeaderboards {
  isLoading: boolean
  leaderboards: any[]
  revalidate: any
}

export default function useDaoGohmLeaderboards(): UseDaoGohmLeaderboards {
  const {
    data,
    error,
    mutate: revalidate
  } = useSWR('/auth/cms/get_dao_gohm_items', fetcher)

  const isLoading = !data && !error
  const leaderboards = data || []

  return {
    isLoading,
    leaderboards,
    revalidate
  }
}
