import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Chip from 'components/chips/Chip'
import { RENDER_VALUE, TITLES, TITLES_SECONDARY } from 'utils/filters'
import { device } from 'utils/screen'

import useGlobals from '../../hooks/useGlobals'
import useGroups from '../../hooks/useGroups'

const useStyles = makeStyles({
  root: {
    padding: '0 0 0 0',
    margin: '-0.5rem -0.5rem 1.5rem -0.5rem',

    [`@media ${device.tablet}`]: {
      padding: '2rem 0 0.5rem 0'
    }
  }
})

interface ActiveFiltersProps {
  filters: Record<string, any>
  clearFilter: (key: string) => void
  titlesVariant?: 'primary' | 'secondary'
  onClick?: () => void
  exclude?: string[]
}

export default function ActiveFilters({
  filters,
  clearFilter,
  titlesVariant = 'primary',
  onClick,
  exclude
}: ActiveFiltersProps) {
  const classes = useStyles()
  const globals = useGlobals()
  const groups = useGroups()

  const activeFilters = Object.keys(filters).filter((key) =>
    !exclude?.includes(key)
      ? Array.isArray(filters[key])
        ? filters[key].filter(Boolean).length
        : !!filters[key]
      : false
  )

  if (!activeFilters.length) {
    return null
  }

  const titles = titlesVariant === 'primary' ? TITLES : TITLES_SECONDARY

  return (
    <Grid container wrap="wrap" className={classes.root}>
      {activeFilters.map((row) => (
        <Chip key={row} onRemove={() => clearFilter(row)} onClick={onClick}>
          {titles[row] || row}:{' '}
          {RENDER_VALUE[row]
            ? RENDER_VALUE[row](
                filters[row],
                row === 'farm_ids'
                  ? globals.farmsIdsToNames
                  : ['coin_group', 'coin_groups'].includes(row)
                  ? groups.groups
                  : undefined
              )
            : filters[row]}
        </Chip>
      ))}
    </Grid>
  )
}
