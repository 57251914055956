import Grid from '@material-ui/core/Grid'
import Button from 'components/buttons/Button'
import AddTextWalletDialog from 'components/dialogs/AddTextWalletDialog'
import ChartTooltip from 'components/tooltips/ChartTooltip'
import { PageTitle } from 'components/typography'
import useWallet from 'hooks/useWallet'
import useWallets from 'hooks/useWallets'
import { useSpinner } from 'providers/SpinnerProvider'
import { useState } from 'react'

import WalletsTable from './assets/WalletsTable'
import { useStrategiesStyles } from './strategies/styles'

export default function Wallets() {
  const classes = useStrategiesStyles()
  const [importDialog, setImportDialog] = useState(false)

  const wallets = useWallets()
  const wallet = useWallet({ revalidate: wallets.revalidate })

  useSpinner(wallets.isLoading)

  return (
    <>
      <Grid container className={classes.titleContainer}>
        <PageTitle>Wallets</PageTitle>

        <div className={classes.titleActions}>
          <ChartTooltip content="Importing wallets can take up to 1 hour until we have synchronized all the data with our servers" />

          <Button
            variant="contained-2"
            size="sm"
            onClick={() => setImportDialog(true)}
          >
            Import Holdings via ETH Address
          </Button>
        </div>
      </Grid>

      <WalletsTable
        loading={wallets.isLoading}
        data={wallets.wallets}
        onRemove={(id) => wallet.onRemove(id)}
      />

      <AddTextWalletDialog
        open={importDialog}
        onClose={() => setImportDialog(false)}
      />
    </>
  )
}
