import { Grid, makeStyles } from '@material-ui/core'
import Tabs from 'components/tabs/Tabs'
import { PageTitle } from 'components/typography'
import { useState } from 'react'

import { TransactionRules } from './transactions/Rules'
import { Transactions } from './transactions/Transactions'

// Styles
const useStyles = makeStyles({
  title: {
    marginBottom: '1.25rem'
  },
  tabs: {
    marginBottom: '1.25rem'
  }
})

interface TransactionTab {
  value: string
  name: string
  component: React.FC
}

const TABS: TransactionTab[] = [
  { value: 'tx', name: 'Transactions', component: Transactions },
  { value: 'rules', name: 'Rules', component: TransactionRules }
]

export const TransactionsPage = () => {
  const classes = useStyles()
  const [tab, onTab] = useState<TransactionTab>(TABS[0])

  /**
   * Changes the current tab.
   * @param value - Tab's value.
   */
  const changeTab = (value: string) => {
    const selectedTab = TABS.find((tab) => tab.value == value)
    onTab(selectedTab ?? TABS[0])
  }

  return (
    <Grid container direction="column">
      <Grid container direction="column">
        <PageTitle className={classes.title}>Transactions overview</PageTitle>

        <Grid container className={classes.tabs}>
          <Tabs
            width="auto"
            tabs={TABS}
            value={tab.value}
            onChange={changeTab}
          />
        </Grid>
      </Grid>

      {<tab.component />}
    </Grid>
  )
}
