import { createContext, PropsWithChildren, useContext } from 'react'

interface DaoGohmLeaderboardsContext {
  revalidate: any
}

const DaoGohmLeaderboardsContext = createContext<DaoGohmLeaderboardsContext>({
  revalidate: () => {}
})

export default function DaoGohmLeaderboardsProvider({
  children,
  ...props
}: PropsWithChildren<DaoGohmLeaderboardsContext>) {
  return (
    <DaoGohmLeaderboardsContext.Provider value={props}>
      {children}
    </DaoGohmLeaderboardsContext.Provider>
  )
}

export function useDaoGohmLeaderboardsProvider() {
  return useContext(DaoGohmLeaderboardsContext)
}
