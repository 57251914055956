import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import { CheckIcon } from 'assets/icons'
import classNames from 'classnames'
import IconButton from 'components/buttons/IconButton'
import Card from 'components/cards/Card'
import Textarea from 'components/form/Textarea'
import { useEffect, useState } from 'react'

import { useStrategiesStyles } from './styles'

interface PremiumSectionCardProps {
  title: string
  text: string
  name: string
  onEdit: (name: string, newText: string, onSuccess: any) => void
  poolId: string
}

export default function PremiumSectionCard({
  title,
  text,
  onEdit,
  name,
  poolId
}: PremiumSectionCardProps) {
  const classes = useStrategiesStyles()
  const [edit, setEdit] = useState(false)
  const [editText, setEditText] = useState('')

  useEffect(() => {
    setEditText('')
  }, [poolId])

  useEffect(() => {
    if (text !== editText && text !== 'N/A') {
      setEditText(text)
    }
  }, [text])

  const handleSave = () => {
    onEdit(name, editText, () => {
      setEdit(false)
    })
  }

  return (
    <Card direction="column">
      <Grid container className={classes.cartTitleContainer}>
        <Typography className={classNames(classes.subtitle, classes.cardTitle)}>
          {title}
        </Typography>

        {edit ? (
          <IconButton size="sm" onClick={handleSave}>
            <CheckIcon data-icon="CheckIcon" />
          </IconButton>
        ) : (
          <IconButton size="sm" onClick={() => setEdit(true)}>
            <EditIcon />
          </IconButton>
        )}
      </Grid>

      {edit ? (
        <Textarea
          value={editText}
          onChange={(e) => setEditText(e.target.value)}
          placeholder="Write notes..."
        />
      ) : (
        <Typography className={classes.cardText}>
          <span>{text}</span>
        </Typography>
      )}
    </Card>
  )
}
