import { stringifyURL } from 'utils/query'

import Api from '../Api'

interface UseBalanceSheetConfig {
  year?: number
}

interface QueryParams extends UseBalanceSheetConfig {}

function getKey(params: QueryParams) {
  return stringifyURL('/auth/edit_balance_sheet', params)
}

export async function addBalanceRecord(year, values: any) {
  const data = await Api.post(getKey({ year }), values)
  return data
}
