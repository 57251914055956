import { fetcher } from 'services/api/common'
import useSWR from 'swr'

export default function useDistributionPerPartner() {
  const { data, error } = useSWR('/auth/get_distributions_per_partner', fetcher)

  const isLoading = !data && !error
  const distribution = data || {}
  const keys = Object.keys(distribution)
  const percentage = data?.['distributions_percentage_per_partner'] || {}
  const values = data?.['distributions_sum_per_partner'] || {}

  return {
    isLoading,
    keys,
    distribution,
    percentage,
    values
  }
}
