import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import { BalanceSheetColumn, BalanceSheetRow } from 'interfaces/balance-sheet'

import { DeleteIcon, EditIcon } from '../../../assets/icons'
import IconButton from '../../../components/buttons/IconButton'
import Table from '../../../components/table/Table'
import TableCell from '../../../components/table/TableCell'
import TableRow from '../../../components/table/TableRow'

const useStyles = makeStyles({
  actionsCell: {
    width: '100px'
  },
  iconsCell: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: '1rem',
      cursor: 'pointer',

      '&:last-child': {
        marginRight: 0
      }
    }
  },
  iconBtn: {
    marginRight: '0.5rem'
  },
  table: {
    margin: '-1.25rem 0 -1.25rem 0'
  }
})

const cellProps: any = {
  color: 'pool'
}

interface BalanceSheetTableProps {
  isLoading?: boolean
  data: BalanceSheetRow[]
  columns: BalanceSheetColumn[]
  className?: string
  onEdit: (balanceSheetRow: BalanceSheetRow) => void
  onDelete: (balanceSheetRowId: string) => void
}

export default function BalanceSheetTable({
  className,
  columns,
  data,
  onEdit,
  onDelete,
  isLoading = false
}: BalanceSheetTableProps) {
  const classes = useStyles()
  return (
    <Table
      inCard
      className={classNames(classes.table, className)}
      cols={columns}
      loading={isLoading}
      empty={!data.length}
    >
      {data.map((row, index) => (
        <TableRow key={index}>
          {columns.map((column) => (
            <TableCell key={column.key} {...cellProps} align={column.align}>
              {column.render
                ? column.render(row[column.key])
                : row[column.key] || 'N/A'}
            </TableCell>
          ))}
          {row.product !== 'Total' ? (
            <TableCell className={classes.actionsCell} {...cellProps}>
              <Grid className={classes.iconsCell}>
                <IconButton size="sm" onClick={() => onEdit(row)}>
                  <EditIcon />
                </IconButton>
                <IconButton size="sm" onClick={() => onDelete(row.product)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </TableCell>
          ) : (
            <TableCell className={classes.actionsCell} {...cellProps} />
          )}
        </TableRow>
      ))}
    </Table>
  )
}
