import Button from 'components/buttons/Button'
import Input from 'components/form/Input'
import usePool from 'hooks/usePool'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { toStartCase } from 'utils/strings'

import Dialog, { DialogBaseProps } from './Dialog'
import DialogActions from './DialogActions'
import DialogBody from './DialogBody'

interface ContentProps extends DialogBaseProps {
  field: string
  value: number
  poolId: string
  revalidate: any
}

function Content(props: ContentProps) {
  const [value, onValue] = useState(`${props.value}`)
  const pool = usePool()

  const handleSubmit = () => {
    if (isNaN(Number(value))) {
      toast.error('Weight is not a number')
      return
    }

    pool.onUpdate(
      props.poolId,
      {},
      {
        [props.field]: Number(value)
      },
      () => {
        props.revalidate?.()
        props.onClose?.()
      }
    )
  }

  return (
    <>
      <DialogBody>
        <Input
          label="Weight"
          variant="secondary"
          placeholder="Weight"
          value={value}
          onChange={(e) => onValue(e.target.value)}
        />
      </DialogBody>

      <DialogActions>
        <Button
          variant="contained-2"
          size="sm"
          color="secondary"
          onClick={props.onClose}
        >
          Reset
        </Button>
        <Button
          variant="contained-2"
          size="sm"
          color="primary"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </>
  )
}

export default function FieldWeightDialog(props: ContentProps) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={`Update Weight (${toStartCase(props.field)})`}
      variant="secondary"
      height={275}
    >
      <Content {...props} />
    </Dialog>
  )
}
