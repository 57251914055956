import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import { EmptyPlaceholder, LoadingPlaceholder } from 'components/placeholders'
import Tabs from 'components/tabs/Tabs'
import useRangeChart from 'hooks/useRangeChart'
import { ASSET_CHART_RANGES } from 'pages/premium/performance/data'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { convertChartDate, DATE_CHART_FORMAT, format } from 'utils/date'
import { RANGE } from 'utils/filters'
import { device } from 'utils/screen'

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(111.6deg, #303659 -2.51%, #292E4D 104.46%)',
    padding: '1.25rem 2rem 1.25rem 1.5rem',
    borderRadius: 15,
    maxWidth: '100%'
  },
  chart: {
    height: 320,
    maxWidth: '100%',

    '& .recharts-wrapper': {
      maxWidth: '100% !important',
      width: '100% !important'
    }
  },
  titleContainer: (props: any) => ({
    padding: '0 0 1.25rem 0',
    justifyContent: props.hasRange ? 'space-between' : 'center',
    gap: '1.25rem'
  }),
  title: {
    fontSize: '1rem',
    fontWeight: 700,

    [`@media ${device.tablet}`]: {
      fontSize: '0.75rem'
    }
  },
  rangeTab: {
    minWidth: 'auto'
  },
  tooltip: {
    background: 'linear-gradient(107.39deg, #474F7E 3.48%, #343B61 97.52%)',
    padding: '0.5rem 0.75rem',
    borderRadius: 10,

    '& p': {
      fontSize: '0.75rem',
      display: 'flex',
      alignItems: 'center',

      '& span[data-color]': {
        width: 10,
        height: 10,
        borderRadius: 9999,
        marginRight: '0.25rem'
      },

      '& span[data-footer]': {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0.75rem'
      }
    }
  }
})

interface AssetChartProps {
  data: any[]
  className?: string
  yTickFormatter: any
  title: string
  tooltipOptions?: any[]
  hasRange?: boolean
  loading?: boolean
}

export default function AssetChart({
  data,
  title,
  className,
  yTickFormatter,
  tooltipOptions,
  hasRange = false,
  loading = false
}: AssetChartProps) {
  const classes = useStyles({ hasRange })

  const { range, setRange, rangeParsedData } = useRangeChart({
    data,
    initialRange: hasRange ? RANGE._30d : RANGE._max
  })

  const renderChart = () => {
    if (loading) {
      return <LoadingPlaceholder height={320} />
    }

    if (!rangeParsedData.length) {
      return <EmptyPlaceholder height={320} />
    }
    return (
      <Grid container direction="column" className={classes.chart}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={rangeParsedData} margin={{ left: -10 }}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#DC4DFF" stopOpacity={0.1} />
                <stop offset="100%" stopColor="#4DBFFF" stopOpacity={0.3} />
              </linearGradient>
            </defs>

            <YAxis
              padding={{ top: 0, bottom: 0 }}
              fontSize="0.625rem"
              domain={['auto', 'auto']}
              tickFormatter={yTickFormatter}
              tick={{
                fill: '#B2BDFF'
              }}
              axisLine={false}
              tickLine={false}
              stroke="0"
            />

            <XAxis
              dataKey="date"
              tickFormatter={(date) =>
                date && date !== 'auto'
                  ? format(convertChartDate(date), DATE_CHART_FORMAT)
                  : ''
              }
              fontSize="0.625rem"
              tickMargin={10}
              axisLine={false}
              tickLine={false}
              stroke="0"
              // interval={0}
              tick={{
                fill: '#B2BDFF'
              }}
            />

            <Area
              type="monotoneX"
              unit="M"
              dataKey="value"
              strokeLinecap="round"
              strokeWidth={2}
              lightingColor="#000"
              stroke="#D750FF"
              fillOpacity={1}
              fill="url(#colorUv)"
              dot={false}
              legendType="none"
            />

            {tooltipOptions && tooltipOptions.length && (
              <Tooltip
                content={(props) => (
                  <Grid className={classes.tooltip}>
                    {tooltipOptions.map((row, index) => (
                      <Typography key={index}>
                        {row(props.payload?.[0]?.payload || {})}
                      </Typography>
                    ))}
                  </Grid>
                )}
              />
            )}
            <CartesianGrid
              stroke="#6B6BB2"
              strokeOpacity="0.4"
              horizontalPoints={[]}
            />
          </AreaChart>
        </ResponsiveContainer>
      </Grid>
    )
  }

  return (
    <Grid
      container
      direction="column"
      className={classNames(classes.root, className)}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.titleContainer}
      >
        <Typography className={classes.title}>{title}</Typography>
        {hasRange && (
          <Tabs
            tabClassName={classes.rangeTab}
            width="auto"
            tabs={ASSET_CHART_RANGES}
            value={range}
            onChange={setRange}
          />
        )}
      </Grid>

      {renderChart()}
    </Grid>
  )
}
