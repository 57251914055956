import { OnTableSort } from 'components/table/Table'
import { SortOrder } from 'components/table/TableHeadCell'
import { OnPage } from 'components/table/TablePagination'
import debounce from 'lodash.debounce'
import { useCallback } from 'react'
import { getFarms } from 'services/api/farms'
import useSWR from 'swr'
import {
  OnClearFilter,
  OnClearFilters,
  OnFilter,
  OnFilters,
  OnSearch
} from 'types/components/filters'
import { omitEmpty } from 'utils/obj'
import { stringifyURL } from 'utils/query'

import useStateUrlSync from './ui/useStateUrlSync'

export interface FarmsFilters {
  pg: number
  blockchain: string[]
  sort: string
  sort_order: SortOrder
  tvl_min: number
  search: string
}

interface UseFarms {
  isLoading: boolean
  farms: any[]
  maxPages: number
  onSort: OnTableSort
  onSearch: OnSearch
  onPage: OnPage
  filters: FarmsFilters
  onFilters: OnFilters<FarmsFilters>
  onFilter: OnFilter<FarmsFilters>
  onClearFilter: OnClearFilter<FarmsFilters>
  onClearFilters: OnClearFilters
}

function getKey(params) {
  return stringifyURL('/get_farms', omitEmpty(params))
}

const initialFilters: FarmsFilters = {
  pg: 1,
  blockchain: [''],
  sort: 'tvlStaked',
  sort_order: 'desc',
  tvl_min: 1000000,
  search: ''
}

interface UseFarmsConfig {
  syncFiltersUrl?: boolean
}

export default function useFarms(config: UseFarmsConfig = {}): UseFarms {
  const [filters, setFilters] = useStateUrlSync<FarmsFilters>(initialFilters, {
    disabled: !config.syncFiltersUrl
  })

  const params = {
    ...filters
  }

  const { data, error } = useSWR(() => getKey(params), getFarms)

  const onFilters: OnFilters<FarmsFilters> = (filters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...filters
    }))
  }

  const onFilter: OnFilter<FarmsFilters> = (key, value) => {
    setFilters((filters) => ({
      ...filters,
      pg: 1,
      [key]: value
    }))
  }

  const onSearch: OnSearch = useCallback(
    debounce((search) => {
      setFilters((filters) => ({
        ...filters,
        pg: 1,
        search
      }))
    }, 400),
    []
  )

  const onSort: OnTableSort = (name, order) => {
    setFilters((filters) => ({
      ...filters,
      sort: name,
      sort_order: order
    }))
  }

  const onPage: OnPage = (page) => {
    setFilters((filters) => ({
      ...filters,
      pg: page
    }))
  }

  const onClearFilter: OnClearFilter<FarmsFilters> = (key) => {
    setFilters((filters) => ({
      ...filters,
      pg: 1,
      [key]: key === 'tvl_min' ? 0 : initialFilters[key]
    }))
  }

  const onClearFilters: OnClearFilters = () => {
    setFilters(initialFilters)
  }

  const isLoading = !data && !error
  const farms = data?.data || []
  const maxPages = data?.max_pages || 1

  return {
    isLoading,
    filters,
    farms,
    maxPages,
    onSort,
    onPage,
    onSearch,
    onFilter,
    onFilters,
    onClearFilter,
    onClearFilters
  }
}
