import { SortOrder } from 'components/table/TableHeadCell'
import debounce from 'lodash.debounce'
import { useCallback, useState } from 'react'
import { getLeaderboards } from 'services/api/leaderboards'
import useSWR from 'swr'
import { OnSearch } from 'types/components/filters'
import { omitEmpty } from 'utils/obj'
import { stringifyURL } from 'utils/query'

import { OnTableSort } from '../components/table/Table'
import { OnPage } from '../components/table/TablePagination'
import useLeaderboard, { UseLeaderboard } from './useLeaderboard'

interface LeaderboardsFilters {
  page: number
  per_page: number
  sort: string
  sort_order: SortOrder
  search: string
  active: boolean
  newest?: number | null
  hottest?: number | null
}

interface UseLeaderboards extends UseLeaderboard {
  isLoading: boolean
  leaderboards: any[]
  maxPages: number
  filters: LeaderboardsFilters
  onSearch: OnSearch
  onPage: OnPage
  onSort: OnTableSort
  onNewest: () => void
  onHottest: () => void
}

function getKey(params) {
  return stringifyURL('/get_leaderboards', omitEmpty(params))
}

const initialFilters: LeaderboardsFilters = {
  page: 1,
  per_page: 25,
  sort: 'change24h',
  sort_order: 'asc',
  search: '',
  active: true,
  newest: 1
}

export default function useLeaderboards(): UseLeaderboards {
  const [filters, setFilters] = useState<LeaderboardsFilters>(initialFilters)

  const params = {
    ...filters,
    sort_order: filters.sort_order === 'asc' ? 1 : 0
  }

  const { data, error, mutate } = useSWR(() => getKey(params), getLeaderboards)

  const onPage: OnPage = (page) => {
    setFilters((filters) => ({
      ...filters,
      page
    }))
  }

  const onSearch: OnSearch = useCallback(
    debounce((search) => {
      setFilters((filters) => ({
        ...filters,
        page: 1,
        search
      }))
    }, 400),
    []
  )

  const onSort: OnTableSort = (name, order) => {
    setFilters((filters) => ({
      ...filters,
      sort: name,
      sort_order: order
    }))
  }

  const onNewest = () => {
    setFilters((filters) => ({
      ...filters,
      newest: 1,
      hottest: null
    }))
  }

  const onHottest = () => {
    setFilters((filters) => ({
      ...filters,
      newest: null,
      hottest: 1
    }))
  }

  const leaderboard = useLeaderboard({ mutate })

  const isLoading = !data && !error
  const leaderboards = data?.leaderboards || []
  const maxPages = data?.max_pages || 1

  return {
    isLoading,
    leaderboards,
    maxPages,
    filters,
    onSearch,
    onPage,
    onHottest,
    onNewest,
    onSort,
    ...leaderboard
  }
}
