import { fetcher } from 'services/api/common'
import useSWR from 'swr'

interface UseHoldingsGrowth {
  isLoading: boolean
  growth: any
}

export default function useHoldingsGrowth(): UseHoldingsGrowth {
  const { data, error } = useSWR('/auth/get_holdings_growth', fetcher)

  const isLoading = !data && !error
  const growth = data || {}

  return {
    isLoading,
    growth
  }
}
