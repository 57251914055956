import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import useOrderDemo from '../../hooks/useOrderDemo'
import { DemoOrderData } from '../../interfaces/demo'
import { validateForm } from '../../utils/order'
import Button from '../buttons/Button'
import Checkbox from '../form/Checkbox'
import Input from '../form/Input'
import Textarea from '../form/Textarea'
import Dialog from './Dialog'
import DialogBody from './DialogBody'

const useStyles = makeStyles({
  input: {
    marginBottom: '1.875rem',
    '&:last-child': {
      marginBottom: '0.5rem'
    }
  },
  link: {
    color: '#33bbff',
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  controls: {
    marginBottom: '3rem'
  },
  btn: {
    marginBottom: '1rem'
  }
})

interface ScheduleDemoDialogProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export default function ScheduleDemoDialog({
  open,
  onClose,
  onSuccess
}: ScheduleDemoDialogProps) {
  const classes = useStyles()
  const [privacy, setPrivacy] = useState(false)
  const [message, setMessage] = useState('')
  const { onOrder } = useOrderDemo()
  const form = useForm<DemoOrderData>()

  const handleSubmit = (values) => {
    if (validateForm({ ...values, privacy })) {
      onOrder(
        {
          ...values,
          message,
          type: 'demo'
        },
        onSuccess
      )
    }
  }

  return (
    <Dialog
      open={open}
      size="sm"
      height={500}
      variant="secondary"
      mobileHeight={false}
      title="Schedule a Demo"
      onClose={onClose}
    >
      <DialogBody>
        <Grid container direction="column">
          <Grid container direction="column" className={classes.controls}>
            <Input
              className={classes.input}
              placeholder="Name of DAO/Company"
              variant="secondary"
              {...form.register('name')}
            />
            <Input
              className={classes.input}
              placeholder="Contact (Discord, Telegram, E-Mail)"
              variant="secondary"
              {...form.register('email')}
            />
            <Textarea
              className={classes.input}
              value={message}
              placeholder="Message (not mandatory)"
              onChange={(e) => setMessage(e.target.value)}
            />
            <Checkbox
              value={privacy}
              onChange={setPrivacy}
              label={
                <>
                  I have read and agree to the{' '}
                  <a
                    className={classes.link}
                    target="_blank"
                    href="https://app.multifarm.fi/privacy"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </>
              }
            />
          </Grid>

          <Button
            color="secondary"
            size="sm"
            variant="contained-2"
            className={classes.btn}
            onClick={() => form.handleSubmit(handleSubmit)()}
          >
            Submit
          </Button>
        </Grid>
      </DialogBody>
    </Dialog>
  )
}
