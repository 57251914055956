import { Strategy } from 'interfaces/strategy'
import { useSpinner } from 'providers/SpinnerProvider'
import { toast } from 'react-toastify'
import { createCustomNft } from 'services/api/cms'

type OnAdd = (data: Strategy, onSuccess?: any) => void

interface useCustomStrategy {
  onAdd: OnAdd
}

const prepareValues = (data: Strategy) => {
  for (const key in data) {
    if (key.toLocaleLowerCase().includes('size')) {
      data[key] = +data[key]
    }
  }

  return data
}

export default function useCustomStrategy(): useCustomStrategy {
  const spinner = useSpinner()

  const onAdd: OnAdd = async (data, onSuccess) => {
    try {
      spinner.open()

      const response = await createCustomNft(prepareValues(data))
      if (response.error) {
        throw new Error(response.error)
      }

      toast.success('Successfully saved. Strategy will appear soon.')
      onSuccess?.()
      spinner.close()
    } catch (e: any) {
      spinner.close()
      toast.error(e.message)
    }
  }

  return {
    onAdd
  }
}
