import 'react-datepicker/dist/react-datepicker.css'

import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import ReactDatePicker from 'react-datepicker'

import Label from './Label'

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& .react-datepicker__portal': {
      backgroundColor: 'rgba(0, 0, 0, 0.4)'
    },

    '& .react-datepicker__header': {
      borderRadius: '15px 15px 0 0',
      padding: '1rem 0',
      borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
      background:
        'rgba(0, 0, 0, 0) linear-gradient(111.6deg, rgb(48, 54, 89) -2.51%, rgb(41, 46, 77) 104.46%) repeat scroll 0% 0%'
    },
    '& .react-datepicker__month': {
      background: 'transparent'
    },
    '& .react-datepicker': {
      borderRadius: 15,
      border: 0,
      fontSize: '0.875rem',
      fontFamily: 'Roboto',
      color: '#fafafa',
      background:
        'rgba(0, 0, 0, 0) linear-gradient(111.6deg, rgb(48, 54, 89) -2.51%, rgb(41, 46, 77) 104.46%) repeat scroll 0% 0%'
    },
    '& .react-datepicker__navigation': {
      top: 15
    },
    '& .react-datepicker__current-month, & .react-datepicker-time__header, & .react-datepicker-year-header':
      {
        fontFamily: 'Roboto',
        color: '#fafafa'
      },
    '& .react-datepicker__day-name, & .react-datepicker__day, & .react-datepicker__time-name':
      {
        fontFamily: 'Roboto',
        color: '#fafafa'
      },
    '& .react-datepicker__day:hover, & .react-datepicker__month-text:hover, & .react-datepicker__quarter-text:hover, & .react-datepicker__year-text:hover':
      {
        borderRadius: 15,
        background:
          'rgba(0, 0, 0, 0) linear-gradient(110.29deg, rgb(51, 182, 255) -3.21%, rgb(26, 107, 159) 105.5%) repeat scroll 0% 0%'
      },
    '& .react-datepicker__day--keyboard-selected, & .react-datepicker__month-text--keyboard-selected, & .react-datepicker__quarter-text--keyboard-selected, & .react-datepicker__year-text--keyboard-selected':
      {
        background: 'transparent'
        // borderRadius: 15,
        // background:
        //   'rgba(0, 0, 0, 0) linear-gradient(110.29deg, rgb(51, 182, 255) -3.21%, rgb(26, 107, 159) 105.5%) repeat scroll 0% 0%'
      },
    '& .react-datepicker__day--selected, & .react-datepicker__day--in-selecting-range, & .react-datepicker__day--in-range, & .react-datepicker__month-text--selected, & .react-datepicker__month-text--in-selecting-range, & .react-datepicker__month-text--in-range, & .react-datepicker__quarter-text--selected, & .react-datepicker__quarter-text--in-selecting-range, & .react-datepicker__quarter-text--in-range, & .react-datepicker__year-text--selected, & .react-datepicker__year-text--in-selecting-range, & .react-datepicker__year-text--in-range':
      {
        borderRadius: 15,
        background:
          'rgba(0, 0, 0, 0) linear-gradient(110.29deg, rgb(51, 182, 255) -3.21%, rgb(26, 107, 159) 105.5%) repeat scroll 0% 0%'
      }
  },
  input: {
    width: '100%',
    outline: 'none',
    fontFamily: 'Roboto',
    borderRadius: 15,
    fontSize: '0.875rem',
    backgroundColor: '#373D66',
    border: '1px solid rgba(255,255,255,0.4)',
    height: 40,
    padding: '0 1.25rem',
    color: '#fafafa'
  }
})

interface DatePickerProps {
  value: Date | undefined
  onChange: (date: Date) => void
  variant?: 'secondary'
  className?: string
  label?: string
  tooltip?: string
  placeholder?: string
}

export default function DatePicker(props: DatePickerProps) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {props.label && <Label tooltip={props.tooltip}>{props.label}</Label>}
      <ReactDatePicker
        onChange={props.onChange}
        selected={props.value}
        className={classNames(classes.input, props.className)}
        placeholderText={props.placeholder}
        withPortal
      />
    </div>
  )
}
