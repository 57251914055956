import Api from '../Api'

export async function editBond(data) {
  const response = await Api.post('/auth/cms/edit_bonds_transactions', data)
  return response.data
}

export async function addTransaction(data) {
  const response = await Api.post('/auth/cms/create_bond_info', data)
  return response.data
}
