import makeStyles from '@material-ui/core/styles/makeStyles'
import { PropsWithChildren } from 'react'
import { TableCol } from 'types/components/table'

const useStyles = makeStyles({
  root: {
    width: 'fit-content'
  }
})

interface ChartTableProps {
  cols: TableCol[]
  head?: boolean
}

export default function ChartTable({
  cols,
  head,
  children
}: PropsWithChildren<ChartTableProps>) {
  const classes = useStyles()
  return (
    <table className={classes.root}>
      {head !== false && (
        <thead>
          <tr>
            {cols.map((col, index) => (
              <HeadCell key={index} align={col.align}>
                {col.title}
              </HeadCell>
            ))}
          </tr>
        </thead>
      )}
      <tbody>{children}</tbody>
    </table>
  )
}

const useHeadCellStyles = makeStyles({
  root: {
    padding: '0.5rem'
  }
})

function HeadCell({ children, align = 'left' }: PropsWithChildren<any>) {
  const classes = useHeadCellStyles()
  return (
    <th align={align} className={classes.root}>
      {children}
    </th>
  )
}

export function Row({ children }: PropsWithChildren<any>) {
  return <tr>{children}</tr>
}

const useCellStyles = makeStyles({
  root: {
    padding: '0.5rem',
    fontSize: '0.875rem',
    width: (props: any) => (props.color ? 20 : undefined)
  },
  color: {
    display: 'flex',
    alignItems: 'center',

    '& span': {
      width: 10,
      height: 10,
      borderRadius: 9999,
      backgroundColor: (props: any) => props.color
    }
  }
})

interface CellProps {
  align?: 'left' | 'right'
  color?: string
}

export function Cell({
  children,
  align = 'left',
  color
}: PropsWithChildren<CellProps>) {
  const classes = useCellStyles({ color })

  return (
    <td align={align} className={classes.root}>
      {color && (
        <div className={classes.color}>
          <span />
        </div>
      )}

      {children}
    </td>
  )
}
