import { useSpinner } from 'providers/SpinnerProvider'
import { toast } from 'react-toastify'
import { addWallet, removeWallet } from 'services/api/wallet'
import { mutate as revalidate } from 'swr'

import { getPoolsKey } from './usePools'

type OnAdd = (wallet: string, onSuccess?: any) => void
type OnRemove = (id: string, onSuccess?: any) => void

interface UseWallet {
  onAdd: OnAdd
  onRemove: OnRemove
}

interface UseWalletConfig {
  revalidate?: any
}

export default function useWallet(config: UseWalletConfig = {}): UseWallet {
  const spinner = useSpinner()

  const onAdd: OnAdd = async (wallet, onSuccess) => {
    try {
      spinner.open()

      await addWallet(wallet)
      toast.success(
        'We are processing your wallet, strategies will be updated soon'
      )

      spinner.close()
      revalidate?.(getPoolsKey())
      config.revalidate?.()
      onSuccess?.()
    } catch (e) {
      spinner.close()
      toast.error('Oops error')
      console.error(e)
    }
  }

  const onRemove: OnRemove = async (id) => {
    try {
      spinner.open()

      await removeWallet(id)
      config.revalidate?.()

      toast.success('Walled removed successfully')
      spinner.close()
    } catch (e) {
      spinner.close()
      toast.error('Oops error')
      console.error(e)
    }
  }

  return {
    onAdd,
    onRemove
  }
}
