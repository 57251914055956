import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import SettingsIcon from '@material-ui/icons/Settings'
import { DeleteIcon } from 'assets/icons'
import Button from 'components/buttons/Button'
import IconButton from 'components/buttons/IconButton'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import SelectAssetType from 'components/form/SelectAssetType'
import useDefaultFields from 'hooks/useDefaultFields'
import usePool from 'hooks/usePool'
import { getPoolNamesKey } from 'hooks/usePoolsNames'
import { useSpinner } from 'providers/SpinnerProvider'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { mutate as globalRevalidate } from 'swr'
import { BOOL_FILTERS, RISKS } from 'utils/filters'
import { getRemoteAssetIcon, getRemoteFarmIcon } from 'utils/icons'
import { getAssetKey, parsePoolValues } from 'utils/pools'
import { toCamelCase, toStartCase } from 'utils/strings'

import TooltipDropdown from '../TooltipDropdown'
import AddFieldDialog from './AddFieldDialog'
import Dialog from './Dialog'
import DialogBody from './DialogBody'
import DialogHeadIconButton, {
  DialogHeadIconButtonContainer
} from './DialogHeadIconButton'
import DialogTitleComponent from './DialogTitleComponent'
import LabelWeightDialog from './LabelWeightDialog'
import UserIconsDialog from './UserIconsDialog'
import WarnEditFieldDialog from './WarnEditFieldDialog'

const useStyles = makeStyles({
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.25rem'
  },
  button: {
    marginRight: '1.25rem',

    '&:last-child': {
      marginRight: 0
    }
  },
  removeIcon: {
    position: 'absolute',
    right: '1.25rem',
    bottom: '0.75rem',
    visibility: 'hidden',
    cursor: 'pointer'
  },
  inputContainer: {
    position: 'relative',

    '&:hover': {
      '& svg': {
        visibility: 'visible'
      }
    }
  },
  actions: {
    padding: '1.25rem'
  },
  weightIcon: {
    margin: '0 0.25rem',

    '& svg': {
      marginTop: -1
    }
  }
})

interface ContentProps {
  poolId: string
  onClose: () => void
  revalidate?: any
  onTitleComponent: any
}

function Content({
  poolId,
  onClose,
  revalidate,
  onTitleComponent
}: ContentProps) {
  const classes = useStyles()
  const [addDialog, setDialog] = useState(false)
  const [weightDialog, setWeightDialog] = useState(false)
  const [weightLabel, setWeightLabel] = useState('')
  const [removeDialog, setRemoveDialog] = useState<string | null>(null)
  const [iconDialog, setIconDialog] = useState(false)
  const [iconType, setIconType] = useState('')

  const defaultFields = useDefaultFields()

  const pool = usePool({ poolId: poolId })

  useSpinner(pool.isLoading)

  const methods = useForm<any>()

  const [imageUrl, farmIconUrl, weights] = useWatch({
    control: methods.control,
    name: ['imageUrl', 'farmIconUrl', 'weights']
  })

  const icons = {
    imageUrl,
    farmIconUrl
  }

  const assetKey = getAssetKey(pool.pool)

  useEffect(() => {
    if (pool.pool.poolId) {
      methods.reset(
        parsePoolValues(
          pool.pool,
          defaultFields.defaultFields,
          defaultFields.customFields
        )
      )
    }
  }, [assetKey, defaultFields.customFields.length])

  const handleSubmit = ({ weights, ...values }) => {
    pool.onUpdate(poolId, values, weights, () => {
      revalidate?.()
      globalRevalidate?.(getPoolNamesKey())
      onClose()
    })
  }

  const isCustomField = (field: string) => {
    return !defaultFields.defaultFields.includes(field)
  }

  const renderIconButton = useCallback(
    (key, tooltip, icon) => (
      <>
        <DialogHeadIconButton
          onClick={() => {
            setIconDialog(true)
            setIconType(key)
          }}
          icon={icon}
          tooltip={tooltip}
        />
      </>
    ),
    []
  )

  useEffect(() => {
    onTitleComponent(
      <DialogTitleComponent>
        <span>{`Edit Values ${pool.pool.asset || '-'}`}</span>
        <DialogHeadIconButtonContainer>
          {renderIconButton(
            'imageUrl',
            'Upload Asset Icon',
            getRemoteAssetIcon({ ...pool.pool, imageUrl })
          )}
          {renderIconButton(
            'farmIconUrl',
            'Upload Farm Icon',
            getRemoteFarmIcon({ ...pool.pool, farmIconUrl })
          )}
        </DialogHeadIconButtonContainer>
      </DialogTitleComponent>
    )
  }, [pool.pool.asset, imageUrl, farmIconUrl])

  const labelComponentProps = (field: string) => {
    return {
      ...(isCustomField(field) && {
        labelComponent: (
          <TooltipDropdown content="Set Weight">
            <IconButton
              size="xs"
              className={classes.weightIcon}
              onClick={() => {
                setWeightDialog(true)
                setWeightLabel(field)
              }}
            >
              <SettingsIcon />
            </IconButton>
          </TooltipDropdown>
        )
      })
    }
  }

  return (
    <>
      <DialogBody>
        <form onSubmit={methods.handleSubmit(handleSubmit)} id="edit-strategy">
          <Grid container direction="column" className={classes.content}>
            {[
              ...defaultFields.defaultFields,
              ...defaultFields.customFields
            ].map((field, index) => (
              <Grid
                container
                alignItems="center"
                key={index}
                className={classes.inputContainer}
              >
                {field === 'type' ? (
                  <Controller
                    control={methods.control}
                    render={({ field: { value, name } }) => (
                      <SelectAssetType
                        clearable
                        searchable={false}
                        variant="secondary"
                        placeholder={toStartCase(field)}
                        label={toStartCase(field)}
                        value={value}
                        onChange={(e) => methods.setValue(name, e?.value || '')}
                      />
                    )}
                    name={field}
                  />
                ) : field === 'riskLevel' ? (
                  <Controller
                    control={methods.control}
                    render={({ field: { value, name } }) => (
                      <Select
                        clearable
                        searchable={false}
                        variant="secondary"
                        options={RISKS}
                        placeholder={toStartCase(field)}
                        label={toStartCase(field)}
                        value={value}
                        onChange={(e) => methods.setValue(name, e?.value || '')}
                        editable
                      />
                    )}
                    name={field}
                  />
                ) : ['active', 'aprActive'].includes(field) ? (
                  <Controller
                    control={methods.control}
                    render={({ field: { value, name } }) => (
                      <Select
                        clearable
                        searchable={false}
                        variant="secondary"
                        options={BOOL_FILTERS}
                        placeholder={toStartCase(field)}
                        label={toStartCase(field)}
                        value={value}
                        onChange={(e) =>
                          methods.setValue(name, e ? e.value : '')
                        }
                      />
                    )}
                    name={field}
                  />
                ) : (
                  <Input
                    variant="secondary"
                    placeholder={toStartCase(field)}
                    label={toStartCase(field)}
                    {...labelComponentProps(field)}
                    {...methods.register(field)}
                  />
                )}

                {isCustomField(field) && (
                  <DeleteIcon
                    className={classes.removeIcon}
                    onClick={() => setRemoveDialog(field)}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </form>
      </DialogBody>

      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        className={classes.actions}
      >
        <Button
          variant="contained-2"
          size="sm"
          type="button"
          color="secondary"
          className={classes.button}
          onClick={() => setDialog(true)}
        >
          Add field
        </Button>
        <Button
          variant="contained-2"
          size="sm"
          type="button"
          color="secondary"
          className={classes.button}
          onClick={onClose}
        >
          Reset
        </Button>
        <Button
          variant="contained-2"
          size="sm"
          type="submit"
          form="edit-strategy"
          className={classes.button}
        >
          Save
        </Button>
      </Grid>

      <AddFieldDialog
        open={addDialog}
        onClose={() => setDialog(false)}
        onApply={(field) => defaultFields.onAddCustom(toCamelCase(field))}
      />

      <WarnEditFieldDialog
        open={!!removeDialog}
        onClose={() => setRemoveDialog(null)}
        onConfirm={() => defaultFields.onRemoveCustom(removeDialog as string)}
      />

      <UserIconsDialog
        title={getTitle(iconType)}
        open={iconDialog}
        onClose={() => setIconDialog(false)}
        initialUrl={icons[iconType]}
        onConfirm={(url) => {
          methods.setValue(iconType, url)
          setIconDialog(false)
        }}
      />

      <LabelWeightDialog
        label={weightLabel}
        open={weightDialog}
        value={weights?.[weightLabel]}
        onConfirm={(value) => methods.setValue(`weights.${weightLabel}`, value)}
        onClose={() => setWeightDialog(false)}
      />
    </>
  )
}

function getTitle(key) {
  return `Icons Library ${key === 'imageUrl' ? '(Asset)' : '(Farm)'}`
}

interface EditSavedAssetDialogProps {
  open: boolean
  onClose: () => void
}

export default function EditStrategyDialog({
  onClose,
  open,
  ...props
}: EditSavedAssetDialogProps & Partial<ContentProps>) {
  const [titleComponent, onTitleComponent] = useState()
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        title={titleComponent}
        variant="secondary"
        height={550}
        size="lg"
      >
        <Content
          {...(props as ContentProps)}
          onTitleComponent={onTitleComponent}
          onClose={onClose}
        />
      </Dialog>
    </>
  )
}
