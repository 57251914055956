import Accordion from 'components/accordions/Accordion'
import Button from 'components/buttons/Button'
import AddCustomAssetDialog from 'components/dialogs/AddCustomAssetDialog'
import useCustomStrategies from 'hooks/useCustomStrategies'
import { useState } from 'react'

import StrategiesTable from '../strategies/StrategiesTable'

export default function CustomStrategiesTable() {
  const customStrategies = useCustomStrategies()
  const [addDialog, setAddDialog] = useState(false)
  return (
    <>
      <Accordion
        open
        arrow={false}
        headerComponent={
          <Button
            size="sm"
            variant="contained-2"
            onClick={() => setAddDialog(true)}
          >
            Add Asset
          </Button>
        }
        title="Custom Assets"
        onClick={() => {}}
      >
        <StrategiesTable
          loading={customStrategies.isLoading}
          page={customStrategies.filters.page}
          maxPages={customStrategies.maxPages}
          data={customStrategies.strategies}
          onPage={customStrategies.onPage}
          revalidate={customStrategies.revalidate}
        />
      </Accordion>

      <AddCustomAssetDialog
        open={addDialog}
        onClose={() => setAddDialog(false)}
      />
    </>
  )
}
