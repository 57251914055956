import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { EmptyPlaceholder, LoadingPlaceholder } from 'components/placeholders'
import { ReactNode, useMemo } from 'react'
import {
  Area,
  AreaChart as ReAreaChart,
  Bar,
  BarChart as ReBarChart,
  CartesianGrid,
  Line,
  LineChart as ReLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { AxisInterval } from 'recharts/types/util/types'
import { calcAxisWidth, renderGradients, renderLegends } from 'utils/charts'
import { device } from 'utils/screen'

const useStyles = makeStyles({
  chart: (props: any) => ({
    width: '100%',

    ...(props.legends && {
      display: 'grid',
      gridTemplateColumns: '1fr 175px',
      gap: '1rem',

      '& > div': {
        minWidth: 0
      },

      [`@media ${device.tablet}`]: {
        gridTemplateColumns: '1fr'
      }
    })
  }),
  chartContainer: {
    width: '100%',
    height: 320
  },
  tooltip: {
    background: 'linear-gradient(107.39deg, #474F7E 3.48%, #343B61 97.52%)',
    padding: '0.5rem 0.75rem',
    borderRadius: 10,

    '& p': {
      fontSize: '0.75rem',
      display: 'flex',
      alignItems: 'center',

      '& span[data-color]': {
        width: 10,
        height: 10,
        borderRadius: 9999,
        marginRight: '0.25rem'
      },

      '& span[data-footer]': {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0.75rem'
      }
    }
  },
  legend: {
    fontSize: '0.875rem',
    fontWeight: 700,
    marginBottom: '0.5rem',
    flexWrap: 'nowrap',

    '& span[data-legend]': {
      width: 10,
      height: 10,
      minWidth: 10,
      minHeight: 10,
      margin: '0 0.75rem 0 0',
      borderRadius: 9999
    }
  }
})

type TooltipOption = (payload: any) => string | ReactNode

export interface LineChartProps {
  id: string
  bar?: boolean
  area?: boolean
  data: any[]
  legends?: string[]
  dataKey: string[]
  colors: string[]
  yTickFormatter: (tick: any) => string
  xTickFormatter: (tick: any) => string
  tooltipOptions?: TooltipOption[]
  xInterval?: AxisInterval
  reverseLegends?: boolean
  loading?: boolean
  noData?: boolean
  memo?: string
  stack?: boolean
}

export default function LineChart({
  id,
  bar,
  area,
  data,
  stack,
  colors,
  legends,
  dataKey,
  yTickFormatter,
  xTickFormatter,
  tooltipOptions,
  xInterval,
  reverseLegends,
  loading,
  noData,
  memo
}: LineChartProps) {
  const classes = useStyles({ legends: !!legends?.length })

  const Component = area ? ReAreaChart : bar ? ReBarChart : ReLineChart
  const LineComponent: any = area ? Area : bar ? Bar : Line

  const yWidth = useMemo(() => calcAxisWidth(data, yTickFormatter), [memo])

  if (loading) {
    return <LoadingPlaceholder height={320} />
  }

  if (noData) {
    return <EmptyPlaceholder height={320} />
  }

  return (
    <Grid container className={classes.chart}>
      <Grid container className={classes.chartContainer}>
        <ResponsiveContainer width="100%" height="100%">
          <Component data={data} {...ChartProps}>
            {(area || bar) && renderGradients(!!bar, id, dataKey, colors)}

            <CartesianGrid {...CartesianGridProps} />

            <YAxis
              tickFormatter={yTickFormatter}
              {...(!bar && (YAxisProps as any))}
              {...(bar && YAxisBarProps)}
              width={yWidth}
            />
            <XAxis
              dataKey="date"
              tickFormatter={xTickFormatter}
              {...(XAxisProps as any)}
              {...(xInterval !== undefined && { interval: xInterval })}
            />

            {tooltipOptions && tooltipOptions.length && (
              <Tooltip
                {...(!bar && TooltipProps)}
                {...(bar && TooltipBarProps)}
                content={(props) => (
                  <Grid className={classes.tooltip}>
                    {tooltipOptions.map((row, index) => (
                      <Typography key={index}>
                        {row(props.payload?.[0]?.payload || {})}
                      </Typography>
                    ))}
                  </Grid>
                )}
              />
            )}

            {dataKey.map((key, index) => (
              <LineComponent
                key={index}
                dataKey={key}
                {...(!bar && {
                  stroke: colors[index],
                  ...(LineProps as any),
                  ...(area && {
                    fill: `url(#${id}-color-${index})`,
                    type: 'monotone',
                    ...(stack !== false && {
                      stackId: '1'
                    })
                  })
                })}
                {...(bar && {
                  fill: `url(#${id}-color-${index})`,
                  maxBarSize: 40,
                  ...(stack !== false && {
                    stackId: '1'
                  })
                })}
              />
            ))}
          </Component>
        </ResponsiveContainer>
      </Grid>

      {!!legends?.length &&
        renderLegends(
          legends,
          typeof reverseLegends === 'boolean' ? reverseLegends : area,
          colors,
          classes
        )}
    </Grid>
  )
}

export const ChartProps = {
  margin: { left: -15, right: 15 }
}

export const YAxisProps = {
  align: 'center',
  padding: { top: 20, bottom: 20 },
  fontSize: '0.625rem',
  type: 'number',
  domain: ['auto', 'auto'],
  interval: 0,
  tick: {
    fill: '#B2BDFF'
  },
  axisLine: false,
  tickLine: false,
  stroke: '0'
}

export const YAxisBarProps = {
  ...YAxisProps,
  padding: { ...YAxisProps.padding, bottom: 0 }
}

export const XAxisProps = {
  fontSize: '0.625rem',
  tickMargin: 10,
  axisLine: false,
  tickLine: false,
  stroke: '0',
  tick: {
    fill: '#B2BDFF'
  }
}

export const LineProps = {
  type: 'monotoneX',
  unit: 'M',
  strokeLinecap: 'round',
  strokeWidth: 2,
  lightingColor: '#000',
  dot: false,
  legendType: 'none'
}

export const CartesianGridProps = {
  stroke: '#6B6BB2',
  strokeOpacity: '0.4',
  horizontalPoints: []
}

export const TooltipProps = {
  cursor: { stroke: '#6B6BB2', strokeOpacity: 1 }
}

export const TooltipBarProps = {
  cursor: false
}
