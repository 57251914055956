import useAllUserPools from 'hooks/useAllUserPools'
import { allPoolsToOptions } from 'utils/pools'

import Select, { SelectProps } from './Select'

export default function UserAllPoolsSelect(props: Partial<SelectProps>) {
  const allUserPools = useAllUserPools()
  const options = allPoolsToOptions(allUserPools.data)
  return <Select options={options} {...props} />
}
