import { useState } from 'react'
import { fetcher } from 'services/api/common'
import useSWR from 'swr'
import { stringifyURL } from 'utils/query'

interface UseTopExchanges {
  isLoading: boolean
  exchanges: any[]
}

function getKey(params) {
  return stringifyURL('/auth/get_top_exchanges', params)
}

export default function useTopExchanges(): UseTopExchanges {
  const [filters] = useState({
    per_page: 9999
  })

  const params = {
    ...filters
  }

  const { data, error } = useSWR(() => getKey(params), fetcher)

  const isLoading = !data && !error
  const exchanges = data?.exchanges || []

  return {
    isLoading,
    exchanges
  }
}
