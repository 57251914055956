import ChartCard from 'components/cards/ChartCard'
import LineChart from 'components/charts/LineChart'
import Tabs from 'components/tabs/Tabs'
import ChartTooltip from 'components/tooltips/ChartTooltip'
import { useMemo, useState } from 'react'
import { parsePercent } from 'utils/api'
import { formatChartDate, getChartId, renderTooltipOptions } from 'utils/charts'
import { getRandomColors } from 'utils/colors'
import { RANGE } from 'utils/filters'
import { parseRangeChart } from 'utils/pools'
import { TOOLTIP } from 'utils/tooltip'

import { RANGES } from './data'

const COLORS_1 = getRandomColors(100)
const TITLE = 'Asset APR (y)'

export interface AprLineChartProps {
  data: any[]
  keys: string[]
}

export default function AprLineChart({ keys, data }: AprLineChartProps) {
  const [aprRange, setAprRange] = useState(RANGE._7d)

  const key = keys.join('-')

  const rangeParsedData = useMemo(
    () => parseRangeChart(aprRange, data),
    [aprRange, key]
  )

  return (
    <ChartCard
      title={TITLE}
      tooltip={<ChartTooltip content={TOOLTIP.LINE_ASSET_APR} />}
      headerComponent={
        <Tabs
          width="auto"
          tabs={RANGES}
          value={aprRange}
          onChange={setAprRange}
        />
      }
    >
      <LineChart
        memo={[key, aprRange].join('_')}
        noData={!rangeParsedData.length}
        data={rangeParsedData}
        id={getChartId(TITLE)}
        legends={keys}
        dataKey={keys}
        colors={COLORS_1}
        yTickFormatter={(tick) => parsePercent(tick)}
        xTickFormatter={formatChartDate}
        tooltipOptions={renderTooltipOptions(
          keys,
          false,
          COLORS_1,
          parsePercent
        )}
      />
    </ChartCard>
  )
}
