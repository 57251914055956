import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { CloseIcon } from 'assets/icons'
import classNames from 'classnames'

import useMediaQueries from '../../hooks/useMediaQueries'

const useStyles = makeStyles({
  root: {
    padding: '1.5rem',
    fontFamily: 'Roboto',
    position: 'relative'
  },
  title: {
    width: '100%',
    fontWeight: 500,
    textAlign: (props: any) => (props.isMobile ? 'center' : 'left'),

    '&[data-variant="primary"]': {
      fontSize: (props: any) => (props.isMobile ? '1rem' : '1.5rem')
    },

    '&[data-variant="secondary"]': {
      fontSize: '1rem',
      textTransform: 'uppercase',
      color: '#fafafa'
    }
  },
  btn: {
    position: 'absolute',
    right: '1.5rem',
    background: 'transparent',
    color: 'inherit',

    '&:hover': {
      color: '#FAFAFA'
    }
  }
})

interface DrawerHeadProps {
  title: string
  onClose: () => void
  titleVariant?: 'primary' | 'secondary'
}

export default function DrawerHead({
  title,
  onClose,
  titleVariant = 'primary'
}: DrawerHeadProps) {
  const { isMobile } = useMediaQueries()
  const classes = useStyles({ isMobile })
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className={classes.root}
    >
      <h2 className={classNames(classes.title)} data-variant={titleVariant}>
        {title}
      </h2>

      <button onClick={onClose} className={classes.btn}>
        <CloseIcon />
      </button>
    </Grid>
  )
}
