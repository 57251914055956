import { useMemo } from 'react'
import { fetcher } from 'services/api/common'
import useSWR from 'swr'
import {
  getYieldTypeDistributionKey,
  parseYieldTypeDistribution
} from 'utils/pools'

interface UseYieldTypeDistributionsProps {
  isLoading: boolean
  chartData: any[]
  yieldTypeDistribution: any
}

export default function useYieldTypeDistributions(): UseYieldTypeDistributionsProps {
  const { data, error } = useSWR('/auth/get_yield_type_distributions', fetcher)

  const isLoading = !data && !error
  const yieldTypeDistribution = data || {}

  const key = getYieldTypeDistributionKey(yieldTypeDistribution)

  const chartData = useMemo(
    () => parseYieldTypeDistribution(yieldTypeDistribution),
    [key]
  )

  return {
    isLoading,
    chartData,
    yieldTypeDistribution
  }
}
