import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { CaretDown } from 'assets/icons'
import { PropsWithChildren, ReactNode } from 'react'

import ChartTooltip from '../tooltips/ChartTooltip'

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(111.6deg, #303659 -2.51%, #2A2F4D 104.46%)',
    borderRadius: 15,
    marginBottom: '1.25rem',

    '&:last-child': {
      marginBottom: 0
    }
  },
  summary: {
    padding: '1.25rem',
    cursor: 'pointer'
  },
  arrow: {
    width: 14,
    height: 14,
    color: '#33B6FF',

    transform: (props: any) => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')
  },
  content: {
    padding: '1.25rem'
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    color: '#fff'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  }
})

interface AccordionProps {
  title: string
  titleTooltip?: string
  open: boolean
  onClick: () => void
  arrow?: boolean
  headerComponent?: ReactNode
}

export default function Accordion({
  title,
  open,
  onClick,
  children,
  arrow = true,
  titleTooltip,
  headerComponent
}: PropsWithChildren<AccordionProps>) {
  const classes = useStyles({ open })
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.summary}
        onClick={onClick}
      >
        <Grid className={classes.titleContainer}>
          <Typography className={classes.title}>{title}</Typography>

          {titleTooltip && <ChartTooltip content={titleTooltip} />}
        </Grid>

        {headerComponent ? (
          headerComponent
        ) : arrow ? (
          <CaretDown className={classes.arrow} />
        ) : null}
      </Grid>

      {open && (
        <Grid container direction="column" className={classes.content}>
          {children}
        </Grid>
      )}
    </Grid>
  )
}
