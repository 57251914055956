import { fetcher } from 'services/api/common'
import useSWR from 'swr'

interface UseIncomePerPartner {
  isLoading: boolean
  incomes: any[]
}

export default function useIncomePerPartner(): UseIncomePerPartner {
  const { data, error } = useSWR('/auth/get_income_per_partner', fetcher)

  const isLoading = !data && !error
  const incomes = data?.income_per_partner || []

  return {
    isLoading,
    incomes
  }
}
