import makeStyles from '@material-ui/core/styles/makeStyles'
import { CaretDown } from 'assets/icons'

export interface ScrollArrowProps {
  side: 'left' | 'right'
  onClick: () => void
  condition?: boolean
  size?: 'lg'
  case?: 'labels' | 'exchanges'
}

const useStyles = makeStyles({
  scrollArrow: (props: ScrollArrowProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 45,
    background: 'rgba(57, 64, 106, 0.75)',
    position: 'absolute',
    top: 0,
    zIndex: 11,
    cursor: 'pointer',
    color: '#fff',

    '&[data-size="lg"]': {
      height: 52
    },

    '& svg': {
      width: 14,
      height: 14
    },

    '&[data-type="left"]': {
      left: 0,

      '& svg': {
        transform: 'rotate(90deg)'
      },

      ...(props.case === 'labels' && {
        left: 450
      }),

      ...(props.case === 'exchanges' && {
        left: 580
      })
    },

    '&[data-type="right"]': {
      right: 0,

      '& svg': {
        transform: 'rotate(-90deg)'
      }
    }
  })
})

export default function ScrollArrow(props: ScrollArrowProps) {
  const classes = useStyles(props)

  if (props.condition === false) {
    return null
  }

  return (
    <div
      className={classes.scrollArrow}
      data-type={props.side}
      data-size={props.size}
      onClick={props.onClick}
    >
      <CaretDown />
    </div>
  )
}
