import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { TableColWithKey } from 'types/components/table'
import { parsePercent } from 'utils/api'

import Table from '../table/Table'
import TableCell from '../table/TableCell'
import TableRow from '../table/TableRow'

const useStyles = makeStyles({
  cell: {
    '&[data-green="true"]': {
      color: '#6BFF44'
    },
    '&[data-red="true"]': {
      color: '#FF6464'
    }
  }
})

function cellProps(): any {
  return {
    color: 'pool',
    align: 'center'
  }
}

function spanProps(val = 0): any {
  return {
    ...(val > 0 && { 'data-green': true }),
    ...(val < 0 && { 'data-red': true })
  }
}
interface HoldingGrowthTableProps {
  data: any
  columns: TableColWithKey[]
}

export default function HoldingGrowthTable({
  data,
  columns
}: HoldingGrowthTableProps) {
  const classes = useStyles()

  return (
    <Grid container>
      <Table cols={columns}>
        <TableRow size="sm">
          {columns.map(({ key }) => {
            return (
              <TableCell key={key} {...cellProps()}>
                <span className={classes.cell} {...spanProps(data[key])}>
                  {parsePercent(data[key])}
                </span>
              </TableCell>
            )
          })}
        </TableRow>
      </Table>
    </Grid>
  )
}
