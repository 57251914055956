import { RANGE } from '../../../utils/filters'

export const RANGES = [
  { name: '24h', value: RANGE._24h },
  { name: '7d', value: RANGE._7d },
  { name: '14d', value: RANGE._14d },
  { name: '30d', value: RANGE._30d },
  { name: '90d', value: RANGE._90d },
  { name: '180d', value: RANGE._180d },
  { name: '1y', value: RANGE._1y },
  { name: 'Max', value: RANGE._max }
]

export const RANGES_SECONDARY = [
  { name: '24h', value: RANGE._24h },
  { name: '30d', value: RANGE._30d }
]

export const RANGES_THIRD = [
  { name: '7d', value: RANGE._7d },
  { name: '30d', value: RANGE._30d }
]

export const ASSET_CHART_RANGES = [
  { name: '7d', value: RANGE._7d },
  { name: '14d', value: RANGE._14d },
  { name: '30d', value: RANGE._30d },
  { name: '90d', value: RANGE._90d },
  { name: '180d', value: RANGE._180d },
  { name: '1y', value: RANGE._1y },
  { name: 'Max', value: RANGE._max }
]

export const CURRENCIES = [
  { name: 'USD', value: 'USD' },
  { name: 'ETH', value: 'ETH' }
]
