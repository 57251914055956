import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  BookIcon,
  DiscordIcon,
  LogoIcon,
  MediumIcon,
  TwitterIcon
} from 'assets/icons'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { device } from 'utils/screen'

import { useAuth } from '../providers/AuthProvider'
import { useDialogsProvider } from '../providers/DialogsProvider'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    padding: '4rem 2.5rem',

    [`@media ${device.laptopL}`]: {
      flexDirection: 'column'
    }
  },
  links: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    margin: '0 -1.25rem',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '0 3rem',

    [`@media ${device.laptopL}`]: {
      flexDirection: 'column',
      padding: '2.5rem 0'
    }
  },
  iconLinks: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    margin: '0 -0.5rem'
  },
  link: {
    fontSize: '0.875rem',
    textDecoration: 'none',
    fontWeight: 400,
    color: '#fff',
    margin: '0.5rem 1.25rem',
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline'
    },

    [`@media ${device.laptopL}`]: {
      margin: '0 0 1.75rem 0'
    }
  },
  iconLink: {
    cursor: 'pointer',
    width: 36,
    height: 36,
    border: '2px solid #03a9f4',
    borderRadius: 9999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 0.5rem',

    '& svg': {
      color: '#03a9f4'
    }
  }
})

function createLink(title: string, to: string, native: boolean, action?: any) {
  return { title, to, native, action }
}

const ICON_LINKS = [
  { icon: TwitterIcon, to: 'https://twitter.com/multifarmfi' },
  { icon: DiscordIcon, to: 'https://discord.com/invite/yMXzezMchs' },
  { icon: MediumIcon, to: 'https://medium.com/@multifarm_fi' },
  { icon: BookIcon, to: 'https://multifarm.gitbook.io/multifarm' }
]

export default function Footer() {
  const classes = useStyles()
  const auth = useAuth()
  const dialogsProvider = useDialogsProvider()

  const LINKS = useMemo(
    () => [
      createLink(
        'Careers',
        'https://www.notion.so/Job-Board-241dbe213eb746a991e463315c5db6b8',
        true,
        false
      ),
      createLink('Disclaimer', '/disclaimer', false, false),
      createLink(
        'Request Listing',
        'https://forms.gle/wzo5di3gr5pfBGDw7',
        true,
        false
      ),
      createLink(
        'Report Data Error',
        'https://forms.gle/sxNDVzN3t2CTwEsG9',
        true,
        false
      ),
      createLink('Privacy Policy', '/privacy', false, false),
      createLink('Advertise', '/advertise', false, false),
      createLink(
        'API',
        'https://commercial.multifarm.fi/docs#/Rest%20API/get_farms_v2_api_v2_get_farms_get',
        true,
        false
      ),
      createLink(
        'Media Kit',
        'https://drive.google.com/drive/u/3/folders/1kGX8lo7zAynyMt4Pqego9r37tGgPFIUU',
        true,
        false
      ),
      auth.isLogged
        ? createLink('Logout', '', false, () => auth.onLogout())
        : createLink('Dashboard Login', '', false, () =>
            dialogsProvider.onLogin(true)
          )
    ],
    [auth.isLogged]
  )

  return (
    <Grid container className={classes.root}>
      <LogoIcon />

      <Grid className={classes.links}>
        {LINKS.map((link, index) =>
          link.action ? (
            <p key={index} className={classes.link} onClick={link.action}>
              {link.title}
            </p>
          ) : link.native ? (
            <a
              key={index}
              className={classes.link}
              href={link.to}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.title}
            </a>
          ) : (
            <Link className={classes.link} to={link.to} key={index}>
              {link.title}
            </Link>
          )
        )}
      </Grid>

      <Grid className={classes.iconLinks}>
        {ICON_LINKS.map((row, index) => (
          <a
            key={index}
            className={classes.iconLink}
            href={row.to}
            target="_blank"
            rel="noopener noreferrer"
          >
            <row.icon />
          </a>
        ))}
      </Grid>
    </Grid>
  )
}
